/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
  checkPermission,
  clean,
  convertEndData,
  convertToOnDayFormat,
  convertToTitleCaseAndAppendWithS,
  customErrorMessage,
  fetchLabelFromId,
  getEAOrAddressDetailsFromContact,
  removeUnderScoreConvertTitleCase,
  renderError,
  setPayeeInformation,
  transformRecurringRequestGlobal,
  isMethodSupported,
  truncateChars,
  updateComponentParams,
  createCommonVerifyProps
} from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
  accountLabelTransformation,
  createSendMyAccount,
  dataSourceTransformation,
  getDestinationValue,
  getEAList,
  getLocationData,
} from "./ScheduleGlobalFunctions";
import { FxSuccess } from "../../Data/FxSuccess";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

Logger.debug(
  "CreateSendContactACH.tsx",
  "create send to Contact Ach initializing"
);

const acc_src = {
  url: "account/list",
  method: "POST",
  data: {
    pageSize: 25,
    pageNumber: 1,
    sortOptions: {
      sortOrder: "DESC",
      sortBy: "createdOn",
    },
    criteria: {
      filters: [
        {
          key: "status",
          operator: "nin",
          values: ["INACTIVE"],
        },
      ],
    },
  },
};

const destination_src = {
  url: "contact/list",
  method: "POST",
  data: {
    pageNumber: 1,
    pageSize: 5,
    criteria: {
      filters: [
        {
          key: "allowedMethod",
          operator: "eq",
          values: ["ACH"],
        },
      ],
    },
  },
};

const authTypeDate: any = [
  { value: "WRITTEN", label: "Written" },
  { value: "ONLINE", label: "Online" },
  { value: "PHONE", label: "Phone" },
];

const processingModeData: any = [
  { label: "Same Day", value: "SAME_DAY" },
  { label: "Next Day", value: "FORWARD" },
];

/**
 * This component handles the send creation to linked contacts through ach method
 */
export const CreateSendContactACH: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const [eaLoad, setEALoad] = useState(false);
  const [contacEA, setContacEA] = useState<any>([]);
  const [authTypeValue, setauthTypeValue] = useState("");
  const [destination, setDestination] = useState<any>();
  const [contactName, setContactName] = useState<any>();
  const [isDestination, setIsDestination] = useState(true);
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [isPpiContact, setIsPpiContact] = useState(false);
  const [selectedPPI, setSelectedPPI] = useState("");
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const [sendEnabled, setSendEnabled] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individual=uiFeat.getIndividualCustomerFeature().available;
  const [location, setLocation] = useState<any>();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  const params = useParams<any>();
  const urlContactId = params?.contactId;
  const scheduleId = params.id;
  const [externalEAId, setExternalEAId] = React.useState();

  const method_Tab = useMemo(() => {
    const tabs = [
      {
        className: "fx-link-sel",
        title: "ACH",
        disabled: !!scheduleId,
        link: "/transaction/send/contact/add/ach",
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Ach Send",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "Ach Send",
          operation: "CREATE"
        }]
      },
      {
        title: "WIRE",
        link: "/transaction/send/contact/add/wire",
        disabled: !!scheduleId,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Wire Send",
          operation: "CREATE"
        }]
      },
      {
        title: "INTERNATIONAL WIRE",
        link: "/transaction/send/contact/add/internationalWire",
        disabled: !!scheduleId,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "International Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "International Wire Send",
          operation: "CREATE"
        }]
      },
      ...(!individual ? [{
        title: "CHECK",
        link: "/transaction/send/contact/add/check",
        disabled: !!scheduleId,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Check Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Check Send",
          operation: "CREATE"
        }]
      }] : []),
      {
        title: "BOOK",
        link: "/transaction/send/contact/add/book",
        disabled: !!scheduleId,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Book Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Book Send",
          operation: "CREATE"
        }]
      },
    ];
    
    // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
    if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
      tabs.push({
        title: "VIRTUAL CARD",
        link: "/transaction/send/contact/add/virtualcard",
        disabled: !!scheduleId,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Virtual Card Send",
          operation: "CREATE"
        }]
      })
    }
    return tabs;
  }, [scheduleId]);

  const [isloading, setIsloading] = React.useState(false);
  const [addendaShow, setAddendaShow] = React.useState(true);

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-contact-ach-form-card-destination" },
    });
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-ach-form-card-file-id-file-upload",
        files: [],
      },
    });
    if (urlContactId) {
      setExternalAccount(urlContactId);
    }
    if (scheduleId) {
      const src = {
        url: "/transaction/id/" + scheduleId,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-to-contact-ach", source: src },
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

  /**
   * Method setting the default values for the text edit component from the context data
   */
  function setFormValues(data: any) {
    if (data?.processingDetail?.authType === 'PHONE') {
      setAddendaShow(false);
    } else {
      setAddendaShow(true);
    }
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue(
      "achCompanyDescription",
      data?.processingDetail?.companyDescription
    );
    businessCustomer ? setValue("authType", data?.processingDetail?.authType) : setValue("authType", 'ONLINE');
    setValue(
      "addenda",
      data?.processingDetail?.addenda?.[0]
    );
    setAllowDuplicate(data?.allowDuplicate);
    if (data?.destination?.contact) {
      setExternalAccount(data?.destination?.contact?.id);
    }
  }
  /**
   * function to get destination details
   * @param sendInfo :data
   */
  async function getDestinationData(sendInfo: any) {
    if (sendInfo) {
      const destinationValue: any = await getDestinationValue(
        sendInfo?.destination
      );
      setValue("destination", destinationValue);
      setContactName(destinationValue);
    }
  }

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passdata(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors["fileError"]) {
        clearErrors("fileError");
      }
    } catch (e) {}
  }
  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removefile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-ach-form-card-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  const handleClose = () => {
    if (scheduleId) {
      history.push("/transactions/send/" + scheduleId);
    } else {
      history.push("/dashboard");
    }
  };

  /**
   * Method to show and hide Addenda Field
   */
  const hideShowAddenda = (event: any) => {
    try {
      setauthTypeValue(event.target.value);
      if ((event?.target.value === "PHONE")) {
        setAddendaShow(false);
      } else {
        setAddendaShow(true);
      }
    } catch (e) {}
  };

  const handleChange = (event: any) => {
    if (event && event?.value) {
      setExternalAccount(event?.value);
      setIsDestination(true);
    } else {
      setContacEA([]);
      setEALoad(false);
      setIsDestination(true);
      setIsPpiContact(false);
    }
    setSendEnabled(true);
    FxSnackBar.closeForce();
  };
  /**
   * // getting the destination data
   * @param contactId
   */
  function setExternalAccount(contactId: any) {
    setContacEA([]);
    setIsDestination(false);
    getEAList(contactId).then((data: any) => {
      setContactName(data?.name);
      const ea = getEAOrAddressDetailsFromContact(data, "EA", "", false, true);
      if (ea && ea.length > 0) {
        setDestination(setPayeeInformation(data));
        setIsDestination(true);
        setExternalEAId(ea[0].value);
        //  setValue('destination', ea[0].label + '(ID: ' + ea[0].value +')')
        // setDestination(ea[0].value)
      }
      if (data && data.ppi) {
        setEALoad(false);
        setIsPpiContact(true);
        setSelectedPPI(data.ppi);
        if(isMethodSupported("ach")) {
          setSendEnabled(true);
        } else {
          setSendEnabled(false);
          FxSnackBar.show({
            text: 'Selected payment method is not supported for sending money to contact with PPI. Available methods: ' + getKey('ppiSupportedMethods'),
          });
        }
      } else {
        setContacEA(ea);
        setIsPpiContact(false);
        setEALoad(true);
        setSelectedPPI("");
        setSendEnabled(true);
        FxSnackBar.closeForce();
      }
    });
  }
  /**
   * useEffect to set edit data details
   */
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues(context?.data?.body);
      getDestinationData(context?.data?.body);
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    let recurringRequest: any = {};
    if (urlContactId) {
      data.destination = urlContactId;
    }
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: recurringRequest["transactionDetail"],
        action: [],
      };
      req["transactionDetail"]["startDate"] = data?.startDate;
      request = {
        transaction: {
          source: {
            account: {
              id: data.source,
            },
          },
          method: "ACH",
          type: "REGULAR",
          amount: data.amount,
          purpose: data.purpose,
          allowDuplicate: allowDuplicate,
        },
      };
      if (data.destination) {
        request["transaction"]["destination"] = {
          contact: {
            id: data.destination,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }
      if (data["processingMode"]) {
        request["transaction"]["processingDetail"] = {};
        request["transaction"]["processingDetail"]["processingMode"] =
          data.processingMode;
        businessCustomer ? request['transaction']['processingDetail']['authType'] = data.authType : request['transaction']['processingDetail']['authType'] = 'ONLINE'
        if((data.authType==='WRITTEN' || data.authType==='ONLINE') && data?.addenda !== ""){
          request["transaction"]["processingDetail"]['addenda'] = [ data.addenda ]
        }
      }
      if (data.achCompanyDescription) {
        request['transaction']['processingDetail']['companyDescription'] = data.achCompanyDescription
      }
      if(data['location']){
        request['transaction']['processingDetail']['location'] = {
            'id' : data['location']
        }
      }
      req["action"].push(request);
      if (fileUploadData?.length) {
        req["linkedDocument"] = fileUploadData.map((item: any) => ({
          purpose: "Authorization",
          document: { id: item.id }
        }));
      }
    } else if (scheduleId) {
      req = {
        id: params?.id,
        method: "ACH",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      };
      if (data["processingMode"]) {
        req["processingDetail"] = {};
        req["processingDetail"]["processingMode"] = data.processingMode;
        if(businessCustomer)
            req['processingDetail']['authType'] = data.authType
        else
            req['processingDetail']['authType'] = 'ONLINE'
        if (
          (data.authType === "WRITTEN" || data.authType === "ONLINE") &&
          data.addenda
        ) {
          req["processingDetail"]["addenda"] = [data.addenda];
        }
      }
      if (data.achCompanyDescription) {
        req['processingDetail']['companyDescription'] = data.achCompanyDescription
      }
      if(data['location']){
        req['processingDetail']['location'] = {
            'id' : data['location']
        }
      }

    } else {
      req = {
        source: {
          account: {
            id: data.source,
          },
        },
        method: "ACH",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      };
      if (data.destination) {
        req["destination"] = {
          contact: {
            id: data.destination,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }


      if (data["processingMode"]) {
        req["processingDetail"] = {};
        req["processingDetail"]["processingMode"] = data.processingMode;
        if(businessCustomer)
            req['processingDetail']['authType'] = data.authType
        else
            req['processingDetail']['authType'] = 'ONLINE'
        if (data.authType === "WRITTEN" || data.authType === "ONLINE") {
            req["processingDetail"]["addenda"] = [data.addenda];
        }
      }
      if (data.achCompanyDescription) {
        req['processingDetail']['companyDescription'] = data.achCompanyDescription
      }
      if(data['location']){
        req['processingDetail']['location'] = {
            'id' : data['location']
        }
      }
    }
    if (!eaLoad && isPpiContact) {
      delete req?.processingDetail //in case of ppi we are deleting ea node
      if (req?.destination?.contact?.externalAccount) {
        delete req["destination"]['contact']['externalAccount'];
      }
      else if (req?.action && req['action'][0] && req['action'][0] && req['action'][0]['transaction']?.destination?.contact?.externalAccount) {
        delete req['action'][0]['transaction'].destination.contact.externalAccount;
      }

    }
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    const sendId = status?.headers?.url?.split("/").pop() || '';

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
        const successMessage = scheduleId? 'Send Updated Successfully!': repeatStatus? 'Recurring Created Successfully!': 'Send Created Successfully';
        const redirectUrl = scheduleId? `/transactions/send/${scheduleId['id']}`: repeatStatus? '/thanks/transactions/send-contact-ach-recurring/': '/thanks/transactions/send-contact-ach/';
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true,
            postSuccessEvent:handlePostSuccessEvent
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: repeatStatus
          ? 'Recurring Created Successfully!'
          : scheduleId
          ? 'Send Updated Successfully'
          : 'Send Created Successfully!',
      });
      if (repeatStatus) {
        dispatch({
          type: "DATA_UPDATE_COMPONENT_PARAM",
          payload: {
            id: "create-send-contact-ach-form-card-file-id-file-upload",
            files: [],
          },
        });
      }
      if (repeatStatus) {
        history.push(
          "/thanks/transactions/send-contact-ach-recurring/" + sendId
        );
      } else if (scheduleId) {
        history.push("/transactions/send/" + scheduleId);
      } else {
        history.push("/thanks/transactions/send-contact-ach/" + sendId);
      }
     }
    } else {
      //api  failed(
        FxSnackBar.show({
          text: customErrorMessage(status)
        });
     
    }
  }
  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  function destinationTransformer(data: any) {
    return data?.map((item: any) => ({
      label: item.name + " (ID: " + item.id + ")",
      value: item.id,
    })) || [];
  }

  const destinationFilterOptions: any = [
    // { label: 'Payee Ext Reference', value: 'externalId', operator: 'in' },
    { label: "Contact Name", value: "name", operator: "like" },
    // { label: 'Payee ID', value: 'id', operator: 'in' },
  ];
  /**
   * Method enables and disables the allow duplicate
   * @param event : allow duplicate status
   */
  const handleAllowDuplicate = (event: any) => {
    setAllowDuplicate(event);
  };
  /**
   * Method enables and disables the repeat functionality
   * @param event : repeat status
   */
  const handleRepeat = (event: any) => {
    setRepeatStatus(event);
    if (authTypeValue !== "PHONE") {
      setAddendaShow(true);
    } else {
      setAddendaShow(false);
    }
  };
  /**
   * Method triggers if the recurring updates
   */
  const handleUpdateRecurring = (data: any) => {
    setRecurringFormData(data);
  };

  const externalAccount = watch("externalAccount");
  const viaToLable = useMemo(() => {
      let ppi = {
          'To PPI': {
              id: 'ppi',
              value: selectedPPI,
          }
      }

      let address = {
        'To External Account': {
          id: 'externalAccount',
          value: externalAccount
          ? fetchLabelFromId(contacEA, externalAccount)
          : fetchLabelFromId(contacEA, externalEAId),
        }
      }

      if(isPpiContact){
         return ppi;         
      }else{
         return address       
      }       
  }, [isPpiContact, selectedPPI, externalAccount, contacEA, externalEAId]);

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Send Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    'From': {
      id: 'from',
      value: accountLabelTransformation(getKey("selectedAccount")),
    },
    'To': {
      id: 'to',
      value: contactName ? contactName : "",
    },
    ...(businessCustomer
      ? { Purpose: { id: "purpose", value: watch("purpose") } }
      : { Memo: { id: "purpose", value: watch("purpose") } }),
    'Via': {
      id: 'method',
      value: 'ACH',
    },
    ...viaToLable ,
    'Processing Mode' : {
      id: 'processingMode',
      value: watch("processingMode")
      ?  watch("processingMode") === 'FORWARD'? removeUnderScoreConvertTitleCase('NEXT_DAY'):removeUnderScoreConvertTitleCase(watch("processingMode"))
      : "",
    }
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: 'achCompanyDescription',
      value: watch("achCompanyDescription"),
    }
}

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
    if (!repeatStatus || !recurringFormData) {
      return {}
    }
    const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
    const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
      ? convertToTitleCaseAndAppendWithS(
        recurringFormData?.repeatEveryFormData?.repeatEverySelect
      )
      : '';
    const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
      'Repeat every': {
        id: 'repeat-every',
        value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
      },
    }
    if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
      result['On'] = {
        id: 'repeat-on',
        value: convertToOnDayFormat(recurringFormData),
      };
    }
    const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
    const ends = recurringFormData?.endFormData
      ? convertEndData(recurringFormData?.endFormData)
      : '';
    if (ends || endOn) {

    }
    result['Ends'] = endOn ? {
      id: 'repeat-ends',
      value: ends,
      ValueComponent: ({ value }) => (
        <>
          On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
        </>
      )
    } : {
      id: 'repeat-ends',
      value: ends,
    };
    return result;
  }, [recurringFormData, repeatStatus]);

  const topSectionDefaultValues = {
      amount: context?.data?.body?.amount,
      source: context?.data?.body?.source?.id,
      purpose: context?.data?.body?.purpose
    }
    const tabClickFunction = () => {
      try{
        const data:any = {
          schedule: 'send',
          type: 'contact',
          formData: { amount: watch('amount'), purpose: watch('purpose')}
        }
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
      }
      catch(e){}
    }
         /**
         * Function to handle the closing of the modal.
         */
	
      const handleCloseModal = () => {
        let verifyProps = {...props.verifyProps}
        verifyProps['open'] = false;
        updateComponentParams(dispatch, props.id, { verifyProps });
      };
       /** 
            *This function is called upon successful completion of a post operation.
            *It dispatches an action to update component parameters related to file uploads.
            */
            const handlePostSuccessEvent=()=>{
              dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-contact-ach-form-card-file-id-file-upload', 'files': [] } });
          }
  return (
    <>
    <ScheduleLayout
        id={"create-send-contact-ach-grid"}
        loading={isloading}
        summaryData={{...summaryData, ...summaryRepeatData}}
        submitTitle={repeatStatus ? 'Create' : scheduleId ? 'Save Changes' : 'Send Money'}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleClose}
        saveEnabled={sendEnabled}
        verifyProps={props?.verifyProps}
        handleCloseModal={handleCloseModal}
    >
      {urlContactId && (<FxSuccess id="sucess-external-account" header={"Contact added successfully"} description={"CONTACT ID - " + urlContactId} />)}
      <ScheduleTopSection id="create-send-contact-ach-schedule-top-section" schedule="send" type="contact" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-contact-ach-form-card-amount-textbox" sourceId="create-send-contact-ach-form-card-source" purposeId="create-send-contact-ach-form-card-purpose-textbox" />
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
          <FxLocationSelectComponent
              label='Location'
              register={{ ...register("location") }}
              control={control}
              rules={{ required: false }}
              id="create-send-contact-ach-form-location"
              name="location"
              className="fx-input-edit"
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              isEditable={!scheduleId}
              value={location ? {
                label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                value: location?.id,
            }: null}
          />
      </Grid>}
      {!scheduleId && (
        <Grid item xs={12} sm={12}>
          {isDestination && (
            <FxAutoComplete
              register={{ ...register("destination") }}
              control={control}
              rules={{ required: !urlContactId ? true : false }}
              id="create-send-contact-ach-form-card-destination"
              name="destination"
              source={destination_src}
              searchBy={'name'}
              className={
                errors.destination
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              dataTransformer={destinationTransformer}
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              value={destination}
              filterOptions={destinationFilterOptions}
              placeholder={"Search Contact..."}
              isEditable={true}
              setParam={handleChange}
            />
          )}
        </Grid>
      )}
      {scheduleId && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            register={{ ...register("destination") }}
            type="text"
            className={
              errors.destination
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            control={control}
            id="edit-send-contact-ach-form-card-destination-textbox"
            label="Contact"
            name="destination"
            isEditable={false}
          />
        </Grid>
      )}
      <Grid item>
        <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-contact-ach-form-card-tab" tabClick={tabClickFunction} />
      </Grid>
      {isPpiContact && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            control={control}
            rules={{ required: false }}
            id="create-send-contact-ach-form-card-ppi-textbox"
            label="Payment ID"
            name="passportPaymentId"
            variant="outlined"
            value={truncateChars(selectedPPI, 60)}
            isEditable={false}
            className={"fx-input-edit fx-input-edit-disabled"}
          />
        </Grid>
      )}
      {((eaLoad && !params?.id) ||
        (externalEAId && scheduleId)) && (
          <Grid item xs={12} sm={12}>
            <FxMaterialSelect
              register={{ ...register("externalAccount") }}
              id="create-send-contact-ach-form-card-ea"
              name="externalAccount"
              data={contacEA}
              control={control}
              label="Contact External Account"
              rules={{ required: true }}
              value={externalEAId ? externalEAId : ""}
              clearError={clearErrors}
              className={
                errors.externalAccount
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              readOnly={scheduleId ? true : false}
              setValue={setValue}
            />
          </Grid>
        )}
      {!isPpiContact && <Grid item xs={12} sm={12}>
        <FxMaterialSelect
          register={{ ...register("processingMode") }}
          control={control}
          rules={{ required: true }}
          id="create-send-contact-ach-form-card-processing-mode"
          name="processingMode"
          data={processingModeData}
          value={"SAME_DAY"}
          label="Processing Mode"
          setValue={setValue}
        />
      </Grid>}
      {businessCustomer && !isPpiContact && (<Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("achCompanyDescription") }}
          className={
            errors.achCompanyDescription
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          rules={{ required: false }}
          id="create-send-contact-ach-form-card-company-description-textbox"
          label="Memo"
          name="achCompanyDescription"
          variant="outlined"
        />
      </Grid>)}
      {businessCustomer && !isPpiContact && (<Grid item xs={12} sm={12}>
        <FxMaterialSelect
          register={{ ...register("authType") }}
          control={control}
          rules={{ required: true }}
          id="create-send-contact-ach-form-card-authType"
          name="authType"
          data={authTypeDate}
          value={"WRITTEN"}
          label="Authorization Type"
          setValue={setValue}
          onChange={hideShowAddenda}
        />
      </Grid>)}
      {!isPpiContact && businessCustomer && (<Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("addenda") }}
          className={addendaShow ? "fx-input-edit" : "fx-hidden"}
          control={control}
          id="create-send-account-ach-form-card-purpose-textbox"
          label="Addenda"
          name="addenda"
          variant="outlined"
        />
      </Grid>)}
      <Grid item xs={12} sm={12}>
        <FxSwitch
          id="create-send-contact-ach-form-card-allow-duplicate"
          value={allowDuplicate}
          onClick={handleAllowDuplicate}
        ></FxSwitch>{" "}
        <FxLabel value="Allow Duplicate"></FxLabel>
      </Grid>
      {(!scheduleId && checkPermission({ entity: "Recurring Transaction", name: "Ach Send", operation: "Create" })) && (
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="repeat-switch"
            value={repeatStatus}
            onClick={handleRepeat}
          ></FxSwitch>{" "}
          <FxLabel value="Repeat"></FxLabel>
        </Grid>
      )}
      {repeatStatus && (
        <>
          <Grid item xs={12} sm={12}>
            <FxDateEdit
              register={{ ...register("startDate") }}
              className={
                errors.startDate
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="create-send-contact-ach-form-card-startDate"
              name="startDate"
              type="date"
              variant="outlined"
              label={"Start Date *"}
              setValue={setValue}
              disablePast={true}
              resetField={resetField}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("name") }}
              className={
                errors.name
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              rules={{ required: true }}
              id="create-send-account-ach-form-card-name-textbox"
              label="Name *"
              name="name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxRecurring
              id="create-send-contact-ach-form-card-fx-recurring"
              updateTrigger={handleUpdateRecurring}
              startDate={watch("startDate")}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
            ></FxRecurring>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={12}>
                <Typography className="filter-popover-label">
                  LINKED DOCUMENTS
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                &nbsp;
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} sm={8}>
                  <FxMaterialSelect
                    register={{ ...register("docType") }}
                    id="create-send-contact-ach-form-card-doc-type"
                    control={control}
                    rules={{ required: false }}
                    name="docType"
                    data={docTypeData}
                    value={"CONTRACT"}
                    readOnly={true}
                    label="Select a document type"
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="fx-choose-file-button">
                  <Controller
                    name={"fileId"}
                    control={control}
                    rules={{ required: false }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FxFileUpload
                          register={{ ...register("fileId") }}
                          name="fileId"
                          passData={passdata}
                          id="create-send-contact-ach-form-card-file-id-file-upload"
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          value={
                            fileUploadData ? fileUploadData : value
                          }
                          acceptedFiles={[
                            ".pdf",
                            ".docx",
                            ".jpg",
                            ".txt",
                            ".png",
                            ".jpeg",
                            ".xls",
                            ".wav",
                            ".xlsx",
                            ".tiff",
                            ".doc",
                            ".rtf",
                            ".bmp",
                            ".efx",
                            ".csv",
                            ".kswps",
                            ".wps",
                          ]}
                          formData={{ type: "CONTRACT" }}
                          setValue={setValue}
                          maxFileSize={10485760}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FxLabelView
                  className="fx-label-small"
                  id="create-send-contact-ach-form-card-file-types-supported"
                >
                  (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                  .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                </FxLabelView>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={"error-message"}>
                  {errors.fileError && renderError(errors.fileError)}
                </div>
              </Grid>
              {fileUploadData && (
                <Grid item container>
                  {fileUploadData.map((key: any) => {
                    return (
                      <Chip
                        icon={<InsertDriveFileTwoToneIcon />}
                        label={key["name"]}
                        onDelete={() => removefile(key["id"])}
                        color="primary"
                        variant="outlined"
                        deleteIcon={<DeleteIcon />}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </ScheduleLayout>
    </>
  )

});
