import React from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import HttpClient from "../../../libs/utils/httpClient";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { Divider } from '@mui/material';
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { ReactComponent as AdjustIcon } from '../../../assets/svg/adjustIcon.svg';
const httpClient = HttpClient.getClient();

Logger.debug("AdjustCollect.tsx", "Adjust Collect initializing");


interface IAdjustCollect {
  id?: string,
  open?: boolean,
  onClose: () => any,
  className?: string,
  isLoading:boolean,
  metadata:any,
  data?: any
}
/**
 * This component handles the Adjust of the collect
 */
export const AdjustCollect: React.FC<IAdjustCollect> =  React.memo(
  (props) => {
      const { register, formState: { errors,isSubmitting }, handleSubmit, control } = useForm();
      const dispatch = useDispatch();


      
   /**
   * Method to handle form submit event
   * @param data :form data
   */
   async function onSubmit(data: any) {
    let req: any;
    let status: any;
    req = {
      amount: data?.adjustedAmount,
    };
    status = await adjustFunds(req);
    status = processAPIResponse(status);
    updateComponentParams(dispatch, props?.id, { 'isLoading': false })
    if (status.status) {
      //api success
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: "success",
        text: "Adjusted Successfully!",
      });
      props.onClose();
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'transaction-Cards-details-config-card1'+props?.metadata?.scheduleId } });
      dispatch({ type: "DATA_COMPONENT_RESET", payload: {id:"transaction-processing-card-" + props?.metadata?.scheduleId} });
      
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }



   /**
   * Method to to call API for creation of adjust
   * @param paylaoddata : request payload
   */
   async function adjustFunds(req: any) {
    try {
      const url: any = props?.metadata?.captureData ? 'transaction/id/' + props?.metadata?.scheduleId + '/capture/id/' + props?.metadata?.captureData.id + '/adjust'  : 'transaction/id/' + props?.metadata?.scheduleId + '/adjust';
      const data: any = await httpClient.post(getCustomerUrl(url, false), req);
      return data;
    } catch (err) {
      Logger.error("FxAvailFundsCard.tsx", "error", err);
      return err;
    }
  }
     

      return (
        <div className="modal-content" >
          <div>
            {<form onSubmit={handleSubmit(onSubmit)}>
              <FxCard className="fx-manage-card-usage-modal">
                <FxCardHeader id="capture-schedule-header">
                  <Grid container direction="row" item xs={12} justifyContent="space-between">
                    <Grid item container xs={11} justifyContent="flex-center" className="fx-capture-collect-header">
                      {/* image */}
                      <Grid><AdjustIcon></AdjustIcon></Grid>
                    </Grid>
                  </Grid>
                </FxCardHeader>
                <FxCardBody id="capture-schedule-modal-body" className="fx-info-card" >
                  <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                  <Grid item xs={12} className="fx-capture-collect-header">
                    <Typography id="sub-title-capture-schedule-modal" variant="h4">
                           Adjust
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography id="sub-title-capture-schedule-modal" className="fx-capture-collect-subheading fx-capture-collect-header" variant="h6">
                          You cannot undo this action
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        register={{ ...register("originalAmount") }}
                        className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                        control={control}
                        prefix="$"
                        type="number"
                        id="originalAmount"
                        defaultValue={props?.metadata?.captureData?.amount ? props?.metadata?.captureData?.amount : props?.metadata?.data?.amount}
                        label={"Original Amount*"}
                        name="originalAmount"
                        showDecimal={true} 
                        placeholder='0.00'
                        isEditable= {false}
                        readOnly= {true}
                        valuePattern={/-|\+|e|E/}
                        variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        register={{ ...register("adjustedAmount") }}
                        className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                        control={control}
                        prefix="$"
                        type="number"
                        id="adjustedAmount"
                        label={"Adjusted Amount*"}
                        name="adjustedAmount"
                        variant="outlined" />
                    </Grid>
                    <Grid className="fx-capture-collect-description-text fx-capture-collect-subheading"> 
                      <Typography>This will change the transaction amount.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </FxCardBody>
                <FxCardFooter id="capture-schedule-modal-Footer" className="fx-footer">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    className="fx-modal-footer"
                  >
                    <Button
                      variant="contained"
                      className="fx-button fx-button-cancel"
                      id="cancel-button"
                      onClick={() => props?.onClose()}
                    >
                      Cancel
                    </Button>
                    <span className="fx-padding-right-16" />
                    <FxButton
                      disableRipple={false}
                      className="fx-button fx-button-theme"
                      id={"replace-debit-card-submit-button"}
                      type="submit"
                      isSubmitting={isSubmitting}
                    >
                      ADJUST
                    </FxButton>
                  </Grid>
                </FxCardFooter>
              </FxCard>
            </form>}
          </div>
        </div>
      );
  });
