import React from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FxButton } from "../../Action/FxButton";
/**
 * This Component handles the creation of Tab for schedules
 */
export const ScheduleCreateMethodTab: React.FC<any> = React.memo((props) => {
  const history = useHistory();

  // redirecting to specific url
  const handleRedirect = (url: any) => {
    if(props.tabClick){
      props.tabClick()
    }
    history.push(url);
  };

  return (
    <>
      <Grid id={props.id} item container xs={12} sm={12}>
        <Grid xs={12} sm={12}>
          <Typography
            id="send-choose-method"
            className={props.customTitle?"fx-create-schedule-subheading fx-create-schedule-custom-title":"fx-create-schedule-subheading"}
          >
            {props?.type && props?.type === "debitcard" ? "" : props.customTitle? props.customTitle: "SELECT METHOD"}
          </Typography>
        </Grid>
        <Grid item className="fx-schedule-create-tab-container">
          {props.tabItems.map((item: any) => {
            return (
                <FxButton
                  permissions={item?.permission}
                  multiplePermission={item.multiplePermission}
                  className={item.className ? item.className : "fx-link"}
                  id={item.title + "-send-method-button"}
                  disabled={item?.disabled}
                  onClick={() => {
                    handleRedirect(item.link);
                  }}
                >
                  {item.title}
                </FxButton>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
});
