/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { ISource } from '../../../types/common.interfaces';
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
  clean,
  getEAOrAddressDetailsFromContact,
  renderError,
  setPayeeInformation,
  transformRecurringRequestGlobal,
  fetchLabelFromId,
  convertToTitleCaseAndAppendWithS,
  convertEndData,
  convertToOnDayFormat,
  deleteUnwantedRequest,
  updateComponentParams,
  checkPermission,
  isMethodSupported,
  truncateChars,
  createCommonVerifyProps
} from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
  createSendMyAccount,
  dataSourceTransformation,
  getEAList,
  accountLabelTransformation,
  getDestinationValue,
  getLocationData
} from "./ScheduleGlobalFunctions";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

Logger.debug(
  "CreateSendContactWIRE.tsx",
  "create send Contact Wire initializing"
);

const acc_src: ISource = {
  url: "account/list",
  method: "POST",
  data: {
    pageSize: 25,
    pageNumber: 1,
    sortOptions: {
      sortOrder: "DESC",
      sortBy: "createdOn",
    },
    criteria: {
      filters: [
        {
          key: "status",
          operator: "nin",
          values: ["INACTIVE"],
        },
      ],
    },
  },
};

const destination_src = {
  url: "contact/list",
  method: "POST",
  data: {
    pageNumber: 1,
    pageSize: 5,
    criteria: {
      filters: [
        {
          key: "allowedMethod",
          operator: "eq",
          values: ["WIRE"],
        },
      ],
    },
  },
};

const destinationFilterOptions: any = [
  { label: "Contact Name", value: "name", operator: "like" },
];

/**
 * This component handles the send creation to linked contacts through wire method
 */
export const CreateSendContactWIRE: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const params = useParams<any>();
  const dispatch = useDispatch();
  const uiFeat = UIFeatureImpl.getInstance();
  const scheduleId = params?.id;
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individual = uiFeat.getIndividualCustomerFeature().available;
  const [location, setLocation] = useState<any>();
  /**
   * Function to be called when context data body changes
   */
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues();
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  let sendId: any = "";
  const [contacEA, setContacEA] = useState<any>([]);
  const [eaLoad, setEALoad] = useState(false);
  const [destination, setDestination] = useState<any>();
  const [contactName, setContactName] = useState<any>();
  const [externalEAId, setExternalEAId] = useState();
  const [isDestination, setIsDestination] = useState(true);
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const [isPpiContact, setIsPpiContact] = useState(false);
  const [selectedPPI, setSelectedPPI] = useState("");
  const [sendEnabled, setSendEnabled] = useState(true);
  const urlContactId = params?.contactId;

  const method_Tab = useMemo(() => {
    const tabs = [
      {
        title: "ACH",
        link: "/transaction/send/contact/add/ach",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Ach Send",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "Ach Send",
          operation: "CREATE"
        }]
      },
      {
        className: "fx-link-sel",
        title: "WIRE",
        link: "/transaction/send/contact/add/wire",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Wire Send",
          operation: "CREATE"
        }]
      },
      {
        title: "INTERNATIONAL WIRE",
        link: "/transaction/send/contact/add/internationalWire",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "International Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "International Wire Send",
          operation: "CREATE"
        }]
      },
      ...(!individual ? [{
        title: "CHECK",
        link: "/transaction/send/contact/add/check",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Check Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Check Send",
          operation: "CREATE"
        }]
      }] : []),
      {
        title: "BOOK",
        link: "/transaction/send/contact/add/book",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Book Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Book Send",
          operation: "CREATE"
        }]
      }];
      // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
      if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
        tabs.push({
          title: "VIRTUAL CARD",
          link: "/transaction/send/contact/add/virtualcard",
          disabled: !!props.isEdit,
          multiplePermission: true,
          permission: [{
            entity: "Accounts",
            name: "Virtual Card Send",
            operation: "CREATE"
          }, {
            entity: "Recurring Transaction",
            name: "Virtual Card Send",
            operation: "CREATE"
          }]
        })
      }
      return tabs;
  }, [props.isEdit]);

  const [isloading, setIsloading] = useState(false);

  const handleClose = () => {
    if (context?.data?.body) {
      history.push("/transactions/send/" + context?.data?.body?.id);
    } else {
      history.push("/dashboard");
    }
  };
  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-contact-wire-form-card-destination" },
    });
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-wire-form-card-file-id-file-upload",
        files: [],
      },
    });
    setValue("type", "REGULAR");
    if (urlContactId) {
      setExternalAccount(urlContactId);
    }
    // checking whether edit or not.
    if (params.id) {
      const src = {
        url: "/transaction/id/" + params.id,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-contact-wire", source: src },
      });
      updateComponentParams(dispatch, "edit-send-contact-wire", {
        isEdit: true,
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);
  /**
   * setting form values for edit form
   */
  const setFormValues = () => {
    setValue("amount", context?.data?.body?.amount);
    setValue("purpose", context?.data?.body?.purpose);
    setValue("memo", context?.data?.body?.processingDetail?.memo);
    getDestinationData();
    setValue(
      "externalAccount",
      context?.data?.body?.destination?.contact?.externalAccount?.id
    );
    setExternalAccount(context?.data?.body?.destination?.contact?.id);
  };

  /**
   * Method handles the api call for getting contact name from the contact api and set the value
   */
  async function getDestinationData() {
    const destinationValue: any = await getDestinationValue(
      context?.data?.body?.destination
    );
    setValue("destination", destinationValue);
  }

  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passdata(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors["fileError"]) {
        clearErrors("fileError");
      }
    } catch (e) {}
  }
  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removefile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-wire-form-card-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    let recurringRequest: any = {};
    if (urlContactId) {
      data.destination = urlContactId;
    }
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: recurringRequest["transactionDetail"],
        action: [],
      };
      req["transactionDetail"]["startDate"] = data?.startDate;
      request = {
        transaction: {
          source: {
            account: {
              id: data.source,
            },
          },
          method: "WIRE",
          type: "REGULAR",
          amount: data.amount,
          purpose: data.purpose,
          allowDuplicate: allowDuplicate,
        },
      };
      if (data.memo) {
        request["transaction"]["processingDetail"] = {
          memo: data.memo,
        };
      }
      if(data['location']){
        request['transaction']['processingDetail']['location'] = {
            'id' : data['location']
        }
      }
      if (data.destination) {
        request["transaction"]["destination"] = {
          contact: {
            id: data.destination,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }

      req["action"].push(request);
      if (fileUploadData?.length) {
        req["linkedDocument"] = fileUploadData.map((item: any) => ({
          purpose: "Authorization",
          document: { id: item.id }
        }));
      }
    } else {
      req = {
        source: {
          account: {
            id: data.source,
          },
        },
        method: "WIRE",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      };
      if (data.memo) {
        req["processingDetail"] = {
          memo: data.memo,
        };
      }
      if(data['location']){
        req['processingDetail']['location'] = {
            'id' : data['location']
        }
      }
      if (data.destination) {
        req["destination"] = {
          contact: {
            id: data.destination,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }
    }
    if (props.isEdit) {
      req["id"] = context?.data?.body?.id;
      const unwantedRequestArray = ["source", "destination", "method", "type"];
      deleteUnwantedRequest(req, unwantedRequestArray);
    }
    if (!eaLoad && isPpiContact) {
      delete req?.processingDetal; //in case of ppi we are deleting ea node
      if (req?.destination?.contact?.externalAccount) {
        delete req["destination"]["contact"]["externalAccount"];
      } else if (
        req?.action &&
        req["action"][0] &&
        req["action"][0] &&
        req["action"][0]["transaction"]?.destination?.contact?.externalAccount
      ) {
        delete req["action"][0]["transaction"].destination.contact
          .externalAccount;
      }
    }
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    if (status?.headers?.url) {
      sendId = status.headers.url.split("/").pop();
    }

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
      const successMessage = props.isEdit? 'Send Updated Successfully!': repeatStatus? 'Recurring Created Successfully!': 'Send Created Successfully';
      const redirectUrl = props.isEdit? `/transactions/send/${context?.data?.body?.id}`: repeatStatus? '/thanks/transactions/send-contact-wire-recurring/': '/thanks/transactions/send-contact-wire/';
      const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
      if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true,
            postSuccessEvent:handlePostSuccessEvent
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      if (props.isEdit) {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: 'Send Updated Successfully!',
        });
        history.push("/transactions/send/" + context?.data?.body?.id);
      } else {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: repeatStatus
            ? 'Recurring Created Successfully!'
            : 'Send Created Successfully!',
        });
        if (repeatStatus) {
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
              id: "create-send-contact-wire-form-card-file-id-file-upload",
              files: [],
            },
          });
          history.push(
            "/thanks/transactions/send-contact-wire-recurring/" + sendId
          );
        } else {
          history.push("/thanks/transactions/send-contact-wire/" + sendId);
        }
      }
    } 
  }
    else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }
  const handleChange = (event: any) => {
    if (event?.value) {
      setExternalAccount(event?.value);
      setIsDestination(true);
    } else {
      setContacEA([]);
      setEALoad(false);
      setIsDestination(true);
      setIsPpiContact(false);
    }
    setSendEnabled(true);
    FxSnackBar.closeForce();
  };
  /**
   * // getting the destination data
   * @param contactId
   */
  function setExternalAccount(contactId: any) {
    setContacEA([]);
    setIsDestination(false);
    getEAList(contactId).then((data: any) => {
      setContactName(data?.name);
      const ea = getEAOrAddressDetailsFromContact(data, "EA", "", false, true);
      if (ea?.length) {
        setDestination(setPayeeInformation(data));
        setIsDestination(true);
        setExternalEAId(ea[0].value);
      }
      if (data && data.ppi) {
        setEALoad(false);
        setIsPpiContact(true);
        setSelectedPPI(data.ppi);
        if(isMethodSupported("wire")) {
          setSendEnabled(true);
        } else {
          setSendEnabled(false);
          FxSnackBar.show({
            text: 'Selected payment method is not supported for sending money to contact with PPI. Available methods: ' + getKey('ppiSupportedMethods'),
          });
        }
      } else {
        setContacEA(ea);
        setIsPpiContact(false);
        setEALoad(true);
        setSelectedPPI("");
        setSendEnabled(true);
      }
    });
  }

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  const destinationTransformer = useCallback((data: any) => data?.map((item: any) => ({
    label: item.name + " (ID: " + item.id + ")",
    value: item.id,
  })) || [], []);

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Send Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    'From': {
      id: 'from',
      value: accountLabelTransformation(getKey('selectedAccount'))
    },
    'To': {
      id: 'to',
      value: watch('destination') && contactName ? contactName : '',
    },
    ...(businessCustomer
      ? { Purpose: { id: "purpose", value: watch("purpose") } }
      : { Memo: { id: "purpose", value: watch("purpose") } }),
    'Via': {
      id: 'method',
      value: 'WIRE',
    },
    ...(isPpiContact ? {
      'To PPI': {
        id: 'ppi',
        value: 'selectedPPI',
      }
    } : {
      'To External Account': {
        id: 'externalAccount',
        value: watch("externalAccount")
        ? fetchLabelFromId(contacEA, watch("externalAccount"))
        : fetchLabelFromId(contacEA, externalEAId ),
      }
    })
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: 'memo',
      value: watch('memo'),
    }
}
  
  if(allowDuplicate) {
    summaryData['Allow Duplicate'] = {
      id: 'allowDuplicate',
      value: allowDuplicate ? 'Yes' : 'No',
    }
  }
  if(watch('currency')) {
    summaryData['Currency'] = {
      id: 'currency',
      value: watch("currency"),
    }
  }

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
      if(!repeatStatus || !recurringFormData) {
          return {}
      }
      const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
      const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
          ? convertToTitleCaseAndAppendWithS(
              recurringFormData?.repeatEveryFormData?.repeatEverySelect
          )
        : '';
      const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
          'Repeat every': {
              id: 'repeat-every',
              value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
          },
      }
      if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
          result['On'] = {
              id: 'repeat-on',
              value: convertToOnDayFormat(recurringFormData),
          };
      }
      const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
      const ends = recurringFormData?.endFormData
          ? convertEndData(recurringFormData?.endFormData)
          : '';
      if (ends || endOn) {

      }
      result['Ends'] = endOn ? {
          id: 'repeat-ends',
          value: ends,
          ValueComponent: ({ value }) => (
              <>
                  On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
              </>
          )
      } : {
          id: 'repeat-ends',
          value: ends,
      };
      return result;
  }, [recurringFormData, repeatStatus]);

  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    source: context?.data?.body?.source?.id,
    purpose: context?.data?.body?.purpose,
  };
  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: "send",
        type: "contact",
        formData: { amount: watch("amount"), purpose: watch("purpose") },
      };
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: { id: "schedule-top-section", data: data },
      });
    } catch (e) {}
  };

     /**
         * Function to handle the closing of the modal.
         */
	
		 const handleCloseModal = () => {
      let verifyProps = {...props.verifyProps}
      verifyProps['open'] = false;
      updateComponentParams(dispatch, props.id, { verifyProps });
  };
    /** 
    *This function is called upon successful completion of a post operation.
    *It dispatches an action to update component parameters related to file uploads.
    */
    const handlePostSuccessEvent=()=>{
      dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-contact-wire-form-card-file-id-file-upload', 'files': [] } });
  }
  return (
    <>
    <ScheduleLayout
      id='create-send-via-contact-wire'
      type='send'
      loading={isloading}
      submitTitle={repeatStatus ? 'Create' : params.id ? 'Save Changes' : 'Send Money'}
      summaryData={{ ...summaryData, ...summaryRepeatData }}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleClose}
      saveEnabled={sendEnabled}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection
        id="create-send-contact-wire-schedule-top-section"
        schedule="send"
        type="contact"
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={topSectionDefaultValues}
        acc_src={acc_src}
        dataSourceTransformation={dataSourceTransformation}
        amountId="create-send-contact-wire-form-card-amount-textbox"
        sourceId="create-send-contact-wire-form-card-source"
        purposeId="create-send-contact-wire-form-card-purpose-textbox"
      />
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
          <FxLocationSelectComponent
              label='Location'
              register={{ ...register("location") }}
              control={control}
              rules={{ required: false }}
              id="create-send-contact-wire-form-location"
              name="location"
              className="fx-input-edit"
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              isEditable={!scheduleId}
              value={location ? {
                label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                value: location?.id,
              }: null}
          />
      </Grid>}
      {!props.isEdit && (
        <Grid item xs={12} sm={12}>
          {isDestination && (
            <FxAutoComplete
              register={{ ...register("destination") }}
              control={control}
              rules={{ required: !urlContactId }}
              id="create-send-contact-ach-form-card-destination"
              name="destination"
              source={destination_src}
              searchBy={'name'}
              className={
                errors.destination
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              dataTransformer={destinationTransformer}
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              value={destination}
              filterOptions={destinationFilterOptions}
              placeholder={"Search Contact..."}
              isEditable={true}
              setParam={handleChange}
            />
          )}
        </Grid>
      )}
      {props.isEdit && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            register={{ ...register("destination") }}
            className={
              errors.destination
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            control={control}
            id="create-send-contact-wire-form-card-destination-textbox"
            label=""
            name="destination"
            variant="outlined"
            isEditable={false}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="create-send-contact-ach-form-card-tab"
          tabClick={tabClickFunction}
        />
      </Grid>
      {isPpiContact && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            control={control}
            rules={{ required: false }}
            id="create-send-contact-wire-form-card-ppi-textbox"
            label="Payment ID"
            name="passportPaymentId"
            variant="outlined"
            value={truncateChars(selectedPPI, 60)}
            isEditable={false}
            className={"fx-input-edit fx-input-edit-disabled"}
          />
        </Grid>
      )}
      {((eaLoad && !props.isEdit) ||
        (props.isEdit && externalEAId)) && (
        <Grid item xs={12} sm={12}>
          <FxMaterialSelect
            register={{ ...register("externalAccount") }}
            id="create-send-contact-ach-form-card-ea"
            name="externalAccount"
            data={contacEA}
            control={control}
            label="Contact External Account"
            rules={{ required: true }}
            value={externalEAId ? externalEAId : ""}
            clearError={clearErrors}
            className={
              errors.externalAccount
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            readOnly={props.isEdit ? true : false}
            setValue={setValue}
          />
        </Grid>
      )}
      {businessCustomer && <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("memo") }}
          className={
            errors.memo
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          id="create-send-contact-wire-form-card-memo-textbox"
          label="Memo"
          name="memo"
          variant="outlined"
          defaultValue={
            context?.data?.body?.memo
              ? context?.data?.body["memo"]
              : ""
          }
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <FxSwitch
          id="create-send-contact-wire-form-card-allow-duplicate"
          value={allowDuplicate}
          onClick={setAllowDuplicate}
        ></FxSwitch>{" "}
        <FxLabel value="Allow Duplicate"></FxLabel>
      </Grid>
      {(!props.isEdit && checkPermission({entity: "Recurring Transaction",name: "Wire Send",operation: "Create" })) && (
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="repeat-switch"
            value={repeatStatus}
            onClick={setRepeatStatus}
          ></FxSwitch>{" "}
          <FxLabel value="Repeat"></FxLabel>
        </Grid>
      )}
      {repeatStatus && (
        <>
          <Grid item xs={12} sm={12}>
            <FxDateEdit
              register={{ ...register("startDate") }}
              className={
                errors.startDate
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="create-send-contact-wire-form-card-startDate"
              name="startDate"
              type="date"
              variant="outlined"
              label={"Start Date *"}
              setValue={setValue}
              disablePast={true}
              resetField={resetField}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("name") }}
              className={
                errors.name
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              rules={{ required: true }}
              id="create-send-account-ach-form-card-name-textbox"
              label="Name *"
              name="name"
              variant="outlined"
            />
          </Grid>
      <Grid item xs={12} sm={12}>
        {/*This section handles the recurring*/}
          <FxRecurring
            id="create-send-contact-wire-form-card-fx-recurring"
            updateTrigger={setRecurringFormData}
            startDate={watch("startDate")}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
          ></FxRecurring>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Typography className="filter-popover-label">
                LINKED DOCUMENTS
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              &nbsp;
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12} sm={8}>
                <FxMaterialSelect
                  register={{ ...register("docType") }}
                  id="create-send-contact-wire-form-card-doc-type"
                  control={control}
                  rules={{ required: false }}
                  name="docType"
                  data={docTypeData}
                  value={"CONTRACT"}
                  readOnly={true}
                  label="Select a document type"
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="fx-choose-file-button">
                <Controller
                  name={"fileId"}
                  control={control}
                  rules={{ required: false }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FxFileUpload
                        register={{ ...register("fileId") }}
                        name="fileId"
                        passData={passdata}
                        id="create-send-contact-wire-form-card-file-id-file-upload"
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        value={
                          fileUploadData ? fileUploadData : value
                        }
                        acceptedFiles={[
                          ".pdf",
                          ".docx",
                          ".jpg",
                          ".txt",
                          ".png",
                          ".jpeg",
                          ".xls",
                          ".wav",
                          ".xlsx",
                          ".tiff",
                          ".doc",
                          ".rtf",
                          ".bmp",
                          ".efx",
                          ".csv",
                          ".kswps",
                          ".wps",
                        ]}
                        formData={{ type: "CONTRACT" }}
                        setValue={setValue}
                        maxFileSize={10485760}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FxLabelView
                className="fx-label-small"
                id="create-send-contact-wire-form-card-file-types-supported"
              >
                (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
              </FxLabelView>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={"error-message"}>
                {errors.fileError && renderError(errors.fileError)}
              </div>
            </Grid>
            {fileUploadData && (
              <Grid item container>
                {fileUploadData.map((key: any) => {
                  return (
                    <Chip
                      icon={<InsertDriveFileTwoToneIcon />}
                      label={key["name"]}
                      onDelete={() => removefile(key["id"])}
                      color="primary"
                      variant="outlined"
                      deleteIcon={<DeleteIcon />}
                    />
                  );
                })}
              </Grid>
            )}
          </Grid>
      </Grid>
      </>
      )}

    </ScheduleLayout>
    </>
  );
});
