/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DailyTransactionSummary } from './DailyTransactionSummary';
import { MyAccount } from './MyAccount';
import { getKey, setKey } from '../../../../libs/utils/storageManager';
import { HomeOverlay } from './HomeOverlay';
import { ActivateCardPayments } from './ActivateCardPayments';
import { checkPermission, defValueFormatter, fetchDataFromState, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { QuickTransfer } from './QuickTransfer';
import { getCustomerDetails } from '../../../../libs/services/homeService';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import HttpClient from '../../../../libs/utils/httpClient';
import FxTopBanner from '../../../Utils/fx-top-banner';
import moment from 'moment';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { HomeTreasureListCard } from '../../../DrawerPages/Treasure/HomeTreasureListCard';
import { AccountTag } from './AccountTag';
import { DashboardSkeleton } from './DashboardSkeleton';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import FxModalGeneric from '../../../Utils/FxModalGeneric';
import { PriorityCapitalAccessAllowedConfirmation } from './PriorityCapitalAccessAllowedConfirmation';


/**
 * This component manages the rendering of the various cards in the home screen
 */
export const CustomerHomePassport: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  const httpClient = HttpClient.getClient();
  const uiFeat = UIFeatureImpl.getInstance();
  const [isFastFundingEnabled, setIsFastFundingEnabled] = React.useState(getKey('mxmFastFunding') === true);
  const dispatch = useDispatch()
  const history = useHistory();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 999px)' })
  const [fastFundingEnabled, setFastFundingEnabled] = useState(false)
  const customerStatus = getKey("customerStatus");
  const isSSOLoggedIn = uiFeat.getSSOUserFeatures().ssoUser;
  const isMerchantLoggedIn = isSSOLoggedIn && getKey('merchantMID') && getKey('customerId') === getKey('midEnabledCustomerID') && getKey('selectedAccount')?.id === getKey('merchantMIDAccount')
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const jointTenancy= uiFeat.getJointTenancyFeature().available;
  let bannerCarouselData:any = [];
  const isMerchantOnboardingEnabled = uiFeat.getMerchantOnboardingFeature().available;
  /**
   * useEffect to set faster funding flag
   */
  useEffect(()=>{
    setIsFastFundingEnabled(getKey('mxmFastFunding') === true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getKey('mxmFastFunding')])

 
  const merchant_request = {
      pageNumber: 1,
      pageSize: 25,
      criteria: {
        filters: []
      }
  }
  const debit_card_list_request = {
      "pageNumber": 1,
      "pageSize": 25,
      "criteria": {
        "filters": []
      }

  }

  const ledger_request = {
    pageNumber: 1,
    pageSize: 25,
    sortOptions: {
      "sortOrder": "desc",
      "sortBy": "lastUpdatedOn"
    },
    criteria: {}
  }
  /**
     * Method to fetch switch customer details from state
     */
  const switchData = useSelector((state: any) =>
  fetchDataFromState(state, 'switch-customer-data')?.data
);
  // Get balance history requst details
  const balanceHistory_list_request = useMemo(() => {
    if(switchData?.selectedAccount){
      let startDate: any;
      if (switchData?.selectedAccount?.activationDate) {
        startDate = moment().subtract(1, 'months') > moment(switchData?.selectedAccount?.activationDate) ? moment().subtract(1, 'months').format('MM/DD/YYYY') : moment(switchData?.selectedAccount?.activationDate).format('MM/DD/YYYY');
      }
    

    return {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        "sortOrder": "ASC",
        "sortBy": "balanceDate"
      },
      criteria: {
        "filters": [
          {
            "operator": "gte",
            "key": "balanceDate",
            "values": [
              startDate
            ]
          },
          {
            "operator": "lte",
            "key": "balanceDate",
            "values": [
              moment().format('MM/DD/YYYY')
            ]
          }]
        }
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchData?.selectedAccount])
    
  /**
   * Useeffect hooks called when switchData?.customerKYC changes
   */
  useEffect(() => {
    if(switchData?.customerKYC !== undefined){
      const kycValue = switchData?.customerKYC;
      if (kycValue !== 'skip') {
        getCustomerDetails()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        getAccountInfo()
      }
      if (getKey("permissionDenied") === 'True') {
        history.push("/user/permission/denied");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [switchData?.customerKYC])

  useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Dashboard' } } })
    if (getKey("customerKYC") != 'skip') {
      updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': true })
      getCustomerDetails()
      getAccountInfo()
    }
    if (getKey("permissionDenied") == 'True') {
      history.push("/user/permission/denied");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if(props?.accountUpdated  && switchData?.selectedAccount){
      getSourceInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.accountUpdated,switchData?.selectedAccount])

  /**
   * to get customer details
   */
  async function getSourceInfo() {
    try {
      const customerDetails = await httpClient.get('customer/id/' + getKey('customerId'));
      updateComponentParams(dispatch, "customer-basic-info", { 'customerDetails': customerDetails?.data })
      // Check if the customer type is not 'INDIVIDUAL'
      if (businessCustomer) {
        // Make a POST request to fetch the merchant list
        const merchantList = await httpClient.post(getCustomerUrl("/merchant/list", false), merchant_request);
        updateComponentParams(dispatch, "customer-basic-info", { 'merchantList': merchantList?.data })

        // Check if the user is a mxmOrSmbUser
        if (uiFeat.getFasterFunding().available) {
          let merchant: any
          //in case of MID Available through sso login
          if (isMerchantLoggedIn) {
            merchant = merchantList?.data?.resources?.find((item: any) => Number(item?.mid) === getKey('merchantMID'));
            setKey('mxmMerchant', merchant);
            // Set the 'mxmFastFunding' key based on the merchant's passport funding status
            setKey('mxmFastFunding', (merchant?.configuration?.passportFunding?.status === 'ACTIVE' || merchant?.configuration?.passportFunding?.status === 'INITIATED'));
            // Check if the merchant's passport funding status is 'ACTIVE' or 'INITIATED'
            if (merchant?.configuration?.passportFunding?.status === 'ACTIVE' || merchant?.configuration?.passportFunding?.status === 'INITIATED') {
              // Set the 'FastFundingEnabled' state to true
              setFastFundingEnabled(true)
            }
          } else {
            //for other accounts
            merchant = merchantList?.data?.resources?.find((item: any) => (item?.configuration?.passportFunding?.status === 'ACTIVE' || item?.configuration?.passportFunding?.status === 'INITIATED') && getKey('selectedAccount')?.id === item?.merchantAccount?.id);
            if (merchant) {
              setFastFundingEnabled(true)
            }
          }
        }
        // Check the active status of the merchant
        const merchantActiveStatus = checkMerchantActiveStatus(merchantList);
        // Set the 'merchantActiveStatus' key based on the merchant's active status
        if (merchantActiveStatus) {
          setKey('merchantActiveStatus', 'true')
        }
        else {
          setKey('merchantActiveStatus', 'false')
        }
      }
      // Check if the 'selectedAccount' key exists
      if(getKey("selectedAccount")?.id ){
        // Fetch the account details
        getAccountDetails(getKey("selectedAccount")?.id)
        const filters: any = [];
        try {
          // Define the filters for the ledger request
          filters.push(
            {
              "operator": "gte",
              "key": "lastUpdatedOn",
              "values": [
                moment().subtract(1, 'months').format('MM/DD/YYYY')
              ]
            },
            {
              "operator": "lte",
              "key": "lastUpdatedOn",
              "values": [
                moment().format('MM/DD/YYYY')
              ]
            },
            {
              "operator": "eq",
              "key": "customView",
              "values": [ "true" ]
            },
            {
              "operator": "eq",
              "key": "account.id",
              "values": [
                getKey("selectedAccount")?.id
              ]
            },
            {
              "operator": "eq",
              "key": "isRealTime",
              "values": [
                  "true"
              ]
          })
          // Set the criteria for the ledger request
          ledger_request.criteria = { filters }

          // Make a POST request to fetch the debit card list
          const debit_card_list = await httpClient.post(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id + '/debitCard/list', false), debit_card_list_request);
          updateComponentParams(dispatch, "customer-basic-info", { 'debitCardlist': debit_card_list?.data })
          // Make a POST request to fetch the ledger list
          const ledger_list = await httpClient.post(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id + '/ledger/list', false), ledger_request);
          updateComponentParams(dispatch, "customer-basic-info", { 'ledgerList': ledger_list?.data })
          // Make a POST request to fetch the balance history list
          const balanceHistory_list = await httpClient.post(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id + '/balanceHistory', false), balanceHistory_list_request);
          updateComponentParams(dispatch, "customer-basic-info", { 'ledgerListGraph': balanceHistory_list?.data })
          dispatch({ type: "DATA_COMPONENT_RESET", payload: { id:'available-balance-chart-card' } });
          // Make a GET request to fetch the balance details
          const balanceDetails = await httpClient.get(getCustomerUrl('account/id/' + getKey('selectedAccount')?.id + '/balances'));
          updateComponentParams(dispatch, "customer-basic-info", { 'viewBalanceDetails': balanceDetails?.data })
        }catch(error){
          Logger.error("CustomerHomePassport.tsx", error);
        }
      }
      // Dispatch an action to update the component parameters
      dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": props?.id, 'accountUpdated': false } });
    } catch (error) {
      Logger.error("CustomerHomePassport.tsx", error)
    }
    finally {
      // Update the component parameters to hide the skeleton
      updateComponentParams(dispatch,  props?.id, {'showSkeleton':false })
    }
  }

  /**
  * to get source details
  */
  const sourceInfo = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']
    }
  });

  const accountList = sourceInfo?.params?.accountList?.resources;
  const activeAccountStatus = accountList?.some((item:any) => item.type !== 'DEPOSIT' && item.status === 'ACTIVE');
  const hasInvestmentAccount = sourceInfo?.params?.hasInvestmentAccount;  
  const hasCashBuilderAccount = sourceInfo?.params?.hasCashBuilderAccount;   

  /**
  * function to get account data and storage quick settle configuration
  * @param id :account id
  */
  async function getAccountDetails(id: number) {
    try {
      const res = await httpClient.get(getCustomerUrl("account/id/" + id));
      const quickSettle = res.data.configuration.credit?.quickSettle;
      if (res?.data?.status === 'INACTIVE') {
        getInactiveAccountDetails(res?.data?.id)
        setKey("accountStatus", 'false');
        setKey("customerKYC", 'stepper')
      }
      else{
        setKey("accountStatus", 'true');
      }
      setKey('accountLevelQuickSettle', quickSettle);
      updateComponentParams(dispatch, "customer-basic-info", { 'accountDetails': res.data })
      dispatch({ type: "DATA_UPDATE_COMPONENT", payload: { id: "home-myaccount", data: res.data } });
    } catch (error) {
      Logger.error("CustomerHomePassport.tsx", error)
    }
  }

  /**
  * Function to check merchant exists for customer
  * @param data :merchant data
  * @returns
  */
    function checkMerchantActiveStatus(data: any) {
      let merchantStatus = false;
      data?.resources?.forEach((item: any) => {
        if (item.status === 'ACTIVE' && item.underwritingStatus === 'APPROVED') {
          merchantStatus = true;
        }
      })
      return merchantStatus;
    }

      /**
        *  function used to get account details
        *  this method will be removed later once showing inactive accounts in sidebar
      */
      async function getInactiveAccountDetails(id: any) {
        try {
          const res = await httpClient.get(getCustomerUrl("account/id/" + id));
          const agreement: any = res?.data?.linkedDocument?.find((doc:any)=> (doc?.document?.type === 'PASSPORT_AGREEMENT' || doc?.document?.type === 'ACCOUNT_AGREEMENT') )? true : false ;
          //  setShowOverlay(!agreement)

          updateComponentParams(dispatch, props.id, { showOverlay: !agreement, showSkeleton: false })
        } catch (e) {
          Logger.error("Home.tsx", "error", e);
        }

    }

  /**
  * function to get account details
  */
 async function getAccountInfo() {
   try {
     const listRequest = {
       "pageSize": 25,
       "pageNumber": 1,
       "sortOptions": {
         "sortOrder": "DESC",
         "sortBy": "createdOn"
       },
       criteria: {
         filters: []
       }
     };
     const accountList: any = await httpClient.post(getCustomerUrl('account/list', false), listRequest).then(response => {
       return response.data
     })
     const data: any = {
       'body': accountList
     }
     // Set the initial account in the storage
     setKey("customerInitialAccount",accountList?.resources?.[0] );
     dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'account-list-sub-menu' } });
     // Update the component parameters with the account list
     updateComponentParams(dispatch, "customer-basic-info", { 'accountList': accountList })
     // Check if the account list has more than one account
     if (accountList && accountList.totalCount > 1) {
       // Set the verification status, account status, and customer KYC in the storage
       setKey("verificationStatus", 'true');
       setKey("accountStatus", 'true');
       setKey("customerKYC", 'skip')
       // Check if the account list has an investment account
       if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER')){
         // Set hasInvestmentAccount flag as true to check current user have investment account or not
         updateComponentParams(dispatch, "customer-basic-info", { 'hasInvestmentAccount': true })
       }
       if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER_PLUS')){
        // Set hasCashBuilderAccount flag as true to check current user have Cash Builder+ account or not
        updateComponentParams(dispatch, "customer-basic-info", { 'hasCashBuilderAccount': true })
      }
       // Update the component parameters with the customer KYC
       updateComponentParams(dispatch, 'account-list-sub-menu', { 'customerKYC': 'skip' })
       // Dispatch the updated data
       dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'menu-top-not-small-accountlist-passport-component', data: data } })
       dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'menu-top-not-small-accountlist-passport-component', 'update': 'Account Updated' } });
       dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'account-list-sub-menu', data: data } })
       dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'account-list-sub-menu', 'update': 'Account Updated' } });
       updateComponentParams(dispatch, props.id, { showSkeleton: false })
     }
     // Check if the account list has only one account
     else if (accountList && accountList.totalCount === 1) {
       // Check if the account status is active
       if(accountList?.resources[0]?.status === 'ACTIVE'){
         // Format the default values
         defValueFormatter(accountList?.resources, dispatch);
         // Set the account status and customer KYC in the storage
         setKey("accountStatus", 'true');
         setKey("customerKYC", 'skip')
         // Update the component parameters with the customer KYC
         updateComponentParams(dispatch, 'account-list-sub-menu', { 'customerKYC': 'skip' })
         // Dispatch the updated data
         dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'menu-top-not-small-accountlist-passport-component', data: data } })
         dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'menu-top-not-small-accountlist-passport-component', 'update': 'Account Updated' } });
         dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'account-list-sub-menu', data: data } })
         dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'menu-drawer-collapse', 'update': 'Account Updated' } });
         dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'account-list-sub-menu', 'update': 'Account Updated' } });
         updateComponentParams(dispatch, props.id, { showSkeleton: false })
       }
       // Check if the user is a mxmOrSmbUser and the account status is inactive
       else if(uiFeat.getActivateAccount().available && accountList?.resources[0]?.status === 'INACTIVE'){
         // Get the details of the inactive account
         getInactiveAccountDetails(accountList?.resources[0]?.id);
       }else{
        updateComponentParams(dispatch, props.id, { showOverlay: true, showSkeleton: false })

         //setShowOverlay(true)
       }
     }
     else {
      //  setShowOverlay(true)
      updateComponentParams(dispatch, props.id, { showOverlay: true, showSkeleton: false })
       setKey("accountStatus", 'false');
     }
   }
    catch (error) {
      Logger.error("CustomerHomePassport.tsx", error)
    }
    finally {
     // Update the component parameters to hide the skeleton
     updateComponentParams(dispatch,  props?.id, {'showSkeleton':false })
    }
 }

  /**
   * checking the ui is mobile or laptop or other devices
   * returns true for mobile
   */
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' })

  /**
  * function to check permission
  */
  function checkPermissionsBlock(data: any) {
    const permissionObject: any = {
      'merchant': {
        entity: "Other Entities",
        name: "Merchant",
        operation: "VIEW"
      }
    }
    return checkPermission(permissionObject[data]);
  }

  /**
   * to get account 
   */
  const componentState = useSelector((state: any) => {
    if (state.data['account-list-sub-menu']) {
      return state.data['account-list-sub-menu']
    } else {
      return { config: { params: {} } };
    }
  });
    
  /**
   * to show/hide investment account banner 
   */
  const canShowInvestmentBanner = useMemo(() => {
    if(businessCustomer && activeAccountStatus && !hasInvestmentAccount){
      return true;
    }else{
      return false;
    }
  },[businessCustomer, activeAccountStatus, hasInvestmentAccount]);
  const isInvestmentAccountCreated = useSelector((state: any) => (state.data['investment-account-created']?.data?.isInvestmentAccountCreated || null));

  /**
   * to show/hide Cash Builder+ account banner 
   */
  const canShowCashBuilderBanner = useMemo(() => {
    return !jointTenancy && activeAccountStatus && !hasCashBuilderAccount && customerStatus === 'ACTIVE';
  },[jointTenancy, activeAccountStatus, hasCashBuilderAccount, customerStatus]);
  const isCashBuilderAccountCreated = useSelector((state: any) => (state.data['cash-builder-account-created']?.data?.isCashBuilderAccountCreated || null));

  /**
   * To get Home screen Grid
   * @returns grid
   */
  function getHomeScreen() {
      const selectedAccountId = componentState?.params?.selectedOption?.id;
    return <>
      <Grid item xs={12} > <QuickTransfer id="home-quick-transfer-card" /></Grid>
      <AccountTag id="activate-card-payments" data={sourceInfo?.params?.merchantList}/>
      {businessCustomer && getKey('accountStatus') == 'true' && checkPermissionsBlock('merchant') && isMerchantOnboardingEnabled && <Grid item xs={12} >
        <ActivateCardPayments id="activate-card-payments" data={sourceInfo?.params?.merchantList}/>
      </Grid>}
      {getKey("accountStatus") && selectedAccountId && <Grid item xs={12} ><DailyTransactionSummary id="home-daily-transaction-summary" selectedAccountId={selectedAccountId}/></Grid>}
    </>
  }

  /**
   * useEffect to load fast funding banner
   */
  useEffect(() => {
      if(isFastFundingEnabled) {
        FxTopBanner.closeForce('faster_funding_banner');
      }
      if(hasInvestmentAccount || isInvestmentAccountCreated) {
        FxTopBanner.closeForce('deposit_account');
      }
      if(hasCashBuilderAccount || isCashBuilderAccountCreated){
        FxTopBanner.closeForce('cash_builder_plus_account');
      }

      /**
      * This block of code handles the scenario when passport account is already created
      * for merchant but faster funding not enabled, so open the Faster Funding Drawer Automatically, when the merchant
      * logs into the passport portal
      */
      if(getKey('goPriorityCapitalUrl')){
        if (uiFeat.getFasterFunding().available && getKey('selectedAccount')?.status === 'ACTIVE' && !getKey('fundingViaPassport') && isFastFundingEnabled === false && isMerchantLoggedIn) {
          bannerCarouselData.push({id: 'faster_funding_banner', component: 'topFasterFundingcontent', isMobileScreen: isMobileScreen, clicked: true})
        }
      }
      else if(uiFeat.getFasterFunding().available && getKey('selectedAccount')?.status === 'ACTIVE' && !isFastFundingEnabled && !getKey('fundingViaPassport') && isMerchantLoggedIn){
        bannerCarouselData.push({id: 'faster_funding_banner', component: 'topFasterFundingcontent', isMobileScreen: isMobileScreen})
      }

      const fasterFundingAvailable = uiFeat.getFasterFunding().available;
      const isFasterFundingEnabled = fasterFundingAvailable && fastFundingEnabled && isMerchantLoggedIn;
      const isFasterFundingDisabled = !(fasterFundingAvailable && isMerchantLoggedIn);

      //  to open Cash Builder+ Banner
      if(canShowCashBuilderBanner && !isCashBuilderAccountCreated && getKey('enableCashBuilderAccount') === 'true' && getKey('role') === 'ADMIN'){
        if(isFasterFundingEnabled || isFasterFundingDisabled){
          bannerCarouselData.push({id: 'cash_builder_plus_account', component: 'topCashBuilderBanner'})
        }
      }

      //  to open Treasure manual
      if(canShowInvestmentBanner && !isInvestmentAccountCreated && getKey('enableInvestmentAccount') === 'true' && getKey('role') === 'ADMIN'){
        if(isFasterFundingEnabled || isFasterFundingDisabled){
          bannerCarouselData.push({id: 'deposit_account', component: 'topTreasuryBanner'})
        }
      }
      if(bannerCarouselData.length > 0){
        updateComponentParams(dispatch, 'customer-basic-info', { 'topBannerData': bannerCarouselData })
        FxTopBanner.storeData(bannerCarouselData);
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFastFundingEnabled, canShowInvestmentBanner, fastFundingEnabled, isInvestmentAccountCreated, isMobileScreen, isCashBuilderAccountCreated, canShowCashBuilderBanner, getKey('fundingViaPassport')])

  return (
    <>
      {!!props?.showSkeleton ? <DashboardSkeleton/> :
        <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className={"fx-passport-home fx-container"} >
          <Grid container item xs={12} >
            {/* {showOverlay && <HomeOverlay id={'home-overlay-page'} />} */}
            {props.showOverlay && <HomeOverlay id={'home-overlay-page'}/>}
            {/**
             * Modal for confirmation popup before redirection to Priority Capital Page
             */}
            {<FxModalGeneric
                id={"fx-faster-funding-success-card"}
                open={props?.openPipeRedirectionModal}
                onClose={() => {
                  updateComponentParams(dispatch, 'customer-home-passport', { 'openPipeRedirectionModal': false })
                }}
                componentProps={{name: ''}}
                className={'fx-modal-small2medium'}
                component={PriorityCapitalAccessAllowedConfirmation}
            >
            </FxModalGeneric>}
          </Grid>
          <Grid container className="fx-container-left" >
            <Grid container xs={12} >
              <Grid item xs={12}>
                <MyAccount id="home-myaccount" />
              </Grid>
              <Grid item xs={12} >
                <HomeTreasureListCard id={'home-treasure-recent-transaction-list-card'} />
              </Grid>
              {isSmallScreen &&
                getHomeScreen()
              }
            </Grid>
          </Grid>
          {!isSmallScreen &&
            <Grid container className="fx-container-right">
              <Grid container xs={12} >
                {getHomeScreen()}
              </Grid>
            </Grid>
          }
        </Grid>}
    </>

  )
})
