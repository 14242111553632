import React, { useCallback, useState }  from 'react';
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { toTitleCase } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("CancelSchedule.tsx", "Cancel Schedule initializing")
/**
 * This component handles the cancelling schedules
 */
export const CancelSchedule: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        const [commentRequired, setCommentRecuired] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory();
        const cancellationData = props.schedule !== 'recurring'? [
            { label: 'Incorrectly created', value: 'INCORRECTLY_CREATED' },
            { label: 'Others', value: 'OTHERS' }
        ]:[
            { label: 'Incorrectly created', value: 'INCORRECTLY_CREATED' },
            { label: 'Paid Externally', value: 'PAID_EXTERNALLY' },
            { label: 'Service Contract Terminated', value: 'SERVICE_CONTRACT_TERMINATED' },
            { label: 'Others', value: 'OTHERS' }
        ]
        /**
         * Async function handles the api call for cancelling schedule
         * @param req : form request
         * @returns : response object
         */
        async function cancelSchedule(req: any) {

            try {
                let url: any = 'transaction/id/' + props.scheduleId + '/cancel';
                if(props.schedule === 'recurring'){
                    url=   'transaction/' + props.schedule + '/id/' + props.scheduleId + '/cancel';
                }
                const data: any = await httpClient.post(getCustomerUrl(url,false), req)
                return data;
            } catch (err) {
                Logger.error("CancelSchedule.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles the cancelling form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                'reason': data.reasonforCancellation,
                'comment': data.comment
            }
            setIsLoading(true);
            let status: any = await cancelSchedule(req);
            setIsLoading(false);
            status = processAPIResponse(status);
            let successMessage = toTitleCase(props.schedule) + 'Cancelled Successfully';
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(props.schedule === 'recurring' ? '/recurring' : '/transactions');
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };
        /**
        * Method to handle changes on event
        * @param event : event type
        */
        const handleChange = useCallback((event: any) => setCommentRecuired(event?.target.value === 'OTHERS'), []);

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="cancel-schedule-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-cancel-schedule-modal" variant="h4">
                                            Are you sure to cancel this {toTitleCase(props.schedule)} ?
                                        </Typography>
                                        <Typography id="sub-title-cancel-schedule-modal" variant="h6">
                                            You cannot undo this action
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='cancel-schedule-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="cancel-schedule-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxMaterialSelect
                                            id="reasonforCancellation"
                                            register={{ ...register("reasonforCancellation") }}
                                            className={errors.reasonforCancellation ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            rules={{ required: true }}
                                            control={control}
                                            name="reasonforCancellation"
                                            data={cancellationData}
                                            label="Reason For Cancellation *"
                                            setValue={setValue}
                                            onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit
                                            register={{ ...register("comment") }}
                                            className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            control={control}
                                            rules={{ required: commentRequired }}
                                            id="comment"
                                            label={commentRequired ? "Comment *" :"Comment"}
                                            name="comment"
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="cancel-schedule-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme fx-button-delete"
                                        id={"cancel-" + props.schedule + "-schedule"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                              props.schedule === 'recurring'?  'Cancel Recurring':  'Cancel Transaction'
                                            )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
