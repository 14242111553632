import * as React from "react";
import { ReactComponent as LogoNameIconOrangeBlack } from '../../assets/svg/logo/logo-passport.svg';
import { ReactComponent as LogoNameIconOrangeWhite } from '../../assets/svg/logo/logo-passport-menu.svg';
import { ReactComponent as LogoNameIconGreenWhite } from '../../assets/svg/logo/passportNameLogoGreenWhite.svg';
import { ReactComponent as LogoNameIconGreenBlue } from '../../assets/svg/logo/passportNameLogoGreenBlue.svg';
import { ReactComponent as LogoCircleIconGreenBlue } from '../../assets/svg/logo/passportCircleLogoGreenBlue.svg';
import { ReactComponent as LogoCircleIconGreenWhite } from '../../assets/svg/logo/passportCircleLogoGreenWhite.svg';
import { ReactComponent as LogoCircleIconMobOrangeBlack } from '../../assets/svg/logo/logo-passport-mobile.svg';
import { ReactComponent as LogoCircleIconOrangeWhite } from '../../assets/svg/logo/leftmenu-passport-logo.svg';
import { ReactComponent as LogoCircleIconOrangeBlack } from '../../assets/svg/logo/passport-account-logo.svg';
import { ReactComponent as LogoCircleIconOrangeBlue } from '../../assets/svg/logo/passport-logo-orenge-blue.svg';
import { ReactComponent as LogoNameIconOrangeBlue } from '../../assets/svg/logo/passport-logo-name-orange-blue.svg';
import { useMediaQuery } from 'react-responsive'

import { Logger } from "../../libs/utils/logger";
import { getKey } from "../../libs/utils/storageManager";


Logger.debug("Logo.tsx", "Fx logo initializing")

export const Logo: React.FC<any> = (props: any) => {
   
    const isSmallScreen = useMediaQuery({ query: '(max-width: 800px)' })
    return (
     
      isSmallScreen && !props.showFull ?
      GetLogoNameIconMobile(props) 
         :
        GetLogoNameIcon(props)
      
    )
  }
export const LogoMenu: React.FC<any> =
  (props: any) => {
  
    return (
      !props.DrawerCollapse ?
      GetMenuIcon(props):
      GetMenuCollapse(props)

    )
  }
  
  export const LogoAccount: React.FC<any> =
  (props: any) => {
  
    return (
      props.noColor ?
      GetAccountWhiteIcon(props):
      GetAccountIcon(props)
      
    )
  }

function GetAccountIcon(props: any) {
    const AccountIcon = getKey('theme');
    let AccountIconComponent;

    switch (AccountIcon) {
      case "passport":
        AccountIconComponent = props.blueIcon ? LogoCircleIconOrangeBlue : LogoCircleIconOrangeBlack
        break;
      case "mxm":
        AccountIconComponent = LogoCircleIconGreenBlue
        break;
      default:
        AccountIconComponent = LogoCircleIconOrangeBlack
    }
    return (<AccountIconComponent {...props} />)
  }

function GetAccountWhiteIcon(props: any) {
  const AccountIcon = getKey('theme');
  let AccountIconComponent;

  switch (AccountIcon) {
    case "passport":
      AccountIconComponent = LogoCircleIconOrangeBlack
      break;
    case "mxm":
      AccountIconComponent = LogoCircleIconGreenBlue
      break;
    default:
      AccountIconComponent = LogoCircleIconOrangeBlack
  }
  return (<AccountIconComponent {...props} />)
}

function GetMenuIcon(props: any) {
  const menuIcon = getKey('theme');
  let MenuIconComponent;
  switch (menuIcon) {
    case "passport":
      MenuIconComponent = LogoNameIconOrangeWhite
      break;
    case "mxm":
      MenuIconComponent = LogoNameIconGreenWhite
      break;
    default:
      MenuIconComponent = LogoNameIconOrangeWhite
  }
  return (<MenuIconComponent {...props} />)
}

function GetMenuCollapse(props: any) {
  const menuIconCollapse = getKey('theme');
  let MenuIconCollapseComponent;
  switch (menuIconCollapse) {
    case "passport":
      MenuIconCollapseComponent = LogoCircleIconOrangeWhite
      break;
    case "mxm":
      MenuIconCollapseComponent = LogoCircleIconGreenWhite
      break;
    default:
      MenuIconCollapseComponent = LogoCircleIconOrangeWhite
  }
  return (<MenuIconCollapseComponent {...props} />)
}

function GetLogoNameIcon(props: any) {
  const logoIconKey = getKey('theme');
  let LogoIconComponent;
  switch (logoIconKey) {
    case "passport":
      LogoIconComponent = props.blueIcon ? LogoNameIconOrangeBlue : LogoNameIconOrangeBlack
      break;
    case "mxm":
      LogoIconComponent = LogoNameIconGreenBlue
      break;
    default:
      LogoIconComponent = LogoNameIconOrangeBlack
  }
  return (<LogoIconComponent {...props} />)
}

function GetLogoNameIconMobile(props: any) {
  const logoIconKey = getKey('theme');
  let LogoMenuIconComponent;
  switch (logoIconKey) {
    case "passport":
      LogoMenuIconComponent = LogoCircleIconMobOrangeBlack
      break;
    case "mxm":
      LogoMenuIconComponent = LogoCircleIconGreenBlue
      break;
    default:
      LogoMenuIconComponent = LogoCircleIconMobOrangeBlack
  }
  return (<LogoMenuIconComponent {...props} />)
}