import React, { useState } from 'react'
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { toTitleCase } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import { FxDateEdit } from '../../Input';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("PauseSchedule.tsx", "Pause Schedule initializing")
/**
 * This component handles the pausing recurring schedules
 */
export const PauseSchedule: React.FC<any> = React.memo(
    (props) => {

        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        const [isLoading, setIsLoading] = useState(false);
        const [mandateComment, setMandateComment] = useState(false);
        const history = useHistory()

        const pauseData = [
            { label: 'Paid Externally', value: 'PAID_EXTERNALLY' },
            { label: 'Service On Hold', value: 'SERVICE_ON_HOLD' },
            { label: 'Others', value: 'OTHERS' }
        ]
        /**
         * Async function handles the api call for pausing a recurring schedule
         * @param req : form request
         * @returns : response object
         */
        async function PauseSchedule(req: any) {

            try {
                const url: any = 'transaction/' + props.schedule + '/id/' + props.scheduleId + '/pause';
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("PauseSchedule.tsx", "error", err);
                return err;
            }
        }


        //handle change in case of reason is others
        const handleChange = (event: any) => {
            setMandateComment(event?.target?.value === 'OTHERS');
        }

        /**
         * Async function handles the pausing form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                'reason': data.reasonforPause,
                'resumeOn':data.resumeOn,
                'comment': data.comment
            }
            setIsLoading(true);
            let status: any = await PauseSchedule(req);
            setIsLoading(false);
            status = processAPIResponse(status)
            const backurl = '/recurring';
            const successMessage = toTitleCase(props.schedule) + 'Paused Successfully';
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(backurl)
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="pause-schedule-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-pause-schedule-modal" variant="h4">
                                            Are you sure to Pause this {toTitleCase(props.schedule)} ?
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='pause-schedule-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="pause-schedule-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxMaterialSelect register={{ ...register("reasonforPause") }} className={errors.reasonforPause ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} onChange={handleChange}  control={control} id="reasonforPause" name="reasonforPause" data={pauseData} label="Reason For Pause *" setValue={setValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxDateEdit register={{ ...register("resumeOn") }} control={control} rules={{ required: false, minDate: (new Date())  }} id="resumeOn" name="resumeOn" label="Resume On" setValue={setValue} disablePast={true} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("comment") }} className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}  control={control} rules={{ required: mandateComment  }} id="comment" label={mandateComment? "Comment*":"Comment"} name="comment" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="pause-schedule-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme fx-button-delete"
                                        id={"pause-" + props.schedule + "-schedule"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                                'Pause Recurring'
                                            )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
