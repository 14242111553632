import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { FxCarousel } from '../../Input/FxCarousel/FxCarousel';
import Slider from 'react-slick';

/**
 * Method help in styling of the arrow buttons of the carousel
 */
function SampleNextArrow() {
  return (
    <div
      style={{ display: "none"}}
    />
  );
}

/**
 * This component handles the Carousel Banner Component
 */
export const FxTopBannerView: React.FC<any> = React.memo(( props ) => {
    const bannerData = props?.bannerData;
    const [sliderRef, setSliderRef] = useState<any>()

    /**
     * This method contains the Theme based Banner styling
     * @param data 
     * @param index 
     * @returns 
     */
    const themeBanner = (data:any, index:any) => {
      return <Grid item container xs={12} className={'fx-top-banner carousel-banner-item'} display={'flex'} key={index}>
                <Grid item xs={6} className={'fx-top-banner-shapes-background fx-top-banner-left-side'}/>
                <Grid item xs={6} justifyContent={'flex-end'} className={'fx-top-banner-shapes-background fx-top-banner-right-side'}/>
                <Grid container item xs={12} className={'fx-top-banner-content'}>
                  {data}
                </Grid>
              </Grid>
    }

    /**
     * This method contains the new grey colored banner for the Cash Builder Account
     * @param data 
     * @param index 
     * @returns 
     */
    const depositBanner = (data:any, index:any) => {
      return <Grid item container xs={12} className={'fx-top-banner carousel-banner-item'} display={'flex'} key={index}>
                <Grid item xs={6} className={'fx-top-banner-shapes-background fx-top-banner-grey-left-side'}/>
                <Grid item xs={6} justifyContent={'flex-end'} className={'fx-top-banner-shapes-background fx-top-banner-grey-right-side'}/>
                <Grid container item xs={12} className={'fx-top-banner-content'}>
                    {data}
                </Grid>
              </Grid>
    }

  /**
   * Settings variable for the Slick Carousel
   */
  const settings = useMemo(() => {
    return {
      infinite: false,
      autoplay: true,
      speed: 2000,
      draggable: false,
      autoplaySpeed: 3000,
      cssEase: "linear",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SampleNextArrow />,
      afterChange: (currentSlide: any) => {
        if ((bannerData?.length > 1) && (currentSlide === bannerData?.length - 1)) {
          setTimeout(() => {
            sliderRef?.slickGoTo(0);
          }, 2000);
        }
      },
    }
  }, [sliderRef, bannerData])

    /**
     * This method returns array of banners to the Carousel Component
     * @returns JSX
     */
    const bannerDataRenderer = () => {
      return bannerData?.map((item:any, index:any)=>{
        if(item.id === 'deposit_account'){
          return depositBanner(item.component, index);
        }else{
          return themeBanner(item.component, index);
        }
      })
    }

    /** Setting the Carousel Ref in the sliderRef */
    const getSliderRef = (ref: Slider | null) => {
      setSliderRef(ref)
    }

    return (
      <Grid container className="fx-top-banner fx-carousel">
        <FxCarousel settings={settings} data={bannerDataRenderer} getSliderRef={getSliderRef} />
      </Grid>
    );
})
