/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useForm } from 'react-hook-form';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clean, updateComponentParams, deleteUnwantedRequest, renderStateError, createCommonVerifyProps } from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createSendMyAccount, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { FxSuccess } from '../../Data/FxSuccess';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { ScheduleTopSection } from './ScheduleTopSection';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input';
import usa_state from '../../Utils/usa_states.json';
import countries from '../../../libs/utils/country/country.json';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxSwiftCode } from '../../Input/FxSwiftCode/FxSwiftCode';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import {  setZipValidationOptions } from '../../Utils/CommonBaseClass';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
interface InputErrType {
    type: string;
    message: string;
}

Logger.debug("CreateSendOneTimeInternationalWire.tsx", "create send One Time International Wire initializing")

const acc_src = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

const holderTypeData = [
    { label: "Consumer", value: "CONSUMER" },
    { label: "Corporate", value: "CORPORATE" },
];

const accountType: any = [
    { label: "Savings", value: "SAVINGS" },
    { label: "Checking", value: "CHECKING" },
];

/**
 * This component handles the send creation through one time for International Wire method
 */
export const CreateSendOneTimeInternationalWire: React.FC<any> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const history = useHistory();
        const { register, formState: { errors }, handleSubmit, setValue, watch, control, setError, clearErrors, resetField } = useForm();
        const sendMyAccountInfo: any = context?.data?.body;
        let sendMyAccountId: any = '';
        const params = useParams<any>();
        const passedOtherSendId = params.othersId;
        const scheduleId = params?.id;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const [location, setLocation] = useState<any>();
        const method_Tab = useMemo(() => {
            const tabs = [
                {
                    title: 'ACH',
                    link: '/transaction/send/onetime/add/ach',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Ach Send",
                        operation: "CREATE"
                    }
                },{
                    title: "WIRE",
                    link: "/transaction/send/onetime/add/wire",
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Wire Send",
                        operation: "CREATE"
                    }
                  },
                {
                    className: 'fx-link-sel',
                    title: 'INTERNATIONAL WIRE',
                    link: '/transaction/send/onetime/add/internationalWire',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "International Wire Send",
                        operation: "CREATE"
                    }
                },
                // {
                //     title: 'CHECK',
                //     link: '/transaction/send/onetime/add/check',
                //     disabled: !!scheduleId,
                //     permission: {
                //         entity: "Accounts",
                //         name: "Check Send",
                //         operation: "CREATE"
                //     }
                // },
                {
                    title: 'BOOK',
                    link: '/transaction/send/onetime/add/book',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Book Send",
                        operation: "CREATE"
                    }
                }
            ];

            // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
            if (getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer) {
                tabs.push({
                    title: "VIRTUAL CARD",
                    link: "/transaction/send/onetime/add/virtualcard",
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Virtual Card Send",
                        operation: "CREATE"
                    }
                })
            }

            return tabs;
        }, [scheduleId]);


        const [isloading, setIsloading] = useState(false);
        const [routingCodeDetails, setRoutingCodeDetails] = useState<any>();

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if (scheduleId) {
                const src = {
                    url: "/transaction/id/" + scheduleId,
                    method: "GET",
                };
                dispatch({
                    type: "DATA_API_PARAM_CHANGED",
                    payload: { id: "edit-send-to-one-time-internationalWire", source: src },
                });
                updateComponentParams(dispatch, "edit-send-to-one-time-internationalWire", {
                    isEdit: true,
                });
            } else {
                dispatch({
                    type: "DATA_UPDATE_COMPONENT_REDUCER",
                    payload: {
                        id: "page-title-details",
                        data: { title: "Send Money", backButton: "/dashboard" },
                    },
                });
            }
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
        }, []);

        /**
        * useEffect to set edit data details
        */
        useEffect(() => {
            if (context?.data?.body) {
                setFormValues(context?.data?.body);
            }
            if(context?.data?.body?.processingDetail?.location){
                getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
            }
        }, [context?.data?.body]);

        /**
        * Method setting the default values for the text edit component from the context data
        */
        function setFormValues(data: any) {
            setValue("amount", data?.amount);
            setValue("purpose", data?.purpose);
            setValue("currency", data?.currency);
            setValue("memo", data?.processingDetail?.memo);
            if (data?.destination?.internationalExternalAccount) {
                setValue("holderType", data?.destination?.internationalExternalAccount?.holderType)
                setValue("swiftCode", data?.destination?.internationalExternalAccount?.swiftCode)
                setValue("internationalRoutingCode", data?.destination?.internationalExternalAccount?.internationalRoutingCode)
                setValue("accountHolderName", data?.destination?.internationalExternalAccount?.holderName)
                setValue("accountNumber", '****' + data?.destination?.internationalExternalAccount?.accountNumberLast4)
            }
            if (data?.destination?.internationalExternalAccount.holderAddress) {
                if (data?.destination?.internationalExternalAccount.holderAddress?.country?.toLowerCase() === "us") {
                    setValue("unitedState", data?.destination?.internationalExternalAccount?.holderAddress?.state);
                } else {
                    setValue("nonUnitedState", data?.destination?.internationalExternalAccount?.holderAddress?.state);
                }
                setValue("country", data?.destination?.internationalExternalAccount?.holderAddress?.country)
                setValue("city", data?.destination?.internationalExternalAccount?.holderAddress?.city)
                setValue("zip", data?.destination?.internationalExternalAccount?.holderAddress?.zip)
                setValue("addressLine1", data?.destination?.internationalExternalAccount?.holderAddress?.addressLine1)
                setValue("addressLine2", data?.destination?.internationalExternalAccount?.holderAddress?.addressLine2)
            }
        }

        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_IWP_INVALID_LENGTH;
            }
            return err.message;
        };
        // account number validation constant
        const setAccountNumberValidation = {
            required: true,
            maxLength: 34
        }

        function getAllCurrencies() {
            return [{ label: "USD", value: "USD" }]
        }

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {
                source: {
                    account: {
                        id: data.source
                    }
                },
                method: 'INTERNATIONAL_WIRE',
                type: 'REGULAR',
                amount: data.amount,
                purpose: data.purpose,
                currency: data.currency,
            };
            if (!scheduleId) {
                if (data.accountHolderName) {
                    req["destination"] = {
                        internationalExternalAccount: {
                            type: data.accountType,
                            holderName: data.accountHolderName,
                            accountNumber: data.accountNumber,
                            holderType: data.holderType,
                            swiftCode: data.swiftCode,
                            ...(routingCodeDetails?.internationalCodeLabel && data.internationalRoutingCode && { internationalRoutingCode: data.internationalRoutingCode }),
                            "holderAddress": {
                                state: data.country !== "US" ? data.nonUnitedState : data.unitedState,
                                country: data.country,
                                city: data.city,
                                addressLine1: data.addressLine1,
                                addressLine2: data.addressLine2,
                                zip: data.zip
                            }
                        },
                    };
                }

                if (req?.destination?.internationalExternalAccount?.holderAddress?.state === '') {
                    delete req.destination.internationalExternalAccount.holderAddress.state;
                }
                if (req?.destination?.internationalExternalAccount?.holderAddress?.zip === '') {
                    delete req.destination.internationalExternalAccount.holderAddress.zip;
                }
            }

            if (data.memo) {
                req['processingDetail'] = {};
                req['processingDetail']['memo'] = data.memo;
            }
            if(data['location']){
                req['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }
            if (props.isEdit) {
                req["id"] = context?.data?.body?.id;
                const unwantedRequestArray = ["source", "destination", "method", "type"];
                deleteUnwantedRequest(req, unwantedRequestArray);
            }
            req = clean(req);
            setIsloading(true);
            let status: any;
            status = await createSendMyAccount(req);
            if (status?.headers?.url) {
                sendMyAccountId = (status.headers.url).split('/').pop();
            }

            status = processAPIResponse(status)
            setIsloading(false);
            if (status.status) {
                //api success
                const successMessage = sendMyAccountInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
                const redirectUrl = sendMyAccountInfo? `/transactions/send/${sendMyAccountInfo["id"]}`: '/thanks/transactions/send-onetime-internationalWire/';
                const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                if(status.responseData?.sessionId){
                    const verifyProps = {
                        ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
                        successMessage : successMessage,
                        redirectUrl: redirectUrl,
                        redirectUrlFromHeader:params?.id?false: true
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                if (sendMyAccountInfo) {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Send Updated Successfully!',
                    });
                    setTimeout(() => {
                        history.push('/transactions/send/' + sendMyAccountInfo['id'])
                    }, 1000);
                }
                else {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Send Created Successfully!',
                    });
                    setTimeout(() => {
                        history.push('/thanks/transactions/send-onetime-internationalWire/' + sendMyAccountId)
                    }, 1000);
                }
            }
        }
            else {
                //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
            }

        }

        const handleClose = () => {
            if (scheduleId) {
                history.push("/transactions/send/" + scheduleId);
            } else {
                history.push("/dashboard");
            }
        };

        /**
         * Transaction summary object
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: accountLabelTransformation(getKey('selectedAccount'))
            },
            'To': {
                id: 'to',
                value: watch('accountHolderName'),
            },
            ...(businessCustomer
            ? { Purpose: { id: "purpose", value: watch("purpose") } }
            : { Memo: { id: "purpose", value: watch("purpose") } }),
            'Via': {
                id: 'method',
                value: 'INTERNATIONAL WIRE',
            },
            'To International External Account': {
                id: 'to',
                value: watch('accountNumber'),
            },
            'Currency': {
                id: 'currency',
                value: 'USD',
            }
        };

        if(businessCustomer) {
            summaryData['Memo'] = {
                id: 'memo',
                value: watch('memo'),
            }
        }

        const tabClickFunction = () => {
            try {
                const data: any = {
                    schedule: 'send',
                    type: 'one-time',
                    formData: { amount: watch('amount'), purpose: watch('purpose') }
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch (e) { }
        }
          /**
         * Method to handle error
         * @param err
         * @returns
         */
          const renderZipError = (err: InputErrType): string => {
            return err.message;
        };
             /**
             * Function to handle the closing of the modal.
             */
	
            const handleCloseModal = () => {
                let verifyProps = {...props.verifyProps}
                verifyProps['open'] = false;
                updateComponentParams(dispatch, props.id, { verifyProps });
            };

        return (
          <>
            <ScheduleLayout
                id={'create-send-onetime-internationalWire'}
                type='send'
                loading={isloading}
                submitTitle={props.isEdit ? 'Save' : 'Send Money'}
                summaryData={summaryData}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => handleClose()}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                {passedOtherSendId && <FxSuccess id="sucess-to-others" header={"Send added successfully"} description={"Send ID - " + passedOtherSendId} />}
                <ScheduleTopSection id="create-send-one-time-internationalWire-schedule-top-section" schedule="send" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={{}} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-onetime-internationalWire-form-card-amount-textbox" sourceId="create-send-onetime-internationalWire-form-card-source" purposeId="create-send-onetime-internationalWire-form-card-purpose-textbox" />
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-send-one-time-internationalWire-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-onetime-internationalWire-form-card-tab" tabClick={tabClickFunction} />
                <Grid item>
                    <fieldset className="fx-container-create-schdule-summery">
                        <legend>Destination Details</legend>
                        <Grid item xs={12} sm={12} container spacing={2}>
                            <>

                                <Grid item xs={12} sm={6}>
                                    <FxMaterialSelect
                                        register={{ ...register("holderType") }}
                                        control={control}
                                        rules={{
                                            required: props.isEdit ? false : true,
                                        }}
                                        className={
                                            errors.holderType
                                                ? "border-error-input fx-input-edit"
                                                : "fx-input-edit"
                                        }
                                        id="create-onetime-internationalwire-form-card-account-purpose"
                                        data={holderTypeData}
                                        name="holderType"
                                        readOnly={props.isEdit ? true : false}
                                        label="Holder Type"
                                        setValue={setValue}
                                        value={
                                            context?.data?.body?.destination?.internationalExternalAccount
                                                ?.holderType
                                                ? context?.data?.body?.destination?.internationalExternalAccount?.holderType?.toUpperCase()
                                                : "CORPORATE"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FxTextEdit
                                        register={{ ...register("accountHolderName") }}
                                        className={
                                            errors.accountHolderName
                                                ? "border-error-input fx-input-edit"
                                                : "fx-input-edit"
                                        }
                                        control={control}
                                        rules={{ required: props.isEdit ? false : true }}
                                        id="create-send-onetime-holdername-internationalWire-onetime-form-card-amount-textbox"
                                        label="Holder Name*"
                                        name="accountHolderName"
                                        type="text"
                                        variant="outlined"
                                        defaultValue={
                                            context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                ? context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                : ""
                                        }
                                        isEditable={props.isEdit ? false : true}
                                    />
                                </Grid>
                            </>
                        </Grid>
                        <>
                            <fieldset className="fx-container-create-schdule-summery fx-container-create-schdule-onetime">
                                <legend>Holder Address Details</legend>
                                <Grid item container xs={12} sm={12} spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'create-onetime-internationalwire-address-add-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine1 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine1 : ''}
                                            isEditable={props.isEdit ? false : true} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'create-onetime-internationalwire-address-add-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine2 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine2 : ''}
                                            isEditable={props.isEdit ? false : true} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={{ required: true }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-onetime-internationalwire-address-add-city-textbox" name="city" label="City*" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city : ''} isEditable={props.isEdit ? false : true} />
                                    </Grid>
                                    {watch('country') === "US" ? <Grid item xs={12} sm={6}>
                                        <FxMaterialSelect register={{ ...register("unitedState") }} rules={{ required: false }} className={errors.unitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-onetime-internationalwire-address-add-state-textbox" name="unitedState" data={usa_state} label="State" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""}
                                            setValue={setValue} readOnly={props.isEdit ? true : false} />
                                    </Grid> : <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("nonUnitedState") }} control={control} rules={{ required: false, maxLength: 3 }} className={errors.nonUnitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-onetime-internationalwire-address-add-city-textbox" name="nonUnitedState" label="State" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""} isEditable={props.isEdit ? false : true} />
                                        <div className={'error-message'}>{errors.nonUnitedState && renderStateError(errors.nonUnitedState)}</div>
                                    </Grid>}
                                    <Grid item xs={12} sm={6}>
                                        <FxMaterialSelect register={{ ...register("country") }} rules={{ required: true }} className={errors.country ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-onetime-internationalwire-address-add-country-textbox" name="country" data={countries} label="Country*" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country : ""}
                                            setValue={setValue} readOnly={props.isEdit ? true : false} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(false,/^[a-zA-Z0-9]{1,9}$/)}  className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-onetime-internationalwire-address-add-zip-textbox" name="zip" label="Zip Code" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.zip || ""} isEditable={props.isEdit ? false : true} />
                                        <div className={'error-message'}>
                                            {errors.zip && renderZipError(errors.zip)}
                                        </div>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </>
                        <Grid item container xs={12} sm={12} spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <FxLabelView id="customer-signup-individual-info-label-create-onetime-internationalwire" className="" >BANK ACCOUNT INFORMATION</FxLabelView>
                            </Grid>
                            <Grid item container xs={12} sm={12} spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <FxMaterialSelect
                                        register={{ ...register("accountType") }}
                                        className={
                                            errors.accountType
                                                ? "border-error-input fx-input-edit"
                                                : "fx-input-edit"
                                        }
                                        rules={{ required: true }}
                                        control={control}
                                        id="create-onetime-internationalwire-form-card-account-type"
                                        name="accountType"
                                        data={accountType}
                                        value={
                                            context?.data?.body?.destination?.internationalExternalAccount?.type
                                                ? context?.data?.body?.destination?.internationalExternalAccount?.type?.toUpperCase()
                                                : "SAVINGS"
                                        }
                                        label="Account Type*"
                                        setValue={setValue}
                                        readOnly={props.isEdit ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FxSwiftCode register={{ ...register("swiftCode") }} setValue={setValue} className={errors.swiftCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-onetime-internationalwire-add-swift-number-textbox" label="Swift Code*" name="swiftCode" variant="outlined"
                                        defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.swiftCode || ""}
                                        isEditable={props.isEdit ? false : true} onSwiftCodeChange={(data: any) => {
                                            setRoutingCodeDetails(data);
                                            props.isEdit && setValue('swiftCode', context?.data?.body?.destination?.internationalExternalAccount?.swiftCode)
                                        }} />
                                </Grid>
                                {routingCodeDetails?.internationalCodeCharacterLength !== 0 && routingCodeDetails?.internationalCodeLabel && <Grid item xs={12} sm={12}>
                                    <FxTextEdit register={{ ...register("internationalRoutingCode") }} className={errors.internationalRoutingCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                                        rules={{ required: false }} id="iea-contact-add-routingcode-number-textbox"
                                        label={routingCodeDetails?.internationalCodeLabel || "International Routing Code"}
                                        name="internationalRoutingCode" variant="outlined" isEditable={props.isEdit ? false : true}
                                        defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.internationalRoutingCode || ''}
                                    />
                                </Grid>}
                                <Grid item xs={12} sm={12}>
                                    <FxTextEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAccountNumberValidation} control={control} id="create-onetime-internationalwire-add-account-number-textbox" label="International Bank Account Number*" name="accountNumber" variant="outlined" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.accountNumber ? context?.data?.body?.destination?.internationalExternalAccount?.accountNumber : ''} isEditable={props.isEdit ? false : true} />
                                    <div className={'error-message'}>
                                        {errors.accountNumber && renderAccountNumberError(errors.accountNumber)}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                <Grid item container xs={12}>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FxMaterialSelect
                        name={"currency"}
                        control={control}
                        rules={{ required: true }}
                        register={{ ...register("currency") }}
                        id="create-send-onetime-acceptedcurrency-internationalWire-form-card-destination"
                        value={context?.data?.body?.currency ? context?.data?.body?.currency : "USD"}
                        label="Currency"
                        data={getAllCurrencies()}
                        setValue={setValue}
                        readOnly={true}
                    />
                </Grid>
                {businessCustomer && <Grid item xs={12} sm={12}>
                    <FxTextEdit
                        register={{ ...register("memo") }}
                        className={
                            errors.memo
                                ? "border-error-input fx-input-edit"
                                : "fx-input-edit"
                        }
                        control={control}
                        rules={{ required: false }}
                        id="create-onetime-from-others-international-wire-form-card-internationalWire-company-description-textbox"
                        label="Memo"
                        name="memo"
                        variant="outlined"
                        defaultValue={
                            context?.data?.body?.processingDetail
                                ?.memo
                                ? context.data.body.processingDetail
                                    .memo
                                : ""
                        }
                    />
                </Grid>}
            </ScheduleLayout>
          </>
        )
    });
