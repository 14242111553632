import { put, takeEvery } from 'redux-saga/effects'
import { Logger } from "../utils/logger";
import HttpClient from "../utils/httpClient";
import { useSelector, useDispatch } from 'react-redux';
import { DATA_STATE } from '../reducer/dataReducer';
import { createUniqueId, getDataFromStorage, saveDataInStorage } from '../utils/cacheHandler';
import { getCustomerUrl } from '../utils/utils';
const httpClient = HttpClient.getClient();

interface ApiResponse {
    isError: boolean;
    error?: any;
    errorCode?: string;
    body?: any;
    status?: number;
    errorData?: any;
}

const getErrorMessage = (error: any) => {
    if (error.data?.errorMessage) {
        return error.data.errorMessage;
    }
    if (error?.response?.data?.errorMessage) {
        return error.response.data.errorMessage
    }

    if (error?.response?.data?.errorDescription) {
        return error.response.data.errorDescription
    }

    if (error.data?.errorDescription) {
        return error.data.errorDescription;
    }

    return null;


}
export default function* apiDataSaga() {
    yield takeEvery("DATA_API_REQUESTED", loadAPI);
    yield takeEvery("DATA_API_PARAM_CHANGED", loadAPI);
    yield takeEvery("DATA_UPDATE_COMPONENT", updateComponentData);

}

function* loadAPI(action: any): any {
    Logger.debug("ApiSaga", "info", "## loading api ", action);

    const apiResponse: any = yield apiSaga(action.payload.source);
    yield put({
        type: "DATA_API_FETCHED",
        payload: {
            id: action.payload.id,
            data: apiResponse,
            source: action.payload.source
        }
    });

}

/**
* 
* @param source source  contain api request details including method,
* Based on the method it will call the specific api method and wait for its response
*/


export function* apiSaga(source: any) {
    Logger.debug("ApiSaga", "Starting the apiSaga", source)
    let apiResponse: ApiResponse = {
        isError: false
    };
    let key = createUniqueId(source);
    let cacheStatus = 0;
    if (source.expiry) {

        apiResponse = getDataFromStorage(key, source.expiry);
        if (apiResponse != null && apiResponse.body != null) {
            cacheStatus = 1;
        }
    };

    if (cacheStatus === 0) {
        switch (source.method) {
            case "POST":
                apiResponse = yield postAPI(source.url, source.data, source.header,source);
                break;
            case "PUT":
                apiResponse = yield putAPI(source.url, source.data, source.header);
                break;
            case "GET":
            default:
                apiResponse = yield getAPI(source.url, source.header,source);
                break;

        }
    }

    if (apiResponse['status'] === 401 || apiResponse['errorCode'] === 'EC-AUTH-0001') {
        return;
    }
    if (source.expiry && cacheStatus === 0 && apiResponse.isError === false) {
        saveDataInStorage(key, apiResponse);
    }

    Logger.debug("ApiSaga", "Ending the apiSaga", source, apiResponse)
    return apiResponse;
}



/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * This is the basic function for the calling api by get method
 * it will return the data or error message
 */
export function* getAPI(url: string, header: any, source:any): any {
    if (url.includes(".json")) {
        return {
            body: yield fetch(url).then(response => response.json()),
            isError: false
        }
    }
        //some api doesn't require customer/id prefix
    const newHttpClient =  httpClient

    const response: ApiResponse = yield newHttpClient.get(getCustomerUrl(url,source.baseUrl), header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error) {
        return {
            status: error.response && error.response.data.errorCode,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.data?.errorCode ? error.data.errorCode : error.response && error.response.data.errorCode ? error.response.data.errorCode : null ,
            errorData: error.data?.errorData ? error.data.errorData : null
        }
    });
    return response;
}

/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* postAPI(url: string, data: any, header: any,source:any): any {
    if (header && header['Content-Type'] && header['Content-Type'] === 'multipart/form-data') {
        let formData = new FormData();
        formData.append("file", data);
        data = formData;
    }

    //some api doesn't require customer/id prefix
    const newHttpClient =  httpClient

    const response: ApiResponse = yield newHttpClient.post(getCustomerUrl(url,source.baseUrl), data, header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.response && error.response.data.errorCode,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.response && error.response.data && error.response.data.errorCode ? error.response.data.errorCode : null,
            errorData: error.response && error.response.data.errorData ? error.response.data.errorData : null
        }
    });
    return response;
}

/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* putAPI(url: string, data: any, header: any): any {
    const response: ApiResponse = yield httpClient.put(url, data, header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.response && error.response.data.errorCode,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.response && error.response.data && error.response.data.errorCode ? error.response.data.errorCode : null,
            errorData: error.response && error.response.data.errorData ? error.response.data.errorData : null
        }
    });
    return response;
}


/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* deleteAPI(url: string, data: any, header: any): any {
    const response: ApiResponse = yield httpClient.delete(url, data).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.status,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.data?.errorCode ? error.data.errorCode : null,
            errorData: error.data?.errorData ? error.data.errorData : null
        }
    });
    return response;
}


//register the component for data
export function RegisterComponent(props: any) {

    const dispatch = useDispatch();
    const state = useSelector((state: any) => {
        if (props.id && state.data[props.id]) {
            return state.data[props.id];
        }
        else {
            return null;
        }
    });


    if (state) {
        if (state.data_state === DATA_STATE.STATUS_INITIAL && props.source) {
            //load the api data
            dispatch({ type: "DATA_API_REQUESTED", payload: props });
        }
        props = { ...props, ...state.params };
    }
    else {
        dispatch({ type: "DATA_COMPONENT_INIT", payload: props });
    }

    return { context: state, props: props };

}

function* updateComponentData(action: any) {
    yield put({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: action.payload });
}
