import React, { useEffect, useState } from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { useForm } from "react-hook-form";
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/delete-icon.svg';
import { FxButton } from "../../../Action/FxButton";
import { FxSwitch } from "../../../Action/FxSwitch";
import { FxMultiSelectWeek } from "../../../Input/FxMultiSelectWeek/FxMultiSelectWeek";
import { nth } from "lodash";
import { useMediaQuery } from "react-responsive";



Logger.debug("FxOnDayRecurring.tsx", "Fx on day Recurring initializing")
/**
 *  This component handles the fx on day recurring
 */

const typeSelectData = [
    {label: 'Calendar Day', value: 'CALENDAR_DAY'},
    {label: 'Business Day', value: 'BUSINESS_DAY'},
    {label: 'Week', value: 'WEEK'}
];
const orderSelectDataCalendar = [
    {label: 'Last', value: 'LAST'}
];
const orderSelectDataBusiness = [
    {label: 'First', value: 'FIRST'},
    {label: 'Last', value: 'LAST'}
];
const orderSelectDataWeek = [
    {label: 'First', value: 'FIRST'},
    {label: 'Second', value: 'SECOND'},
    {label: 'Third', value: 'THIRD'},
    {label: 'Fourth', value: 'FOURTH'},
    {label: 'Fifth', value: 'FIFTH'},
    {label: 'Last', value: 'LAST'}
];

export const FxOnDayRecurring: React.FC<any> = React.memo(
    (props: any) => {
        const { register, setValue, control } = useForm();
        const [onDay, setOnDay] = useState<any>(props.data);
        const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

        // Method passes the form data on order change
        const handleOrderChange = (event: any, index: any) => {
            const copyDay:any = [...onDay];
            const item = nth(copyDay, index) as any;
            if(item) {
                item.order = event.target.value;
            }
            setOnDay(copyDay);
            props.onSubmit?.(copyDay);
        }
        // Method passes the form data on type change
        const handleTypeChange = (event: any, index: any) => {
            const copyDay:any = [...onDay];
            const item = nth(copyDay, index) as any;
            if(item) {
                item.type = event.target.value;
            }
            setOnDay(copyDay);
            props.onSubmit?.(copyDay);

        }
        // Method handles the addition of the new element
        const handleAdd = (event: any, index: any) => {
            try{
                let copyDay:any = [...onDay];
                copyDay.push({order: 'FIRST', type: 'CALENDAR_DAY'});
                props.onSubmit?.(copyDay);
            }
            catch(e){}

        }
        // Method handles the removal of the new element
        const handleDelete = (event: any, index: any) => {
            try{
                 let copyDay:any = [...onDay];
                 copyDay.splice(index, 1);
                 props.onSubmit?.(copyDay);
            }
            catch(e){}

        }
        // Method handles the switch change event
        const handleSwitch = (value: any) => {
            if(props.onDaySwitchChange){
                props.onDaySwitchChange(value)
            }
        }

        const handleUpdateFormData = (value: any, index: any) => {
            const copyDay:any = [...onDay];
            const item = nth(copyDay, index) as any;
            if(item) {
                item.week = value;
            }
            setOnDay(copyDay);
            props.onSubmit?.(copyDay);
        }
        /**
         * Method updates the onDay data whenever the props.data changes
         */
        useEffect(()=>{
            if(props.data){
                setOnDay(props.data)
            }
        },[props.data])

        return (
            <Grid item xs={12} className="fx-repeat-payment-month">
                 <Grid item xs={12}>&nbsp;&nbsp;</Grid>
                <Grid item xs={12}>
                    On Day <FxSwitch id='on-day-recurring-switch' value={props.switchValue} onClick={handleSwitch}></FxSwitch>
                </Grid>
            {props.switchValue && onDay?.map((item: any, index: any) => {
            return <Grid item container xs={12} spacing={2} alignItems ='center'>
                <Grid item xs={5}>
                    <FxMaterialSelect register={{ ...register('order'+index) }} key={Math.floor(Math.random() * 100) +'order'} className="" control={control} onChange={(e: any) => handleOrderChange(e,index)} id={props.id +'-'+index+ '-order-select'} name={"order"+index} value={item?.order?item.order:''} data={item?.type === 'CALENDAR_DAY'?orderSelectDataCalendar: item?.type === 'BUSINESS_DAY'? orderSelectDataBusiness: item?.type === 'WEEK'? orderSelectDataWeek:orderSelectDataCalendar} setValue={setValue} />
                </Grid>
                <Grid item xs={isMobile ? 5.5 : 5}>
                    <FxMaterialSelect register={{ ...register('type'+index) }} key={Math.floor(Math.random() * 100) +'type'} className="" control={control} onChange={(e: any) => handleTypeChange(e,index)} id={props.id + '-'+index+'-type-select'} name={"type"+index} value={item?.type?item.type:''} data={typeSelectData} setValue={setValue} />
                </Grid>
                <Grid item xs={isMobile ? 1.5 : 2} display='flex' justifyContent={isMobile ? 'end' : ''}>
                    {(onDay.length-1) === index?<FxButton id={props.id+'-'+index+'-add'} title="Add" className="fx-button fx-button-lite" variant="contained" onClick={(e: any) => handleAdd(e,index)} startIcon={<AddIcon />} >
                    </FxButton>:<FxButton id={props.id+'-'+index+'-delete'} title="Delete" className="fx-button" variant="contained" onClick={(e: any) => handleDelete(e,index)} startIcon={<DeleteIcon />} >
                    </FxButton>}

                </Grid>
                {item.type === 'WEEK'&&<Grid item xs={12} className="fx-repeat-payment-week">
                    <FxMultiSelectWeek register={{ ...register("weekRange"+index) }} control={control}  id={props.id + '-'+index+'-type-week-range'} key={props.id + '-'+index +'-week'} name={"weekRange"+index} label="" week={true} updateFormData={(e: any) => handleUpdateFormData(e,index)} data={item['week']}/>
                </Grid>}
                <Grid item xs={12} sm={12}></Grid>
            </Grid>
            })}
            </Grid>
        )
    })
