import React from 'react'
import { Grid, Typography } from '@mui/material';
import { ReactComponent as ThanksIcon } from '../../../assets/svg/thanks.svg';
import { Logger } from '../../../libs/utils/logger';
import Copyright from '../Auth/Copyright';
import { Logo } from '../../Utils/Logo';


Logger.debug("SignUpSuccessRegistration.tsx", "sign up success registration initializing")
/**
 * This component handles the thank you page. Create pages are getting redirected to thank you page after successful api call
 */
export const SignUpSuccessRegistration: React.FC<any> = React.memo(() => {
        return (
            <Grid item xs={12} container direction="row" className='fx-form-grid'>
                <Grid item xs={12} container direction="row" alignContent="flex-start" sm={12}  >
                    <div className="login-page">
                        <Grid xs={12} sm={10} md={5} className={"login-page-content-signup"}>
                            <div className="login-logo">
                                <Logo showFull />
                            </div>
                            <Grid item xs={12}>&nbsp;</Grid>
                            <Grid item xs={12}>&nbsp;</Grid>
                            <Grid id="thank-you-Grid" item xs={12} className="">
                                <Grid id="thank-you-page-body" className="fx-info-card fx-thankyou fx-thankyou-signup" >
                                    <Grid item container justifyContent="center" xs={12}>
                                        <ThanksIcon className="fx-icon" height="15rem" />
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12} >
                                        <Typography id="title-thank-you" variant="h3" >Sign up successful!</Typography>
                                    </Grid>
                                    <Grid item container alignItems= "center" justifyContent="center" xs={12} >
                                        <Grid item xs={8} >
                                        <Typography id="title-thank-you-message" variant="h6">We have sent a mail to your registered email address with login instructions. </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="login-page-bottomstatic">
                            <Copyright />
                        </Grid>
                    </div>
                </Grid>

            </Grid>
        )
    });

