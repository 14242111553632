import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Modal } from '@mui/material';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxDateButtonGroupModal } from '../../../Page/Schedules/Recurring/FxDateButtonGroupModal';
import { ReactComponent as Calendar } from '../../../../assets/svg/Calendar.svg';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import { FxChip } from '../../../Input/FxChip/FxChip';

export const CalenderChip: React.FC<any> = (props: any) => {
    ({ props } = RegisterComponent(props));

    const [selectedDates, setSelectedDates] = useState<any>([]);
    const [changedValue, setChangedValue] = useState<any>();
    const [openModal, setModalOpen] = useState(false);

    const setDateValidationOptions = {
        required: selectedDates.length > 0 ? false : true,
        min: 1,
        max: 31
    };

    /**
     * Method to handle change date value
     */
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        if (event.target.value <= 31 && event.target.value >= 1) {
            let val = Math.floor(event.target.value) * 1;
            setChangedValue(val)
            props?.clearErrors('date')
            props?.setValue('date', val)
        } else {
            props?.setValue('date', '')
        }
    };
    /**
     * method to handle key press event
     * @param event :event
     */
    const handleKeyDownChange = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!selectedDates.includes(changedValue)) {
                setSelectedDates([...selectedDates, changedValue]);
            }
            const copySelectedDates = [...selectedDates];
            if (!selectedDates.includes(changedValue)) {
                copySelectedDates.push(changedValue)
            }
            updateFormData(copySelectedDates)
            props?.setValue('date', '')
        }
    }
    /**
     * method to pass data to parent
     * @param data :data
     */
    function updateFormData(data: any) {
        setSelectedDates(data);
        if (props.updateFormData) {
            props.updateFormData(data)
        }

    }
    /**
     * use effect to load edit data
     */
    useEffect(() => {
        const newValue: number[] = [];
        props?.data?.forEach((item: any) => {
            if(!item) {
                return;
            }
            const numberItem = Number(item);
            if(numberItem > 0 && numberItem < 32) {
                newValue.push(numberItem);
            }
        });
        setSelectedDates(newValue);
    }, [props?.data])


    /**
    * Method to handle delete date value
    */
    const handleDelete = (dateToDelete: any) => {
        setSelectedDates(selectedDates.filter((date: any) => date !== dateToDelete));
        const copySelectedDates = [...selectedDates];
        const index = copySelectedDates.indexOf(dateToDelete);
        if (index > -1) {
            copySelectedDates.splice(index, 1);
        }
        if (props.updateFormData) {
            props.updateFormData(copySelectedDates)
        }
    };

    /**
    * method handles modal open or close
    */
    const handleCalendarClick = () => {
        setModalOpen(!openModal)
    }

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems='center' >
            <Grid item xs={12}> &nbsp;</Grid>
            <Grid item xs>
                <FxTextEdit register={{ ...props?.register("date") }} className={props?.errors.date ? "border-error-input fx-input-edit" : "fx-input-edit"} control={props?.control} rules={setDateValidationOptions} id="date-textbox" label="Date" name="date" type="number" onChange={handleDateChange} onKeyDown={handleKeyDownChange} variant="outlined" showDecimal={false} />
            </Grid>
            <Grid item xs>
                <IconButton onClick={handleCalendarClick} id='calender-icon' size="large">
                    <Calendar />
                </IconButton>
            </Grid>
            <Modal
                style={{ overflow: 'scroll' }}
                open={openModal}
                onClose={handleCalendarClick}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small2medium"
            >
                <FxDateButtonGroupModal id={'button-group-modal'} close={handleCalendarClick} data={selectedDates} updateFormData={updateFormData} />
            </Modal>
            <Grid item container>
                <FxChip id={props?.id} data={selectedDates} handleDelete={handleDelete} />
            </Grid>
        </Grid>
    );
}
