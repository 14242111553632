import React from 'react';
import { Grid, Typography, Divider, Badge, Box } from '@mui/material';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { LogoMenu } from '../../../Utils/Logo';
import { ReactComponent as Verified } from '../../../../assets/svg/verify-filled-icon.svg';
import { FxLink } from '../../../Action/FxLink';
import { getKey } from '../../../../libs/utils/storageManager';
import { useMediaQuery } from 'react-responsive';


/**
 * Component: SignUpLeftBar
 */

export const JointTenancySignUpLeftBar: React.FC<any> = React.memo((props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  // menu options
  const options: any = [
    {
      title: "Verify Yourself",
      action: "verify_yourself",
      order: 1
    }
  ];
  options.push({title: "Add Co-Owner",action: "add_a_co-owner",order: 2 })
  if(getKey('isPPIEnabled') === 'TRUE'){
    options.push({title:  "Account Identifier",action: "account_identifier",order: 3 })
  }
  options.push({title: "Review and Submit",action: "review_and_submit",order: 4 })


  return (
<>
    {!isSmallScreen?
    <Grid id="login-promo-Home-Grid" item xs={12} >
    <FxCard id="login-promo-Home-Card" className="fx-login-promo">
      <FxCardHeader>
        <Grid container direction="row" justifyContent="space-between" >
          <Grid item xs={12} >
            <Typography id="business-owner-card-left-card-icon" variant="h3" >
              <Grid className="fx-logo-passport-menu-icon"><FxLink redirect="/dashboard"><LogoMenu /></FxLink></Grid>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography id="business-owner-card-left-card-title" variant="h2" >
              Open Joint Account
            </Typography>
          </Grid>
        </Grid>
      </FxCardHeader>
      <FxCardBody id="login-promo-Home-Card-Body" className="fx-card-general">
        <Grid item container xs={12} className='fx-signup-stage'>
        <Grid xs={12}>
              <Typography id="business-owner-card-left-card-sub-title" variant="h6" >
                VERIFY OWNERS’ DETAILS
              </Typography>
            </Grid>
          {options.map((link: any, index: any) => {
            return (
              <>
              {link.title === 'Review and Submit' && <Grid item xs={12}>
                    <Grid>&nbsp;</Grid>
                    <Typography id="business-owner-card-left-card-sub-title" variant="h6" >
                      SUBMIT
                    </Typography>
                  </Grid>}
                  <Grid item xs={12}>
                <ul id={'login-promo-icon-' + link.action}  >
                <li className={props.activeMenu === link.order ? 'fx-signup-stage-selected' : ''}>
                  <Grid item container alignItems="center" xs={12}>
                    <Grid item>
                      <Badge badgeContent={index + 1} >
                        <Box className='fx-signup-stage-icon'> {(((props?.activeMenu === 2) && (link.title !==  "Add Co-Owner" && link.title !==  "Account Identifier" && link.title !== 'Review and Submit' )) || ((props?.activeMenu === 3) && (link.title !==  "Account Identifier" && link.title !== 'Review and Submit' )) || ((props?.activeMenu === 4) && (link.title !== 'Review and Submit' ))) && <Verified />}</Box>
                      </Badge>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" className={'fx-list-item'}>
                        {link.title}
                      </Typography>
                    </Grid>
                  <ul>
                    {link.childOptions?.map((childLink: any) => {
                      return <li><Typography variant="h6" className={props.activeMenu === link.order ? 'fx-selected-list' : ''}>{childLink.title}</Typography></li>
                    })}
                  </ul>
                  </Grid>
                </li>

              </ul>
              </Grid>
              </>
             
            )
          })}
        </Grid>
        <Grid xs={12}>
          <Typography id="signup-left-bar-note" className={'fx-signup-left-bar-note'} >
            Note: Your progress is auto-saved.
          </Typography>
        </Grid>
      </FxCardBody>
    </FxCard>
  </Grid>
  :
  <Grid id="login-promo-Home-Grid" item xs={12} >
  <FxCard id="login-promo-Home-Card" className="fx-login-promo fx-login-promo-topmenu-mobile">
    <FxCardBody id="login-promo-Home-Card-Body" >
      <Grid item container xs={12} className='fx-signup-stage fx-signup-stage-mobile '>
        {options.map((link: any, index: any) => {
          return (
            <ul id={'login-promo-icon-' + link.action} className='fx-login-promo-list-box' >
              <li className={props.activeMenu === link.order ? 'fx-signup-stage-selected ' : ''}>
                <Grid item container alignItems="center" xs={12}> <Grid item className='fx-login-promo-list-box-item'>
                  <Badge badgeContent={index + 1} >
                    <Box className='fx-signup-stage-icon'> {(((props?.activeMenu === 2) && ( link.title !==  "Account Identifier" && link.title !==  "Account Identifier" && link.title !== 'Review and Submit' )) || ((props?.activeMenu === 3) && (link.title !== 'Review and Submit' ))) && <Verified />}</Box>
                  </Badge>
                </Grid><Grid item xs><Typography variant="h5">
                  {link.title}</Typography></Grid>
                <ul>
                  {link.childOptions?.map((childLink: any) => {
                    return <li><Typography variant="h6" className={props.activeMenu === link.order ? 'fx-selected-list' : ''}>{childLink.title}</Typography></li>
                  })}
                </ul>
                </Grid>
              </li>

            </ul>
          )
        })}
      </Grid>
    </FxCardBody>
  </FxCard>
</Grid>

    }
     </>
  )
})