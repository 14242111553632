import { DataSourceType } from "../utils/SourceProcessor";

/**
 * 
 * */
const ApiEndpoint = function () { return "entity/" };

export const RestEntityMapper = function (entityfri: string) {
    let entity = entityfri.replace("fri::entity:", "")
    return {
        "get": function (id: string): string {
            return ApiEndpoint() + entity + '/' + id
        },
        "update": function (id: string): string {
            return ApiEndpoint() + entity + '/' + id
        },

        "list": function (params: any): string {
            return ApiEndpoint() + entity + '/list'
        },
        create: function () { return ApiEndpoint() + "/" + entity; }

    }
}

export const UrlSourceMapper = function (url: string, method: string = 'GET', source: any = {}): any {
    let newSource = source;
    if (typeof source.api == "string") {
        source.api = { "url": source.api }
    }

    newSource.api = source.api ? source.api : {};
    newSource.type = DataSourceType.API;
    newSource.api.url = url;
    newSource.api.method = method;
    newSource.status = 0;
    return newSource;
} 
