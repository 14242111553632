import { Grid, Typography } from '@mui/material'
import React from 'react'
import { getKey } from '../../utils/storageManager'
import { Logo, LogoAccount } from '../../../components/Utils/Logo';
import { ReactComponent as DebitCardEnthusiastic } from '../../../assets/svg/debit-card-enthusiastic.svg';

/**
 * Component used to draw default debit card content for aply debit card
 * @returns 
 */

export default function DefaultContent() {
  return (
    <Grid item className={'fx-drawer-left-content fx-treasure-drawer-left-content'}>
    <Grid item xs={12} className={`fx-treasure-left-layout-header fx-treasure-left-layout-header-grey`}>
        <Grid item className='fx-account-summary-card-center-account-logo fx-logo'> <LogoAccount /></Grid> &nbsp;
        <Grid item xs={10}>
            <Typography variant='h2' className={'fx-welcome'}>Welcome!</Typography>
            <Typography variant='h3' className={'fx-username'}>{getKey("CustomerName") || getKey('CustomerIndividualFullName')}</Typography>
        </Grid>
    </Grid>
    <Grid container className={'fx-treasure-drawer-left-layout'}>
        <Grid item className='fx-treasure-left-layout-header-icon'>
            <Grid item xs={12} className="fx-treasure-passport-icon">
                <h1>Instant spending</h1>
                <h1>power at your</h1>
                <h1>finger tips with</h1>
                <div className='fx-passport-text-logo'><Logo /></div>
                <h1>debit cards</h1>
            </Grid>
            <Grid><DebitCardEnthusiastic /></Grid>
            <Grid item xs={12} >
                <Typography variant='h5'>Simple Pricing, No Hidden Charges</Typography>
            </Grid>
            <hr />
        </Grid>
    </Grid>
</Grid>
  )
}
