/* eslint-disable eqeqeq */
import React from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { ScheduleActions } from '../../ScheduleDetails/ScheduleActions'
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { getEAOrAddressDetailsFromContact, removeUnderScoreConvertTitleCase, maskAccountNumber } from '../../../Utils/CommonBaseClass';
import { SourceInfoCard } from './SourceInfoCard';
import { getKey } from '../../../../libs/utils/storageManager';
import { MerchantLocationCard } from '../../Merchants/MerchantLocationCard';
import { getCustomerUrl } from '../../../../libs/utils/utils';

Logger.debug("RecurringInfoCard.tsx", "RecurringInfoCard initializing")
/**
 * This component handles the view of the recurring details
 */
export const RecurringInfoCard: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let destinationInfo: any = {};
        let sourceCardInfo: any = {};
        let location_source : any;
        //   check if destination data is available
        if (context && context.data && context.data.body) {
            destinationInfo = { ...context.data.body };
        }
        const history = useHistory()
        const dispatch = useDispatch()
        let schedule_attributes_data: any = props?.data;
                //setting the processingDetail and transaction details
        if (schedule_attributes_data) {
            schedule_attributes_data['freequency']= toSetFrequency( schedule_attributes_data?.transactionDetail?.frequency);
            schedule_attributes_data['endValue']= toSetEndValue(schedule_attributes_data?.transactionDetail);
            schedule_attributes_data['repeatOn']= toSetRepeatOnValue(schedule_attributes_data?.transactionDetail);
            let transaction: any = schedule_attributes_data?.action[0]?.transaction
            if(transaction?.processingDetail && transaction?.processingDetail?.authType)
            {
                schedule_attributes_data.authType=transaction?.processingDetail?.authType
            }
            if(transaction?.processingDetail && transaction?.processingDetail?.addenda)
            {
                schedule_attributes_data.addenda=transaction?.processingDetail?.addenda?.[0]
            }
            if(transaction?.processingDetail && transaction?.processingDetail?.memo)
            {
                schedule_attributes_data.memo=transaction?.processingDetail?.memo
            }
            if (transaction?.processingDetail?.processingMode) {
                schedule_attributes_data.processingMode = transaction?.processingDetail?.processingMode == 'FORWARD' ? 'Next Day' : 'Same Day';
            }
            if (transaction?.processingDetail?.companyDescription) {
                schedule_attributes_data.companyDescription = transaction?.processingDetail?.companyDescription ? transaction.processingDetail.companyDescription : '';
            }
            if (transaction) {
                schedule_attributes_data.amount = transaction?.amount;
                schedule_attributes_data.method = transaction?.method;
                schedule_attributes_data.purpose = transaction?.purpose
            }

           //added for contact ea and address changes
            if(destinationInfo?.resourceName == 'contact')
            {
                let id = transaction?.method !== 'CHECK' ? transaction?.destination?.contact?.externalAccount?.id : transaction?.destination?.contact?.mailingAddress?.id;
                let destination= transaction?.method !== 'CHECK'?getEAOrAddressDetailsFromContact( destinationInfo,"EA",id,false):getEAOrAddressDetailsFromContact( destinationInfo,"ADDRESS",id,false);
                if(transaction?.method !== 'CHECK' && !props.data?.action[0]?.transaction?.source?.contact?.externalAccount?.id)
                {
                    destinationInfo.externalAccount=destination;
                }
                else
                {
                    destinationInfo.mailingAddress=destination;
                }
            }

        }
        if (destinationInfo && schedule_attributes_data.method !== 'CHECK') {
            destinationInfo.accountInfo = maskAccountNumber(destinationInfo?.accountNumber, false)
        }
        else
                //if method is check then address setting also processingMode
            if (destinationInfo && destinationInfo && schedule_attributes_data.method === 'CHECK') {
                destinationInfo['address'] = destinationInfo.addressLine1 + ' , ' + (destinationInfo?.addressLine2 ? destinationInfo?.addressLine2 + ' , ' : '') + destinationInfo.city + ' , ' + destinationInfo.state + ' , ' + destinationInfo.zip + ' , ';
                let transaction: any = schedule_attributes_data?.action[0]?.transaction
                if (transaction?.processingDetail?.deliveryMode) {
                    schedule_attributes_data.processingMode = transaction?.processingDetail?.deliveryMode
                }
            }
        if (destinationInfo && destinationInfo && schedule_attributes_data.method === 'BOOK') {
            destinationInfo['accountInfo'] = maskAccountNumber(destinationInfo['accountNumber'], false)
        }

        // Left section labels for Collect via contact ACH method
        let ach_left_section_label = [
            {
                label: 'Contact',
                value: 'contactName'
            },
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Account Type',
                value: 'type'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            }
        ];

        let ach_right_section_label = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },
            {
                label: 'Bank Name',
                value: 'bankName'
            }
        ]   

        let scheduleId = props?.data?.id;
        let scheduleInfo = props?.data;

        const card_left_section_label = [
            {
                label: 'Contact Name',
                value: 'contactName'
            },
            {
                label: 'Card Number',
                value: 'cardNumber'
            }
        ];
        
        const card_right_section_label = [
            {
                label: 'Card Holder Name',
                value: 'cardHolderName'
            },
            {
                label: 'Brand',
                value: 'cardBrand'
            }
        ];

        let destination_info_card_section = [
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            }

        ]
        let destination_info_external_account_section = [
            {
                label: 'Bank Name',
                value: 'bankInfo.name'
            },
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            }
        ]

        let audit_details_section = [
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ]
        let audit_details_section_two = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }
        ]

        let schedule_attributes_section = props.data && props.data.action[0].transaction.method === 'ACH'? [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Amount',
                value: 'amount',
                type: 'fri://fieldtype/currency'

            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Authorization Type',
                value: 'authType'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            },
            {
                label: 'Status Date',
                value: 'statusDate'
            },
            {
                label: 'Processing Mode',
                value: 'processingMode'
            },
            {
                label: 'Memo',
                value: 'companyDescription'
            },

        ]:props?.data?.action[0]?.transaction.method === 'CHECK'? [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Amount',
                value: 'amount',
                type: 'fri://fieldtype/currency'
            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            },
            {
                label: 'Status Date',
                value: 'statusDate'
            },
            {
                label: 'Delivery Mode',
                value: 'processingMode'
            },
            {
                label: 'Memo',
                value: 'memo'
            }
        ]:[
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Amount',
                value: 'amount',
                type: 'fri://fieldtype/currency'
            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            },
            {
                label: 'Status Date',
                value: 'statusDate'
            },
            {
                label: 'Memo',
                value: 'memo'
            }
        ]
        let schedule_attributes_section_book = [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Amount',
                value: 'amount',
                type: 'fri://fieldtype/currency'

            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            },
            {
                label: 'Status Date',
                value: 'statusDate'
            },
            {
                label: 'Memo',
                value: 'memo'
            }
        ]
        let schedule_attributes_section_two = [

            {
                label: 'Method',
                value: 'method',
                type: 'fri://fieldtype/paymentmodestatus'
            },
            {
                label: 'Start Date',
                value: 'transactionDetail.startDate',
                type: 'fri://fieldtype/date'
            },
            {
                label: 'Next transaction Date',
                value: 'nextTransactionDate',
                type: 'fri://fieldtype/date'
            },
            {
                label: 'End',
                value: 'endValue'
            },
            {
                label: 'Repeat Every',
                value: 'transactionDetail.interval'
            },
            {
                label: 'Frequency',
                value: 'freequency'
            },

        ]
        let address_info_section = [
            {
                label: 'Address',
                value: 'address'
            },


        ]

        let contact_section_left = [
            {
                label: 'Contact',
                value: 'name'
            },
            {
                label: 'Holder Name',
                value: 'externalAccount.holderName'
            },
            {
                label: 'Account Type',
                value: 'externalAccount.type'
            },
            {
                label: 'Holder Type',
                value: 'externalAccount.holderType'
            }
        ];

        let contact_section_right =[
            {
                label: 'Account Number',
                value: 'externalAccount.accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'externalAccount.routingNumber'
            },
            {
                label: 'Bank Name',
                value: 'externalAccount.bankInfo.name'
            }
        ]
        let contact_section_wire_right =[
            {
                label: 'Account Number',
                value: 'externalAccount.accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'externalAccount.routingNumber'
            },
            {
                label: 'Wire Routing Number',
                value: 'externalAccount.wireRoutingNumber'
            },
            {
                label: 'Bank Name',
                value: 'externalAccount.bankInfo.name'
            }
        ]
        let contact_section_check_left=[
            {
                label: 'Contact',
                value: 'name'
            },
            {
                label: 'Primary Address',
                value: 'mailingAddress',
                type: 'fri://fieldtype/address'
            }
        ]

        let contact_section_check_right=[
            {
                label: 'Shipped To',
                value: 'mailingAddress.name'
            },
            {
                label: 'Phone',
                value: 'mailingAddress.phone',
            }
        ]

        let contact_section_book_left=[
            {
                label: 'Contact',
                value: 'name'
            }
        ]

       const virtual_card_section_left = [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Amount',
                value: 'amount',
                type: 'fri://fieldtype/currency'

            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            },
            {
                label: 'Status Date',
                value: 'statusDate'
            }
        ];

        let virtual_card_destination_section_left = [
            {
                label: 'Contact',
                value: 'name'
            }
        ];

        let virtual_card_destination_section_right = [
            {
                label: 'Email',
                value: 'email'
            }
        ];

        // External Account source Data for Collect Via Contact Recurring ACH method
        if(props?.data?.method === 'ACH' && props.data?.action[0]?.transaction?.source?.contact?.url && props.data?.action[0]?.transaction?.source?.contact?.externalAccount?.id && destinationInfo?.externalAccount) {
            let externalAccountDetails = destinationInfo?.externalAccount?.find((externalAcc: any) => externalAcc.id === props.data?.action[0]?.transaction?.source?.contact?.externalAccount?.id);
            const contactName = destinationInfo?.name || '';
            destinationInfo = {};
            destination_info_card_section = ach_left_section_label;
            destination_info_external_account_section = ach_right_section_label;
            if(externalAccountDetails) {
                destinationInfo['contactName'] = contactName;
                destinationInfo['holderName'] = externalAccountDetails?.holderName;
                destinationInfo['holderType'] = externalAccountDetails?.holderType;
                destinationInfo['type'] = externalAccountDetails?.type;
                destinationInfo['accountNumberLast4'] = externalAccountDetails?.accountNumberLast4;
                destinationInfo['routingNumber'] = externalAccountDetails?.bankInfo?.routingNumber;
                destinationInfo['bankName'] = externalAccountDetails?.bankInfo?.name
            }
        }

        // External Account source Data for Collect Via Contact Recurring CARD method
        if(props?.data?.method === 'CARD' && props.data?.action[0]?.transaction?.source?.contact?.url && props.data?.action[0]?.transaction?.source?.contact?.card?.id && destinationInfo?.card) {
            sourceCardInfo = {}
            let cardDetails = destinationInfo?.card?.find((cardInfo: any) => cardInfo.id === props.data?.action[0]?.transaction?.source?.contact?.card?.id);
            if(cardDetails) {
                sourceCardInfo = {
                    cardBrand: cardDetails.brand,
                    cardHolderName: cardDetails.holderName,
                    cardNumber: maskAccountNumber(cardDetails.cardNumberLast4, false),
                    contactName: destinationInfo?.name || '',
                }
            }
        }

        if(props?.data?.action[0]?.transaction?.processingDetail?.location){
            const url = getCustomerUrl('location/id/' + props?.data?.action[0]?.transaction?.processingDetail?.location.id);
            location_source = {
                url: url,
                method: "GET",
                baseUrl:true
            }
        }

         /**
         * Method to return Virtual Card Info
         */
        const dataTransformerForVirtualCardInfo =(data:any)=>{
            let newData = {...data};
            newData.method = newData?.method === 'VIRTUAL_CARD'? 'VIRTUAL CARD':newData?.method;

            return newData;
        }

        function toSetFrequency(value:any)
        {
            let object:any={
                CALENDAR_DAY:'Calendar Day',
                BUSINESS_DAY:'Business Day',
                WEEK:'Week',
                MONTH:'Month',
                YEAR:'Year'
            }
            return object[value]
        }
        /**
        * function to handle End Date
        */
        function toSetEndValue(value:any)
        {
           var endValue: any;
            if(value?.endDate)
              endValue= value.endDate
            else if (value?.occurrence)
              endValue= 'After ' + value.occurrence + " Occurrence(s)"
            else
              endValue = 'NEVER'
            return endValue
        }

          /**
        * function to handle Repeat On Date
        */
          function toSetRepeatOnValue(value:any)
          {
             var repeatOnValue: any;
              if(value?.frequency == 'MONTH' &&  value?.dayOfMonth){
                repeatOnValue = displayDays(value.dayOfMonth)
              } else if (value?.frequency == 'YEAR'){
                repeatOnValue = displayDays(value.dayOfMonth) + ' of (' + displayDays(value.monthOfYear) +')'
              } else if(value?.frequency == 'WEEK'){
                repeatOnValue = value.dayOfWeek.join()
              } else
                repeatOnValue = ''
              return repeatOnValue
          }


        //Method to format the Repeat Days Node and display the Days
        function displayDays(data: any){
            var values;
            let newData:any=[]
            for (let value in data) {
                var val = removeUnderScoreConvertTitleCase(data[value]);
                newData.push(val)
            }
            values= newData.join();
            return values;
        }

        /**
        * function to handle destination data
        */
        function destinationDataTransformer(data: any) {

            try {
                let newData: any = { ...data };
                newData['accountInfo'] = (data?.nickName ? data?.nickName + ' ' : '') + maskAccountNumber(data?.accountNumber, false)
                return newData;
            }
            catch (e) {
                return false;
            }
        }
        /**

        /**
         * Method to return schedule information based on type
         */
        function scheduleInfoSection() {
            const method = schedule_attributes_data?.method === 'VIRTUAL_CARD'? 'VIRTUAL CARD': schedule_attributes_data?.method;
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Recurring Transaction |' + schedule_attributes_data?.name + ', via ' + method, backButton: '/recurring' } } });
            // eslint-disable-next-line no-mixed-operators
            if (props.data && props.data.action[0].transaction.method === 'ACH' || (props.data && props.data.action[0].transaction.method === 'WIRE')) {
                if (props.data?.action[0].transaction.processingDetail?.authType != 'PHONE') {
                    schedule_attributes_section_two.push({
                        label: 'Addenda',
                        value: 'addenda'
                    })
                }
                return (<FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data} />)
            } else{
                if (props.data && props.data.action[0].transaction.method === 'BOOK') {
                    return (<FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section_book} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data} />)
                }
                else if (props.data && props.data.action[0].transaction.method === 'CHECK') {
                        return (<FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data} />)
                    }
                    else if (props.data && props.data.action[0].transaction.method === 'CARD') {
                        return <FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data} />
                    }
                else if (props.data && props.data.action[0].transaction.method === 'VIRTUAL_CARD') {
                        return (<FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={virtual_card_section_left} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data} dataTransformer={dataTransformerForVirtualCardInfo} />)
                    }
                else if (props.data && props.data.action[0].transaction.method === 'INTERNATIONAL_WIRE') {
                        return (<FxInfoCard id={"recurring-deposit-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section} rightcolumn={schedule_attributes_section_two} data={schedule_attributes_data}  />)
                    }
                }
        }

        let book_source_account_section = [
            {
                label: 'Account ID',
                value: 'id'
            }
        ];
        let book_source_account_section_two = [
            {
                label: 'Account Number',
                value: 'accountInfo'
            }
        ];
        const destination_info_section = [
            {
                label: 'Account ID',
                value: 'id'
            }
        ];
        
        const destination_info_section_two = [
            {
                label: 'Account',
                value: 'accountInfo'
            }
        ];
        // if status is satisfied these conditions then will insert the Resume Date node
        if( schedule_attributes_data?.status != 'ACTIVE' && schedule_attributes_data?.status != 'CANCELLED' && schedule_attributes_data?.status != 'COMPLETED' )
        {
            schedule_attributes_section_two.push({
                label: 'Resume Date',
                value: 'resumeDate',
                type: 'fri://fieldtype/date'
            })
        }
        if(schedule_attributes_data?.transactionDetail?.frequency != 'CALENDAR_DAY' && schedule_attributes_data?.transactionDetail?.frequency != 'BUSINESS_DAY' ){
            schedule_attributes_section_two.push(
                {
                    label: 'Repeats On',
                    value: 'repeatOn'
                }
            )
        }
        let ppi_destination_section_left = [
            {
                label: 'PPI ID',
                value: 'ppi'
            }
        ]

        /**
        * Method handles the redirection to the edit page
        */
        function redirectToEditPage() {
            if(props?.data?.action?.[0]?.transaction?.destination?.contact){
                history.push(`/recurring/contact/edit/${props.data.id}`);
            }
            else if(props?.data?.action?.[0]?.transaction?.source?.contact) {
                history.push(`/contact-recurring/collect/edit/${props.data.id}`);
            }
            else if(props?.data?.action?.[0]?.transaction?.destination?.account?.id == getKey('selectedAccount')?.id){
                history.push(`/recurring/collect/edit/${props.data.id}`);
            } 
            else{
                history.push(`/recurring/account/edit/${props.data.id}`);
            }
        }
        const destinationLabel = props.isCollect ? 'SOURCE INFORMATION' : 'DESTINATION INFORMATION';

        return (
            <Grid id="recurring-details-grid" item xs={12}>
                <FxCard id="recurring-details-card" className="fx-theme-passport">
                    <FxCardHeader id={'recurring-details-card-header'} className="fx-card-header">
                        <Grid id="recurring-details-card-header-grid" item container justifyContent="space-between" alignItems={'center'} xs={12} >
                        <Grid id="recurring-details-card-header-second-grid" >
                                <Typography id="recurring-details-card-title" variant="h4" >
                                    Recurring Information
                                </Typography>
                            </Grid>
                            <Grid id="collect-info-transaction-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                                {((schedule_attributes_data?.status == 'PAUSED' || schedule_attributes_data?.status == 'ACTIVE') && schedule_attributes_data?.action[0]?.transaction?.method !== 'CARD' ) &&
                                  <ScheduleActions id='recurring-details-card-schedule-actions-' schedule={schedule_attributes_data} type="recurring" redirect={redirectToEditPage}/>
                                }
                                {((schedule_attributes_data?.status == 'PAUSED' || schedule_attributes_data?.status == 'ACTIVE') && schedule_attributes_data?.action[0]?.transaction?.source?.contact?.card) &&
                                  <ScheduleActions id='recurring-details-card-schedule-actions-' schedule={schedule_attributes_data} type="recurring" redirect={redirectToEditPage}/>
                                }
                           </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="recurring-details-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                            {scheduleInfoSection()}
                            <Grid item xs={12}>
                                
                               {destinationInfo && destinationInfo?.resourceName != 'contact' && schedule_attributes_data?.action[0]?.transaction?.method !== 'BOOK' && schedule_attributes_data?.action[0]?.transaction?.method !== 'CHECK' && schedule_attributes_data?.action[0]?.transaction?.method !== 'CARD' && <FxInfoCard id={"recurring-details-card-destination-information-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={destination_info_card_section} rightcolumn={destination_info_external_account_section} dataTransformer={destinationDataTransformer} data={destinationInfo} ></FxInfoCard>}
                            {destinationInfo && destinationInfo?.resourceName == 'contact' && scheduleInfo?.method === 'ACH' &&  !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={contact_section_left} rightcolumn={contact_section_right} data={destinationInfo}
                            ></FxInfoCard></Grid>}
                            {destinationInfo && destinationInfo?.resourceName == 'contact' && scheduleInfo?.method === 'WIRE' &&  !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-destination-information-wire-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={contact_section_left} rightcolumn={contact_section_wire_right} data={destinationInfo}
                            ></FxInfoCard></Grid>}
                            {destinationInfo && destinationInfo?.resourceName == 'contact' && scheduleInfo?.method === 'CHECK' &&  !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={contact_section_check_left} rightcolumn={contact_section_check_right} data={destinationInfo}
                            ></FxInfoCard></Grid>}
                            {destinationInfo && destinationInfo?.resourceName == 'contact' && scheduleInfo?.method === 'BOOK' &&  !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={contact_section_book_left} data={destinationInfo}
                            ></FxInfoCard></Grid>}
                                {schedule_attributes_data?.action[0]?.transaction?.method === 'CHECK' && destinationInfo?.resourceName != 'contact' && <FxInfoCard id={"recurring-details-card-destination-information-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={address_info_section} data={destinationInfo} ></FxInfoCard>}
                                {schedule_attributes_data?.action[0]?.transaction?.method === 'BOOK' && destinationInfo?.resourceName != 'contact' && <FxInfoCard id={"recurring-book-info-details-card-destination-information-refund-" + scheduleId + "-section-info-card-component"} leftcolumn={book_source_account_section} rightcolumn={book_source_account_section_two} title={destinationLabel} data={destinationInfo}
                                ></FxInfoCard>}
                                {(destinationInfo && destinationInfo?.ppi ) && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-ppi-destination-information" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={ppi_destination_section_left} data={destinationInfo}
                            ></FxInfoCard></Grid>}

                                {scheduleInfo?.method === 'VIRTUAL_CARD' && <Grid item xs={12} direction="column"> <FxInfoCard id={"recurring-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title={destinationLabel} leftcolumn={virtual_card_destination_section_left} rightcolumn={virtual_card_destination_section_right} data={destinationInfo}
                            ></FxInfoCard></Grid>}
                            {(scheduleInfo?.method === 'CARD' && props.data?.action[0]?.transaction?.source?.contact?.url) && <Grid item xs={12} direction="column">
                                    <FxInfoCard id={"recurring-details-card-destination-information-" + scheduleId + "-section-info-card-component"} title="SOURCE INFORMATION" leftcolumn={card_left_section_label} rightcolumn={card_right_section_label} data={sourceCardInfo} ></FxInfoCard>
                                </Grid>}
                                {/* display destination information*/}
                                <SourceInfoCard
                                    id={"source-info-details-card-destination-information-refund-" + scheduleId + "-section-info-card-component"}
                                    source={props.accountSource}
                                    label={props.isCollect ? 'DESTINATION INFORMATION' : 'SOURCE INFORMATION'}
                                />
                                {(scheduleInfo?.method === 'CARD' && !props.data?.action[0]?.transaction?.source?.contact?.card) && <Grid item xs={12} direction="column">
                                    <FxInfoCard id={"recurring-details-card-destination-information-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_section} rightcolumn={destination_info_section_two} dataTransformer={destinationDataTransformer} data={destinationInfo} ></FxInfoCard>
                                </Grid>}
                            </Grid>
                            {location_source && <Grid item xs={12}>
                                <MerchantLocationCard id={"recurring-details-card-location-information-" + scheduleId + "-section-info-card-component"} source={location_source} />
                            </Grid>}
                            <Grid item xs={12}>
                                <FxInfoCard id={"collect-details-card-audit-details-one-" + scheduleId + "-section-info-card-component"} title="AUDIT DETAILS" leftcolumn={audit_details_section}  rightcolumn={audit_details_section_two} data={props.data} ></FxInfoCard>
                            </Grid>
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
