import React, { useCallback, useEffect } from 'react'
import { Grid, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { clean, createCommonVerifyProps, updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import { setAmountValidationOptions } from './ScheduleGlobalFunctions';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { AuthenticationVerificationModal } from '../AuthenticationModal/AuthenticationVerificationModal';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReactComponent as DividerIcon } from '../../../assets/svg/divider-icon.svg';
import { useDispatch } from 'react-redux';
const httpClient = HttpClient.getClient();

Logger.debug("RefundCollect.tsx", "Collect Refund initializing")

const refundReasonData = [
    { label: 'On Customer Request', value: 'ON_CUSTOMER_REQUEST' },
    { label: 'Others', value: 'OTHERS' }
];

/**
 * This component handles the collect refund action
 */
export const RefundCollect: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control } = useForm();
        const dispatch = useDispatch();
        /**
         * Useeffect hook initializes the parameters
         */
        useEffect(() => {
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps, commentRequired : false })
        },[dispatch,props.id])

        function handleClose(){
            props?.onClose();
        }

        const history = useHistory()

        /**
         * Async function handles the api call for refund collect
         * @param req : form request
         * @returns : response object
         */
        async function refundCollect(req: any) {

            try {
                const url: any = props?.metadata?.captureData ?  ('transaction/id/' + props?.metadata?.scheduleId + '/capture/id/' + props?.metadata?.captureData.id  + '/refund' )  : '/transaction';
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("RefundCollect.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles the refund form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {


            let req: any = {    
                'amount': data.amount,
                'purpose': data.purpose,
                'reason': data.reasonforCancellation,
                'comment': data.comment
            }

            if(!props?.metadata?.captureData){
                req = {
                    ...req,
                    "method": "CARD",
                    "type": "REFUND",
                    "processingDetail": {
                        "parent": {
                            "id": props?.metadata?.scheduleId,
                        }
                    }
                }
            }
            req = clean(req);

            let status: any = await refundCollect(req);
            status = processAPIResponse(status)
            const backurl = '/transactions/collect/' +  props?.metadata?.scheduleId;
            const successMessage = 'Refund Successful';
            if (status.status) {
                //api success
                const postSuccessActions = () => {
                    handleClose();
                    history.push(backurl);
                }
                if(status.responseData?.sessionId){
                    const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                    const verifyProps = {
                    ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'collect refund'),
                    successMessage : successMessage,
                    postSuccessEvent: postSuccessActions,
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: successMessage,
                    });
                    postSuccessActions();
                }
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

        /**
         * Method to handle changes on event
         * @param event : event type
         */
        const handleChange = useCallback((event: any) =>  updateComponentParams(dispatch, props?.id, { commentRequired : (event?.target.value === 'OTHERS' )}), [dispatch, props.id]);

        /**
         * Function to handle the closing of the modal.
         */
        const handleCloseModal = () => {
            updateComponentParams(dispatch, props.id, { verifyProps: { open: false } });
        };

        return (
            <>
                <FxModalGeneric
                    id="refund-collect-otp"
                    open={props.verifyProps?.open}
                    onClose={handleCloseModal}
                    componentProps={props.verifyProps}
                    className={'fx-modal-small2medium'}
                    component={AuthenticationVerificationModal}
                >
                </FxModalGeneric>
                    {<form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container item xs direction='column'>
                    <span>
                        <DividerIcon />
                    </span>
                    <Grid container className="fx-modal-form flex column" paddingTop={'1.25rem'}>
                        <Grid item xs={12} sm={6}>
                            <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} id="create-collect-my-payer-ach-form-card-amount-textbox" label="Amount *" name="amount" valuePattern={/-|\+|e|E/} type="number" variant="outlined" prefix={'$'} showDecimal={true} setValue={setValue}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-collect-my-payer-ach-form-card-purpose-textbox" label="Purpose *" name="purpose" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxMaterialSelect register={{ ...register("reasonforCancellation") }} className={errors.reasonforCancellation ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} control={control} id="reasonforCancellation" name="reasonforCancellation" data={refundReasonData} label="Reason For Cancellation" setValue={setValue} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxTextEdit register={{ ...register("comment") }} className="fx-input-edit" control={control} rules={{ required: props?.commentRequired }} id="comment" label={props?.commentRequired ? "Comment *" : "Comment"} name="comment" variant="outlined" />
                        </Grid>
                    </Grid>
                    <span className='fx-padding-top-bottom-16'>
                        <DividerIcon />
                    </span>
                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                        <Button
                            variant="contained"
                            className="fx-button fx-button-cancel"
                            id="edit-nickname-cancel-button"
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>
                        <span className="fx-padding-right-16" />
                        <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-theme"
                            id={"edit-nickname-submit-button"}
                            type="submit"
                            isSubmitting={isSubmitting}
                        >
                            Refund
                        </FxButton>
                    </Grid>
                </Grid>
                </form>}
            </>
        );
    });
