import React from 'react'; // we need this to make JSX compile
import { Logger } from '../../../../libs/utils/logger';
import { BusinessUserSignup } from './BusinessUserSignup';

/**
 * Component: BusinessUserSignup
 */

Logger.debug("BusinessUserSignupWrapper.tsx", "Business User Signup Wrapper initializing")

/**
 * Used to call /customer api and passing data to BusinessUserSignup 
 */

export const BusinessUserSignupWrapper: React.FC<any> = React.memo(() => {
        const source = {
            url: "",
            method: "GET",
            data: {}
          } 
         return <BusinessUserSignup source={source} id={"business-user-singup"}/>
    })
