/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"; // we need this to make JSX compile
import { Logger } from "../../../../libs/utils/logger";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid } from "@mui/material";
import FxRadioEdit from "../../../Input/FxRadio/FxRadioEdit";
import { updateComponentParams } from "../../../Utils/CommonBaseClass";
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FxCheckboxTree } from "../../../Input/FxCheckboxTree/FxCheckboxTree";

Logger.debug("WebhookEvents.tsx","Create Webhook Form");

type FormValueType = Record<string, any>;
type TFieldValues = Record<string, string | null>;

interface IWebhookEvents {
    id: string;
    data: any;
    register: UseFormRegister<TFieldValues>,
    setValue: UseFormSetValue<any>;
    control: Control<any>,
    errors: FieldErrors<TFieldValues>;
    getValues: UseFormGetValues<FormValueType>;
    watch: UseFormWatch<FormValueType>;
    resetField : UseFormResetField<any>;
    triggerType?: string;
    treeData:any;
}

/**
 * This component handles the Webhook Event Selection Section in the Create Webhook Form
 */
export const WebhookEvents: React.FC<IWebhookEvents> = React.memo(
    (props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    let areAllEventsSelected: boolean = false;

    /**
     * use effect to set the initial state of the radio button and to handle the loading/unloading of the component
     */
    useEffect(()=>{
        updateComponentParams(dispatch,  props.id, { 'triggerType': 'select_events' })
        return () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });  
        }
    },[])

    /**
     * useEffect: to be called at the time of 'Edit', this sets the radio button value to the desired value as per the API data
     */
    useEffect(()=>{
        if(props?.treeData?.length > 0){
            areAllEventsSelected = checkEventLengths(props?.treeData, props?.data);
            updateComponentParams(dispatch,  props.id, { 'triggerType': areAllEventsSelected ? 'all_events' : 'select_events' }) 
        }
    },[props?.treeData])

    /**
     * Method handles the change in the Webhook Trigger Radio Button Selection
     * @param event 
     */
    const handleEndRecurringChange = (event: any) => {
        try {
            updateComponentParams(dispatch,  props.id, { 'triggerType': event.target.value })    
        }
        catch (e) { 
            Logger.error("WebhookEvents.tsx", "handleEndRecurringChange", "error", e);
        }
    }

    /**
     * Method checks if the total number of subscribed events recieved from the GET api
     * are equal to total number of events returned by the event list api
     * @param subscribedEvents 
     * @param allEvents 
     * @returns 
     */
    function checkEventLengths(subscribedEvents:any, allEvents:any) {
        const totalEventNames = allEvents?.reduce((sum: any, event: any) => sum + event.eventName.length, 0);
        return subscribedEvents.length === totalEventNames;
      }

    /**
     * Data for the Webhook Trigger Radio Button Group
     */
    const webhookTriggerTypes: any = [
        { label: 'All Events', value: 'all_events' },
        { label: 'Select Events', value: 'select_events' },
    ]

    return (
        <Grid container id='create-webhook-form-event-selection-grid' xs={12} sm={12}>
            <Grid container item id='create-webhook-form-event-selection-grid-radio-buttons' xs={12} sm={12}>
                <FxRadioEdit register={{ ...props?.register("webhookTrigger") }} control={props?.control} className={props?.errors.webhookTrigger ? "border-error-input fx-input-edit" : "fx-input-edit"} data={webhookTriggerTypes} id={'create-webhook-webhook-trigger-radio-selection'} name="webhookTrigger" label="" onChange={handleEndRecurringChange} defaultValue={ props?.triggerType } row="vertical" setValue={props?.setValue}  />
            </Grid>
            <Grid container item id='create-webhook-form-event-selection-grid-checkbox-tree-grid' xs={12} sm={12} marginTop={'1rem'}>
                { 
                    props?.data?.map((dataObj:any, index:any)=>{
                        return (
                            <Grid container xs={12} marginBottom={'0.9rem'}>
                                <FxCheckboxTree id={`create-webhook-form-event-selection-checkbox-tree-${index}`} register={props?.register} control={props?.control} setValue={props?.setValue} getValues={props?.getValues} key={index} index={index} data={dataObj} webhookTrigger={props?.watch('webhookTrigger')} triggerType={props?.triggerType ?? 'select_events'} treeData={props?.treeData}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
});
