import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider, Modal, IconButton, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
import { processAPIResponse } from '../../../libs/utils/utils';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning-icon.svg';
import { ReactComponent as TickIcon } from '../../../assets/svg/tick-icon.svg';
import { FxButton } from '../../Action/FxButton';
import CloseIcon from '@mui/icons-material/Close';
import { FxSuccess } from '../../Data/FxSuccess';
import { FxResendOtp } from '../../Data/FxResendOtp';
import { FxOtpInput } from '../../Input/FxOtpInput/FxOtpInput';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();

Logger.debug("CustomerPasswordChange.tsx", "Customer change password initializing");
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component used to change user password
 */
export const CustomerPasswordChange: React.FC<any> = React.memo((props) => {
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })

        const { register, getValues, trigger, formState: { errors }, handleSubmit, control } = useForm();
        const [isLoading, setIsLoading] = useState(false);
        const [verificationCodeOpen, setVerificationCodeOpen] = useState(false);
        const [formRequest, setFormRequest] = useState<any>();
        const [formResponse, setFormResponse] = useState<any>();
        const [success, setSuccess] = useState(false);
        const [resendStatus, setResendStatus] = useState(false);
        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Change Password',backButton:'/profile' } }});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
         * Async method used to call update API
         * @param payloadData :request
         * @returns
         */
        async function updatePassword(payloadData: any, resend: boolean = false) {
            try {
                if(formResponse && !resend){
                    const httpClientOtp = HttpClient.newClientCustomHeaders({ "token": formResponse.token, "otp": payloadData.otp})
                    delete payloadData.otp;
                    const data: any = await httpClientOtp.post('user/resetCustomerPassword', payloadData).then(response => {
                        return response;
                    })
                        .catch((error) => {
                            return { ...error };
                        })
                    return data;
                }
                else{
                    const data: any = await httpClient.post('user/resetCustomerPassword', payloadData).then(response => {
                        return response;
                    })
                        .catch((error) => {
                            return { ...error };
                        })
                    return data;
                }


            } catch (err) {
                Logger.error("CustomerPasswordChange.tsx", "error", err);
                return false;
            }
        }
        /**
         * Method used to format request and call API
         * @param data :form data
         */
        async function onSubmit(data: any) {
            let request: any;
            if(formRequest){
                request = {
                    value: formRequest.value,
                    userId: getKey("id"),
                    oldPassword: formRequest.oldPassword,
                    otp: data.otp
                }
            }
            else{
                request = {
                    value: data.newPassword,
                    userId: getKey("id"),
                    oldPassword: data.oldPassword
                }
            }
            setIsLoading(true);
            let status: any = await updatePassword(request);
            setIsLoading(false);
            if(status.data?.twoStepVerificationRequired){
                setFormRequest(request)
                setFormResponse(status.data)
                setVerificationCodeOpen(true)
                setResendStatus(true);
                setTimeout(() => {
                    setResendStatus(false);
                }, 30000);
            }
            else{
                status = processAPIResponse(status)
                if (status.status) {
                    //api successx
                    setVerificationCodeOpen(false)
                    setSuccess(true)
                }
                else {
                    //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }
            }

        }
        const history = useHistory()
        /**
         * Details section in change password screen
         */
        const infoblock = <Grid item xs={12} className="fx-info-container">
            <Grid item container justifyContent="space-between">
                <Typography id="title-customer-profile-edit-password-change1" variant="h3" >PASSWORD MUST CONTAIN</Typography>
                <WarningIcon />
            </Grid>
            <br></br>
            <Grid item container justifyContent="flex-start">
                <TickIcon /><Typography id="title-customer-profile-edit-password-change2" variant="h6" >Atleast 8 characters</Typography>
            </Grid>
            <Grid item container justifyContent="flex-start">
                <TickIcon /><Typography id="title-customer-profile-edit-password-change3" variant="h6" >Atleast 1 upper case (A-Z)</Typography>
            </Grid>
            <Grid item container justifyContent="flex-start">
                <TickIcon /><Typography id="title-customer-profile-edit-password-change4" variant="h6" >Atleast 1 lower case (a-z)</Typography>
            </Grid>
            <Grid item container justifyContent="flex-start">
                <TickIcon /><Typography id="title-customer-profile-edit-password-change5" variant="h6" >Atleast 1 number (0-9)</Typography>
            </Grid>
            <Grid item container justifyContent="flex-start">
                <TickIcon /><Typography id="title-customer-profile-edit-password-change6" variant="h6" >Atleast 1 special character (- , @)</Typography>
            </Grid>

        </Grid>
        /**
         * Method handles the resending of the otp
         */
        const handleResendCode = async () => {
            setResendStatus(true);
            const request: any = {
                value: formRequest.value,
                userId: getKey("id"),
                oldPassword: formRequest.oldPassword
            }
            const status: any = await updatePassword(request,true);
            if (status.data?.twoStepVerificationRequired) {
                FxSnackBar.show({
                    severity: 'success',
                    text: 'Otp send successfully',
                });
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setTimeout(() => {
                setResendStatus(false);
            }, 30000);
        }
        /**
         * Method handles the closing of the otp verification popup
         */
        const handleClose = () => {
            setVerificationCodeOpen(false);
        }
        // Code Error render function
        const renderCodeError = (err: InputErrType): string => {
            if (err.type === "required") {
                return "Please enter the OTP";
            }
            return err.message;
        };
        return <>
            {success && <FxSuccess id="sucess-change-password" header={"You have successfully updated password"} footer={"GO TO MY PROFILE"} redirect={"/profile"}/>}

            <Grid id="customer-profile-info-Grid" container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <FxCard id="customer-profile-info-card" className="fx-theme-passport">
                        <div className="fx-form-edit-profile flex column">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCardHeader id={'customer-profile-info-card#card-header'}>

                            </FxCardHeader>
                            <FxCardBody id="customer-profile-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                                <Grid container direction="row" spacing={1} className="fx-info-card-form">

                                    <Grid item xs={12}>
                                        <FxTextEdit register={{ ...register("oldPassword") }} control={control}
                                            rules={{ required: 'Please enter old password' }} className={errors.oldPassword ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" id="password-change-oldPassword" name="oldPassword" label="Old Password" />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FxTextEdit register={{ ...register("newPassword") }} control={control} rules={{
                                            required: "Please enter a new password",
                                            validate: () => {
                                                trigger('confirmPassword');
                                                return true;
                                            },
                                        }} className={errors.newPassword ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" id="password-change-newPassoword" name="newPassword" label="New Password" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FxTextEdit register={{ ...register("confirmPassword") }} control={control} rules={{
                                            required: "Please enter confirm password",
                                            validate: (value: any) => {
                                                if (value === getValues()["newPassword"]) {
                                                    return true;
                                                } else {
                                                    return "The passwords do not match";
                                                }
                                            }
                                        }} className={errors.confirmPassword ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" id="password-change-confirmPassword" name="confirmPassword" label="Confirm New Password" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="customer-change-password-form-card-footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">

                                    <FxButton
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="cancel-change-password"
                                        onClick={() => history.push('/profile')}
                                    > Cancel
                                            </FxButton>
                                    <Grid className='fx-padding-right-15' />
                                    <FxButton type='submit'
                                        variant="contained"
                                        className="fx-button fx-button-theme"
                                        id="password-change-save-btn"

                                    > Change Password
                                        </FxButton>
                                </Grid>

                            </FxCardFooter>
                        </form>
                        </div>
                    </FxCard>
                    {isSmallScreen && infoblock}
                </Grid>

                {/* right side  */}
                {!isSmallScreen &&
                    <Grid container xs={12} sm={4} className="fx-container-right"> {infoblock} </Grid>
                }
            </Grid>

            {/*      Verification code popup starts here         */}
            <Modal
                style={{ overflow: 'scroll' }}
                open={verificationCodeOpen}
                onClose={handleClose}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small2medium"
            >
                <div className="modal-content" >
                    <div className="modal-body">
                        <FxCard id="customer-profile-password-card" className="fx-theme-passport">
                            <FxCardHeader id={'customer-profile-password-card-header'} >
                                <Grid item container justifyContent="space-between" xs={12} >
                                    <Grid item xs={11}>
                                        <Typography id="customer-profile-password-card-title" variant="h3" >Enter Your Verification Code</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={handleClose} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {formResponse?.channel === 'EMAIL' && <Typography id="customer-profile-password-card-title" variant="h6" >{"An email with a verification code was sent to "+formResponse.email+".Enter code below and verify."}</Typography>}
                                        {formResponse?.channel === 'SMS' && <Typography id="customer-profile-password-card-title" variant="h6" >{"A text message with a verification code was sent to "+formResponse.phoneNumber+".Enter code below and verify."}</Typography>}
                                    </Grid>

                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="customer-profile-password-card-body" className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid className="flex column">
                                            <FxOtpInput
                                                    register={{ ...register("otp") }}
                                                    className={
                                                        errors.otp
                                                            ? "border-error-input fx-input-edit"
                                                            : "fx-input-edit"
                                                    }
                                                    control={control}
                                                    rules={{ required: true }}
                                                    id="verify-code"
                                                    name="otp"
                                                />
                                               {errors.otp && (
                                                    <div className={"error-message"}>
                                                        {renderCodeError(errors.otp)}
                                                    </div>
                                                )}
                                         </Grid>
                                        <Grid item xs={12}>&nbsp;</Grid>
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Grid>
                                                Didn't get it?
                                            </Grid>
                                            <Grid item justifyContent="center">
                                                <FxResendOtp id="resend-code" resendStatus={resendStatus} className={"fx-mfa-selection-resndbtn"} onClick={handleResendCode} buttonName="RESEND OTP"/>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>&nbsp;</Grid>
                                        <Grid item xs={12}>
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button fx-button-theme fx-mfa-selection-btnfullwidth"
                                                id="verify-mfa-btn"
                                                type="submit"
                                            >
                                                {isLoading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                    'VERIFY'
                                                )}
                                            </FxButton>
                                        </Grid>
                                </form>
                            </FxCardBody>
                        </FxCard>
                    </div>
                </div>
            </Modal>
        </>;
    })
