/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, IconButton, Chip, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { Logger } from '../../../../libs/utils/logger';
import { ReactComponent as MoreOptions } from '../../../../assets/svg/3-dots-with-bg-icon.svg';
import { ReactComponent as SelectIcon } from '../../../../assets/svg/select-icon.svg';
import { ReactComponent as UnselectIcon } from '../../../../assets/svg/unselect-icon.svg';
import { ReactComponent as ArrowPermsRight } from '../../../../assets/svg/arrowPermsRight.svg';
import { ReactComponent as ArrowPermsUp } from '../../../../assets/svg/arrowPermsUp.svg';
import {PermissionAccess, checkViewShouldBeDisabled, maskAccountNumber, removeElementFromArray, setPermissionAccess, updateComponentParams} from '../../../Utils/CommonBaseClass';
import { FxMultiSelectDataTable } from '../../../Data/FxMultiSelectDataTable/FxMultiSelectDataTable';
import { FxButton } from '../../../Action/FxButton';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit-access.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/svg/view-access.svg';

Logger.debug("Accordion.tsx", "Accordion initialized");
/**
 * Component: RolesAccordion
 * Accordion Configurations for Displaying Roles
 */
export const RolesAccordion: React.FC<any> = React.memo(
    (props) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [customAnchorEl, setCustomAnchorEl] = useState(null);
        const [selectedValue, setSelectedValue] = useState('');
        const [selectedOption, setSelectedOption] = useState<any>();
        let context: any;
        ({context , props} = RegisterComponent(props));
        const open = Boolean(anchorEl);
        const customOpen = Boolean(customAnchorEl);
        const dispatch = useDispatch();
        let options = [
            {
                id: PermissionAccess.FullAccess,
                title: 'Full Access',
                bgColor: '#00B05C15',
                fontColor: '#00B05C'
            },
            {
                id: PermissionAccess.EditAccess,
                title: 'Edit Access',
                bgColor: '#00AEEF15',
                fontColor: '#00AEEF'
            },
            {
                id: PermissionAccess.ViewAccess,
                title: 'View Access',
                bgColor: '#F4822015',
                fontColor: '#F48220'
            },
            {
                id: PermissionAccess.NoAccess,
                title: 'No Access',
                bgColor: '#FF4E4E14',
                fontColor: '#FF4E4E'
            },
            {
                id: PermissionAccess.CustomObjectAccess,
                title: 'Custom Object Access',
                bgColor: '#00B05C15',
                fontColor: '#00B05C'
            },
        ];
        if(!props?.columnName.includes('EDIT'))
        {
           options= removeElementFromArray(options,PermissionAccess.EditAccess)
         }
        /**
         * Method fetching account list data from state
         */
        const accountListData = useSelector((state: any) => {
            if (state.data['customer-basic-info']) {
                return state.data['customer-basic-info']?.params?.accountList
            } else {
                return { config: { params: {} } };
            }
        });
      
        /**
         * Method fetching debit card list data from state
         */
        const debitCardListData = useSelector((state: any) => {
            if (state.data['debit-card-object-data']) {
                return state.data['debit-card-object-data']
            } else {
                return { config: { params: {} } };
            }
        });

        /**
         * Method fetching account object access data from state
         */
        const accountObjectAccessState = useSelector((state: any) => {
            if (state.data['account-object-access']) {
                return state.data['account-object-access']
            } else {
                return { config: { params: {} } };
            }
        });

        /**
         * Method fetching debit card object access data from state
         */
        const debitCardObjectAccessState = useSelector((state: any) => {
            if (state.data['debit-card-object-access']) {
                return state.data['debit-card-object-access']
            } else {
                return { config: { params: {} } };
            }
        });

        const rolePermissionsState = useSelector((state: any) => {
            if (state.data['role-permissions']) {
                return state.data['role-permissions']
            } else {
                return { config: { params: {} } };
            }
        });

        const objectAccessPermissionsState = useSelector((state: any) => {
            if (state.data['object-access-permissions']) {
                return state.data['object-access-permissions']
            } else {
                return { config: { params: {} } };
            }
        });

        const objectAccessMenuTransformer = (data: any, entity: any) => {
            try {
                const newData: any = [];
                    data?.forEach((item: any) => {
                        let title = (entity == 'account') ?'Account - ':'Debit card - ';
                        if(entity == 'account' && item?.nickName){
                            title = title + item?.nickName + ' ';
                        }
                        if(entity == 'card' && item?.cardHolder?.name){
                            title = title + item?.cardHolder?.name + ' ';
                        }
                        title = title + maskAccountNumber(item?.accountNumber, false);
                        newData.push({ id: item['id'], title: title, checked: false})
                    });
                return newData;
            }
            catch (e) {}
        }
        
        /**
        * Method set the accountObjectAccess parameter for rendering the Account object access list and status
        * */
        useEffect(() => {
            if(accountListData?.resources?.length > 0){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "account-object-access", data: objectAccessMenuTransformer(accountListData?.resources,'account') } });
            }
        }, [accountListData?.resources]);
        /**
        * Method set the debitCardObjectAccess parameter for rendering the Debit card object access list and status
        * */
        useEffect(() => {
            if(debitCardListData?.data?.resources?.length > 0){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "debit-card-object-access", data: objectAccessMenuTransformer(debitCardListData?.data?.resources,'card') } });
            }
        }, [debitCardListData?.data?.resources]);

      /**
       * Method disabling the view
       * @param data : array contains the permissions
       * @returns : array after disabling the view
       */

        const handleDisablingView = (data: any) => {
            try{
                data?.forEach((item: any)=>{
                    item.permissions?.forEach((permItem: any)=>{
                        if(permItem.operation === 'VIEW'){
                            permItem.disabled = checkViewShouldBeDisabled(item.permissions);
                            
                        }
                    })
                })
                return data;
            }
            catch(e){}
        }

        /**
         * Method for selection handle for Access
         * @param id 
         */
        const handleSelect = (id: string) => {
            try{
                updateComponentParams(dispatch, props?.id, { "expand": true });
                const dummyContentData =  JSON.parse(JSON.stringify(props?.content));
                const data=setPermissionAccess(dummyContentData,id)
                setSelectedOption(options.find((option:any) => option.id === id))
                updateComponentParams(dispatch,props?.id, { tableData: handleDisablingView(data) });
                setSelectedValue(id);
                handleClose();
                if(props.title == 'Accounts' && (id == PermissionAccess.FullAccess.toString() || id == PermissionAccess.ViewAccess.toString())){
                    const objectData:any = {...objectAccessPermissionsState?.data};
                    objectData['accountButton'] = true;
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "object-access-permissions", data: objectData} });
                }
                else if(props.title == 'Accounts' && (id == PermissionAccess.NoAccess.toString())){
                    const objectData:any = {...objectAccessPermissionsState?.data};
                    objectData['accountButton'] = false;
                    objectData['account'] = false;
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "object-access-permissions", data: objectData} });
                    
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "account-object-access", data: resetObjectAccessValues([...accountObjectAccessState?.data]) } });
                }
                props.customMessage=''
                 updateComponentParams(dispatch, props?.id, { "customMessage": '' });
            }
            catch(e){}
        };
         /**
         * Method handles the selection of object access items
         * @param id 
         */
         const handleObjectAccessSelect = (id: string, entity: any) => {
            if(entity == 'Accounts'){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "account-object-access", data: toggleObjectAccessValue([...accountObjectAccessState?.data],id) } });
            }
            else if(entity == 'Debit Card'){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "debit-card-object-access", data: toggleObjectAccessValue([...debitCardObjectAccessState?.data],id) } });
            }
        };

         /**
         * Method updates the object access value array 
         * @param id 
         */

        const toggleObjectAccessValue = (data: any, id: any) => {
            try{
                const newData:any = [];
                data?.forEach((item: any, i: number)=>{
                    newData[i] = { ...data[i] } ;
                    if(item['id'] == id){
                        newData[i].checked = !item['checked'];
                    }
                })
                return newData;
            }
            catch(e){}
        }

         /**
         * Method reset the object access value array 
         * @param id 
         */

         const resetObjectAccessValues = (data: any) => {
               return data?.map((item: any, i: number)=>{
                   return {
                       ...item,
                       checked: false
                   }
                }) || [];
        }

        /**
         * Method called before component loading
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, []);

        
          
        
        useEffect(() => {
            if(rolePermissionsState?.data?.body && accountObjectAccessState?.data){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "account-object-access", data: populateRestrictedObjectFromApi(accountObjectAccessState?.data,'account') } });
            }
            if(rolePermissionsState?.data?.body && debitCardObjectAccessState?.data){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "debit-card-object-access", data: populateRestrictedObjectFromApi(debitCardObjectAccessState?.data,'card') } });
            }
            if(rolePermissionsState?.data?.body && props.title == 'Accounts'){
                let objectAccessPermissionsData:any;
                if(rolePermissionsState?.data?.body?.restrictedObject?.length>0){
                    objectAccessPermissionsData = { account: true, accountButton: true }
                }
                else if(props.customMessage == 'NO ACCESS'){
                    objectAccessPermissionsData = { account: false, accountButton: false }
                }
                else{
                    objectAccessPermissionsData = { account: false, accountButton: true }
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "object-access-permissions", data: objectAccessPermissionsData} });
            }
        }, [rolePermissionsState?.data?.body]);

        /**
         * Method fetches the id based on the label
         * @param label : label of the custom message
         * @returns : id of the custom message
         */

        const getIdFromCustomLabel = (label: any) => {
            try{
                let itemId;
                options?.forEach((item: any)=>{
                    if(item.title?.toUpperCase() == label){
                        itemId = item.id;
                    }
                })
                return itemId;
            }
            catch(e){}
        }
        /**
         * Method gets called when the props.customMessage changes
         */
        
         useEffect(() => {
            if(props.customMessage){
                const id:any = getIdFromCustomLabel(props.customMessage)
                setSelectedOption(options.find((option:any) => option.id === id))
                setSelectedValue(id);
                if(props.customMessage == 'CUSTOM ACCESS' && props.title == 'Accounts'){
                    const objectData:any = {...objectAccessPermissionsState?.data};
                    objectData['accountButton'] = true;
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "object-access-permissions", data: objectData} });
                }
                
            }
        }, [props.customMessage]);

        /**
         * Method to handle click
         * @param event 
         */
        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };
        /**
         * Method to handle click custom object access
         * @param event 
         */
        const handleCustomClick = (event: any) => {
            setCustomAnchorEl(event.currentTarget);
        };

        const handleCustomAccessClick = (event: any, id: any, entity: any) => {
            try{
                handleClose();
                updateComponentParams(dispatch, props.id, { "showObject": true });
                const objectData:any = {...objectAccessPermissionsState?.data};
                if(entity == 'Accounts'){
                    objectData['account'] = !objectData['account'];
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "object-access-permissions", data: objectData} });
                if(!objectData['account']){
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "account-object-access", data: resetObjectAccessValues([...accountObjectAccessState?.data]) } });
                }

            }
            catch(e){}
        }


      
        
        /**
               * Method to handle close
               * @param event 
               */
        const handleClose = () => {
            setAnchorEl(null);
        };
        /**
               * Method to handle close custom object access
               * @param event 
               */
        const handleCustomClose = () => {
            setCustomAnchorEl(null);
        };
        /**
         * Method to handle row click
         * @param event 
         */
        const handleArrowClick = () => {
            updateComponentParams(dispatch, props?.id, { "expand": !props.expand });
        }
        const getEntityAccessState = (entity: any) => {
            try{
                if(entity == 'Accounts' && accountObjectAccessState?.data){
                    return accountObjectAccessState?.data;
                }
                else if(entity == 'Debit Card' && debitCardObjectAccessState?.data){
                    return debitCardObjectAccessState?.data;
                }
            }
            catch(e){}
        }
        const populateRestrictedObjectFromApi = (data: any, entity: any) => {
            try{
                let selectedItems: any;
                if(rolePermissionsState?.data?.body?.restrictedObject){
                    selectedItems = getEntityRestrictedObjectData([...rolePermissionsState?.data?.body?.restrictedObject],entity);
                }
                let newData:any = [];
                data?.forEach((item:any, i:number)=>{
                    newData[i] = { ...data[i] };
                    newData[i]['checked'] = !!selectedItems?.includes(item.id);
                })
                return newData;
            }
            catch(e){}
        }
        const getEntityRestrictedObjectData = (data: any, entity: any) => {
            try{
                let idArray: any = [];
                data?.forEach((item: any)=>{
                    if(item.entity == entity){
                        idArray = item.id;
                    }
                })
                return idArray;
            }
            catch(e){}
        }
        return (
            <Grid item container xs={12} className="fx-permissions">
                <Grid item xs={12} >
                    <Accordion expanded={props.expand === true}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item container>
                                <Grid item className="fx-permissions-accordian-title-cell">
                                        {!props.expand && <ArrowPermsRight  onClick={handleArrowClick} /> }
                                        {props.expand &&<ArrowPermsUp  onClick={handleArrowClick}/> } 

                                        <Typography onClick={handleArrowClick}>
                                            {props.title}
                                        </Typography>
                                    
                                </Grid>
                                <Grid item className={props?.readOnly === false ? "fx-selected-value fx-permissions-accordian-title-cell" : "fx-selected-value fx-permissions-accordian-title-cell disabled"}>
                                    <MoreOptions id='access-button' className={'more'} onClick={handleClick} />
                                    <p className={props.customMessage && props?.customMessage != '' && props.customMessage == 'CUSTOM ACCESS' ? 'selected-access selected-access-custom-color' : 'selected-access'} style={{ backgroundColor: selectedOption?.bgColor, color: selectedOption?.fontColor }}>
                                        <span className="selected-value-text">{props?.customMessage && props?.customMessage != '' ? props?.customMessage : selectedOption?.title?.toUpperCase()}</span>
                                    </p>
                                    <Menu
                                        id="access-menu-dropdown"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        className="fx-permissions-dotted-menu fx-menu-dotted"
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        {options.map((option: any) => {
                                            if(!(props.title != 'Accounts' && option.title == 'Custom Object Access')){
                                                return <MenuItem disabled={(props.title == 'Accounts' && option.title == 'Custom Object Access' && !objectAccessPermissionsState?.data?.accountButton)} key={option.id} onClick={(e: any) => option.title == 'Custom Object Access'? handleCustomAccessClick(e,option.id,props.title): handleSelect(option.id)}>
                                                {((selectedValue === option.id) || (option.title == 'Custom Object Access' && objectAccessPermissionsState?.data?.account)) ? (
                                                    <SelectIcon className={'permission-svg'}/>
                                                ) : (
                                                    <UnselectIcon className={'permission-svg'}/>
                                                )}
                                                &nbsp;&nbsp;{option.title}
                                                </MenuItem>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </Menu>
                                </Grid>
                                {/* custom object access */}
                                {(props.title == 'Accounts' && objectAccessPermissionsState?.data?.account) && <Grid item xs={12} sm={12} className={'custom-object-access'}>
                                    <Chip className="chip-object" label="CUSTOM OBJECT ACCESS" onClick={handleCustomClick}/>
                                    <IconButton
                                        className="chip-object-icon"
                                        onClick={handleCustomClick}
                                        id='access-edit-icon'
                                        size="large">
                                                {props.readOnly?<ViewIcon/>:<EditIcon />}
                                    </IconButton>
                                </Grid>}
                                {/* custom object access popover */}
                                <Drawer
                                    id="custom-object-access-list"
                                    anchor={'right'}
                                    open={customOpen}
                                    onClose={handleCustomClose}
                                >
                                    <Grid className={props.readOnly?"fx-permissions-object-access-drawer disabled": "fx-permissions-object-access-drawer"}>
                                    <Typography variant="h3">Custom Object Access</Typography>
                                    <Typography variant="h4">{props.title == 'Accounts' ? 'Accounts' : 'Debit Cards'}</Typography>
                                    <Typography variant="h4" className="title-with-bg">OBJECTS</Typography>
                                    {/* {getEntityAccessState(props.title)?.map((option: any) => ( */}
                                    {rolePermissionsState?.data?.body && getEntityAccessState(props.title)?.map((option: any) => (
                                        <MenuItem className="menu-items" key={option.id} onClick={() => handleObjectAccessSelect(option.id, props.title)}>
                                            {option.checked ? (
                                                <SelectIcon className={'permission-svg'}/>
                                            ) : (
                                                <UnselectIcon className={'permission-svg'}/>
                                            )}
                                            &nbsp;&nbsp;{option.title}
                                        </MenuItem>
                                    ))}
                                    <Grid  container xs={12} justifyContent="flex-end">
                                        <Grid className="fx-padding-right-15 footer-action">
                                            <FxButton variant="contained" className={props.readOnly?"fx-button fx-permissions-object-access-cancel-button":"fx-button"} id="roles-accordian-cancel" onClick={handleCustomClose}>Cancel</FxButton>
                                            {!props.readOnly && <FxButton disableRipple={false} className="fx-button fx-button-theme" id="roles-accordian-save-changes" type="submit" onClick={handleCustomClose}>Save Changes</FxButton>}
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Drawer>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            { context &&
                                <FxMultiSelectDataTable valueChanged={props?.valueChanged}  readOnly={props?.readOnly}  tableData={props.content} columns={props.columnName} id={props.title}></FxMultiSelectDataTable>}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );

    });


