/**
 * file contains list of common methods used in the webhook implementation
 */

import UIFeatureImpl from "../../../libs/services/uiFeatures";


const uiFeat = UIFeatureImpl.getInstance();

/**
 * This method handles the removal of the creation events from the event array fetched from the event/list API
 * @param data 
 * @param removableEventsArray 
 * @returns 
 */
export const removeValues = (data: any, removableEventsArray: any) => {
    return data?.map((obj: any) => ({
        ...obj,
        eventName: obj?.eventName?.filter((event: any) => !removableEventsArray?.includes(event))
      }));
}

/**
 * This method handles the displaying of the eventName as per the customer type
 * @param data 
 * @returns 
 */
export const updateEventListPerCustomerType = (data: any) => {
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;

    return data?.map((obj: any) => {
      if (obj?.entity?.toLowerCase() === "customer") {
        return {
          ...obj,
          eventName: obj?.eventName?.filter((event: any) => businessCustomer && event?.includes("business")),
        };
      } else {
        return obj;
      }
    });
};

