import React, { useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { useHistory, useLocation } from 'react-router-dom';
import { getScheduleType, removeUnderScoreConvertTitleCase } from '../../../Utils/CommonBaseClass';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { CheckImageCard } from '../CheckImageCard';
import { getEditSchedulePath, maskAccountNumber, toTitleCase } from '../../../Utils/CommonBaseClass';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleActions } from '../ScheduleActions';
import { useIsMobileScreen } from '../../../../libs/utils/utils';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { DownLoadDocs } from '../DownLoadDocs';
import { MerchantLocationCard } from '../../Merchants/MerchantLocationCard';


Logger.debug("CollectInfoCard.tsx", "collect infocard initializing")


/**
 * This component handles the view of the collect details
 */
export const CollectInfoCard: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const scheduleId = props?.data?.id;
        const scheduleInfo = props?.data;
        const location = useLocation();
        const schedule = location.pathname.split("/")[2];
        const history = useHistory<any>()
        let external_account_source_data: any;
        let card_source_data: any;
        let external_account_source: any;
        let collect_via_contact_ach_external_account_source_data: any;
        let collect_via_contact_card_source_data: any;

        let sourceDetails: any = props?.sourceInfo?.data?.body
        const destinationDetails: any = props?.destinationInfo?.data?.body
        let location_source : any;
        const scheduleType = getScheduleType(businessCustomer,'title'); 

        const destination_info_section = [
            {
                label: 'Account ID',
                value: 'id'
            }
        ];

        const destination_info_section_two = [
            {
                label: 'Account',
                value: 'accountInfo'
            }
        ];

        const card_left_section_label = [
            {
                label: 'Contact Name',
                value: 'contactName'
            },
            {
                label: 'Card Number',
                value: 'cardNumber'
            }
        ];
        
        const card_right_section_label = [
            {
                label: 'Card Holder Name',
                value: 'cardHolderName'
            },
            {
                label: 'Card Brand',
                value: 'cardBrand'
            }
        ];
        
        const card_right_source_section_label = [
            {
                label: 'Card Brand',
                value: 'brand'
            }
        ];

        const audit_details_section = [
            {
                label: 'Created',
                value: 'createdInfo',
            },
        ];
        
        const audit_details_section_two = [
            {
                label: 'Last Updated',
                value: 'updatedInfo',
            }
        ];
        
        const exteranl_account_section_api_data = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Account Purpose',
                value: 'accountPurpose'
            },
            {
                label: 'Account Type',
                value: 'type'
            }
        ];
        
        const exteranl_account_section_api_data_two = [
            {
                label: 'Bank Name',
                value: 'bankInfo.name'
            },
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            }
        ];
        
        const exteranl_account_section_card_data = [
            {
                label: 'Card Number',
                value: 'cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Brand',
                value: 'brand'
            },
            {
                label: 'Holder Name',
                value: 'holderName'
            },
        ];
        const card_left_source_section_label = [
            {
                label: 'Card Number',
                value: 'cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Card Holder Name',
                value: 'holderName'
            },
        ];
        const exteranl_account_section = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },
            {
                label: 'Bank Name',
                value: 'bankName'
            },
        
        ];
        
        const exteranl_account_section_two = [
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            },
            {
                label: 'Account Type',
                value: 'type'
            },
            {
                label: 'Account Purpose',
                value: 'accountPurpose'
            },
        ];

        const contact_exteranl_account_section = [      
            {
                label: 'Contact',
                value: 'name'
            },      
            {
                label: 'Holder Name',
                value: 'holderName'
            },           
            {
                label: 'Account Type',
                value: 'type'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            },      
        ];
        
        const contact_exteranl_account_section_two = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },
            {
                label: 'Bank Name',
                value: 'bankName'
            }          
        ];
        
        const schedule_info_section = [
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
            {
                label: 'Faster Funding',
                value: 'quickSettle'
            }
        ];
        
        const schedule_info_section_two:any = [
            {
                label: 'Processing Mode',
                value: 'processingMode'
            },
            {
                label: 'Memo',
                value: 'processingDetail.companyDescription'
            },
            {
                label: 'Auth Type',
                value: 'processingDetail.authType'
            },
        ];
        
        /**
        * left column data for TRANSACTION DETAILS for card method
        */
        const schedule_info_card_section = [
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Auth Code',
                value: 'authCode'
            },
            {
                label: 'Merchant ID',
                value: 'merchantID'
            },
            {
                label: 'Invoice Number',
                value: 'invoiceNumber' 
            }
        ];

       /**
        * left column data for AMOUNT DETAILS for card REGULAR method
        */
        const schedule_info_amount_card_section_left =[
            {
                label: 'Surcharge Amount',
                value: 'surchargeAmount' 
            }
        ];

       /**
        * Right column data for AMOUNT DETAILS for card REGULAR method
        */
        const schedule_info_amount_card_section_right =[
            {
                label: 'Tip Amount',
                value: 'tipAmount' 
            }
        ]

        /**
        * left column data for TRANSACTION DETAILS for card method and EXTERNAL Type
        */
        const schedule_info_external_card_left_section = useMemo(() => {
            const section_one = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
            {
                label: 'Purpose',
                value: 'purpose'
            },
            {
                label: 'Faster Funding',
                value: 'quickSettle'
            }
        ]
    
        let parentNode = scheduleInfo?.linkedSchedule;
        if (parentNode && parentNode.id) {
            section_one.unshift({
                label: 'Parent Transaction ID',
                value: 'linkedSchedule.id'
            })
        }
        return section_one
        }, [scheduleInfo?.linkedSchedule])

        /**
        * right column data for TRANSACTION DETAILS for card method and EXTERNAL Type
        */
         const schedule_info_external_card_right_section = [
            {
                label: 'Batch Number',
                value: 'processingDetail.batchNumber'
            },
            {
                label: 'Batch ID',
                value: 'batchId'
            },
            {
                label: 'Statement Descriptor',
                value: 'processingDetail.statementDescriptor'
            }
        ];

        /**
        * left column data for TRANSACTION DETAILS for Source Details for card method and EXTERNAL Type
        */
        const source_external_card_left_section = [
            {
                label: 'Auth Code',
                value: 'authCode',
            },
            {
                label: 'Card Brand',
                value: 'brand'
            }
        ];

        /**
        * right column data for TRANSACTION DETAILS for Source Details for card method and EXTERNAL Type
        */
        const source_external_card_right_section = [
            {
                label: 'Card Number',
                value: 'cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
        ];
        
        /**
        * left column data for TRANSACTION DETAILS for card method
        */
        const schedule_info_card_section_two = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
            {
                label: 'Auto Capture',
                value: 'isAutoCapture'
            },
          // to show the statement descriptor for card method if it is not a refund transaction and statement descriptor is enabled
          ...(scheduleInfo?.type !== 'REFUND' && uiFeat.getTransactionFeatures().statementDescriptor ? [{
            label: 'Statement Descriptor',
            value: 'statementDescriptor'
          }] : [])
        ];
        
        /**
         * left column data for TRANSACTION DETAILS for mobile check method
         */
        const schedule_info_mobile_check_section = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];
        
        /**
         * right column data for TRANSACTION DETAILS for mobile check method
         */
        const schedule_info_mobile_check_section_two = [
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            }
        ];
        
        const schedule_info_mobile_check_source_section = [
            {
                label: 'Account Number',
                value: 'processingDetail.checkVerification.checkDetail.accountNumber',
                type: 'fri://fieldtype/accountnumber',
            },
            {
                label: 'Check Number',
                value: 'processingDetail.checkVerification.checkDetail.checkNumber'
            },
            {
                label: 'Check Processing Status',
                value: 'processingDetail.checkVerification.status'
            }
        ];
        
        const schedule_info_mobile_check_source_section_two = [
            {
                label: 'Routing Number',
                value: 'processingDetail.checkVerification.checkDetail.routingNumber'
            }
            ,
            {
                label: 'Check Processing Status Reason',
                value: 'processingDetail.checkVerification.statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];

        if (sourceDetails) {
            sourceDetails = { ...sourceDetails };
            sourceDetails['accountPurpose'] = props?.sourceInfo?.data?.body?.purpose ? props?.sourceInfo?.data?.body?.purpose : '';
        }

        if (props?.data?.source?.externalAccount?.holderName) {
            external_account_source_data = { ...props.data.source.externalAccount };
            external_account_source_data['type'] = props.data.source.externalAccount['type'] ? props.data.source.externalAccount['type'] : (props.data['type'] ? props.data['type'] : '');
            external_account_source_data['bankName'] = props.data.source.externalAccount?.bankInfo?.name ?? props.data.source.externalAccount?.bankName ?? '';
            external_account_source_data['accountPurpose'] = props.data.source.externalAccount?.purpose ? props.data.source.externalAccount?.purpose : '';
        }
        if (props?.data?.source?.card?.cardNumberLast4 && props?.data?.type !== 'EXTERNAL') {
            card_source_data = { ...props.data.source.card };
        }

        if(props?.data?.processingDetail?.location){
            const url = props?.data?.processingDetail?.location.url.split('/v1/')[1]
            location_source = {
                url: url,
                method: "GET",
                baseUrl:true
            }
        }

        // External Account source Data for Collect Via Contact ACH method
        if(props?.data?.method === 'ACH' && props?.data?.source?.contact?.externalAccount && sourceDetails?.externalAccount?.length) {
            let externalAccountDetails = sourceDetails?.externalAccount.find((externalAcc: any) => externalAcc.id === props?.data?.source?.contact?.externalAccount.id);
            collect_via_contact_ach_external_account_source_data = {};
            if(!uiFeat.getTransactionFeatures().fastFunding) {
                schedule_info_section.pop();
                schedule_info_card_section.pop();
            }
            if(externalAccountDetails) {
                collect_via_contact_ach_external_account_source_data['accountNumberLast4'] = externalAccountDetails?.accountNumberLast4;
                collect_via_contact_ach_external_account_source_data['routingNumber'] = externalAccountDetails?.bankInfo?.routingNumber;
                collect_via_contact_ach_external_account_source_data['bankName'] = externalAccountDetails?.bankInfo?.name;
    
                collect_via_contact_ach_external_account_source_data['holderName'] = externalAccountDetails?.holderName;
                collect_via_contact_ach_external_account_source_data['holderType'] = externalAccountDetails?.holderType;
                collect_via_contact_ach_external_account_source_data['type'] = externalAccountDetails?.type;
                collect_via_contact_ach_external_account_source_data['name'] = sourceDetails?.name;
            }
        }

        // External Account source Data for Collect Via Contact CARD method
        if(props?.data?.method === 'CARD' && props?.data?.source?.contact?.card && sourceDetails?.card?.length) {
            let selectedCardDetail = sourceDetails?.card.find((cardDetail: any) => cardDetail.id === props?.data?.source?.contact?.card.id);
            collect_via_contact_card_source_data = {};
            if(!uiFeat.getTransactionFeatures().fastFunding) {
                schedule_info_section.pop();
            }
            if(selectedCardDetail) {
                collect_via_contact_card_source_data['cardBrand'] = selectedCardDetail?.brand;
                collect_via_contact_card_source_data['cardHolderName'] = selectedCardDetail?.holderName;
                collect_via_contact_card_source_data['cardNumber'] = maskAccountNumber(selectedCardDetail.cardNumberLast4, false);
                collect_via_contact_card_source_data['contactName'] = sourceDetails?.name || '';
            }
        }
        const options = [
            {
                id: 'downnload-docs-option',
                title: 'Download'              
            }
        ];
        /**
         * Method to display title based on collect type
         */
        function getTitle() {
            let title: any;
            if (scheduleInfo?.method === 'CARD') {
                if (scheduleInfo?.source?.card?.id) {
                    title = {
                        header: scheduleType,
                        method: scheduleInfo?.method
                    }
                }
                else if ((scheduleInfo?.method === 'CARD' && scheduleInfo.type === 'EXTERNAL')) {
                    title = {
                        header: `${scheduleType} from External`,
                        method: scheduleInfo?.method
                    }
                }
                else if (scheduleInfo?.source?.contact?.id) {
                    title = {
                        header: `${scheduleType} from ${sourceDetails?.name}`,
                        method: scheduleInfo?.method
                    }
                }
                else {
                    title = {
                        header: `${scheduleType} from ${props?.data?.source?.card?.holderName}`,
                        method: scheduleInfo?.method
                    }
                }
            }
            else if (scheduleInfo?.method === 'ACH') {
                if (scheduleInfo?.source?.externalAccount?.id) {
                    title = {
                        header: `${scheduleType} from ${sourceDetails?.holderName}`,
                        method: scheduleInfo?.method
                    }
                } else if (scheduleInfo?.source?.contact?.id) {
                    title = {
                        header: `${scheduleType} from ${sourceDetails?.name}`,
                        method: scheduleInfo?.method
                    }
                }
                else {
                    title = {
                        header: `${scheduleType} from ${props?.data?.source?.externalAccount?.holderName}`,
                        method: scheduleInfo?.method
                    }
                }
            }
            else if (scheduleInfo?.method === 'CHECK') {
                title = {
                    header: scheduleType,
                    method: scheduleInfo?.method
                }
            }
            return title;
        }

        /**
        * Method handles the redirection to the edit page
        */
        function redirectToEditPage() {
            const path = getEditSchedulePath(props.data)
            history.push(path);
        }

        /**
         * Method handles the display of the source information based on various conditions
         * @returns : section info card component
         */
        function sourceData() {
            if (external_account_source_data) {
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={exteranl_account_section} rightcolumn={exteranl_account_section_two} data={external_account_source_data} />
            }
            else if (card_source_data) {
                return <FxInfoCard id={"section-info-card-card-source" + scheduleId} title="SOURCE INFORMATION"  leftcolumn={card_left_source_section_label} rightcolumn={card_right_source_section_label} data={card_source_data} />
            }
            else if (props?.data?.method === 'CARD' && props?.data?.source?.card?.url) {
                return <FxInfoCard id={"section-info-card-card-from-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={exteranl_account_section_card_data} data={sourceDetails} />
            }
            else if (props?.data?.method === 'ACH' && props?.data?.source?.externalAccount?.url) {
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={exteranl_account_section_api_data} rightcolumn={exteranl_account_section_api_data_two} data={sourceDetails} />
            } else if(props?.data?.method === 'ACH' && props?.data?.source?.contact?.externalAccount) {
                return <FxInfoCard id={"section-info-card-collect-contact-ach-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={contact_exteranl_account_section} rightcolumn={contact_exteranl_account_section_two} data={collect_via_contact_ach_external_account_source_data} />;
            } else if(props?.data?.method === 'CARD' && props?.data?.source?.contact?.card) {
                return <FxInfoCard id={"section-info-card-collect-contact-card-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={card_left_section_label} rightcolumn={card_right_section_label} data={collect_via_contact_card_source_data} />;
            }
            // section for showing source information and check information for mobile check method
            else if (props?.data?.method === 'CHECK') {
                return <>

                    <Grid item xs={12} sm={12} direction="column">
                        {/*   check image card for displaying check image                        */}
                        <CheckImageCard id={schedule + "-check-image-" + scheduleId} data={props.data} title="CHECK INFORMATION" hideDownload2={true}></CheckImageCard>
                    </Grid>
                    {props?.data && props?.data?.type==='EXTERNAL' && <Grid item xs={12} sm={12} direction="column">
                    <DownLoadDocs data={props.data} options={options} anchorEl={null} id={'downolad-doc-check-image-multple'}></DownLoadDocs>
                    </Grid>}
                    <Grid item xs={12} sm={6} direction="column">
                        {/*   section info card for displaying source information                        */}
                        <FxInfoCard id={"section-info-card-mobile-check-source-one" + scheduleId} title="SOURCE INFORMATION" column={schedule_info_mobile_check_source_section} data={scheduleInfo} ></FxInfoCard></Grid>
                    <Grid item xs={12} sm={6} direction="column">
                        <FxInfoCard id={"section-info-card-mobile-check-source-two" + scheduleId} title="" column={schedule_info_mobile_check_source_section_two} data={scheduleInfo} ></FxInfoCard>
                    </Grid>
                </>
            }
            else if (props?.data?.method === 'CARD' && props?.data.type !== 'EXTERNAL') {
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="SOURCE INFORMATION" column={exteranl_account_section_card_data} source={external_account_source} />
            }else if (props?.data?.method === 'CARD' && props?.data.type === 'EXTERNAL') {
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="SOURCE INFORMATION" leftcolumn={source_external_card_left_section} rightcolumn={source_external_card_right_section} data={scheduleInfo?.source?.card} />
            }
        }
        
        /**
         * This method is used to transform purpose of transaction.
         */
        function purposeDataTransformer(data: any) {
            let newData: any = { ...data };
            newData.surchargeAmount= data?.amountDetails?.surchargeAmount;
            newData.tipAmount= data?.amountDetails?.tipAmount;
            newData.authCode = data?.authCode;
            newData.type= toTitleCase(data?.transactionType);
            newData.invoiceNumber= data?.processingDetail?.order?.invoice?.number;
            newData.isAutoCapture = data?.isAutoCapture === true ? 'Yes' : 'No';
            newData.statementDescriptor = data?.processingDetail?.statementDescriptor;
            newData.merchantID= data?.processingDetail?.merchant?.id;
            newData.batchId = data?.processingDetail?.trimmedBatchId ? data?.processingDetail?.trimmedBatchId : data?.processingDetail?.batchId 
            newData.purpose = ((newData?.type === 'EXTERNAL' || newData?.type === 'REFUND') && newData?.destination?.account?.id) ? ('SALE - Ref: ' + newData?.purpose) : newData?.purpose;
            return newData;
        }
        /**
        * Method handles the display of the TRANSACTION DETAILS based on various conditionss
        * @returns : section info card component
        */
        function scheduleInfoData() {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: getTitle(), backButton: history?.location?.state?.backUrl ? history?.location?.state?.backUrl : "/transactions", url: history?.location?.state?.url, tab: 'TRANSACTION', jsonContent: true } } });
            if (props?.data?.feeAgainstTransaction) {
                schedule_info_section_two.push({
                    label: 'Fee For',
                    value: 'feeAgainstTransaction.id',
                    className:'fx-link-sel',
                    url:'/transactions/send/'+props?.data?.feeAgainstTransaction?.id,
                    type:'fri://fieldtype/hyperlink'
                })
            }
    
            if (scheduleInfo?.method === 'ACH') {
                if (props.data?.processingDetail?.memo) {
                    schedule_info_section_two.push({
                        label: 'Memo',
                        value: 'processingDetail.memo'
                    })
                }
                if (props?.data && props.data.type === 'REVERSAL' && scheduleInfo?.processingDetail?.parent?.id) {
                    schedule_info_section_two.push({
                        label: 'Parent Transaction id',
                        value: 'parentTxnId',
                    })
                    if(scheduleInfo?.processingDetail?.parent?.id){
                        scheduleInfo.parentTxnId=scheduleInfo?.processingDetail?.parent?.id ? `Send (ID ${scheduleInfo?.processingDetail?.parent?.id})` : '';
                    }
                }
                if (scheduleInfo) {
                    if (scheduleInfo?.processingDetail?.processingMode) {
                        scheduleInfo.processingMode = scheduleInfo.processingDetail.processingMode === 'FORWARD' ? 'Next Day' : 'Same Day';
                    }
                }
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="TRANSACTION DETAILS" leftcolumn={schedule_info_section} rightcolumn={schedule_info_section_two} data={scheduleInfo} />
            } else if (scheduleInfo?.method === 'CARD') {
                if(props?.data?.source?.card?.type === 'DEBIT' || props?.data?.source?.card?.cardType === 'DEBIT' || scheduleInfo?.source?.card?.debitCard){
                    scheduleInfo['cardMethod'] = 'CARD - (DEBIT)'
                    scheduleInfo['regulated'] = props.data?.source?.card?.debitCard?.regulated? 'Yes': 'No'
                }
                else if(props?.data?.source?.card?.type === 'CREDIT' || props?.data?.source?.card?.cardType === 'CREDIT'){
                    scheduleInfo['cardMethod'] = 'CARD - (CREDIT)'
                }
                else if(props?.data?.source?.card?.type){
                    scheduleInfo['cardMethod'] = 'CARD - ('+props?.data?.source?.card?.type+')'
                }
                else if(props?.data?.source?.card?.cardType){
                    scheduleInfo['cardMethod'] = 'CARD - ('+props?.data?.source?.card?.cardType+')'
                }
                else{
                    scheduleInfo['cardMethod']=scheduleInfo?.method
                }
                if((scheduleInfo?.source?.card?.type === 'DEBIT')|| (scheduleInfo?.source?.card?.cardType === 'DEBIT') || (scheduleInfo?.source?.card?.debitCard)){
                    schedule_info_card_section_two.splice(2,0, {
                        label: 'Regulated Card',
                        value: 'regulated'
                    })
                }
                return props?.data.type === 'EXTERNAL' ? 
                  <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="TRANSACTION DETAILS" leftcolumn={schedule_info_external_card_left_section} rightcolumn={schedule_info_external_card_right_section} dataTransformer={purposeDataTransformer} data={scheduleInfo} /> : 
                    <Grid container item xs={12} direction="row" >
                        <Grid item xs={12}>
                        <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="TRANSACTION DETAILS" leftcolumn={schedule_info_card_section} rightcolumn={schedule_info_card_section_two} dataTransformer={purposeDataTransformer} data={scheduleInfo} />
                        </Grid>
                        <Grid item xs={12}>
                        <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="AMOUNT DETAILS" leftcolumn={schedule_info_amount_card_section_left} rightcolumn={schedule_info_amount_card_section_right} dataTransformer={purposeDataTransformer} data={scheduleInfo} />
                        </Grid>
                    </Grid>
            } else if (scheduleInfo?.method === 'CHECK') {
                // section info card for showing TRANSACTION DETAILS
                return <FxInfoCard id={"section-info-card-deposit-source-mobile-one" + scheduleId} title="TRANSACTION DETAILS" leftcolumn={schedule_info_mobile_check_section} rightcolumn={schedule_info_mobile_check_section_two} data={scheduleInfo} />
            } else {
                return <FxInfoCard id={"section-info-card-deposit-source" + scheduleId} title="TRANSACTION DETAILS" column={exteranl_account_section_card_data} source={external_account_source} ></FxInfoCard>
            }
        }
        let edit: boolean = false;
        if (props.data) {
            if (props.data['status'] === 'SCHEDULED' || props.data['status'] === 'PENDING') {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                edit = true;
            }
            scheduleInfo.quickSettle = props.data.processingDetail?.quickSettle ? 'Yes' : 'No';
        }
        /**
         * function to handle destination data
         */
        function destinationDataTransformer(data: any) {
            try {
                let newData: any = { ...data };
                newData['accountInfo'] = (data?.nickName ? data?.nickName + ' ' : '') + 'A/c '+ maskAccountNumber(data?.accountNumber, false)


                return newData;
            }
            catch (e) {
                return false;
            }
        }

        /**
        * to get salesData
        */
        const salesData = useSelector((state: any) => {
            if (state.data['collect-card-sales-info']) {
                return state.data['collect-card-sales-info']?.data?.body;
            }
        });


        return (
            <Grid id="collect-details-grid" item xs={12}>
                <FxCard id="collect-details-card" className="fx-theme-passport">
                    <FxCardHeader id={'collect-details-card-header'}>
                        <Grid id="collect-details-card-header-grid" item container justifyContent="space-between" xs={12} alignItems={'center'}>
                            <Grid item id="collect-details-card-header-second-grid"  >
                                {useIsMobileScreen()?<Grid className="fx-inline-flex"><Typography id="collect-details-card-title" variant="h3" >
                                Transaction ID: 
                                </Typography><Typography id="collect-details-card-title" variant="h3" >
                                    {scheduleId}
                                </Typography></Grid>:<Typography id="collect-details-card-title" variant="h3" >
                                Transaction ID: {scheduleId}
                                </Typography>}
                            </Grid>
                            {(scheduleInfo?.type !== 'REVERSAL') && <Grid id="collect-info-transaction-filter-grid" item container spacing={2} className="fx-action-block">
                                <Grid id="collect-details-card-header-third-grid" item container justifyContent="space-between">
                                   <Grid item className="fx-schedule-action-buttons"><ScheduleActions id='collect-details-card-schedule-actions-component' schedule={scheduleInfo} type="collect" redirect={redirectToEditPage} salesDetails={salesData}/></Grid>
                                </Grid>
                            </Grid>}
                        </Grid>

                    </FxCardHeader>
                    <FxCardBody id="collect-details-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                            {props?.data?.method && scheduleInfoData()}
                            {/* display source information */}
                            {props?.data?.method && sourceData()}
                            {location_source && <Grid item xs={12}>
                                {/* display location information*/}
                                <MerchantLocationCard id={"collect-details-card-location-information-" + scheduleId + "-section-info-card-component"} source={location_source} />
                            </Grid>}
                            <Grid item xs={12}>
                                {/* display destination information*/}
                                <FxInfoCard id={"collect-details-card-destination-information-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_section} rightcolumn={destination_info_section_two} dataTransformer={destinationDataTransformer} data={destinationDetails} ></FxInfoCard>
                            </Grid>
                            <Grid item xs={12}>
                                {/* audit details section */}
                                <FxInfoCard id={"collect-details-card-audit-details-one-" + scheduleId + "-section-info-card-component"} title="AUDIT DETAILS" leftcolumn={audit_details_section} rightcolumn={audit_details_section_two} data={props.data} ></FxInfoCard>
                            </Grid>
                        </Grid>
                    </FxCardBody>
                </FxCard>

            </Grid >
        )
    })