import React, { useEffect, useState } from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { FxRepeatEveryRecurring } from "./FxRepeatEveryRecurring";
import { FxEndRecurring } from "./FxEndRecurring";
import { FxWeekRecurring } from "./FxWeekRecurring";
import { FxYearRecurring } from "./FxYearRecurring";
import { FxOnDayRecurring } from "./FxOnDayRecurring";
import { FxOnDateRecurring } from "./FxOnDateRecurring";

Logger.debug("FxRecurring.tsx", "Fx Recurring initializing")
/**
 *  This component handles the recurring
 */

export const FxRecurring: React.FC<any> = React.memo(
    (props: any) => {
        // default data to be passed to the state
        const defDetails: any = {
            onDayStatus: props?.defaultValue?.dayOfMonth ? getDayStatus() : false,
            onDateStatus: props?.defaultValue?.dayOfMonth ? getDateStatus() : false,
            repeatEveryFormData: {},
            endFormData: {}
        }
        const defOnDayData = [{ order: 'FIRST', type: 'CALENDAR_DAY' }];
        const [recurringDetails, setRecurringDetails] = useState<any>(defDetails);
        const [onDayData, setOnDayData] = useState<any>(defOnDayData);
        const [repeatEvery, setRepeatEvery] = useState<any>(props?.defaultValue ? props?.defaultValue.frequency : '');
        // Method passes the form data for repeat every component
        const updateFormDataRepeatEvery = (data: any) => {
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['repeatEveryFormData'][data['name']] = data['value'];
            setRecurringDetails(copyRecurringDetails);
            if (props.updateTrigger) {
                props.updateTrigger(copyRecurringDetails)
            }
        }

         /**
         * method to check day exists
         * @returns :true/false
         */
        function getDayStatus() {
            let flag: any = false;
            if (props?.defaultValue?.dayOfMonth?.length) {
                flag = props.defaultValue.dayOfMonth.some((item: any) => item.includes('FIRST') || item.includes('SECOND') || item.includes('THIRD') || item.includes('FOURTH') || item.includes('FIFTH') || item.includes('LAST'));
            }
            return flag
        }
        /**
         * method to check date exists
         * @returns :true/false
         */
        function getDateStatus() {
            let flag = false;
            if (props?.defaultValue?.dayOfMonth?.length) {
                flag = props.defaultValue.dayOfMonth.some((item: any) => {
                    const numberItem = Number(item);
                    return numberItem > 0 && numberItem < 32;
                });
            }
            return flag
        }

        // Method passes the form data for end component
        const updateFormDataEnd = (data: any) => {
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['endFormData'][data['name']] = data['value'];
            setRecurringDetails(copyRecurringDetails);
            if (props.updateTrigger) {
                props.updateTrigger(copyRecurringDetails)
            }
        }
        // Method passes the form data for week component
        const updateFormDataWeek = (data: any) => {
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['weekFormData'] = data;
            setRecurringDetails(copyRecurringDetails);
            props.updateTrigger?.(copyRecurringDetails);
        }
        // Method passes the form data for year component
        const updateFormDataYear = (data: any) => {
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['yearFormData'] = data;
            setRecurringDetails(copyRecurringDetails);
            props.updateTrigger?.(copyRecurringDetails);
        }
        // Method handles the onchange event in the type selection
        const updateRepeatEveryTriggerFunction = (value: any) => {
            try {
                setRepeatEvery(value);
                setRecurringDetails({
                    ...recurringDetails,
                    'onDayStatus': (value === 'MONTH' || value === 'YEAR'),
                    'onDateStatus': false,
                })
                setOnDayData(defOnDayData)
            }
            catch (e) { }
        }
        // Method handles the output event from the on day switch component
        const handleOnDaySwitchChange = (value: any) => {
            try {
                const copyRecurringDetails = { ...recurringDetails }
                copyRecurringDetails['onDayStatus'] = value;
                props.updateTrigger?.(copyRecurringDetails);
                setRecurringDetails(copyRecurringDetails);
                setOnDayData(defOnDayData);
            }
            catch (e) { }
        }
        // Method handles the output event from the on date switch component
        const handleOnDateSwitchChange = (value: any) => {
            try {
                const copyRecurringDetails = { ...recurringDetails }
                copyRecurringDetails['onDateStatus'] = value;
                props.updateTrigger?.(copyRecurringDetails);
                setRecurringDetails(copyRecurringDetails);
            }
            catch (e) { }
        }
        // Method passes the form data for on day component
        const handleSubmitOnDay = (data: any) => {
            setOnDayData(data);
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['onDayFormData'] = data;
            setRecurringDetails(copyRecurringDetails);
            props.updateTrigger?.(copyRecurringDetails);
        }
        // Method passes the form data for on date component
        const handleSubmitOnDate = (data: any) => {
            const copyRecurringDetails = { ...recurringDetails }
            copyRecurringDetails['onDateFormData'] = data;
            setRecurringDetails(copyRecurringDetails);
            props.updateTrigger?.(copyRecurringDetails);
        }
        // function returns the week array from the data
        function getWeekArray(data: any, order: any){
            const weekArray: any = [];
            data?.forEach((item: any) => {
                if(item.includes(order+'_') && !item.includes('BUSINESS_DAY') && !item.includes('CALENDAR_DAY')){
                    weekArray.push(item.split(order+'_')[1]);
                }
            })
            return weekArray;
        }
        // checking that week already exists
        function checkWeekArray(data: any, order: any){
            let statusFlag = false;
            if(data?.length) {
                statusFlag = data.some((item: any) => item.type === 'WEEK' && item.order === order);
            }
            return statusFlag;
        }


        useEffect(()=>{
            const onDayArray: any = [];
            if(props?.defaultValue){
                    props?.defaultValue?.dayOfMonth?.forEach((val:any) => {
                        if(val.includes('BUSINESS_DAY')){
                            const newObj:any = {order : val.split('_BUSINESS_DAY')[0], type: 'BUSINESS_DAY' }
                            onDayArray.push(newObj)
                        }
                        else if(val.includes('CALENDAR_DAY')){
                            const newObj:any = {order : val.split('_CALENDAR_DAY')[0], type: 'CALENDAR_DAY' }
                            onDayArray.push(newObj)
                        }
                        else{
                            if(val.includes('_')){
                                        const weekArray:any = getWeekArray(props?.defaultValue?.dayOfMonth,val.split('_')[0])
                                        if(!checkWeekArray(onDayArray,val.split('_')[0])){
                                            const newObj:any = {order : val.split('_')[0], type: 'WEEK', week: weekArray }
                                            onDayArray.push(newObj)
                                        }
                            }
                        }
                    })
                    setOnDayData(onDayArray);
                }
            setRecurringDetails({
                ...recurringDetails,
                'onDayFormData': onDayArray,
                'repeatEveryFormData': {'repeatEvery':props?.defaultValue?.interval.toString(),'repeatEverySelect':props?.defaultValue?.frequency ?? 'CALENDAR_DAY'},
                'yearFormData': props?.defaultValue?.monthOfYear,
                'onDateFormData': props?.defaultValue?.dayOfMonth,
                'weekFormData': props?.defaultValue?.dayOfWeek,
                'endFormData': {'afterDate': props?.defaultValue?.endDate,'endRecurring': props?.defaultValue?.endDate ? 'AFTER' : (props?.defaultValue?.occurrence ? 'THE' : 'NEVER'),transactions: props?.defaultValue?.occurrence}
            })
            if (props.updateTrigger) {
                props.updateTrigger(recurringDetails)
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[props?.defaultValue])


        return (
            <Grid item className="fx-repeat-payment">
                <FxRepeatEveryRecurring id={props.id + 'fx-repeat-every-recurring'} updateRepeatEveryTrigger={updateRepeatEveryTriggerFunction} updateFormData={updateFormDataRepeatEvery} data={props?.defaultValue} register={props?.register} control={props?.control} errors={props?.errors} setValue={props?.setValue} clearErrors={props?.clearErrors}/>
                {repeatEvery === 'WEEK' && <FxWeekRecurring id={props.id + 'fx-week-recurring'} updateFormData={updateFormDataWeek} data={props?.defaultValue?.dayOfWeek} />}
                {repeatEvery === 'YEAR' && <FxYearRecurring id={props.id + 'fx-year-recurring'} updateFormData={updateFormDataYear} data={props?.defaultValue?.monthOfYear} />}
                {(repeatEvery === 'MONTH' || repeatEvery === 'YEAR') && <FxOnDayRecurring id={props.id + 'fx-on-day-recurring'} switchValue={recurringDetails?.onDayStatus} onDaySwitchChange={handleOnDaySwitchChange} data={onDayData} onSubmit={handleSubmitOnDay} />}
                {(repeatEvery === 'MONTH' || repeatEvery === 'YEAR') && <FxOnDateRecurring id={props.id + 'fx-on-date-recurring'} switchValue={recurringDetails?.onDateStatus} onDateSwitchChange={handleOnDateSwitchChange} onSubmit={handleSubmitOnDate} data={props?.defaultValue?.dayOfMonth} register={props?.register} control={props?.control} errors={props?.errors} setValue={props?.setValue} clearErrors={props?.clearErrors}/>}
                <FxEndRecurring id={props.id + 'fx-end-recurring'} updateFormData={updateFormDataEnd} data={props?.defaultValue} startDate={props.startDate} register={props?.register} control={props?.control} errors={props?.errors} setValue={props?.setValue} clearErrors={props?.clearErrors}/>
            </Grid>
        )
    })
