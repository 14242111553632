import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { AddMailingAddress } from './AddMailingAddress';
/**
 * Component used to display mailing address form
 */

Logger.debug("CreateMailingAddress.tsx", "mailing address initializing")

export const CreateMailingAddress: React.FC<any> = React.memo(() => {
        return (
            <Grid id="customer-profile-info-Grid" item container xs={12}>
                <Grid item xs={12} sm={8}>
                    <AddMailingAddress id="add-mailing-address"></AddMailingAddress>
                </Grid>
            </Grid >
        )
    })
