import React, { useCallback, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as VoidIcon } from '../../../assets/svg/cancel-icon-with-circle.svg';
import { clean, updateComponentParams } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import {  Divider } from '@mui/material';
import FxSnackBar from "../../Utils/fx-snack-bar";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
const httpClient = HttpClient.getClient();

Logger.debug("VoidCollect.tsx", "Void Collect initializing");



interface IVoidCollect {
  id?: string,
  open?: boolean,
  onClose: () => any,
  className?: string,
  commentRequired:boolean,
  metadata?: any;
  data?: any,
  scheduleId?:string
}
/**
 * This component handles the Voiding of the Auth Created
 */
export const VoidCollect: React.FC<IVoidCollect> = React.memo((props) => {

  ({ props } = RegisterComponent(props));
  const { 
    register, 
    handleSubmit, 
    control, 
    setValue, 
    formState: { isSubmitting, errors } 
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
    updateComponentParams(dispatch, props?.id, { commentRequired : false})  
    // eslint-disable-next-line react-hooks/exhaustive-deps    
}, []);


  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {
    let status: any;
    let request: any;

    request = {
      "reason": data.voidReason ,
      "comment": data.comment,
    };

    status = await voidCollect(clean(request));
    status = processAPIResponse(status)

    if(status.status){
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'Voided Successfully',
    });
    handleClose();
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'transaction-Cards-details-config-card1'+props?.metadata?.scheduleId } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: {id:"transaction-processing-card-" + props?.metadata?.scheduleId} });
    props.onClose();
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  
  }

  /**
  * On close handler
  */
  function handleClose() {
    props?.onClose();
  }

  /**
  * Method to handle changes on event
  * @param event : event type
  */
  const handleChange = useCallback((event: any) =>  updateComponentParams(dispatch, props?.id, { commentRequired : (event?.target.value === 'OTHERS' )}), [dispatch, props.id]);

  /**
   * Method to call api for void the collect via Card
   * @param payloadData : request payload
   */
  async function voidCollect(payloadData: any) {
    try {
      const url: any = props?.metadata?.captureData ? ('transaction/id/' + props?.metadata?.scheduleId + '/capture/id/' + props?.metadata?.captureData.id  + '/void' ) : 'transaction/id/' + props?.metadata?.scheduleId + '/void';
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("voidCollect.tsx", "error", err);
      return err;
    }
  }


  // Void Auth Card options data
  const voidOptions = [
    { label: "Incorrectly Charged", value: "INCORRECTLY_CHARGED" },
    { label: "Fraudulent", value: "FRAUDULENT" },
    { label:'Transaction Void Request Successful', value:"TRANSACTION_VOID_REQUEST_SUCCESSFUL"},
    { label: "Duplicate", value: "DUPLICATE" },
    { label: "Others", value: "OTHERS" },
  ];

  return (
    <div className="modal-content" >
    <div>
      {<form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className="fx-manage-card-usage-modal">
          <FxCardHeader id="void-schedule-header">
            <Grid container direction="row" item xs={12} justifyContent="space-between">
              <Grid item container xs={11} justifyContent="flex-center" className="fx-capture-collect-header">
                {/* image */}
                <Grid><VoidIcon></VoidIcon></Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="void-schedule-modal-body" className="fx-info-card" >
            <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
            <Grid item xs={12}>
               <Typography id="sub-title-void-schedule-modal" className="fx-void-header fx-capture-collect-header" variant="h6">
                Are you sure that you want to void?
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography id="sub-title-void-schedule-modal" className="fx-capture-collect-subheading fx-capture-collect-header" variant="h6">
                    You cannot undo this action
                  </Typography>
              </Grid>
                <Grid item xs={12} sm={12}>
                  <FxMaterialSelect
                    register={{ ...register("voidReason") }}
                    className={errors.voidReason ? "border-error-input fx-input-edit" : "fx-input-edit"}
                    rules={{ required: true }}
                    control={control}
                    id="freeze-debit-card-reason"
                    name="voidReason"
                    data={voidOptions}
                    label="Reason of Void*"
                    setValue={setValue}
                    onChange={handleChange}
                    placeholder="Select"
                    value={''}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FxTextEdit
                    register={{ ...register("comment") }}
                    type="text"
                    passwordIcon={true}
                    className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                    control={control}
                    rules={{ required: props?.commentRequired }}
                    id="comment"
                    label={props?.commentRequired ? "Comment*" : "Comment"}
                    name="comment"
                    variant="outlined"
                  />
                </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </FxCardBody>
          <FxCardFooter id="void-schedule-modal-Footer" className="fx-footer">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={() => props?.onClose()}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
                <FxButton
                  disableRipple={false}
                  className="fx-button fx-button-theme"
                  id={"void-auth-collect-submit-button"}
                  type="submit"
                  isSubmitting={isSubmitting}
                >
                  VOID
                </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>}
    </div>
  </div>
  );
});

