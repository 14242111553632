import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useDispatch } from "react-redux";
import moment from 'moment';
import { first } from 'lodash';
import { DATA_STATE } from '../../../../libs/reducer/dataReducer';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxSkeltonList } from '../../Cards/FxSkelton';
import { RecurringInfoCard } from './RecurringInfoCard';
import { RecurringViewDocuments } from './RecurringViewDocCard';
import { RecurringHistoryListingCard } from './RecurringHistoryCard';

Logger.debug("RecurringCards.tsx", "RecurringCards initializing")

/**
 * This component handles the redirection to corresponding schedule details cards
 */
export const RecurringCards: React.FC<any> = React.memo((props) => {
        let context: any;
        let schedule_info: any;
        let scheduleId: any;
        ({ context, props } = RegisterComponent(props));

        if (context && context.data && context.data.body) {
            try {
                schedule_info = { ...context.data.body };
                scheduleId = schedule_info?.id;
                schedule_info.createdInfo = (context.data.body['createdBy']['username'] + '\nOn ' + moment(context.data.body['createdOn']).format('MM/DD/YYYY'));
                schedule_info.updatedInfo = (context.data.body['lastUpdatedBy']['username'] + '\nOn ' + moment(context.data.body['lastUpdatedOn']).format('MM/DD/YYYY'));

            }
            catch (e) { }
        }
        // @ts-ignore
        const isCollect = !!first(schedule_info?.action)?.transaction?.source?.externalAccount || !!first(schedule_info?.action)?.transaction?.source?.contact?.externalAccount || !!first(schedule_info?.action)?.transaction?.source?.contact?.card;

        //select schedule info by type
        /**
         * Function handles the rendering of the corresponding schedule details cards based on the schedule type
         * @returns : info card component
         */
        function getRecurringData() {
            let account_source: any;
            let external_account: any;
            if (schedule_info?.action) {
                for (let recurring of schedule_info?.action) {
                    if (recurring?.transaction?.method === 'CHECK') {
                        external_account = {
                            url: recurring?.transaction?.destination?.address?.url.replace('/v1/', ''),
                            method: "GET",
                            baseUrl:true
                        }
                    } else
                        if (recurring?.transaction?.method === 'BOOK') {
                            external_account = {
                                url: recurring?.transaction?.destination?.account?.url.replace('/v1/', ''),
                                method: "GET",
                                baseUrl:true
                            }
                        }
                        else if (recurring?.transaction?.method === 'INTERNATIONAL_WIRE') {
                            external_account = {
                                url: recurring?.transaction?.destination?.internationalExternalAccount?.url.replace('/v1/', ''),
                                method: "GET",
                                baseUrl:true
                            }
                        }
                        else {

                            const externalUrl = recurring?.transaction?.destination?.externalAccount?.url || recurring?.transaction?.destination?.account?.url;
                            external_account = {
                                url: externalUrl?.replace('/v1/', ''),
                                method: "GET",
                                baseUrl:true
                            }
                        }
                    const sourceUrl = recurring?.transaction?.source?.account?.url || recurring?.transaction?.source?.externalAccount?.url || recurring?.transaction?.source?.card?.url || recurring?.transaction?.source?.contact?.url;
                    account_source = {
                        url: sourceUrl?.replace('/v1/', ''),
                        method: "GET",
                        baseUrl:true
                    }
                    if (recurring?.transaction?.destination?.contact) {

                        external_account = {
                            url: recurring?.transaction?.destination?.contact?.url.replace('/v1/', ''),
                            method: "GET",
                            baseUrl:true
                        }
                    }

                    return <RecurringInfoCard id={"deposit-info-card" + scheduleId} isCollect={isCollect} accountSource={isCollect ? external_account : account_source} data={schedule_info} source={isCollect ? account_source : external_account} ></RecurringInfoCard>

                }

            }
        }

        const dispatch = useDispatch();
        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //check api call completed or not
        let contextState = context?.data_state;
        let dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container" >
                        <Grid container xs={12} sm={8} className="fx-container-left">
                            {dataLoadStatus ?
                                (schedule_info && getRecurringData())
                                :
                                <div className={'fx-margin-bottom-2 fx-width-100'}>
                                    <FxSkeltonList height="25rem" />
                                </div>
                            }
                            {dataLoadStatus ?
                            (schedule_info && <RecurringViewDocuments id={'documents-recurring-account'} data={schedule_info} />)
                                :
                                <div className={'fx-margin-bottom-2 fx-width-100'}>
                                    <FxSkeltonList height="8rem" />
                                </div>
                            }
                            {dataLoadStatus ?
                                (schedule_info && <RecurringHistoryListingCard id={'recurring-account'} source={schedule_info}></RecurringHistoryListingCard>)
                                :
                                <div className={'fx-margin-bottom-2 fx-width-100'}>
                                    <FxSkeltonList height="8rem" />
                                </div>
                            }
                        </Grid>
                    </Grid>
        )
    })