import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DataGrid } from '../../../Data/DataGrid';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { ReactComponent as LeftDownArrow } from '../../../../assets/svg/arrow-left-downbig.svg'
import { ReactComponent as RightUpArrow } from '../../../../assets/svg/arrow-right-topbig.svg'
import { IFilterColumns, IFilterFields, IDataGridProps } from '../../../../types/common.interfaces';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { Filter } from '../../../Data/Filter';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { truncateChars, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { isBatchRequired, setColumnsBasedOnSettings } from '../../../Utils/ThemeChecker';
import ShowFasterFundingAsPerTheme from '../../../Utils/ThemeChecker';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../../Data/FxGridList';
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { useHistory } from 'react-router-dom';
import { ReactComponent as HoldLedger } from '../../../../assets/svg/hold-ledger.svg';
import { FxButtonGroup } from '../../../Action/FxButtonGroup';
import { getBatchIdJsx, getBatchNumberJsx } from '../../../Utils/batch';

/**
 * This component handles the list of Batched View Ledgers
 */
export const BatchedViewList: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    const history = useHistory()

        const accountId = getKey("selectedAccount")?.id;
        function sourceInfo() {
            var filters: any = [];
            if (accountId) {
                const src = {
                    url: "account/id/"+getKey("selectedAccount")?.id+"/ledger/batch/list",
                    method: "POST",
                    data: {
                        "pageNumber": 1,
                        "pageSize": 25,
                        "sortOptions": {
                            "sortBy": "lastUpdatedOn",
                            "sortOrder": "desc"
                        },
                        "criteria": {
                        }
                    }
                }

                try {
                    filters.push({
                            "operator": "gte",
                            "key": "lastUpdatedOn",
                            "values": [
                                moment().subtract(1, 'months').format('MM/DD/YYYY')
                            ]
                        },
                        {
                            "operator": "lte",
                            "key": "lastUpdatedOn",
                            "values": [
                                moment().format('MM/DD/YYYY')
                            ]
                        }, {
                            "operator": "eq",
                            "key": "account.id",
                            "values": [accountId]
                        })

                    src.data.criteria = { filters }
                }

                catch (e) { }
                return src
            }else{
                return  {
                    url: "",
                    method: "POST",
                    data: {}
                }
            }
        }

    /**
    * useEffect to load title while loading page
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Ledger' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * useEffect to reset the filter while unmount the page
    */
      useEffect(() => () => {
        updateComponentParams(dispatch, 'batched-view-ledger-card-filter', { resetFilter: true });
    }, [dispatch]);

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "icon",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem", "padding-left": "0 !important" },
                    "dataStyle": { "text-align": "left !important" }
                },
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "ledgerDate",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": { "width": "10rem" }
                },
                {
                    "label": "Batch ID",
                    "enable": true,
                    "fieldName": "batchId",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "7rem" },
                },
                {
                    "label": "TXN ID",
                    "enable": true,
                    "fieldName": "transactionId",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "7rem" },
                },
                {
                    "label": "NARRATION",
                    "enable": true,
                    "fieldName": "narration",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "30rem" },
                },
                {
                    "label": "CREDIT",
                    "enable": true,
                    "fieldName": "credit",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"width": "7rem", "text-align": "end" }
                },
                {
                    "label": "DEBIT",
                    "enable": true,
                    "fieldName": "debit",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"width": "7rem", "text-align": "end" }
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4rem"}
                },
            ]
        }

        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        //type of Ledger
        const ledgerTypeData = [
            { id: 'BATCHED_VIEW', value: 'BATCHED_VIEW', label: 'BATCHED VIEW', selected: true },
            { id: 'ALL_LEDGERS', value: 'ALL_LEDGERS', label: 'ALL LEDGERS', selected: false}
        ];

        /**
         * Method handles the state that from which parent component BatchDetail is being called
         */
        const handleTabClick = (item:any) => {
            history.push({pathname: (item.trimmedBatchId ? `/ledgerBatchDetails/${item.trimmedBatchId}` : `/ledgerBatchDetails/${item.batchId}` ), state: {url:'/batchedviewledgers'}})
        }

        /**
         * Method handles the transformation of ledger batch list data
         * @param data
         * @returns : transformed data
         */
        function transactionBatchList(data: any) {
            const newData: any = [];
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                newData[i].ledgerDate = item.ledgerDate;
                newData[i].batchId = getBatchIdJsx(item, isSmallScreen,()=>handleTabClick(item));
                newData[i].transactionId = item?.schedule?.id ? <Typography>{item.schedule.id}</Typography>  : '-'  
                newData[i].narration = <><span title={item.narration}>{` ${item.narration ? truncateChars(item?.narration, 40) : ''}`} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'recurring-method'} value={ (item.ledgerType === "HOLD" || item.ledgerType === 'RELEASE') ? "BOOK" : item?.method } noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>{(item.isFasterFunded && item.isFasterFunded === true) && <ShowFasterFundingAsPerTheme/>}</>
                newData[i].currentBalance = item?.currentBalance ? <FxCurrencyView value={item.currentBalance} justifyContent="flex-end" prefix={"$"}/> : <span className='fx-span-float-right'>-</span>
                newData[i].credit = item.type === "CREDIT" ?  <FxCurrencyView className={ "balance-amount-green"} justifyContent="flex-end" value={item.amount} prefix={"$"} /> : <span className='fx-span-float-right'>-</span> 
                newData[i].debit= item.type === "DEBIT" ?  <FxCurrencyView className={ "balance-amount-red"} justifyContent="flex-end" value={item.amount} prefix={"$"} /> :  <span className='fx-span-float-right'>-</span> 
                newData[i].icon = item.ledgerType === "HOLD" ?  <div className={"fx-passport-debit-credit-svg"} ><HoldLedger /></div> :
                   item.type === "DEBIT"? <div className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></div>:   <div className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></div>
                             
            });
            return newData;
        }
         /**
         * @param data : ledger batch List
         * @returns : transformed data
         */
        const ledgerBatchGridTransformerMobile =(data:any)=>{
            const newData: any = [];
            let cur_date: any = '';
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                let title: any
                if (cur_date === '' || cur_date !== moment(new Date(item?.ledgerDate)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.ledgerDate)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.ledgerDate)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                // eslint-disable-next-line eqeqeq
                newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={2} alignItems='center' justifyContent={'flex-start'}>
                        {item.type === "DEBIT" ? <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></Grid></Grid> :
                            <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></Grid></Grid>}
                    </Grid>
                    <Grid item container xs={10} alignItems='center' alignContent={'center'}>
                        <Grid item container xs={12}>
                            <Grid item>
                                <Grid title={item?.narration}><Typography variant='h3'><span>{truncateChars(item?.narration, 25)}&nbsp;</span></Typography></Grid>
                            </Grid>
                            <Grid item>
                                &nbsp;<FxPaymentModeStatus tooltip={true} id={'transaction-method'} value={(item.ledgerType === "HOLD" || item.ledgerType === 'RELEASE') ? "BOOK" : item?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>{(item.isFasterFunded && item.isFasterFunded === true) && <ShowFasterFundingAsPerTheme/>}
                            </Grid>
                            <Grid item container xs justifyContent='end'>
                                <Grid item xs>
                                    <Grid>{item.type === "DEBIT" ? <FxCurrencyView className={"balance-amount-red"} value={item.amount} justifyContent="flex-end" prefix={"$"} showPlus /> : <FxCurrencyView className={"balance-amount-green"} value={item.amount} justifyContent="flex-end" prefix={"$"} showPlus />}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs alignItems='center'>
                            {item?.memo && <Grid item>
                                <Grid><Typography variant='h5'>Memo: {item?.memo}&nbsp;</Typography></Grid>
                            </Grid>}
                            {item?.schedule?.id && <Grid item>
                                <Grid><Typography variant='h5'>Txn ID: {item?.schedule?.id}</Typography></Grid>
                            </Grid>}
                            {getBatchNumberJsx(item, isSmallScreen, ()=>handleTabClick(item))}
                        </Grid>
                    </Grid>
                </Grid>
            });
            return newData;
        }
        const datagridProps: IDataGridProps = {
            id: "batched-view-ledger-data-grid-list",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
        };

        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "Batch ID",
                    "fieldName": "trimmedBatchId",
                    "type": "fri://fieldtype/textfield",
                    "operator":"eq"
                },
                {
                    "label": "TXN ID",
                    "fieldName": "transactionId",
                    "type": "fri://fieldtype/textfield",
                    "operator":"eq"
                },
                {
                    "label": "Method",
                    "fieldName": "method",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "ACH", "value": "ACH", "checked": false },
                        { "label": "Card", "value": "CARD", "checked": false },
                        { "label": "Check", "value": "CHECK", "checked": false },
                        { "label": "Book", "value": "BOOK", "checked": false },
                        { "label": "Wire", "value": "WIRE", "checked": false },
                        { "label": "International Wire", "value": "INTERNATIONAL_WIRE", "checked": false },
                        { "label": "Virtual Card", "value": "VIRTUAL_CARD", "checked": false },
                        { "label": "Debit Card", "value": "DEBIT_CARD", "checked": false },
                    ]
                },
                {
                    "label": "Date",
                    "toLabel": "To",
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                    "notAllowFutureDate": true,
                    "defaultValue": {
                        "from": moment().subtract(1, 'months').format('MM/DD/YYYY'),
                        "to": moment().format('MM/DD/YYYY')
                    }
                }
            ]
        }
        const permission ={
            entity: "Accounts",
            name: "Ledger",
            operation: "View"
          }

          /**
           * Method handles the change event of the Ledger Type
           */
 const handleTypeChange = (item: any) => {
    try {
        if (item.value && item.value === 'ALL_LEDGERS') {
              history.push('/accountledgers');
        }
    }
    catch (e) { }
  }

        return (
            
            <Grid id="batched-view-ledger-first-grid" item xs={12}>
                <Grid item xs={12} className="fx-flex-grow fx-ea-type-button-mobile" >
                    <FxButtonGroup id="all-ledgers-card-button-group" options={ledgerTypeData} onClick={handleTypeChange} />
                </Grid>
                <Grid item xs={12} >&nbsp;</Grid>
                <Grid container xs={12} className="fx-layout-list" >
                    <FxCard id="batched-view-ledger-card" className="fx-theme-passport">
                        <FxCardHeader>
                            <Grid id="batched-view-ledger-header-first-grid" item container justifyContent="flex-end" xs={12}>
                                <Grid id="batched-view-ledger-card-header-filter-grid" item className="fx-inline-flex" >
                                    <Filter id="batched-view-ledger-card-filter" field={isBatchRequired(filterFields)} permissions={permission} dataGridProps={datagridProps} hiddenParameter={['account.id']}></Filter>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="batched-view-ledger-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="batched-view-ledger-card-body-grid" item xs={12}>
                                {!isSmallScreen ? <DataGrid id="batched-view-ledger-data-grid-list" permissions={permission} tableClass="fx-table-passport" dataTransformer={transactionBatchList} source={sourceInfo()} column={setColumnsBasedOnSettings(column)} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                                    : <FxGridList id="batched-view-ledger-data-grid-list" source={sourceInfo()} permissions={permission} dataTransformer={ledgerBatchGridTransformerMobile} column={setColumnsBasedOnSettings(gridColumn)} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />}
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="batched-view-ledger-card-footer">
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>

        )
    })
