/**
 * Component : JointTenancyCoOwnerAdd
 * Using for Add multiple co owner
 */
import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { AUTH_STRINGS } from '../../../../../constants/strings';
import FxCard from '../../../../Container/FxCard';
import FxCardBody from '../../../../Container/FxCardBody';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxTextEdit } from '../../../../Input/FxText/FxTextEdit';
import FxLabelView from '../../../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../../../Action/FxButton';
import usa_state from '../../../../Utils/usa_states.json'
import { deleteUnwantedRequest, handleKeyDownChange, handleZipChange, handleAddressChange, renderError, setZipValidationOptions, setPhoneValidationOptions, convertToPhoneRequestFormat, renderPhoneError, setSsnValidationOptions, setEmailValidationOptions } from '../../../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../../../Input/FxPhone/FxPhoneEdit';
import FxCardHeader from '../../../../Container/FxCardHeader';
import { FxTextEditSSN } from '../../../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../../../Input/FxSelect/FxSelectAutoSearch';
import { useMediaQuery } from 'react-responsive';

export const JointTenancyCoOwnerAdd: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();        
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        
  

        // Method handles the submit for add beneficial owner form
        function onSubmit(data: any) {
            const req: any = { ...data, isPrimaryOwner:false,portalAccess: {
                grantAccess: true
            } };
            if (data['dob']) {
                req['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            req['mailingAddress'] = [{
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip'],
                "isPrimary": true
            }]
            
            req['mobilePhone'] = convertToPhoneRequestFormat(req.mobilePhone)
            if(req['mobilePhone'] === ""){
                delete req['mobilePhone'];
            }
            
            let unwantedRequestArray = ['state', 'addressLine1', 'city', 'zip', 'addressLine2','mobilePhone-select'];
            deleteUnwantedRequest(req, unwantedRequestArray)
            if (props.onSubmit) {
                props.onSubmit(data, req, 'add', undefined)
            }

        }

        /**
         * Method handles the click operation for going back to joint tenancy list screen
         */
        const handleGoBackClick = () => {
            if (props?.cancel) {
                props.cancel()
            }
        }

         /**
  *  Address line 1 validation constant
  * */ 
         const setAddressLine1Validation = {
            required: true,
            minLength: {
                value: 4,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            },
            maxLength: {
                value: 40,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            }
        }

        return (
            <Grid container id='signUp-joint-tenancy' xs={12} className='fx-joint-tenancy-add'>
                <FxCard id="signUp-joint-tenancy-form-card" className={isSmallScreen?"fx-theme-passport fx-joint-tenancy-add-border":"fx-theme-passport"}>
                    <FxCardHeader>
                      <Typography className='fx-new-invite-owner-profile-label'>{props?.data ? props?.data?.length + 1 + ". " : 1 + ". "}Co-Owner Information</Typography>
                    </FxCardHeader>
                    <div className="fx-form-edit-profile">
                        <form id="signUp-joint-tenancy-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCardBody id="signUp-joint-tenancy-form-card-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-joint-tenancy-form-card-first-name" label="First Name " name="firstName" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("middleName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-joint-tenancy-form-card-middle-name" label="Middle Name" name="middleName" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("lastName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-joint-tenancy-form-card-last-name" label="Last Name *" name="lastName" variant="outlined" />
                                    </Grid>
                                   
                                    <Grid item sm={6} xs={12}>
                                        <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-joint-tenancy-form-card-dob" label="Date of Birth *" name="dob" variant="outlined"  maxDate={moment()}  setValue={setValue}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEditSSN
                                            register={{ ...register("ssn") }}
                                            className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            error={errors?.ssn}
                                            control={control}
                                            rules={setSsnValidationOptions}
                                            id="signUp-joint-tenancy-form-card-ssn"
                                            label="Social Security Number *"
                                            placeholder="XXX-XX-XXXX"
                                            name="ssn"
                                            variant="outlined"
                                            setValue={setValue}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />
                                        <div className={'error-message'}>
                                            {errors.ssn && renderError(errors.ssn)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="joint-tenancy-details-label" className="" >PRIMARY ADDRESS</FxLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"}  rules={setAddressLine1Validation} control={control} id="signUp-joint-tenancy-addressLine1" label="Address Line 1 *" name="addressLine1"  onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                        <div className={'error-message'}>
                                            {errors.addressLine1 && renderError(errors.addressLine1)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-joint-tenancy-addressLine2" label="Address Line 2" name="addressLine2" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-joint-tenancy-city" label="City *" name="city" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-joint-tenancy-select-state" name="state" data={usa_state} label="State *" setValue={setValue} value={''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="signUp-joint-tenancy-zip" label="ZIP *" name="zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                        <div className={'error-message'}>
                                            {errors.zip && renderError(errors.zip)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxPhoneEdit register={register} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id="signUp-joint-tenancy-form-card-mobilePhone" label="Mobile Phone *" name="mobilePhone" placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} clearErrors={clearErrors} isEditable={true} />
                                        <div className={'error-message'}>
                                            {errors.mobilePhone && renderPhoneError(errors.mobilePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions(true)} id="signUp-joint-tenancy-form-card-email" label="Email *" name="email" variant="outlined" />
                                        <div className={'error-message'}>
                                            {errors.email && renderError(errors.email)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="signUp-joint-tenancy-Card-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton
                                        disableRipple={false}
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="cancel-add-joint-tenancy-btn"
                                        onClick={handleGoBackClick}
                                    > CANCEL
                                    </FxButton>
                                    <Grid className='fx-padding-right-15'></Grid>
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-add-joint-tenancy-btn"
                                        type="submit"
                                    > Save
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>
                    </div>
                </FxCard>
            </Grid>
        )
    }
)
