import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import countries from '../../../libs/utils/country/country.json';
import { Logger } from '../../../libs/utils/logger';
import { FxSwitch } from '../../Action/FxSwitch';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { renderStateError } from '../../Utils/CommonBaseClass';
import {
  handleKeyDownChange,
  handleZipChange,
  renderError,
  renderPhoneError,
  setZipValidationOptions,
  setPhoneValidationOptions,
  renderAddressLine1Error,
  renderCityNameError,
  setCityNameValidation,
  setAddressLine1Validation
} from '../../Utils/CommonBaseClass';
import usa_state from '../../Utils/usa_states.json';

Logger.debug("AVSDetailsForm.tsx", "AVS Details Form initializing");

type FieldValues = Record<string, string>;
type TFieldValues = Record<string, string | number>;

interface IAVSDetailsForm {
  register: UseFormRegister<TFieldValues>,
  control: Control<FieldValues>,
  setValue: UseFormSetValue<any>;
  errors: any;
  clearErrors: any;
  setError: any;
  watch: any;
  defaultValues?: {
    avsFirstName: string,
    avsLastName: string,
    avsAddressLine1: string,
    avsCity: string,
    avsState: string,
    avsCountry: string,
    avsZip: string,
    avsEmail: string,
    avsPhone: string,
    addressSame: boolean
  };
  handlePopulateAVSDetails: () => void;
}

/**
 * `AVSDetailsForm` is a form component for entering AVS (Address Verification System) details.
 * It includes fields for personal and address information, with validation and error handling.
 * It also has a switch to auto-fill the form with holder address details.
 */

export const AVSDetailsForm: React.FC<IAVSDetailsForm> = React.memo((props) => {

  const {
    register,
    setValue,
    clearErrors,
    control,
    setError,
    errors,
    watch,
    handlePopulateAVSDetails,
    defaultValues
  } = props;


  /**
   * Initializing the properties with default values for the address fields in the accordions
   */
  const [addressSame, setAddressSame] = useState<boolean>(false);

  /**
   * Initializing the properties with default values for the address fields in the accordions when data is present already
   */
  useEffect(() => {
    setAddressSame(defaultValues?.addressSame || false);
  }, [defaultValues?.addressSame]);


  /**
   * Handles the toggling of the Address Button
   */
  const handleAddressToggle = (e: boolean) => {
    if(e){
      setValue('addressSame', !addressSame);
      handlePopulateAVSDetails();
    } else {
      setValue('avsFirstName', '');
      setValue('avsLastName', '');
      setValue('avsAddressLine1', '');
      setValue('avsCity', '');
      setValue('avsState', '');
      setValue('avsZip', '');
      setValue('avsEmail', '');
      setValue('avsPhone', '');
      setValue('addressSame', !addressSame);
    }
    setAddressSame(e);
  }

  return (
    <Grid item container xs={12}>
       <Grid item container xs={12} alignItems='center' alignContent={'center'}>
            <FxSwitch
              id="additional-info-toggle"
              value={addressSame}
              onClick={handleAddressToggle}
              disabled={false}
            />{" "}
            <FxLabel value="AVS details are same as holder address details"></FxLabel>
          </Grid>
      {!addressSame && <fieldset className="fx-container-create-schdule-summery fx-invoice-details-address">
        <legend >AVS Details</legend>
        <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FxTextEdit
                  register={{ ...register("avsFirstName") }}
                  control={control}
                  rules={{ required: false }}
                  className={
                    errors.avsFirstName
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-first-name-textbox"
                  name="avsFirstName"
                  label="First Name"
                  setValue={setValue}
                  isEditable={!addressSame}
                  defaultValue={defaultValues?.avsFirstName || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FxTextEdit
                  register={{ ...register("avsLastName") }}
                  control={control}
                  rules={{ required: false }}
                  className={
                    errors.avsLastName
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-last-name-textbox"
                  name="avsLastName"
                  label="Last Name"
                  setValue={setValue}
                  isEditable={!addressSame}
                  defaultValue={defaultValues?.avsLastName || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FxTextEdit
                  register={{ ...register("avsAddressLine1") }}
                  control={control}
                  rules={setAddressLine1Validation(false)}
                  className={
                    errors.avsAddressLine1
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-address-line-1-textbox"
                  name="avsAddressLine1"
                  label="Address Line"
                  setValue={setValue}
                  isEditable={!addressSame}
                  defaultValue={defaultValues?.avsAddressLine1 || ''}
                />
                <div className={"error-message"}>
                  {errors.avsAddressLine1 &&
                    renderAddressLine1Error(errors.avsAddressLine1)}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FxTextEdit
                  register={{ ...register("avsCity") }}
                  control={control}
                  rules={setCityNameValidation(false)}
                  className={
                    errors.avsCity
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-city-textbox"
                  name="avsCity"
                  label="City"
                  isEditable={!addressSame}
                  defaultValue={defaultValues?.avsCity || ''}
                  setValue={setValue}
                />
                <div className={"error-message"}>
                  {errors.avsCity && renderCityNameError(errors.avsCity)}
                </div>
              </Grid>
              {watch('avsCountry') === "US" ?
                <Grid item xs={12} sm={6}>
                  <FxMaterialSelect
                    register={{ ...register('avsState') }}
                    rules={{ required: false }}
                    className={errors.avsState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                    control={control}
                    id="additional-info-avs-state-select"
                    name="avsState"
                    data={usa_state}
                    label="State"
                    value={defaultValues?.avsState || ''}
                    readOnly={addressSame}
                    setValue={setValue}
                  />
                  <div
                    className={'error-message'}>{errors.avsState && renderStateError(errors.avsState)}
                  </div>
                </Grid>
                : <Grid item xs={12} sm={6}>
                  <FxTextEdit
                    register={{ ...register('avsState') }}
                    control={control}
                    rules={{ required: false}}
                    className={errors.avsState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                    id="additional-info-avs-state-textbox"
                    name="avsState"
                    label="State"
                    isEditable={!addressSame}
                    defaultValue={defaultValues?.avsState || ''} />
                  <div
                    className={'error-message'}>{errors.avsState && renderStateError(errors.avsState)}
                  </div>
                </Grid>}
              <Grid item xs={12} sm={6}>
                <FxMaterialSelect register={{ ...register("avsCountry") }} rules={{ required: false }}
                                  className={errors.avsCountry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                                  id="additional-info-avs-country-textbox" name="avsCountry" data={countries.slice(1)} label="Country" readOnly={true}
                                  value={'US'} setValue={setValue}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FxTextEdit
                  register={{ ...register("avsZip") }}
                  control={control}
                  rules={setZipValidationOptions(false)}
                  className={
                    errors.avsZip
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-zip-textbox"
                  name="avsZip"
                  label="ZIP"
                  defaultValue={defaultValues?.avsZip || ''}
                  onChange={(e: any) => {
                    handleZipChange(e, setValue, setError, clearErrors);
                  }}
                  onKeyDown={(e: any) => {
                    handleKeyDownChange(e, setValue);
                  }}
                  isEditable={!addressSame}
                />
                <div className={"error-message"}>
                  {errors.avsZip && renderError(errors.avsZip)}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FxTextEdit
                  register={{ ...register("avsEmail") }}
                  className={
                    errors.avsEmail
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  control={control}
                  rules={{ required: false }}
                  id="additional-info-avs-email-textbox"
                  label="Email"
                  name="avsEmail"
                  type="email"
                  variant="outlined"
                  defaultValue={defaultValues?.avsEmail || ''}
                  isEditable={!addressSame}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FxPhoneEdit
                  register={register}
                  control={control}
                  rules={setPhoneValidationOptions(false)}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  isEditable={!addressSame}
                  className={
                    errors.avsPhone
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id="additional-info-avs-phone-textbox"
                  name="avsPhone"
                  defaultValue={defaultValues?.avsPhone || ''}
                  label="Mobile Phone"
                />
                <div className={"error-message"}>
                  {errors.avsPhone &&
                    renderPhoneError(errors.avsPhone)}
                </div>
              </Grid>
        </Grid>
      </fieldset>
      }
    </Grid>
  );
});
