import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import FxCardHeader from '../../../../Container/FxCardHeader';
import FxCard from '../../../../Container/FxCard';
import { BusinessOwnerAdd } from './BusinessOwnerAdd';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxButton } from '../../../../Action/FxButton';
import { BusinessOwnerCardView } from './BusinessOwnerCardView';
import FxCheckEdit from '../../../../Input/FxCheck/FxCheckEdit';
import FxCardBody from '../../../../Container/FxCardBody';

//icons
import { ReactComponent as AddIcon } from '../../../../../assets/svg/add-new-icon.svg';
import { ReactComponent as MultipleUser } from '../../../../../assets/svg/multiple-user-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { renderError, updateComponentParams, validateData } from '../../../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
//component used to handle business owner pages

export const BusinessOwnerCard: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
        const { register, formState: { errors }, setValue, control, setError, clearErrors } = useForm();
        const [btnDisabled, setBtnDisabled] = useState(true);
        const [hideCreateButton, setHideCreateButton] = useState(false);
        const [addScreenHide, setAddScreenHide] = useState(true);
        const [editScreenOpen, setEditScreenOpen] = useState(false);

        const certifyBusinessOwnerDate: any = [{ label: 'I certify that I have added all business owners in the business and all authorised signatory who control and manage business finances.' }]

        useEffect(() => {
            updateComponentParams(dispatch, props.id, {isLoading: false})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        /**
         * submit data
         */
        const onSubmitData = (data: any, req: any, action: any, index: any) => {
            if (props?.onSubmit) {
                setAddScreenHide(true)
                props.onSubmit(data, req, action, index)
            }
        }
        /**
         * method to handle removed data
         * @param index :index
         * @param array :updated array
         */
        const updatedData = (index: any, array: any) => {
            if (props?.updatedData) {
                props?.updatedData(index, array)
            }
            if (props?.data?.length === 1) {
                setHideCreateButton(false)
                setBtnDisabled(true)
            }
        }

        /* method to validate Beneficial Owner data
        * @param item 
        */
        const validateBOData = (item: any) => {
            if(item?.authSignatory === true && (props?.businessDetails['businessCategory'] === 'GOVERNMENT_ORGANISATION' || props?.businessDetails['businessCategory'] === 'NON_PROFIT' || props?.businessDetails['businessCategory'] === 'TAX_EXEMPTION' || props?.businessDetails['businessCategory'] === 'LLC' || props?.businessDetails['businessCategory'] === 'LLP' || props?.businessDetails['businessCategory'] === 'S-CORP ' || props?.businessDetails['businessCategory'] === 'C-CORP')){
                return validateData(item);
            }else{
                return validateData(item) && item?.ownerShipPercentage;
            }
        };

        /**
         * function to validate all the Beneficial Owners Data
         * @param data 
         */
        const checkBusinessOwnerData = (data: any) => {
            return data?.every(validateBOData) ?? true;
        };

        /**
         * handle change method for checkbox
         * @param event :event
         */
        const handleChangeCertifyBusinessOwner = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target.checked === true) {
                setBtnDisabled(false)
            }
            else {
                setBtnDisabled(true)
            }
        }

        // Method handles the click operation for going back to business details screen
        const handleGoBackToDetailsClick = () => {
            props.goBackClick(2, 2);
        }

        /**
         * Method check whether an authorized signatory exists in the beneficial owners data
         * @param data : beneficial owner data
         * @returns : returns true if authorized signatory exists and false else
         */
        const checkAuthorizedSignatoryExists = (data: any) => {
            return data?.some((item: any) => item.authSignatory === true || (Array.isArray(item.authSignatory) && item.authSignatory[0] === 'authorized'));
        }

        // Method set the click event for going forward to next screen
        const handleClick = () => {
            if(!checkAuthorizedSignatoryExists(businessOwnerData)){
                setError("authMandatory", {
                    type: "manual",
                    message: "Please provide at least one authorized signatory to proceed.",
                  });
                setTimeout(() => {
                    clearErrors('authMandatory')
                }, 10000);
                return;
            }
            if(!checkBusinessOwnerData(businessOwnerData)){
                setError("validateAuthDetails", {
                    type: "manual",
                    message: "Please ensure all the mandatory fields are filled.",
                  });
                setTimeout(() => {
                    clearErrors('validateAuthDetails')
                }, 10000);
                return;
            }

            if (props.onClick) {
                updateComponentParams(dispatch, props.id, { isLoading: true })
                props.onClick(()=>{
                    updateComponentParams(dispatch, props.id, { isLoading: false })
                });
            }
        }

        // Method set the click event for adding business owner
        const addAnotherBusinessOwner = () => {
            setHideCreateButton(true)
            setAddScreenHide(false)
        }

        //update data when changes happened
        const businessOwnerData = useMemo(() => {
            if (props?.data) {
                return props?.data
            }
        }, [props?.data])

        /**
         * method to cancel add screen 
         */
        const cancelAdd = () => {
            setAddScreenHide(true)
            setHideCreateButton(false)
        }


        return (
            <Grid container id='business-owner-card' xs={12} justifyContent='center'>
                <Grid item xs={12} sm={isSmallScreen?undefined:11} md={isSmallScreen?undefined:7}>
                    <FxCard id="business-owner-card-form-card" className={isSmallScreen?"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-signup-formkyc-mobile fx-business-owner":"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-business-owner"}>
                            <FxCardHeader id="business-owner-card-form-card-header">
                                    <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                                        <Grid item xs={12} flexWrap={'nowrap'} container>
                                            <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                                              <MultipleUser />
                                            </Grid>
                                            <Grid item>
                                              <Typography id="business-owner-card-form-card-title" variant="h3">
                                                Add your business owners
                                              </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} marginTop={2.5}>
                                          <Typography variant="h6" id='business-owner-card-form-card-label' className={isSmallScreen?"fx-word-wrap":''}>
                                            Due to regulatory guidelines, we are required to collect business owner information from you. Please add any individual who owns 25% or more stake of the company. Additionally one authorized signatory, an individual with significant responsibility within the company (like the CEO, CFO) to control, direct, manage company's finances.
                                          </Typography>
                                        </Grid>
                                    </Grid>
                            </FxCardHeader>
                            <FxCardBody id="signUp-business-owner-add-form-card-body" className="fx-info-card fx-margin-top-reducer fx-info-kyc" >
                              <Grid container className="fx-info-card-form" >
                                    {props?.data?.length === 0 && !hideCreateButton && <FxCardBody id="signUp-business-owner-add-form-card-body" className="fx-info-card" >
                                        <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                            <Grid id="address-Grid" item container justifyContent="space-between" xs={12} sm={12} className={isSmallScreen? 'fx-business-owner-add-button': '' } >
                                                <FxButton
                                                    disableRipple={false}
                                                    className={"fx-button fx-button-passport-border fx-button-passport-border-kyc fx-svg-theme"}
                                                    id="add-business-owner"
                                                    startIcon={<AddIcon />}
                                                    onClick={addAnotherBusinessOwner}
                                                > Add Business Owner
                                                </FxButton>
                                            </Grid>
                                        </Grid>
                                    </FxCardBody>}
                                    {businessOwnerData && businessOwnerData.map((item: any, index: any) => {
                                        return <BusinessOwnerCardView addAnotherBusinessOwner={addAnotherBusinessOwner} item={item} index={index} data={businessOwnerData} updatedData={updatedData} businessDetails={props?.businessDetails} onSubmit={onSubmitData} editOpen={() => { setEditScreenOpen(!editScreenOpen) }} />
                                    })}
                                    {businessOwnerData?.length > 0 && addScreenHide && !editScreenOpen && <Grid id="address-Grid" item container justifyContent="space-between" xs={12} >
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-passport-border fx-button-passport-border-kyc fx-svg-theme"
                                            id="add-business-owner"
                                            startIcon={<AddIcon />}
                                            onClick={addAnotherBusinessOwner}
                                        > Add Another Business Owner
                                        </FxButton>
                                    </Grid>}
                                    {!addScreenHide && <BusinessOwnerAdd data={props?.data} onSubmit={onSubmitData} businessDetails={props?.businessDetails} onClick={props?.onClick} goBackClick={props?.goBackClick} cancel={cancelAdd} id='add-business-owner' />}
                                    {props?.data?.length > 0 && <><Grid className={isSmallScreen? 'fx-business-owner-certify-checkbox': '' }>
                                        <FxCheckEdit register={{ ...register("certifyBusinessOwner") }} rules={{ required: "Please click Agree" }} control={control} onChange={(e: any) => { handleChangeCertifyBusinessOwner(e); }} id="certify-Business-Owner" name="certifyBusinessOwner" data={certifyBusinessOwnerDate} className="fx-kyc-privacy" row="vertical" setValue={setValue} />
                                    </Grid>
                                        <Grid item xs={12} sm={12}>
                                        </Grid></>
                                    }
                                    <Grid item xs={12} sm={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                
                            </FxCardBody>
                            <Grid item container xs={12} sm={12}>&nbsp;</Grid>
                            <Grid item container xs={12} sm={12}>
                                <div className={'error-message'} >
                                    {errors.authMandatory && renderError(errors.authMandatory)}
                                </div>
                                <div className={'error-message'} >
                                    {errors.validateAuthDetails && renderError(errors.validateAuthDetails)}
                                </div>
                            </Grid>
                        <FxCardFooter id="business-owner-card-Card-Footer" className="fx-footer">
                        
                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer fx-footer-kyc">
                                <FxButton
                                    disableRipple={false}
                                    variant="contained"
                                    className="fx-button fx-button-cancel"
                                    id="back-business-owner-card-btn"
                                    onClick={handleGoBackToDetailsClick}
                                > GO BACK
                                </FxButton>
                                <Grid className='fx-padding-right-15'></Grid>
                                <FxButton
                                    disableRipple={false}
                                    className={btnDisabled ? "fx-button fx-button-theme-disabled" : "fx-button fx-button-theme"}
                                    id="continue-business-owner-card-btn"
                                    type="submit"
                                    onClick={handleClick}
                                    isSubmitting={props?.isLoading}
                                    disabled={btnDisabled}
                                > CONTINUE
                                </FxButton>
                            </Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    }
)
