import { RestEntityMapper } from "./RestEntityMapper";
import { getDownloadedDoc } from "./downloadDoc";
import { getAPI, postAPI, putAPI, deleteAPI } from "../saga/apiSaga";
import { Logger } from "./logger";
import { getKey } from "./storageManager";
Logger.debug("sagaHelper.tsx", "Saga Helper initializing")


export class SagaHelper {
    private dispatch: any
    private history: any
    constructor(dispatch: any, history?: any) {
        this.dispatch = dispatch;
        this.history = history;
    }
    /**
     * It's a function for updating the param node inside the config in redux
     * @param componentId Component Id of which component's param to be update 
     * @param key which node to be updated
     * @param value data to be updated
     */
    UpdateComponentParam(
        componentId: string,
        key: string,
        value: any
    ) {
        this.dispatch({ type: "CONTEXT_COMPONENT_UPDATE_PARAM", payload: { "id": componentId, data: { [key]: value } } });
    }

    /*
        reset a component completely based on props
    */
    UpdateComponentReset(config: any) {
        try {
            this.dispatch({ type: "SET_CONTEXT_HARD", payload: config })
        } catch (e) { }

    }
    /**
     * It's a function for updating the param node inside the config in redux / it can be used to update multiple data rather single data
     * @param componentId Component Id of which component's param to be update 
     * @param data Object of data to be updated 
     */

    UpdateComponentMultipleParams(
        componentId: string,
        data: any
    ) {
        try {
            this.dispatch({ type: "CONTEXT_COMPONENT_UPDATE_PARAM", payload: { "id": componentId, data: data } });
        } catch (e) {
            // Logger.error("sagaHelper.tsx","error","Update failed ", e.message);
        }

    }
    /**
     * It's a function for updating the data node in redux
     * @param componentId Component Id of which component's data to be update 
     * @param node which node to be updated
     * @param data data to be updated
     */
    UpdateComponentData(
        componentId: string,
        node: string,
        data: any
    ) {
        this.dispatch({ type: "CONTEXT_COMPONENT_UPDATE_DATA", payload: { "id": componentId, data: data, node: node } });
    }
    /**
     * 
     * @param componentId Component Id of which component's source to be update 
     * @param source new source for the api to be call.
     */
    UpdateComponentSourceStatus(
        componentId: string
    ) {
        this.dispatch({ type: "UPDATE_SOURCE_STATUS", payload: { "componentId": componentId } });
    }

    getAPIAsync(url: string, headers: any, callback: any) {
        getAPI(url, headers).next().value.then((data: any) => callback(data, null)).catch((err: any) => callback(null, err));
    }
    postAPIAsync(url: string, data: any, headers: any, callback: any) {
        postAPI(url, data, headers).next().value.then((data: any) => callback(data, null)).catch((err: any) => callback(null, err));
    }

    deleteAPIAsync(url: string, data: any, headers: any, callback: any) {
        deleteAPI(url, data, headers).next().value.then((data: any) => callback(data, null)).catch((err: any) => callback(null, err));
    }

    putAPIAsync(url: string, data: any, headers: any, callback: any) {
        putAPI(url, data, headers).next().value.then((data: any) => callback(data, null));
    }
    createEntity(component: string, entity: string, body: any, header: any) {
        return this.postAPI(component, RestEntityMapper(entity).create(), body, header, "createEntity")
    }
    updateEntity(component: string, id: string, entity: string, body: any, header: any) {
        return this.postAPI(component, RestEntityMapper(entity).update(id), body, header, "updateEntity")
    }

    postAPI(component: string, url: string, body: any, header: any, target: string) {
        this.dispatch({ type: "CALL_API", payload: { id: component, method: "POST", url: url, body: body, header: header, target: target } });
    }
    putAPI(component: string, url: string, body: any, header: any, target: string) {
        this.dispatch({ type: "CALL_API", payload: { id: component, method: "PUT", url: url, body: body, header: header, target: target } });
    }
    createContextIfNull(componentId: string, componentObject: any) {
        this.dispatch({ type: "SET_CONTEXT_IF_NULL", payload: { data: componentObject } })
    }
    openPopup(id: string, args: any) {
        let payload = {
            id: id,
            data: { open: true, args: args }
        }
        this.dispatch({ "type": "CONTEXT_COMPONENT_UPDATE_PARAM", payload: payload });
    }
    closePopup(id: string, args: any) {
        let payload = {
            id: id,
            data: { open: false, args: args }
        }
        this.dispatch({ "type": "CONTEXT_COMPONENT_UPDATE_PARAM", payload: payload });
    }
    redirect(path: any, target: any) {
        if (target) {
            return window.open(path, '_blank');
        }
        var regex = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (regex.test(path)) {
            return window.location.href = path;
        } else {
            return this.history.push(path);
        }
    }
    moveNextAndSaveId(id: any, key: any, value: any) {
        this.UpdateComponentParam(id, key, value);
    }
    openPopover(id: string, args: any) {
        let payload = {
            id: id,
            data: args
        }
        try {
            this.dispatch({ "type": "CONTEXT_COMPONENT_UPDATE_PARAM", payload: payload });
        } catch (e) {
            // Logger.error("sagaHelper.tsx","error","Popover failed ", e.message);
        }

    }
    reloadSource(id: string) {
        let payload = { source: { status: 0 }, id: id }
        try {
            this.dispatch({ "type": "COMPONENT_UPDATE_CONFIG_SOURCE", payload: payload });
        } catch (e) { }

    }

    getDownloadDocument(data: any, mime: any, extension: any) {
        getDownloadedDoc(data, mime, extension);
    }

    getSession(data: any) {
        try {
            let sessionData: any = getKey(data);
            return sessionData;
        } catch (e) { }
    }
}