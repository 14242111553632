/**
 * GenerateJwtToken.tsx
 * This component handles the creation of the jwt token
 */
import { HmacSHA256, enc } from 'crypto-js';
/**
 * Function to encode a string to a base64 URL-safe format.
 * @param data - The input string to be base64 URL encoded.
 * @returns A base64 URL-safe encoded string.
 */
const base64UrlEncode = (data: string): string => {
  return window.btoa(data)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};
/**
 * Function to generate a JSON Web Token (JWT) using a payload and a secret key.
 * @param payload - The payload object to be included in the token.
 * @param secretKey - The secret key used to sign the token.
 * @returns The generated JWT as a string.
 */
export const GenerateJwtToken = (payload: any, secretKey: string): string => {
  const header = {
    alg: 'HS256', // Algorithm used for signature (HS256 for HMAC SHA-256)
    typ: 'JWT'   // Type of token
  };

  const encodedHeader = base64UrlEncode(JSON.stringify(header)); // Base64 URL encode the header
  const encodedPayload = base64UrlEncode(JSON.stringify(payload)); // Base64 URL encode the payload

  const dataToSign = `${encodedHeader}.${encodedPayload}`;

 // const secretArray = Array.from(secretKey).map(c => c.charCodeAt(0)); // Convert secret key to number array
 const secretArray = Array.from(secretKey, char => char.charCodeAt(0)); // Convert secret key to number array

  // Perform HMAC-SHA256 operation using crypto-js
  const signature = calculateHmacSha256(dataToSign, secretArray);

  return `${dataToSign}.${signature}`;
};
/**
 * Function to calculate the HMAC-SHA256 hash of data using a provided key.
 * @param data - The data string to be hashed.
 * @param key - An array of numbers representing the key.
 * @returns The HMAC-SHA256 hash of the data as a base64 URL-safe encoded string.
 */
const calculateHmacSha256 = (data: string, key: number[]): string => {
  const hmac = HmacSHA256(data, enc.Latin1.parse(String.fromCharCode.apply(null, key)));
  return base64UrlEncode(hmac.toString(enc.Latin1));
};
