import { combineReducers } from "redux";
import componentReducer from "./componentReducer";
import pageReducer from "./pageReducer";
import layoutReducer from "./layoutReducer";
import { entityInfoReducer } from './entityInfoReducer';
import { Logger } from "../utils/logger";
import { dataReducer } from "./dataReducer";

Logger.debug("RootReducer", "Init the RootReducer")

/**
 * Root reducer
 */

export interface ComponentState {
  params?: any;
  id?: string;
  events?: any;
  toolbar?: any;
  source?: any;
  data?: any;
}
export enum PageStatus {
  PENDING = 0,
  CONTEXT_PENDING = 1,
  CONTEXT_READY = 2,
}

/**
 * Each component id will be a key with value as an object with properties of config and data.
 * 
 */
export interface ContextState {
  [name: string]: { config: ComponentState; data?: any };

}

export interface PageState {
  [name: string]: { status: PageStatus; data: Object; params: any };
}

export interface LayoutState {
  [name: string]: Object;
}

export interface DataState {
  [name: string]: Object;
}
export interface ConfigState {
  page: PageState;
  layout: LayoutState;
}

export interface PortalState {
  name: string;
  status: PageStatus;
  path: [
    { [url: string]: { fri: string; params?: string[]; exact?: boolean } }
  ];
}

export interface State {
  data: DataState;
  portal: PortalState;
  config: ConfigState;
  context: ContextState;
}

const rootReducer = combineReducers({
  data: dataReducer,
  config: combineReducers({
    page: pageReducer,
    layout: layoutReducer
  }),
  context: componentReducer,
  entity: entityInfoReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
