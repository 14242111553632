import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import { ReactComponent as VerifiedIcon } from '../../../../assets/svg/verified-new-icon.svg';
import { ReactComponent as TriangleDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../assets/svg/triangle-up-icon.svg';

Logger.debug('ReviewInfoCard.tsx', 'Review info card initializing')

interface ICardDataItemData {
  value: string | boolean;
  title?: string;
}

interface ICardDataItem {
  name: string;
  index?: number;
  data: {
    [key: string]: ICardDataItemData;
  };
}

export interface ICardData {
  header: string;
  items: ICardDataItem[];
}

interface IReviewInfoCard {
  id: string;
  cardData?: ICardData;
  handleClick?: () => void;
}

/**
 * Component: ReviewInfoCard
 * This component is used to display the review information card
 * It takes the cardData as props and displays the information
 */


export const ReviewInfoCard: React.FC<IReviewInfoCard> = React.memo(({ cardData, handleClick, id }) => {
  const [showItems, setShowItems] = useState(false);

  // Method handles the onclick event to redirect to proper page
  const onCardClick = () => {
    handleClick && handleClick();
  }

  if (!cardData) {
    return null;
  }

  /**
   * handle items view
   */
  const handleShowItems = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowItems(!showItems);
  }


  return (
    <Grid container id={`review-info-card-${id}`} xs={12} className={'fx-review-info-card'} onClick={onCardClick}>
      <Grid item xs={12} className={'fx-review-info-card-header'} container justifyContent={'space-between'}>
        <Grid>
          <Typography variant="h3">
            {cardData.header}
          </Typography>
        </Grid>
        {cardData.items.length > 1 && <Grid item className="fx-info-kyc-view-topbutton">
            <IconButton
                id={'btn-show-hide-icon'}
                className="fx-button fx-border-gray"
                onClick={handleShowItems}
                size="large">
              {showItems ? <TriangleUp /> : <TriangleDown />}
            </IconButton>
        </Grid>}
      </Grid>
      {cardData.items.map((item, index) => {
          // Always render the first item or when showItems is true
          if (index === 0 || showItems) {
            return (
              <Grid container className={'fx-review-info-card-item'}>
                {!!item.index &&
                    <Grid item xs={'auto'}>
                        <Typography variant="h3" className={'fx-review-info-card-item-header'}>
                          {`#${item.index}`}&nbsp;
                        </Typography>
                    </Grid>}
                <Grid container item xs={11}>
                  {item.name && <Grid item xs={12}>
                      <Typography variant="h3" className={'fx-review-info-card-item-header'}>
                        {item.name}
                      </Typography>
                  </Grid>}
                  {
                    Object.keys(item.data).map((key) => {
                      return (
                        !!item.data[key].value &&
                        <Grid item xs={12} key={key} className={'fx-review-info-card-data-item'}>
                            <Grid item container xs={12}>
                              {key === 'authSignatory' &&
                                  <Grid item xs={'auto'} container alignItems={'center'} className={'fx-review-info-card-data-item-auth-signatory-label'}>
                                      <VerifiedIcon />
                                      <FxLabelView>&nbsp;AUTHORIZED SIGNATORY</FxLabelView>
                                  </Grid>}
                            </Grid>
                            <Typography  className={'fx-review-info-card-data-item-info'}>
                              {item.data[key].title ? item.data[key].title : ''} {item.data[key].value}
                            </Typography>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            )
          }
          return null;
        })
      }
    </Grid>
  )
})
