import React, { useEffect, useState } from "react"; // we need this to make JSX compile
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import { Logger } from "../../../../libs/utils/logger";
import { WebhookDetail } from "./WebhookDetail";
import { getCustomerUrl } from "../../../../libs/utils/utils";
import HttpClient from "../../../../libs/utils/httpClient";
import { removeValues, updateEventListPerCustomerType } from "../WebhookUtility";

Logger.debug("WebhookInfo.tsx", "Webhook Details Initializing");

/**
 * Component: WebhookInfo
 * Usage: To display Webhook Details Screen
 */
const httpClient = HttpClient.getClient();
export const WebhookInfo: React.FC<any> = React.memo(() => {
  const [eventList, setEventList] = useState([]);
  interface RouteParams {
    id: string;
  }
  const params = useParams<RouteParams>();
  /**
   * get ID from  url
   */
  const id = params.id;
  /**
   * webhook details API
   */
  const webhook_source = {
    url: "webhookSubscription/id/" + id,
    method: "GET",
  };

  /**
   * Method for calling event/list api to fetch all the available events
   */
  const getAllAvailableEventList = async () => {
    try {
        const payloadData = {
            "pageNumber": 1,
            "pageSize": 25,
            "sortOptions": {
              "sortBy": "lastUpdatedOn",
              "sortOrder": "desc"
            },
            "criteria": {
              "filters": []
            }
        }
        await httpClient.post(getCustomerUrl('/webhookSubscription/event/list'),payloadData).then((response:any) => {
          const eventArray = response?.data?.resources['INSTANT'];
          const trimmedData = removeValues(eventArray, ["customer.individual.create", "customer.business.create","customer.jointtenancy.create"]);
          const updatedData = updateEventListPerCustomerType(trimmedData);
          setEventList(updatedData)
        });
    } catch (e) { 
      Logger.error("WebhookInfo.tsx", "getAllAvailableEventList", "error", e);
     }
}

  useEffect(() => {
    getAllAvailableEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      xs={12}
      alignItems="flex-start"
      justifyContent="space-between"
      className="fx-container"
    >
      <WebhookDetail id={`webhook-detail-info-card-${id}`} source={webhook_source} eventList={eventList}/>
    </Grid>
  );
});
