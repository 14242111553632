/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ISource } from '../../../types/common.interfaces';
import { useHistory, useParams } from "react-router-dom";
import { clean, createCommonVerifyProps, customErrorMessage, removeUnderScoreConvertTitleCase, updateComponentParams } from "../../Utils/CommonBaseClass";
import { AUTH_STRINGS } from "../../../constants/strings";
import { useForm } from "react-hook-form";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { Logger } from "../../../libs/utils/logger";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxSwitch } from '../../Action/FxSwitch';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { createUpdateCollect, accountLabelTransformation, getLocationData } from "./ScheduleGlobalFunctions";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import { isAchControlAllowed } from "../../Utils/ThemeChecker";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

interface InputErrType {
  type: string;
  message: string;
}

Logger.debug(
  "CreateOnetimeCollectAch.tsx",
  "create onetime collect card initializing"
);
interface InputErrType {
  type: string;
  message: string;
}

//Account list source
const acc_src: ISource = {
  url: "account/list",
  method: "POST",
  data: {
    pageSize: 25,
    pageNumber: 1,
    sortOptions: {
      sortOrder: "DESC",
      sortBy: "createdOn",
    },
    criteria: {
      filters: [
        {
          key: "status",
          operator: "nin",
          values: ["INACTIVE"],
        },
      ],
    },
  },
};


//bank list source for routing number info
const bank_src = {
  url: "list/bank",
  method: "POST",
  expiry: 300,
  baseUrl: true,
  data: {
    pageSize: 25,
    pageNumber: 1,
    criteria: {
      filters: [],
    },
  },
};

const accountTypeData = [
  { label: "Checking", value: "CHECKING" },
  { label: "Savings", value: "SAVINGS" },
];

const holderTypeData = [
  { label: "Consumer", value: "CONSUMER" },
  { label: "Corporate", value: "CORPORATE" },
];

const processingModeData = [
  { label: "Next Day", value: "FORWARD" },
  { label: "Same Day", value: "SAME_DAY" },
];

const authTypeDate = [
  { value: "WRITTEN", label: "Written" },
  { value: "ONLINE", label: "Online" },
  { value: "PHONE", label: "Phone" },
];

// source for getting subscriber settings enabled for MFA
const preference_src: ISource = {
  url: '/programManager/getProgramManagerSetting',
  method: 'POST',
  baseUrl: true,
  data: {
    "pageNumber": 1,
    "pageSize": 1,
    "sortOptions": {
      "sortBy": "lastUpdatedOn",
      "sortOrder": "desc"
    },
    "criteria": {
      "filters": [
        {
          "operator": "eq",
          "key": "settingName",
          "values": [
            "SUPPORTED_TRANSACTION_CATEGORIES"
          ]
        }
      ]
    }
  }
}


/**
 * This component handles the collect creation through payer for card method
 */
export const CreateOnetimeCollectAch: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    clearErrors,
    control,
    setError,
    watch,
  } = useForm();
  const params = useParams<any>();
  let scheduleInfo: any = {};
  let collectId: any = "";

  // Declaring Constants
  const [isLoading, setIsLoading] = useState(false);
  const isEdit = !!props.source;
  const history = useHistory();
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [selectedRoutingNumber, setSelectedRoutingNumber] = useState("");
  const [instantFunding, setInstantFunding] = useState(false);
  const pmLevelQuickSettle = getKey('isAchQuickSettleEnabled');
  const accountLevelQuickSettle = getKey('accountLevelQuickSettle')?.ach;
  const showInstantFunding = pmLevelQuickSettle || context?.data?.body?.processingDetail?.quickSettle;
  // account number validation constant
  const setAccountNumberValidation = {
    required: true,
    maxLength: 17,
  };
  const scheduleId = params.id;
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available
  const [location, setLocation] = useState<any>();

  // routing number validation constant
  const setRoutingNumberValidation = {
    required: true,
    minLength: 9,
    maxLength: 9,
    pattern: {
      value: /^[0-9-_&,.]*$/,
      message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
    },
  };
  // routing number validation error messages
  const renderRoutingNumberError = (err: InputErrType): string => {
    if (err.type === "minLength") {
      return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
    } else if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
    }
    return err.message;
  };
  const renderNameError = (err: InputErrType): string => {
    if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
    }
    return err.message;
  };
  /**
   * Method to render account number error
   * @param err
   */
  const renderAccountNumberError = (err: InputErrType): string => {
    if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_NOT_VALID_LENGTH;
    }
    return err.message;
  };
  const dispatch = useDispatch();
  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-collect-one-time-ach-form-card-category' } });
    if (!isEdit) {
      setValue("method", "ACH");
      setValue("sourceType", "EXISTING");
      setValue("allowDuplicate", "false");
      if (scheduleId) {
        const src = {
          url: "/transaction/id/" + scheduleId,
          method: "GET"
        }
        dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id: 'edit-collect-onetime-ach', source: src } });
      }
      else {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Collect Money', backButton: '/dashboard' } } });
      }
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  scheduleInfo = context?.data?.body;

  /**
  * Method enables and disables the instant fund functionality
  * @param event : instant fund status
  */
  const handleInstantFund = (event: any) => {
    setInstantFunding(event);
  };

  const method_Tab = [
    {
      className: "",
      title: "CHECK",
      link: "/transaction/collect/add/mobile-check-deposit",
      permission:{
        entity: "Accounts",
        name: "Check Collect",
        operation: "CREATE"
      }
    }
  ];
  if(businessCustomer && isAchControlAllowed(getKey('collectAchControl'))){
    method_Tab.unshift({
      className: "fx-link-sel",
      title: "ACH",
      link: "/transaction/collect/add/onetime-ach",
      permission:{
        entity: "Accounts",
        name: "ACH Collect",
        operation: "CREATE"
      }
    })
  }
  if(businessCustomer){
    method_Tab.push({
      className: "",
      title: "CARD",
      link: "/transaction/collect/add/onetime-card",
      permission: {
        entity: "Accounts",
        name: "Card Collect",
        operation: "CREATE"
      }
    })
  }

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  const dataTransformation = useCallback((data: any) => data?.map((item: any) => ({
    value: item.id,
    label: accountLabelTransformation(item)
  })) || [], []);

  /**
   * Function handles transformation of category data
   * @param data : category data array
   * @returns : array
   */

  const settingsDataTransformation = useCallback((data: any) => {
    if (!data) return []; // Return an empty array if data is false

    const transformedArray = data?.flatMap((item: any) => {
      const categoryValues = item?.settingValue?.split(',');
      return categoryValues?.map((value:any) => ({
        value,
        label: value
      })) || [];
      });

    return transformedArray;
  }, []);

  /**
   * Method to transform routing number data as dropdown options
   * @param data : routing number data
   */
  const routingNumberTransformer = useCallback((data: any) => data?.map((item: any) => ({
    label: item.routingNumber + " (" + item.name + ")",
    value: item.routingNumber,
  })) || [], []);

  /**
   * Async function handles the create collect form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    req.source = {
      externalAccount: {
        type: data.accountType,
        holderType: data.holderType,
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        holderName: data.holderName
      },
    };
    req.method = "ACH";

    req.processingDetail = {
      processingMode: data["processingMode"],
      quickSettle: instantFunding
    };
    if (data["statementDescriptor"]) {
      req["processingDetail"]["statementDescriptor"] =
        data["statementDescriptor"];
    }

    // req.method = data.method;
    if (data.externalId) {
      req.externalId = data.externalId;
    }

    if (data.category) {
      req.category = data.category;
    }
    req.amount = data.amount;
    req.allowDuplicate = data.allowDuplicate
      ? data.allowDuplicate === "true"
      : false;
    req.destination = {
      account: {
        id: data["depositAccount"],
      },
    };
    req.type = "REGULAR";
    req.purpose = data.purpose;
    req.processingDetail = {
      processingMode: data.processingMode,
      authType: data.authType,
      quickSettle: instantFunding
    }
    if (data.achCompanyDescription) {
      req['processingDetail']['companyDescription'] = data.achCompanyDescription
    }
    if (data["location"]) {
      req["processingDetail"]["location"] = {
        'id' : data['location']
      }
    }
    if (scheduleId) {
      req.id = scheduleId;
      delete req.source;
      delete req.destination;
      delete req.method;
    }
    req = clean(req);
    setIsLoading(true);
    let status = await createUpdateCollect(req);
    if (status?.headers?.url) {
      collectId = status.headers.url.split("/").pop();
    }
    setIsLoading(false);
    status = processAPIResponse(status);
 
   
    if (scheduleId && scheduleId !== undefined) {
     
    }
    
    if (status.status ) {
      //api success
      //thank you page
      const redirectUrl = scheduleId? `/transactions/collect/${scheduleId}`: '/thanks/transactions/onetime-ach/';
      const successMessage = scheduleId? 'Updated Successfully': 'Created Successfully';  
      if(status.responseData?.sessionId){
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        const verifyProps = {
            ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'collect'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:scheduleId?false: true
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: successMessage,
      });
      if(scheduleId){
        history.push("/transactions/collect/" + scheduleId);
      }else{
        history.push("/thanks/transactions/onetime-ach/" + (collectId ?collectId:(scheduleId?scheduleId:'')));
      }
    }
  } else {
      //api  failed
    
      FxSnackBar.show({
        text: customErrorMessage(status),
      });
  
     
    }
  
  }

  /**
   * Method handles the onchange event
   * @param event : onchange event parameter
   */
  const handleChange = (event: any) => {
    if (event && event?.label) {
      setSelectedRoutingNumber(event?.label);
    } else {
      setSelectedRoutingNumber("");
    }
  };

  /**
        * useEffect to set edit data details
        */
  useEffect(() => {
    // if (context?.data?.body) {
    setFormValues(context?.data?.body);
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
    // }
    // getDestinationData(context?.data?.body)
  }, [context?.data?.body])

  /**
   * Method setting the default values for the text edit component from the context data
   */
  function setFormValues(data:any) {
    setValue("amount", data?.amount)
    setValue("purpose", data?.purpose)
    setValue("achCompanyDescription", data?.processingDetail?.companyDescription)
    // setValue("authType", data?.processingDetail?.authType)
    setValue("holderName", data?.source?.externalAccount && data?.source?.externalAccount?.holderName)
    setValue("accountNumber", data?.source?.externalAccount && ('****'+data?.source?.externalAccount?.accountNumberLast4))
    setValue("externalAccount", data?.source?.externalAccount?.id)
    // setValue("holderType", data?.source?.externalAccount && data?.source?.externalAccount?.holderType)
    setValue("routingNumber", data?.source?.externalAccount && data?.source?.externalAccount?.bankInfo?.routingNumber)
    setSelectedRoutingNumber(data?.source?.externalAccount && data?.source?.externalAccount?.bankInfo?.routingNumber + " (" + data?.source?.externalAccount?.bankInfo?.name + ")")
    setValue("category", data?.category)
    setInstantFunding(data?.processingDetail?.quickSettle);
    setAllowDuplicate(data?.allowDuplicate);
}

  /**
  * Handle closing of the model
  */

  const handleClose = () => {
    if (context?.data?.body) {
      history.push('/transactions/collect/' + context.data.body.id)
    } else {
      history.push('/dashboard')
    }
  };

  /**
   * The main summary data
   */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
      'Collect Amount': {
          id: 'amount',
          ValueComponent: FxSummaryCurrency,
          value: amount && !isNaN(amount) ? amount : '',
      },
      'To': {
          id: 'to',
          value: accountLabelTransformation(getKey('selectedAccount')),
      },
      'Purpose': {
          id: 'purpose',
          value: watch('purpose'),
      },
      'Via': {
          id: 'method',
          value: 'ACH',
      },
      'Account Holder Name': {
        id: 'accountHolderName',
        value: watch('holderName'),
      },
      'Account Type': {
        id: 'accountType',
        value: watch('accountType'),
      },
      'Holder Type': {
        id: 'holderType',
        value: watch('holderType'),
      },
      'Account Number': {
        id: 'accountNumber',
        value: watch('accountNumber'),
      },
      'Routing Number': {
        id: 'routingNumber',
        value: selectedRoutingNumber,
      },
      'Processing Mode': {
        id: 'processingMode',
        value: watch('processingMode')
        ?  watch('processingMode') === 'FORWARD' ? removeUnderScoreConvertTitleCase('NEXT_DAY') : removeUnderScoreConvertTitleCase(watch('processingMode'))
        : '',
      },
      'Authorization Type': {
        id: 'authType',
        value: watch('authType'),
      },
      'Category': {
        id: 'category',
        value: watch('category'),
      },
      'Memo': {
          id: 'achCompanyDescription',
          value: watch('achCompanyDescription'),
      }
  };
  if(showInstantFunding) {
      summaryData['Faster Funding'] = {
          id: 'quickSettle',
          value: instantFunding ? 'Yes' : 'No',
      }
  }

  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    depositAccount: context?.data?.body?.destination?.url?.split("/").pop() || '',
    purpose: context?.data?.body?.purpose
  }
  const tabClickFunction = () => {
    try{
      const data:any = {
        schedule: 'collect',
        type: 'one-time',
        formData: { amount: watch('amount'), purpose: watch('purpose')}
      }
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
    }
    catch(e){}
  }

  const isInstantFundingDisabled = !accountLevelQuickSettle || (accountLevelQuickSettle && !pmLevelQuickSettle);
  /** 
  * Function to handle the closing of the modal.
  */
const handleCloseModal = () => {
     let verifyProps = {...props.verifyProps}
     verifyProps['open'] = false;
     updateComponentParams(dispatch, props.id, { verifyProps });
 };
  return (
    <>
    <ScheduleLayout
      id='create-collect-onetime-ach'
      type='collect'
      loading={isLoading}
      submitTitle={scheduleId ? 'Save Changes' : 'Collect Money'}
      summaryData={summaryData}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleClose}
      saveEnabled={true}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection id="create-collect-from-my-account-ach-schedule-top-section" schedule="collect" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataTransformation={dataTransformation} amountId="create-collect-one-time-ach-form-card-amount-textbox" destinationId="create-collect-one-time-ach-form-card-destination-account" purposeId="create-collect-one-time-ach-form-card-purpose-textbox"/>
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
        <FxLocationSelectComponent
            label='Location'
            register={{ ...register("location") }}
            control={control}
            rules={{ required: false }}
            id="add-one-time-collect-ach-form-location"
            name="location"
            className="fx-input-edit"
            setError={setError}
            clearError={clearErrors}
            setValue={setValue}
            resetField={resetField}
            isEditable={!scheduleId}
            value={location ? {
              label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
              value: location?.id,
          }: null}
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="create-collect-one-time-ach-form--card-tab" tabClick={tabClickFunction}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          title="Address"
          id="create-collect-one-time-ach-form-typography"
          className="fx-create-schedule-subheading"
        >
          EXTERNAL ACCOUNT DETAILS
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={12}>
        <fieldset className="fx-container-create-schdule-summery">
            <legend>Source Details</legend>
            <Grid item container xs={12} sm={12} spacing={2}>
              <Grid item xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("holderName") }}
                  className={
                    errors.holderName
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  control={control}
                  rules={{ required: true }}
                  id="create-collect-onetime-ach-form-card-holdername-textbox"
                  label="Account Holder Name*"
                  placeholder="Account Holder Name"
                  name="holderName"
                  variant="outlined"
                  defaultValue={
                    context?.data?.body?.bankAccount?.holderName
                      ? context.data.body.bankAccount.holderName
                      : ""
                  }
                />
              </Grid>
      <Grid
        item
        container
        xs={12}
        className="fx-repeat-payment-repeat"
      >
        <Grid item xs={6}>
          <FxMaterialSelect
            register={{ ...register("accountType") }}
            className={
              errors.accountType
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            rules={{ required: true }}
            control={control}
            onChange={handleChange}
            id="create-collect-onetime-ach-form-card-account-type"
            name="accountType"
            data={accountTypeData}
            value={
              context?.data?.body?.source?.externalAccount?.type
                ? context.data.body.source.externalAccount.type
                : "SAVINGS"
            }
            label="Account Type*"
            setValue={setValue}
          />
          <Grid item xs={6}>
            <div className={"error-message"}>
              {errors.holderName &&
                renderNameError(errors.holderName)}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FxMaterialSelect
            register={{ ...register("holderType") }}
            control={control}
            rules={{ required: isEdit ? false : true }}
            className={
              errors.holderType
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            id="create-payee-form-card-account-purpose"
            data={holderTypeData}
            name="holderType"
            readOnly={isEdit ? true : false}
            label="Holder Type"
            setValue={setValue}
            value={context?.data?.body?.source?.externalAccount?.holderType || "CORPORATE"}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("accountNumber") }}
          className={
            errors.accountNumber
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          rules={setAccountNumberValidation}
          control={control}
          isEditable={scheduleId? false:true}
          id="create-collect-onetime-ach-form-card-account-number-textbox"
          label="Account Number*"
          placeholder="Account Number"
          name="accountNumber"
          variant="outlined"
          defaultValue={
            context?.data?.body?.bankAccount?.accountNumberLast4
              ? context.data.body.bankAccount.accountNumberLast4
              : ""
          }
        />
        <div className={"error-message"}>
          {errors.accountNumber &&
            renderAccountNumberError(errors.accountNumber)}
        </div>
      </Grid>
      {((scheduleId && context?.data?.body?.source) || (!scheduleId)) && <Grid item xs={12} sm={12}>
      <FxLabelView className="fx-label-top" id="create-collect-onetime-ach-form-card-routing-number-label">Routing Number*</FxLabelView>
        <FxAutoComplete
          register={{ ...register("routingNumber") }}
          control={control}
          rules={setRoutingNumberValidation}
          id="create-collect-onetime-ach-form-card-routing-number"
          name="routingNumber"
          source={bank_src}
          searchBy={"searchPattern"}
          className={
            errors.routingNumber
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          setParam={handleChange}
          value={
            context?.data?.body?.source?.externalAccount?.bankInfo?.routingNumber
              ? {
                label:
                context?.data?.body?.source?.externalAccount?.bankInfo?.routingNumber + " (" + context?.data?.body?.source?.externalAccount?.bankInfo?.name + ")",
                value:
                context?.data?.body?.source?.externalAccount?.bankInfo?.routingNumber,
              }
              : null
          }
          dataTransformer={routingNumberTransformer}
          setValue={setValue}
          resetField={resetField}
          setError={setError}
          clearError={clearErrors}
          defaultOperator={"eq"}
          placeholder={"Routing Number"}
          isEditable={(context?.data?.body?.source?.externalAccount?.bankInfo?.routingNumber) ? false : true}
        />
        <div className={"error-message"}>
          {errors.routingNumber &&
            renderRoutingNumberError(errors.routingNumber)}
        </div>
      </Grid>}
      </Grid>
      </fieldset>
      </Grid>

      {((scheduleId && context?.data?.body?.processingDetail?.processingMode) || (!scheduleId)) && <Grid item xs={12} sm={12}>
        <FxMaterialSelect
          register={{ ...register("processingMode") }}
          control={control}
          rules={{ required: true }}
          id="create-collect-one-time-ach-form-card-processing-mode-textbox"
          name="processingMode"
          value={
            scheduleInfo?.processingDetail?.processingMode
              ? scheduleInfo.processingDetail.processingMode
              : "SAME_DAY"
          }
          data={processingModeData}
          label="Processing Mode"
          setValue={setValue}
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <FxMaterialSelect
          register={{ ...register("authType") }}
          control={control}
          rules={{ required: true }}
          id="create-collect-one-time-ach-form--card-authType"
          name="authType"
          data={authTypeDate}
          value={context?.data?.body?.processingDetail?.authType ? context?.data?.body?.processingDetail?.authType :"WRITTEN"}
          label="Authorization Type"
          setValue={setValue}
        />
      </Grid>
      {((scheduleId && context?.data?.body?.processingDetail?.processingMode) || (!scheduleId)) && <Grid item xs={12} sm={12}>
        <FxMaterialSelect
          register={{ ...register("category") }}
          control={control}
          rules={{ required: false }}
          className={
            errors.category
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          id="create-collect-one-time-ach-form-card-category"
          name="category"
          dataTransformation={settingsDataTransformation}
          readOnly={false}
          source={preference_src}
          label="Category"
          setValue={setValue}
          value={
            context?.data?.body?.category
              ? context?.data?.body?.category
              : ""
          }
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-from-others-ach-form-card-ach-company-description-textbox" label="Memo" name="achCompanyDescription" variant="outlined" defaultValue={scheduleInfo?.companyDescription ? scheduleInfo['companyDescription'] : ''} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FxSwitch id='create-collect-one-time-ach-form--card-allow-duplicate' value={allowDuplicate} onClick={setAllowDuplicate}></FxSwitch> <FxLabel value="Allow Duplicate"></FxLabel>
      </Grid>
      {showInstantFunding &&
          <Grid item xs={12} sm={12}>
        <FxSwitch id="create-collect-one-time-ach-form-card-instant-funding" value={instantFunding} onClick={handleInstantFund} disabled={isInstantFundingDisabled}></FxSwitch>
        <FxLabel value="Faster Funding"></FxLabel>
      </Grid>
      }
    </ScheduleLayout>
  </>
  );
});
