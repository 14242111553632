import React from 'react'; // we need this to make JSX 
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxInfoCard } from '../Cards/FxInfoCard';

Logger.debug("VirtualCardSummary.tsx", "View Virtual Card summary initializing");
/**
 * Component: View Virtual Card Summary
 * Usage: view virtual card summary on first login
 */

const VirtualCardSummary = (props: any): JSX.Element => {
    const virtualCardDetails = props.data;
    
    const summarySection = [
        {
            label: 'Card Holder Name',
            value: 'holderName'           
        },
    ];

    return (
        <FxCard id='virtual-card-summary-card' className="fx-theme-passport fx-virtual-card-summary-card">
            <FxCardBody>
                <Grid container direction="row" justifyContent="center" alignItems="center" className='fx-virtual-card-balance' spacing={2}>
                    <Grid xs={12} item>
                        <Grid container direction="row" justifyContent="center" alignItems="center"  spacing={2}>
                        <Grid item md={5}>
                            <Grid item xs={12}><Typography id="" variant="subtitle1" className='fx-account-summary-card-bal-card-bal-text' >PAYMENT AMOUNT</Typography></Grid>
                            <Grid item xs={12} className="fx-digest-grid"><FxCurrencyView className="balance-amount" id="home-account-info-card-currencyview" value={virtualCardDetails?.paymentAmount ? virtualCardDetails.paymentAmount : 0} prefix={"$"} /></Grid>
                        </Grid>
                        <Grid item md={7}>
                            <Grid item xs={12}><Typography id="" variant="subtitle1" className='fx-account-summary-card-bal-card-bal-text' >AVAILABLE ON CARD</Typography></Grid>
                            <Grid item xs={12} className="fx-digest-grid"><FxCurrencyView className="balance-amount" id="home-account-info-card-currencyview" value={virtualCardDetails?.availableBalance ? virtualCardDetails.availableBalance : 0} prefix={"$"} /></Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                 
                    <Grid xs sm={12} item></Grid>
                    <Grid xs sm={12} item>
                        <Grid container direction="row" spacing={2} className="fx-virtual-card-summery-details">
                            
                                <Grid item key="virtual-card-summary-info-container" className='fx-virtual-card-summary-info' direction="row" xs={12} justifyContent="start" alignItems="center">
                                    <FxInfoCard notiltle={true} id={"virtual-card-summary-details"} column={summarySection} data={props.data} ></FxInfoCard>
                                </Grid>
                            </Grid>                        
                        </Grid>
                </Grid>
            </FxCardBody>
        </FxCard>
    );
};

export default VirtualCardSummary;
