import React from 'react'; // we need this to make JSX 
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { ReactComponent as CircleIcon } from '../../../assets/svg/link-circle.svg';
/**
 * Component: Copyright blobk
 * Usage:Copyright for login related pages
 */

Logger.debug("Copyright.tsx", "Copyright initializing")

const Copyright = (): JSX.Element => {


    return (
        <Grid className="login-page-bottom-copyright" xs={12}>
            © {(new Date().getFullYear())} Priority Passport
            <span>
                <CircleIcon />
                 {/*eslint-disable-next-line*/}
                <a target="_blank" id="terms-of-service" href="https://prioritycommerce.com/enterprise-payments/passport/legal-terms/"> Passport Privacy & Terms </a>
            </span>
            <span>
                <CircleIcon />
                {/*eslint-disable-next-line*/}
                <a target="_blank" id="terms-of-service" href="https://prioritycommerce.com/contact/"> Contact Us </a>
            </span>
        </Grid>
    );
};

export default Copyright;
