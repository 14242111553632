import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * @param key
 * @param value
 * set the cookie with key and value
 */
export const setCookie = (key: string, value: any): void => {
    cookies.set(key, value, { path: '/' ,secure: true, sameSite: 'strict'});
    //cookies.set("loggedIn",true)
};

/**
 * @param key
 * get cookie using the key
 */

export const getCookie = (key: string): any => {
    const value = cookies.get(key)
    if (value) {
        return value;
    }
    return null;
};

/**
 * remove specified cookie
 * @param key 
 */
export const removeCookie = (key: string): any => {
    cookies.remove(key)
};

/**
 * remove all cookie . for example during logout
 */
export const removeAllCookie = (): any => {
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
};