import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ISource } from '../../../types/common.interfaces';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { useHistory, useParams } from 'react-router-dom';
import { clean, formatDate } from '../../Utils/CommonBaseClass';
import { useForm } from 'react-hook-form';
import FxRadioEdit from '../../Input/FxRadio/FxRadioEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { Logger } from '../../../libs/utils/logger';
import moment from 'moment';
import { processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import FxBreadCrumbs from '../../Navigation/FxBreadCrumbs';
import { FxLink } from '../../Action/FxLink';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { createUpdateCollect, setAmountValidationOptions } from './ScheduleGlobalFunctions';
import { FxButton } from '../../Action/FxButton';
import { FxSuccess } from '../../Data/FxSuccess';
import { useMediaQuery } from 'react-responsive';
import FxSnackBar from '../../Utils/fx-snack-bar';

Logger.debug("CreateCollectFromSelfCard.tsx", "create collect from self card initializing");

const acc_src: ISource = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

const card_src: ISource = {
    url: "card/list",
    method: "GET"
};
const allowDuplicateData = [
    { label: 'Yes', value: "true" },
    { label: 'No', value: "false" }
];
const page_menu_payer_card = [
    {
        title: 'Dashboard',
        link: '/'
    },
    {
        title: 'Collect ',
        link: '/collect'
    },
    {
        title: "From Self",
        link: '#'
    }
];

/**
 * This component handles the collect creation from self for card method
 */
export const CreateCollectFromSelfCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));

        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        const params = useParams<any>();
        const cardId = params.cardid;

        let scheduleInfo: any = {};
        // Declaring variables
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })

        const merchant_src: ISource = {
            url: "merchant/list",
            method: "POST",
            expiry: 300,
            data: {
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "account.id",
                            "values": [getKey("selectedAccount")?.id]
                        }
                    ]
                }
            }
        }

        const [isLoading, setIsLoading] = useState(false);
        const isEdit = !!props.source;
        const [isEditDataAvailabe, setIsEditDataAvailabe] = useState(false);
        const history = useHistory()
        const dispatch = useDispatch()
        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-collect-from-self-card-form-card-source-account' } });
            setValue('method', 'ACH')
            setValue('sourceType', 'EXISTING')
            setValue('allowDuplicate', 'false')
            setIsEditDataAvailabe(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.id]);


        scheduleInfo = context?.data?.body;

        /**
         * Method handles the redirecting to specific url
         * @param url : redirecting url
         */
        const handleRedirect = (url: any) => {
            history.push(url)
        }
        /**
         * Function handles transformation of destination data
         * @param data : destination data array
         * @returns : array
         */
        const dataTransformation = useCallback((data: any) => data?.map((item: any) => ({
            value: item.id,
            label: item.accountNumber,
        })) || [], []);

        /**
         * Function handles transformation of merchant data
         * @param data : merchant data array
         * @returns : array
         */
        const merchantDataTransformation = useCallback((data: any) => data?.map((option: any) => ({
            value: option.id,
            label: `ID1:${option.id}, (${getKey('CustomerName')})`,
        })), []);

        /**
         * Async function handles the create collect form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any;

            req = {
                method: "CARD",
                scheduleDate: moment(data.scheduleDate).format('MM/DD/YYYY'),
                amount: data.amount,
                type: "REGULAR",
                purpose: data.purpose,
                source: {
                    card: {
                        id: data['card']
                    }
                },
                processingDetail: {
                    merchant: {
                        id: data['merchantId']
                    },
                    quickSettle: data['quickSettle'] === 'true',
                    statementDescriptor: data['statementDescriptor']
                },
                destination: {
                    account: {
                        id: data['depositAccount']
                    }
                },
                allowDuplicate: data.allowDuplicate ? (data.allowDuplicate === "true") : false
            }

            if (data.id) {
                req.id = data.id;
                delete req.source;
                delete req.destination;
                delete req.method;
            }
            req = clean(req);
            setIsLoading(true);
            let status = await createUpdateCollect(req);
            const collectId = status?.headers?.url?.split('/').pop() || '';
            setIsLoading(false);
            status = processAPIResponse(status)
            let successMessage = 'Created Successfully';
            if (data.id) {
                successMessage = 'Updated Successfully';
            }
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push('/thanks/transactions/from-self-card/' + collectId)
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };
        /**
         * Function handles transformation of source data
         * @param data : source data array
         * @returns : array
         */
        const sourcedataTransformation = useCallback((data: any) => data?.map((option: any) => ({
            value: option.id,
            label: `(*${option.cardNumberLast4})`,
        })), []);

        /**
         * Method handles the validation for schedule date
         */
        const scheduleDateValidation = {
            required: !scheduleInfo?.scheduleDate,
            minDate: new Date(),
        };

        const hasSelectedAccount = !!getKey('selectedAccount');

        return (
            <Grid container xs={12} >
                <FxBreadCrumbs menuItems={page_menu_payer_card} id="create-collect-from-self-card-breadcrumbs" className="fx-breadcrumbs fx-edit-page-bread-crumbs" />
                <Grid container  >
                    <Grid id="create-collect-from-self-card-grid" item xs={12}>
                        {cardId && <FxSuccess id="create-collect-from-self-card-card-success" header={"Card added successfully"} description={"Card ID - "+ cardId}/>}
                        <div className="fx-form-edit-profile flex column">
                            {isEditDataAvailabe && <form id="create-collect-from-self-card-form" onSubmit={handleSubmit(onSubmit)}>
                                <FxCard id="create-collect-from-self-card-form-card" className="fx-schedule-card-general fx-theme-passport">
                                    <FxCardHeader id="create-collect-from-self-card-form-card-header" className="fx-card-header fx-modal-header">
                                        <Grid container direction="row" justifyContent="space-between" >
                                            <Grid item xs >
                                                <Typography id="create-collect-from-self-card-form-card-title" variant="h3" >
                                                    Collect From Self
                                                            </Typography>
                                            </Grid>
                                        </Grid>

                                    </FxCardHeader>
                                    <FxCardBody id="create-collect-from-self-card-form-card-body" className="fx-info-card" >
                                        <Grid item container xs={12} className="fx-schedule-create-tab-container">
                                            <Grid item container xs={12}>
                                                <Grid item>
                                                    <FxLabelView id="create-collect-from-self-card-form-card-choose-method-label" className="" >Choose Method</FxLabelView>
                                                </Grid>
                                                <Grid item xs ={isSmallScreen ? 12: undefined} className="fx-schedule-create-tab-container-button-grid">
                                                    <FxButton
                                                        className="fx-link"
                                                        id='create-collect-from-self-card-form-card-ach-button'
                                                        onClick={() => { handleRedirect('/transaction/collect/add/from-self-ach') }}>
                                                        ACH
                                                                </FxButton>
                                                </Grid>
                                                <Grid item xs ={isSmallScreen ? 12: undefined} className="fx-schedule-create-tab-container-button-grid">
                                                    <FxButton
                                                        className="fx-link-sel"
                                                        id='create-collect-from-self-card-form-card-card-button'
                                                        onClick={() => { handleRedirect('/transaction/collect/add/from-self-card') }}>
                                                        CARD
                                                                </FxButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justifyContent="space-between" className="flex column fx-container-create-schdule" >
                                            <Grid item xs={12} container direction="row" sm={4} className="fx-select-grid" >
                                            <Grid item xs={12} container>
                                                    <><Grid item xs={12}>
                                                        <FxMaterialSelect register={{ ...register("card") }} control={control} rules={{ required: true}} className={errors.card ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-collect-from-self-card-form-card-source-account" readOnly={false} dataTransformation={sourcedataTransformation} source={card_src} name="card" label={"Source Account"} setValue={setValue} noResources={true} value={cardId || ''}/>
                                                    </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography id={"create-collect-from-self-card-form-card-description-label"} variant="h5" className="fx-select-grid-description"> Collect funds from a card account you own and move to your Passport Account</Typography>
                                                        </Grid>
                                                        <Divider />
                                                        <Grid item xs={12} >
                                                            <Typography id={"create-collect-from-self-card-form-card-dont-see-label"} variant="h5" className="fx-select-grid-link-description"> Dont see your card account?</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} container justifyContent="center" >
                                                            <FxLink id="create-collect-from-self-card-create-card-link" redirect={"/create-card/from-self-card"} className="fx-select-grid-link">Create a Card</FxLink>
                                                        </Grid></>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={8} className='fx-container-create-schdule-right'>
                                                <Grid item container xs={12} >
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("depositAccount") }} control={control} rules={{ required: true }} className={errors.depositAccount ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-collect-from-self-card-deposit-account" name="depositAccount" dataTransformation={dataTransformation} readOnly={true} value={scheduleInfo?.destination?.url?.split("/").pop() || getKey("selectedAccount")?.id} source={acc_src} label="Destination Account" setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("merchantId") }} rules={{ required: true }} control={control} id="create-collect-from-self-card-form-card-merchant-id" name="merchantId" dataTransformation={merchantDataTransformation} value={scheduleInfo?.processingDetail?.merchant?.id || ''} source={merchant_src} readOnly={isEdit ? true : false} label="Merchant ID" className={errors.merchantId ? "border-error-input fx-input-edit" : "fx-input-edit"} setValue={setValue} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} >

                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} id="create-collect-from-self-card-form-card-amount-textbox" label="Amount*" name="amount" valuePattern={/-|\+|e|E/} type="number" variant="outlined" defaultValue={scheduleInfo?.amount || ''} prefix={'$'} showDecimal={true} setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("scheduleDate") }} className={errors.scheduleDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={scheduleDateValidation} id="create-collect-from-self-card-form-card-date-textbox" label="Date" name="scheduleDate" type="date" variant="outlined" defaultValue={scheduleInfo?.scheduleDate ? formatDate(scheduleInfo.scheduleDate) : formatDate(new Date())} notAllowPastDate={true} />
                                                    </Grid>

                                                </Grid>

                                                <Grid item container xs={12} sm={12} >
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-collect-from-self-card-form-card-purpose-textbox" label="Purpose*" name="purpose" variant="outlined" defaultValue={scheduleInfo?.purpose ? scheduleInfo['purpose'] : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("statementDescriptor") }} className={errors.statementDescriptor ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-from-self-card-form-card-statement-descriptor-textbox" label="Statement Descriptor" name="statementDescriptor" variant="outlined" defaultValue={scheduleInfo?.processingDetail?.statementDescriptor} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} sm={12} >
                                                    <Grid item xs={12} sm={6}>
                                                        <FxRadioEdit register={{ ...register("quickSettle") }} control={control} className={errors.quickSettle ? "border-error-input fx-input-edit" : "fx-input-edit"} data={allowDuplicateData} id="create-collect-from-self-card-form-card-quick-settle-radio" name="quickSettle" label="Faster Funding" defaultValue={scheduleInfo?.processingDetail?.quickSettle ? String(scheduleInfo.processingDetail.quickSettle) : 'false'} row="vertical" setValue={setValue} />
                                                    </Grid>
                                                </Grid>
                                                <FxTextEdit register={{ ...register("id") }} className="fx-hidden" control={control} id="create-collect-from-self-card-form-card-id-textbox" label="ID" name="id" variant="outlined" defaultValue={scheduleInfo?.id} />
                                            </Grid>

                                        </Grid>
                                    </FxCardBody>
                                    <FxCardFooter id="create-collect-from-self-card-form-card-footer">
                                        <Grid container xs={12} justifyContent="flex-end">
                                            {/* <Grid className='fx-padding-right-15'>
                                                            <FxButton
                                                                variant="contained"
                                                                className="fx-button"
                                                                id="cancel-collect-modal"
                                                                onClick={() => props.close()}
                                                            > Cancel
                                                            </FxButton>
                                                        </Grid> */}
                                            <FxButton
                                                forbiddenMessage={!hasSelectedAccount ? 'Passport Account Activation is pending. Please Activate to proceed.' : ''}
                                                disableRipple={false}
                                                className="fx-button fx-button-theme"
                                                id={(context?.data?.body ? 'create-collect-from-self-card-form-card-update-' : 'create-collect-from-self-card-form-card-create-') + "-button"}
                                                type="submit"
                                            >
                                                {isLoading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                    context?.data?.body ? 'Update Collect' : 'Collect'
                                                )}
                                            </FxButton>
                                        </Grid>
                                    </FxCardFooter>
                                </FxCard>
                            </form>}
                        </div>
                    </Grid >
                </Grid>
            </Grid>
        );
    });
