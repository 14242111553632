/**
 * Function handles the download of the file
 * @param data : data
 * @param fileName : file name
 * @param extension : "." +file type
 */
export const getImageDownload = function (data: any, fileName: any, extension: any) {
    const link = document.createElement('a');
    link.download = fileName + extension;
    link.href = URL.createObjectURL(new Blob([data]));
    link.click();
    URL.revokeObjectURL(link.href);
}
