import React, { useEffect, useState } from 'react';
import { Grid, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import HttpClient from '../../../libs/utils/httpClient';
import { useParams } from 'react-router';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { getImageDownload } from './DownloadImage';
import { FxButton } from '../../Action/FxButton';
import { useMediaQuery } from 'react-responsive'
import { getCustomerUrl } from '../../../libs/utils/utils';


/**
 * This component handles the display of the check image
 */
export const CheckImageCard: React.FC<any> = React.memo(
  (props) => {
    let account_info: any = {};
    let lockBoxImageId:any;
    let lockBoxImageName:any;
    const params = useParams<any>();
    const schedule = params.type;
    if (props && props.data) {
      account_info = props.data;
      /**
       * Is using for lockbox image exist or not
       * Checking the data is exteral and schecdule type is 'Collect'
       * Then it will check the document type is CK then assiging the name and id for the same
       *
       */
      if (props?.data?.type === 'EXTERNAL' && schedule === 'collect') {
        try {
          const lockboxDocument = account_info?.linkedDocument?.find((item: any) => item?.document?.type === 'CK')?.document;
          if (lockboxDocument) {
            lockBoxImageId = lockboxDocument?.id
            lockBoxImageName = lockboxDocument.name?.split('.')[0];
          }
        }
        catch (e) {
        }
      }
    }
 

    const scheduleId = account_info?.id;
    const docId = account_info?.linkedDocument?.[0]?.document?.id;
    const docfullname = account_info?.linkedDocument?.[0]?.document?.name;
    const docName =lockBoxImageName?lockBoxImageName: docfullname?.split('.')[0];
    const [imgFront, setImgFront] = useState<any>();
    const [imgBack, setImgBack] = useState<any>();

    const [imageType, setImageType] = useState('Front');

    const httpClientBlob = HttpClient.newClientBlob();
    /**
    * Checking the UI is mobile or other devices
    */
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
    /**
     * Async function handles the api call for getting check image details
     */
    async function loadImageUi() {
      if (schedule === 'send' || schedule === 'withdrawal') {
        const requestf = {
          "imageType": 'front',
          [schedule]: {
            "id": scheduleId
          }
        }
        const requestb = {
          "imageType": 'back',
          [schedule]: {
            "id": scheduleId
          }
        }

        try {
          await httpClientBlob.post(schedule + '/checkImage', requestf).then(response => {
            if (response.data) {
              const imageBlob = response.data;
              const imageObjectURL: any = URL.createObjectURL(imageBlob);
              setImgFront(imageObjectURL)
            }

          })
          await httpClientBlob.post(schedule + '/checkImage', requestb).then(response => {
            if (response.data) {
              const imageBlob = response.data;
              const imageObjectURL: any = URL.createObjectURL(imageBlob);
              setImgBack(imageObjectURL)
            }

          })
        }
        catch (err) {
        }
      }

      // if the schedule type is collect and method is mobile_check, call the api for getting front image and back image
      if(schedule === 'collect' && props.data.method === 'CHECK'){
        try{
          let documentId:any;
          if(props?.data?.type==='EXTERNAL' && lockBoxImageId){
            documentId=lockBoxImageId;
          }
          else{
            documentId=account_info?.linkedDocument?.[0]?.document?.id
          }
          await httpClientBlob.get(getCustomerUrl('/document/id/' + documentId, false)).then(response => {
            if (response.data) {
              const imageBlob = response.data;
              const imageObjectURL: any = URL.createObjectURL(imageBlob);
              setImgFront(imageObjectURL)
            }
          })
          await httpClientBlob.get(getCustomerUrl('/document/id/' + account_info?.linkedDocument?.[1]?.document?.id, false)).then(response => {
            if (response.data) {
              const imageBlob = response.data;
              const imageObjectURL: any = URL.createObjectURL(imageBlob);
              setImgBack(imageObjectURL)
            }
          })
        }
        catch(e){}
      }
      else if (schedule === 'collect') {
        try {
          await httpClientBlob.get('document/id/' + docId).then(response => {
            if (response.data) {
              const imageBlob = response.data;
              const imageObjectURL: any = URL.createObjectURL(imageBlob);
              setImgFront(imageObjectURL)
            }

          })
        }
        catch (err) {
        }
      }
    }
    /**
     * Method set the property of image type for front
     */
    const loadfrontImage = () => {
      setImageType('Front')

    }
    /**
     * Method set the property of image type for back
     */
    const loadbackImage = () => {
      setImageType('Back')
    }

    /**
     * Async function handles the api call for downloading check image
     */
    async function downloadImage() {
      if (schedule === 'send' || schedule === 'withdrawal') {
        const request: any = {
          "imageType": imageType,
          [schedule]: {
            "id": scheduleId
          }
        }
        await httpClientBlob.post(schedule + '/checkImage', request).then(response => {
          if (response.data) {
            getImageDownload(response.data, 'checkImage_' + imageType, '.png')
          }
        })
      }
      if (schedule === 'collect') {
        let documentId: any = lockBoxImageId ? lockBoxImageId : account_info?.linkedDocument?.[0]?.document?.id;
        if (imageType === 'Back') {
          documentId = account_info?.linkedDocument?.[1]?.document?.id;
        }
        await httpClientBlob.get(getCustomerUrl('/document/id/' + documentId, false)).then(response => {
          if (response.data) {
            getImageDownload(response.data, docName, '.jpg')
          }
        })
      }
    }
    /**
    * Function to be called before loading the component
    */
    useEffect(() => {
      loadImageUi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Grid container id="check-image-Grid" item xs={12} className={"fx-check-card fx-info-label-value-container"}>
          {!props.notiltle && (!isSmallScreen || props.title) && <Grid item xs={12}>
            <Typography variant="h5" id={props.id + '_' + props.title} className="">{props.title} &nbsp;</Typography>
          </Grid>}
            <Grid container xs={12} justifyContent="flex-end" alignItems="flex-start" className="fx-schedule-image-front-back" >
              <Grid item xs={6}>
                {!lockBoxImageId &&<ToggleButtonGroup
                  exclusive
                >
                  <ToggleButton value="Front" id="check-image-front-button" onClick={loadfrontImage}
                    className={(imageType === 'Front' ? "fx-link-sel" : 'fx-link')}>
                    Front
                        </ToggleButton>
                  <ToggleButton value="Back" id="check-image-back-button" onClick={loadbackImage}
                    className={(imageType === 'Back' ? "fx-link-sel" : 'fx-link')}>
                    Back
                        </ToggleButton >
                </ToggleButtonGroup>}
              </Grid>
              <Grid item xs={6} className='fx-check-download-svg' >
              {/*   check for removing download button in the check image card         */}
                {!props.hideDownload && <FxButton id="check-image-download-button" className="fx-button fx-button-theme fx-button-icon" onClick={downloadImage} startIcon={<SaveAltIcon />}>Download</FxButton>}
              </Grid>
            </Grid>
            <Grid item xs={12}  className ="fx-schedule-image-front-back-container">
              {imgFront && imageType === 'Front' && <img alt='' src={imgFront} width="100%"></img>}
              {imgBack && imageType === 'Back' && <img alt='' src={imgBack} width="100%"></img>}
            </Grid>
      </Grid >
    )
  })
