import React, { useEffect, useState } from "react";
import { Logger } from "../../../libs/utils/logger";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { FxButtonGroup } from "../../Action/FxButtonGroup";
import { ApprovalsQueueListing } from "./ApprovalQueueList";
import { useHistory } from "react-router";

Logger.debug(
    "PendingApprovalsQueue.tsx",
    "Pending Approval Policy Queue Listing initializing"
);

/**
 * Component: PendingApprovalsQueue
 * Usage: Listing of the Pending Approval Policy Queue
 */
export const PendingApprovalsQueue: React.FC<any> = React.memo((props) => {
    const [listingType, setListingType] = useState("pending");
    const dispatch = useDispatch();
    const history = useHistory();

    //API for Approval Queue list
    let src = {
        url: "/approval/list",
        method: "POST",
        data: {
            pageNumber: 1,
            pageSize: 25,
            criteria: {
                filters: [
                    {
                        operator: "eq",
                        key: "status",
                        values: ['PENDING'],
                    }
                ],
            },
        },
    };

    //type of Approval Listing Options
    const typeData = [
        { id: "pending", value: "PENDING", label: "PENDING", selected: true, path: '/pendingApprovals' },
        { id: "approved", value: "APPROVED", label: "APPROVED", selected: false, path: '/approvedApprovals' },
        { id: "rejected", value: "REJECTED", label: "REJECTED", selected: false, path: '/rejectApprovals' },
    ];

    /**
     * useEffect to load title while loading page
     */
    useEffect(() => {
        dispatch({
            type: "DATA_UPDATE_COMPONENT_REDUCER",
            payload: { id: "page-title-details", data: { title: "Approval Queue" } },
        });
    }, [dispatch]);

    /**
    * Method handles the onchange of the Approval Queue List type
    */
    const handleTypeChange = (event: any, updatedFromModal = false) => {
        if (listingType !== event.id) {
            setListingType(event.id);
            history.push(event.path);
        }
    };

    return (
        <Grid id="pending-approvals-queue-grid" item xs={12}>
            <Grid className="fx-tab-border">
                <FxButtonGroup
                    id="approvals-queue-list-card-button-group"
                    options={typeData}
                    onClick={(event: any) => handleTypeChange(event)}
                />
            </Grid>

            <Grid item xs={12}>
                &nbsp;
            </Grid>
            <ApprovalsQueueListing src={src} approvalQueue={"PENDING"}/>
        </Grid>
    );
});
