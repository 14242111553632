import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { FxButton } from '../Action/FxButton';
import { ReactComponent as BackIcon } from '../../assets/svg/back-button-icon.svg';
import { RegisterComponent } from '../../libs/saga/dataSaga';
import { getKey } from '../../libs/utils/storageManager';
import { useSelector } from 'react-redux';
import { useIsMobileScreen } from '../../libs/utils/utils';
import { FxPaymentModeStatus } from '../Input/FxPaymentModeStatus/FxPaymentModeStatus';

export const FxPageHeader: React.FC<any> = React.memo((props) => {

    ({ props } = RegisterComponent(props));
    const history = useHistory();
    const location = useLocation();
    const isSmallScreen = useIsMobileScreen();

    /**
     * method to get layout details
     */
    const layoutInfo = useSelector((state: any) => {
        if (state.data['layout-passport']) {
            return state.data['layout-passport']?.params
        }
    });

    const selectedAccount = getKey("selectedAccount");
    const nickName = selectedAccount?.nickName;
    const accountNumber = selectedAccount?.accountNumber || '';
    const simplifiedStatement = selectedAccount ? `${nickName ? `${nickName} ` : selectedAccount.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+' : selectedAccount.type === 'CASH_BUILDER' ? 'Cash Builder Account ': 'Passport Account '}**${accountNumber.substring(accountNumber.length - 4)}` : '';

    /**
     * Method to load json content
     */
    const jsonContent = useMemo(() => {
        let title, method;
        const data = props?.data;

        if (data?.jsonContent) {
            if (data?.title?.method) {
                method = <FxPaymentModeStatus id="collect-method" value={data.title.method} noColor={true} fill={false} className="fx-status" />;
            }
            if (data?.title?.header) {
                title = data.title.header;
            }
        }

        return (
            <>
                <Grid><span> {title}</span>&nbsp;{method}</Grid>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data?.jsonContent, props?.data?.title?.method, props?.data?.title?.header]);

    return (
            <Grid item container xs={12} flexWrap={'nowrap'} justifyContent={'flex-start'} direction='row'>
                {props?.data?.backButton && <Grid>
                    <FxButton id={"back-button"} variant="contained" className={'fx-button fx-button-theme fx-button-top-back'} onClick={() => { history.push({pathname:props?.data?.backButton, state: {url:props?.data?.url, tab: props?.data?.tab}}) }}><BackIcon /></FxButton>
                </Grid>}
            <Grid item container xs>
                {!props?.data?.jsonContent && <Grid item xs={12} className={`fx-top-area-passport-left-title ${props?.data && typeof (props?.data?.title) === 'string' ? 'fx-top-area-passport-left-title-mobile' : ''}`}>{props?.data && props?.data?.title != null ? props?.data?.title : (location.pathname === '/' || location.pathname === '/dashboard') && layoutInfo?.accountBasedMenu ? 'Dashboard' : !!layoutInfo?.accountBasedMenu ? 'Home' : null}</Grid>}
                {props?.data?.jsonContent  && jsonContent && <Grid item xs={12} className={`fx-top-area-passport-left-title ${props?.data && typeof (props?.data?.title) === 'string' ? 'fx-top-area-passport-left-title-mobile' : ''}`}>{props?.data && props?.data?.title != null ? jsonContent : ''}</Grid>}
                <Grid item container xs={12} spacing={1} alignContent={"center"} alignItems={"center"}>
                    <Grid item className={'fx-top-area-passport-left-customer'} >{getKey("CustomerName") ? getKey("CustomerName") : getKey('CustomerIndividualFullName')}</Grid>
                    {(!!layoutInfo?.accountBasedMenu && layoutInfo?.selectedPage !== 'LEADS') &&  <Grid item xs={isSmallScreen ? 12:0} className={'fx-top-area-passport-left-sub-title'}>
                      { !isSmallScreen && selectedAccount && '| ' }{simplifiedStatement}
                    </Grid>}
                </Grid>
            </Grid>
            </Grid>
    );
})
