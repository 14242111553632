import React  from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { FxSwitch } from "../../../Action/FxSwitch";
import { CalenderChip } from "./CalenderChip";


Logger.debug("FxOnDateRecurring.tsx", "Fx on date Recurring initializing")
/**
 *  This component handles the fx on date recurring
 */

export const FxOnDateRecurring: React.FC<any> = React.memo(
    (props: any) => {

        // Method handles the switch change event
        const handleSwitch = (value: any) => {
            props.onDateSwitchChange?.(value);
        }
        // Method passes the form data
        const handleUpdateFormData = (value: any) => {
            props.onSubmit?.(value);
        }

        return (
            <Grid item xs={12} className="fx-on-date-recurring">
                <Grid item xs={12}>&nbsp;&nbsp;</Grid>
                <Grid item xs={12}>
                    On Date <FxSwitch id='on-date-recurring-switch' value={props.switchValue} onClick={handleSwitch}></FxSwitch>
                </Grid>
            {props.switchValue && <Grid item container xs={12} spacing={2} className="fx-on-date-recurring">
                <Grid item xs={12}>
                    <CalenderChip id="fx-on-date-recurring-calender-chip" updateFormData={handleUpdateFormData} data={props?.data} control={props?.control} register={props?.register} errors={props?.errors} setValue={props?.setValue} clearErrors={props?.clearErrors}/>
                </Grid>
            </Grid>}
            </Grid>
        )
    })
