import React, { useEffect }  from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { Grid, Typography, Divider } from '@mui/material';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { AUTH_STRINGS } from '../../../../constants/strings';
import { Logger } from '../../../../libs/utils/logger';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import { FxButton } from '../../../Action/FxButton';
import usa_state from '../../../Utils/usa_states.json';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { getKey } from '../../../../libs/utils/storageManager';
import { ageLimit, convertToPhoneDisplayFormat, handleAddressChange, handleKeyDownChange, handleZipChange, renderError, setPhoneValidationOptions, setZipValidationOptions, updateComponentParams , } from '../../../Utils/CommonBaseClass';
import { ReactComponent as EditIcon } from '../../../../assets/svg/business-signup-edit-icon.svg';
import { ReactComponent as PhoneVerificationICon } from '../../../../assets/svg/verification.svg';
import { FxDateEdit } from '../../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../../Input/FxSelect/FxSelectAutoSearch';
import { FxTextEditSSN } from '../../../Input/FxSSN/FxTextEditSSN';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';


/**
 * Component: JointTenancyIdentificationSignUp
 * Usage: Joint tenanct SignUp Individual Details Add page
 */

Logger.debug("JointTenancyIdentificationSignUp.tsx", "SignUp identification initializing")

interface InputErrType {
    type: string;
    message: string;
}

// Ssn validation constant
const setSsnValidationOptions = {
    required: true,
    maxLength: 11,
    pattern: {
        value: /^\d{3}-?\d{2}-?\d{4}$/,
        message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
    },
};

// Phone validation constant
const setEmailValidationOptions = {
    required: true,
    maxLength: 250,
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
        message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
    },
};

 /**
  *  Address line 1 validation constant
  * */ 
const setAddressLine1Validation = {
    required: true,
    minLength: {
        value: 4,
        message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
    },
    maxLength: {
        value: 40,
        message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
    }
}

export const JointTenancyIdentificationSignUp: React.FC<any> = React.memo((props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();
        ({ props } = RegisterComponent(props));
        const history = useHistory();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })

        const dispatch = useDispatch();

        useEffect(() => {
            updateComponentParams(dispatch, props.id, {isLoading: false})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        /**
        * Method to redirect to previous page on clicking cancel button
        */
        const handleClose = () => {
            history.push('/');
        }
      
        /**
         * Method to handle on submit event
         * @param data :data
         */
        async function onSubmit(data: any) {
            if (props.onSubmit) {
                updateComponentParams(dispatch, props.id, { isLoading: true })
                props.onSubmit(data,()=>{
                    updateComponentParams(dispatch, props.id, { isLoading: false })
                });
            }
        }
        // Email error render function
        const renderEmailError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return AUTH_STRINGS.ERRORS.EMAIL_PLEASE_ENTER;
            }
            return err.message;
        };

         useEffect(() => {
              const primaryOwner = props.data;
              if(primaryOwner) {
                setValue('firstName', primaryOwner.firstName)
                setValue('middleName', primaryOwner.middleName)
                setValue('lastName', primaryOwner.lastName)
                setValue('dob', primaryOwner.dob)
                setValue('ssn', primaryOwner.ssn)
                setValue('addressLine1', primaryOwner.addressLine1)
                setValue('addressLine2', primaryOwner.addressLine2)
                setValue('city', primaryOwner.city)
                setValue('state', primaryOwner.state)
                setValue('zip', primaryOwner.zip)
                setValue('mobilePhone', primaryOwner.mobilePhone)
                setValue('email', primaryOwner.email)
              } else {
                if (getKey('email') && getKey('phone') && getKey('firstName') && getKey('lastName')) {
                  setValue('email', getKey('email'))
                  setValue('mobilePhone', convertToPhoneDisplayFormat(getKey('phone')))
                  setValue('firstName', getKey('firstName'))
                  setValue('lastName', getKey('lastName'))
                }
              }
             // eslint-disable-next-line react-hooks/exhaustive-deps
         },[props.data]);

        return (
            <Grid container id="create-external-account-main-grid" xs={12}  justifyContent="center">
                <Grid item xs={12} sm={isSmallScreen?undefined:11} md={isSmallScreen?undefined:7}>
                    <FxCard id="customer-signup-info-card" className={!isSmallScreen?"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-individual-details":"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-signup-formkyc-mobile fx-individual-details"}>
                        <div className="fx-form-edit-profile" >
                            <form id='signup-details-form' onSubmit={handleSubmit(onSubmit)} >
                                <FxCardHeader id="customer-signup-form-card-header">
                                <Grid container direction="row" xs={12}   >
                                        <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                                            <Grid item xs={12} container flexWrap={'nowrap'}>
                                                <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                                                  <EditIcon />
                                                </Grid>
                                                <Grid item>
                                                  <Typography id="customer-signup-form-card-title" variant="h3" >
                                                    Tell us about yourself
                                                  </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                      <Typography id="customer-signup-form-card-title" variant="h6" className={isSmallScreen?"fx-word-wrap":''}>
                                                        To get started, we’ll need to verify your identification details
                                                      </Typography>
                                                    </Grid>
                                            </Grid>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody id="customer-signup-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                                    <Grid xs={12} sm={12}>
                                        <Grid xs={12} container className="fx-info-card-form" spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxLabelView id="customer-signup-info-label" className="" >MORE ABOUT YOU</FxLabelView>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxTextEdit register={{ ...register("firstName") }} type="text" className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signup-firstName" label="First Name *" name="firstName" defaultValue={getKey('firstName')} isEditable={false} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("middleName") }} type="text" className={errors.middleName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signup-middleName" label="Middle Name" name="middleName" defaultValue={props?.data.middleName ? props.data.middleName : ''} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("lastName") }} type="text" className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signup-lastName" label="Last Name *" name="lastName" defaultValue={getKey('lastName')} isEditable={false} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signup-dob-textbox" label="Date of Birth *" name="dob" variant="outlined" defaultValue={props?.data.dob ? props.data.dob : ''} maxDate={ageLimit(18)} setValue={setValue} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEditSSN
                                                    register={{ ...register("ssn") }}
                                                    className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    error={errors?.ssn}
                                                    control={control}
                                                    rules={setSsnValidationOptions}
                                                    id="signUp-indivudal-form-card-ssn"
                                                    label="Social Security Number *"
                                                    placeholder="XXX-XX-XXXX"
                                                    name="ssn"
                                                    variant="outlined"
                                                    setValue={setValue}
                                                    setError={setError}
                                                    clearErrors={clearErrors}
                                                    defaultValue={props?.data.ssn ? props.data.ssn : ''}
                                                />                                               
                                                <div className={'error-message'}>
                                                    {errors.ssn && renderError(errors.ssn)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxLabelView id="customer-signup-info-label-primary-address">YOUR CONTACT INFO</FxLabelView>
                                            </Grid>
                                            <FxTextEdit register={{ ...register("id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-id-id"  name="id" variant="outlined" defaultValue={props.data?.id} />
                                            <FxTextEdit register={{ ...register("address_id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-address-id"  name="address_id" variant="outlined" defaultValue={props.data?.address_id} />
                                            <Grid item xs={12} sm={12}>
                                                <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAddressLine1Validation} id={'addressLine1'} label="Address Line 1 *" name={'addressLine1'} variant="outlined" defaultValue={props?.data?.addressLine1 ? props?.data?.addressLine1 : ''} onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                                <div className={'error-message'}>
                                                    {errors.addressLine1 && renderError(errors.addressLine1)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxTextEdit register={{ ...register('addressLine2') }} control={control} className="fx-input-edit" id={'addressLine2'} label="Address Line 2" name={'addressLine2'} variant="outlined" defaultValue={props?.data?.addressLine2 ? props?.data?.addressLine2 : ''} />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxTextEdit register={{ ...register('city') }} control={control} rules={{ required: true }} className={errors['city'] ? "border-error-input fx-input-edit" : "fx-input-edit"} id={'city'} label="City *" name={'city'} variant="outlined" defaultValue={props?.data?.city ? props?.data?.city : ''} />
                                            </Grid>
                                            <Grid item xs={12} sm={!isSmallScreen ? 6 : 12}>
                                                <FxSelectAutoSearch register={{ ...register('state') }} control={control} className={errors['state'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'state'} name={'state'} data={usa_state}
                                                    label="State *" setValue={setValue} value={props?.data['state']} />
                                            </Grid>
                                            <Grid item xs={12} sm={!isSmallScreen ? 6 : 12}>
                                                <FxTextEdit register={{ ...register('zip') }} control={control} rules={setZipValidationOptions(true)} className={errors['zip'] ? "border-error-input fx-input-edit" : "fx-input-edit"} id={'zip'} label="ZIP *" name={'zip'} variant="outlined" defaultValue={props?.data?.zip ? props?.data?.zip : ''} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                                <div className={'error-message'}>
                                                    {errors.zip && renderError(errors.zip)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                            <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="signup-mobilePhone" name="mobilePhone" label="Phone Number *" defaultCountryCode={getKey('countryCode')} defaultValue={convertToPhoneDisplayFormat(getKey('phone'))} isEditable={false} setValue={setValue} setError={setError} clearErrors={clearErrors} suffixIcon={PhoneVerificationICon} />
                                                <div className={'error-message'}>
                                                    {errors.mobilePhone && renderError(errors.mobilePhone)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit login-email"} control={control} rules={setEmailValidationOptions} id="signup-email" label="Email *" name="email" defaultValue={getKey('email')} isEditable={false} suffixIcon={PhoneVerificationICon} />
                                                <div className={'error-message'}>
                                                    {errors.email && renderEmailError(errors.email)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="customer-signup-Card-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer fx-footer-kyc">
                                        <FxButton
                                            variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="cancel-add-mailing-address"
                                            onClick={handleClose}
                                        > Go Back
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id="add-mailing-address-save-changes"
                                            type="submit"
                                            isSubmitting={props?.isLoading}
                                        > Save & Continue
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </form>
                        </div>
                    </FxCard>
                </Grid>
            </Grid>
        );
    });