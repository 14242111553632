import React from 'react'; // we need this to make JSX compile
import { useParams } from 'react-router-dom';
import { checkPermission } from '../../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../../Index/Passport/FxAccessDenied';
import { TransactionCards } from './TransactionCards';

const permissionObject: any = {
    'transactions_card_collect': {
        entity: "Accounts",
        name: "Card Collect",
        operation: "View"
    },
    'transactions_check_collect': {
        entity: "Accounts",
        name: "Check Collect",
        operation: "View"
    },
    'transactions_ach_collect': {
        entity: "Accounts",
        name: "Ach Collect",
        operation: "View"
    },
    'transactions_wire_send': {
        entity: "Accounts",
        name: "Wire Send",
        operation: "View"
    },
    'transactions_book_send': {
        entity: "Accounts",
        name: "Book Send",
        operation: "View"
    },
    'transactions_check_send': {
        entity: "Accounts",
        name: "Check Send",
        operation: "View"
    },
    'transactions_ach_send': {
        entity: "Accounts",
        name: "Ach Send",
        operation: "View"
    },
    'transactions_virtual_card_send': {
        entity: "Accounts",
        name: "Virtual Card Send",
        operation: "View"
    }
}

/**
* function to check permission
*/
const checkViewPermission = (data: any) => {
    return checkPermission(permissionObject[data]);
}

/**
 * This component handles the rendering of the Transaction details
 */
export const TransactionDetails =
    () => {
        interface ScheduleParams {
            id: string
            type: string;
        }

        const params = useParams<ScheduleParams>();
        const id = params.id
        const schedules_source = {
            url: 'transaction/id/' + id,
            method: 'GET'
        }

        const hasPermission = ['transactions_card_collect', 'transactions_check_collect', 'transactions_ach_collect', 'transactions_wire_send', 'transactions_book_send', 'transactions_check_send', 'transactions_ach_send', 'transactions_virtual_card_send']
        .some(checkViewPermission);

        return (
            <>
            {hasPermission ? (
                <TransactionCards id={`transaction-Cards-details-config-card1${id}`} source={schedules_source} />
            ) : (
                <FxAccessDenied id="transaction-Cards-details-access-denied" title="You are not authorized to access this page" />
            )}
        </>
        )

    }
