import HttpClient from './httpClient';
import { getKey, setKey, isUserLoggedIn } from './storageManager';

//http client
const httpClient = HttpClient.getClient();

/**
 ** get the entity info 
 * @param state 
 * @param entity 
 * @param dispatch 
 */
export const getEntityInfo = (state: any, entity: string, dispatch: any) => {
    if (state.entity && state.entity[entity]) {
        return state.entity[entity];
    } else {
        httpClient.get(`/entityType/${entity}`).then((response) => {
            dispatch({
                type: "GET_ENTITY_INFO",
                payload: { data: response },
                entity
            });
            return response;
        }).catch((e) => {
            return e;
        })
    }
}

/**
 * 
 * @param entityfri contains entity info
 * @param method get/list entity
 * @param params  req params
 * @returns api data
 */
export function prepareHTTPRequest(entityfri: string, method: string, params: any) {
    let entity = entityfri.replace("fri::entity:", "")
    switch (method) {
        case 'get':
            return httpClient.get('entity/' + entity + '/' + params.id);
        case 'list':
            return httpClient.post('entity/' + entity + '/list');
        default: return httpClient.get('entity/' + entity);

    }

}


/**
 ** token  will expire in n minutes.
 ** before expiry have to renew token using the refresh token 
 */
export function refreshToken() {
    let refToken = getKey("refreshToken");
    let loggedInStatus = isUserLoggedIn();

    setTimeout(() => {
        if (refToken && loggedInStatus) {
            // call refresh token api
            getRefreshTokenData((data: any) => {
                // console.log("getRefreshTokenData",data)
                if (data && data.accessToken) {
                    // console.log("setting refreshToken",data)
                    // console.log("old refreshToken",refToken)
                    HttpClient.setToken(data.accessToken);
                    setKey("refreshToken", data.refreshToken);
                }
            });
        }
        refreshToken()
    }, 60000 * 20);
}

//refresh token api call
async function getRefreshTokenData(callback: any) {
    return; //not required with unified and keycloak api
    // const data: any = await httpClient.post('user/authToken', { refreshToken: getKey("refreshToken"), "userType": "CUSTOMER" }).then(response => {
    //     // console.log("getRefreshTokenData",response)
    //     return callback(response.data)
    // })
}


