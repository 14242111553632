import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ViewProfile } from '../Index/Passport/ViewProfile';
import { CustomerEditProfile } from './CustomerEditProfile';
import { CustomerEditAuthorizedSignatory } from './CustomerEditAuthorizedSignatory';
/**
 * Component used to display customer profile and Authorized Signatory details
 */
const info_source = {
    url: "/",
    method: "GET"
}


export const EditProfile: React.FC = () => {
        const params = useParams<any>();
        const profile = params.type ? params.type : 'general';

        /**
         * Method used to display customer page tabs
         * @param value :params type
         * @returns 
         */
        let getData = (value: any) => {
            switch (value.toUpperCase()) {
                case 'GENERAL':
                    return <CustomerEditProfile key="customereditprofile" id="customer-edit-profile" source={info_source}></CustomerEditProfile>
                case 'AUTHORIZEDSIGNATORY':
                    return <CustomerEditAuthorizedSignatory key="authorizedsignatory" id="customer-edit-authorized-signatory" source={info_source}></CustomerEditAuthorizedSignatory>
                default:
                    return <CustomerEditProfile key="customereditprofile" id="customer-edit-profile" source={info_source}></CustomerEditProfile>
            }
        }
        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container className="fx-top-header" id="customer-top-grid" item justifyContent="space-between" alignItems="center" xs={12} >
                    <Grid item><h3 >Edit Customer Profile</h3></Grid>
                    <ViewProfile />
                </Grid>
                <Grid container xs={12} >
                    {getData(profile)}
                </Grid>
            </Grid>
        )
    }
