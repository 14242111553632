/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxSwitch } from '../../Action/FxSwitch';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { FxFileUpload } from '../../Input/FxFile/FxFileUpload';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { checkPermission, createCommonVerifyProps, updateComponentParams } from '../../Utils/CommonBaseClass';
import {
  convertEndData,
  convertToOnDayFormat,
  convertToTitleCaseAndAppendWithS,
  formatDate,
  renderError,
  transformRecurringRequestGlobal
} from '../../Utils/CommonBaseClass';
import { FxRecurring } from './Recurring/FxRecurring';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { ScheduleTopSection } from './ScheduleTopSection';
import FxSnackBar from '../../Utils/fx-snack-bar';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

const httpClient = HttpClient.getClient();

Logger.debug('CreateTransferPage.tsx', 'create transfer initializing');

interface ITransferData {
  processingDetail: {
    memo:string;
  }
  id: number;
  allowDuplicate: boolean;
  amount: string;
  createdBy: {
    status: string;
    userType: string;
    username: string;
  }
  createdOn: string;
  destination: {
    account: {
      id: number;
      resourceName: string;
      url: string;
    }
  }
  lastUpdatedBy: {
    status: string;
    userType: string;
    username: string;
  }
  lastUpdatedOn: string;
  method: string;
  nickName: string;
  purpose: string;
  resourceName: string;
  scheduleDate: string;
  source: {
    account: {
      id: number;
      nickName: string;
      resourceName: string;
      url: string;
    }
  }
  status: string;
  statusDate: string;
  statusReason: string;
  type: string;
  url: string;
}

interface ITransactionData {
  scheduleDate: string;
  amount: string;
  method: string;
  type: string;
  source?: {
    account: {
      id: number;
    },
  },
  destination?: {
    account: {
      id: number;
    },
  },
  processingDetail?:any;
  purpose: string;
  allowDuplicate: boolean;
  externalId?: string;
  id?: number;
}

interface IAction {
  transaction: ITransactionData
}

interface IRepeatTransactionData {
  id?: number;
  action: IAction[];
  name: string;
  transactionDetail: {
    startDate: string;
  }
}

/**
 * This component handles the send creation through payee for book method
 */
export const CreateTransferPage: FC<any> = memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const transferInfo: ITransferData = context?.data?.body;
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individual = uiFeat.getIndividualCustomerFeature().available;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    clearErrors,
    control,
    watch,
    setError
  } = useForm();

  const method_Tab = [
    {
      title: 'ACH',
      link: '/transaction/send/add/ach',
      disabled: props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Ach Send",
        operation: "CREATE"
      },
      {
        entity: "Recurring Transaction",
        name: "Ach Send",
        operation: "CREATE"
      }]
    },
    {
      title: 'WIRE',
      link: '/transaction/send/add/wire',
      disabled: props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Wire Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Wire Send",
        operation: "CREATE"
      }]
    },
      {
      title: 'INTERNATIONAL WIRE',
      link: '/transaction/send/add/internationalWire',
      disabled: props.isEdit,
      multiplePermission: true,
      permission: [{
          entity: "Accounts",
          name: "International Wire Send",
          operation: "CREATE"
        },{
          entity: "Recurring Transaction",
          name: "International Wire Send",
          operation: "CREATE"
        }]
  },
    ...(!individual ? [{
      title: 'CHECK',
      link: '/transaction/send/add/check',
      disabled: props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Check Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Check Send",
        operation: "CREATE"
      }]
    }] : []),
    {
      className: 'fx-link-sel',
      title: 'Book',
      link: '/transaction/send/transfer',
      disabled: props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Book Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Book Send",
        operation: "CREATE"
      }]
    }
  ];

  const dest_acc_src: any = {
    url: 'account/list',
    method: 'POST',
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: 'DESC',
        sortBy: 'createdOn'
      },
      criteria: {
        filters: [
          {
            key: 'status',
            operator: 'nin',
            values: ['INACTIVE']
          }
        ]
      }
    }
  };

  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [destinationAccountData, setDestinationAccountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDataAvailable, setIsEditDataAvailable] = useState(false);
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const [location, setLocation] = useState<any>();
  const scheduleId = params?.id

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: 'DATA_COMPONENT_RESET', payload: { id: props.id } });
    dispatch({
      type: 'DATA_UPDATE_COMPONENT_PARAM',
      payload: {
        id: 'create-send-account-book-form-card-file-id-file-upload',
        files: []
      }
    });
    if (params.id) {
      const src = {
        url: `/transaction/id/${params.id}`,
        method: 'GET'
      };
      dispatch({
        type: 'DATA_API_PARAM_CHANGED',
        payload: { id: 'edit-send-account-book', source: src }
      });
      updateComponentParams(dispatch, 'edit-send-account-book', {
        isEdit: true
      });
      setIsEditDataAvailable(true);
    }
    else{
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Send Money',backButton:'/dashboard' } }});
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  const docTypeData: any = [{ label: 'Contract', value: 'CONTRACT' }];

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passData(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors['fileError']) {
        clearErrors('fileError');
      }
    } catch (e) {
    }
  }

  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removeFile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: 'DATA_UPDATE_COMPONENT_PARAM',
      payload: {
        id: 'create-send-account-book-form-card-file-id-file-upload',
        files: newval
      }
    });
    setFileUploadData(newval);
  };


  const handleClose = () => {
    if (params?.id) {
      history.push("/transactions/send/" + params?.id);
    } else {
      history.push("/dashboard");
    }
  };

  /**
   * Method enables and disables the allow duplicate
   * @param event : allow duplicate status
   */
  const handleAllowDuplicate = (event: any) => {
    setAllowDuplicate(event);
  };

  const currentAccount = useMemo(() => getKey('selectedAccount')?.id, []);

  /**
   * setting isEditDataAvailabe state after getting data from context and setting the default value for fields from api data
   */
  useEffect(() => {
    if (transferInfo && isEditDataAvailable) {
      setValue('amount', transferInfo.amount);
      setValue('purpose', transferInfo.purpose);
      setValue('destinationTransfer', transferInfo?.destination?.account?.id);
      setValue("memo", context?.data?.body?.processingDetail?.memo);
      setAllowDuplicate(transferInfo?.allowDuplicate);
    }
  }, [transferInfo, isEditDataAvailable]);

  useEffect(()=>{
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  },[context?.data?.body])

  /**
   * Method triggers if the recurring updates
   */
  const handleUpdateRecurring = (data: any) => {
    setRecurringFormData(data);
  };

  /**
   * Method enables and disables the repeat functionality
   * @param event : repeat status
   */
  const handleRepeat = (event: any) => {
    setRepeatStatus(event);
  };

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  function destinationDataTransformation(data: any) {
    setDestinationAccountData(data);
    const options: any = [];
    data?.forEach(function (option: any) {
      if (currentAccount && currentAccount !== option.id) {
        options.push({
          value: option.id,
          label: accountLabelTransformation(option)
        });
      }
    });
    return options;
  }

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let transferId = '';
    let req: any = {};

    //regular transfer data
    const transaction: ITransactionData = {
      scheduleDate: moment(formatDate(new Date())).format('MM/DD/YYYY'),
      amount: data.amount,
      method: 'BOOK',
      type: 'REGULAR',
      source: {
        account: {
          id: data.source
        }
      },
      destination: {
        account: {
          id: data.destinationTransfer
        }
      },
      purpose: data.purpose,
      allowDuplicate: allowDuplicate
    };
    if(data.memo)
    {
      transaction.processingDetail={
         memo:data.memo
      }
    }
    if(data['location']){
      transaction['processingDetail'] = {
        'location' : {
          'id' : data['location']
        }
      }
    }
    if (data.externalId) {
      transaction.externalId = data.externalId;
    }
    if (data.id) {
      transaction.id = data.id;
    }

    let recurringRequest: any = {};
    let status: any;

    //transfer data with repeat
    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: recurringRequest.transactionDetail,
        action: []
      };
      req.transactionDetail.startDate = data?.startDate;
      req.action.push({transaction});
      if (fileUploadData) {
        const linkedArray = fileUploadData.map((item: any) => {
          return { purpose: 'Authorization', document: { id: item.id } }
        });
        if (linkedArray.length > 0) {
          req.linkedDocument = linkedArray;
        }
      }
    }
    else {
      req = transaction
    }

    //edit action
    if (props.isEdit) {
      delete req.source;
      delete req.destination;
      status = await updateTransfer(req);
    }
    //create action
    else {
      status = await createTransfer(req);
      if (status?.headers?.url) {
        transferId = status.headers.url.split('/').pop();
      }
    }
    status = processAPIResponse(status);
    setIsLoading(false);
    if (status.status) {
      //api success
      const successMessage = transferInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
        const redirectUrl = transferInfo? `/transactions/send/${transferInfo['id']}`: repeatStatus? '/thanks/transactions/send-transfer-recurring/': '/thanks/transactions/send-transfer/';
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        if(status.responseData?.sessionId){
        
        const verifyProps = {
            ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      if (transferInfo) {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: 'Send Updated Successfully!',
        });
        history.push(`/transactions/send/${transferInfo.id}`);
      } else {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: repeatStatus
            ? 'Recurring Created Successfully!'
            : 'Send Created Successfully!',
        });
        if (repeatStatus) {
          dispatch({
            type: 'DATA_UPDATE_COMPONENT_PARAM',
            payload: {
              id: 'create-send-account-book-form-card-file-id-file-upload',
              files: []
            }
          });
          history.push(
            `/thanks/transactions/send-transfer-recurring/${transferId}`
          );
        } else {
          history.push(`/thanks/transactions/send-transfer/${transferId}`);
        }
      }
    } 
  }else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
   * Async function handles the api call for create transfer
   * @param payloadData : form request
   * @returns : response object
   */
  async function createTransfer(payloadData: ITransactionData | IRepeatTransactionData) {
    try {
      let url = '/transaction'
      if (payloadData.id) {
        url = `/transaction/id/${payloadData.id}`;
      }
      if (repeatStatus) {
        url = '/transaction/recurring'
      }
      return await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
        return response
      })
        .catch((error) => {
          return { ...error };
        });
    } catch (err) {
      Logger.error('CreateSendAccountCHECK.tsx', 'error', err);
      return err;
    }
  }

  /**
   * Async function handles the api call for update transfer
   * @param payloadData : form request
   * @returns : response object
   */
  async function updateTransfer(payloadData: ITransactionData) {
    try {
      const url = `/transaction/id/${transferInfo.id}`;
      return await httpClient
        .post(getCustomerUrl(url,false), payloadData)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return { ...error };
        });
    } catch (err) {
      Logger.error('CreateTransferPage.tsx', 'error', err);
      return false;
    }
  }

  /**
   * Method fetches Account using id from an array
   * @param data : array
   * @param id: selected account id
   */
  function fetchAccountFromId(data: any, id: any) {
    const item = data?.find((item: any) => item.id === id);
    if(!item) {
      return;
    }
    return accountLabelTransformation(item);
  }

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Send Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    'From': {
      id: 'from',
      value: accountLabelTransformation(getKey("selectedAccount")),
    },
    'To': {
      id: 'to',
      value: watch('destinationTransfer') ? fetchAccountFromId(destinationAccountData, watch('destinationTransfer')) : '',
    },
    ...(businessCustomer
    ? { 'Purpose': { id: "purpose", value: watch("purpose") } }
    : { 'Memo': { id: "purpose", value: watch("purpose") } }),
    'Via': {
      id: 'method',
      value: 'BOOK',
    }
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: 'memo',
      value: watch('memo'),
    }
}

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
    if (!repeatStatus || !recurringFormData) {
      return {}
    }
    const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
    const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
      ? convertToTitleCaseAndAppendWithS(
        recurringFormData?.repeatEveryFormData?.repeatEverySelect
      )
      : '';
    const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
      'Repeat every': {
        id: 'repeat-every',
        value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
      },
    }
    if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
      result['On'] = {
        id: 'repeat-on',
        value: convertToOnDayFormat(recurringFormData),
      };
    }
    const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
    const ends = recurringFormData?.endFormData
      ? convertEndData(recurringFormData?.endFormData)
      : '';
    if (ends || endOn) {

    }
    result['Ends'] = endOn ? {
      id: 'repeat-ends',
      value: ends,
      ValueComponent: ({ value }) => (
        <>
          On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
        </>
      )
    } : {
      id: 'repeat-ends',
      value: ends,
    };
    return result;
  }, [recurringFormData, repeatStatus]);

  let topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    source: context?.data?.body?.source?.id,
    purpose: context?.data?.body?.purpose
  }
  const tabClickFunction = () => {
    try{
      const data:any = {
        schedule: 'send',
        type: 'my-account',
        formData: { amount: watch('amount'), purpose: watch('purpose')}
      }
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
    }
    catch(e){}
  }
     /**
     * Function to handle the closing of the modal.
     */
	
  const handleCloseModal = () => {
    let verifyProps = {...props.verifyProps}
    verifyProps['open'] = false;
    updateComponentParams(dispatch, props.id, { verifyProps });
};
  return (
    <>
    <ScheduleLayout
        id={'create-send-account-book'}
        type='send'
        loading={isLoading}
        submitTitle={repeatStatus ? 'Create' : props.isEdit ? 'Save Changes' : 'Send Money'}
        summaryData={{ ...summaryData, ...summaryRepeatData }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleClose}
        saveEnabled={true}
        verifyProps={props?.verifyProps}
        handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection id="create-send-account-book-schedule-top-section" schedule="send" type="my-account" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={dest_acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-account-transfer-form-card-amount-textbox" sourceId="create-send-account-transfer-form-card-account" purposeId="create-send-account-transfer-form-card-purpose-textbox"/>
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
        <FxLocationSelectComponent
            label='Location'
            register={{ ...register("location") }}
            control={control}
            rules={{ required: false }}
            id="create-send-account-book-form-location"
            name="location"
            className="fx-input-edit"
            setError={setError}
            clearError={clearErrors}
            setValue={setValue}
            resetField={resetField}
            isEditable={!scheduleId}
            value={location ? {
              label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
              value: location?.id,
            }: null}
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="create-send-contact-ach-form-card-tab" tabClick={tabClickFunction}
        />
      </Grid>
      {(!params?.id || (transferInfo && params?.id)) && <Grid item xs={12} sm={12}>
        <FxMaterialSelect
          name={'destinationTransfer'}
          control={control}
          rules={{ required: true }}
          register={{ ...register('destinationTransfer') }}
          id="create-send-account-transfer-form-card-destination"
          value={props.isEdit ? transferInfo?.destination?.account?.id : ''}
          readOnly={props.isEdit}
          dataTransformation={destinationDataTransformation}
          label="Select Destination Passport Account"
          source={dest_acc_src}
          setValue={setValue}
        />
      </Grid>}
      {businessCustomer && <Grid item xs={12} sm={12}>
        <FxTextEdit register={{ ...register("memo") }} className={errors.memo ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-from-others-ach-form-card-ach-memo-textbox" label="Memo" name="memo" variant="outlined" defaultValue={transferInfo?.processingDetail?.memo ? transferInfo.processingDetail.memo : ''} />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <FxSwitch
          id="create-send-account-transfer-form-card-allow-duplicate"
          value={allowDuplicate}
          onClick={handleAllowDuplicate}
        ></FxSwitch>{' '}
        <FxLabel value="Allow Duplicate"></FxLabel>
      </Grid>
      {(!props.isEdit && checkPermission({entity: "Recurring Transaction",name: "Book Send",operation: "Create" })) &&
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="repeat-switch"
            value={repeatStatus}
            onClick={handleRepeat}
          ></FxSwitch>{' '}
          <FxLabel value="Repeat"></FxLabel>
        </Grid>}
      {repeatStatus && (
        <>
          <Grid item xs={12} sm={12}>
            <FxDateEdit
              register={{ ...register('startDate') }}
              className={
                errors.startDate
                  ? 'border-error-input fx-input-edit'
                  : 'fx-input-edit'
              }
              control={control}
              label="Start Date *"
              id="create-send-account-ach-form-card-startDate"
              name="startDate"
              type="date"
              variant="outlined"
              setValue={setValue}
              disablePast={true}
              resetField={resetField}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register('name') }}
              className={
                errors.name
                  ? 'border-error-input fx-input-edit'
                  : 'fx-input-edit'
              }
              control={control}
              rules={{ required: true }}
              id="create-send-account-ach-form-card-name-textbox"
              label="Name *"
              name="name"
              variant="outlined"
            />
          </Grid>

      <Grid item xs={12} sm={12}>
        {/*This section handles the recurring*/}
          <FxRecurring
            id="create-send-account-book-form-card-fx-recurring"
            updateTrigger={handleUpdateRecurring}
            startDate={watch('startDate')}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
          ></FxRecurring>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={12}>
              <Typography className="filter-popover-label">
                LINKED DOCUMENTS
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              &nbsp;
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12} sm={8}>
                <FxMaterialSelect
                  register={{ ...register('docType') }}
                  id="create-send-account-book-form-card-doc-type"
                  control={control}
                  rules={{ required: false }}
                  name="docType"
                  data={docTypeData}
                  value={'CONTRACT'}
                  readOnly={true}
                  label="Select a document type"
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="fx-choose-file-button">
                <Controller
                  name={'fileId'}
                  control={control}
                  rules={{ required: false }}
                  render={({
                              field: { onChange, value },
                              fieldState: { error }
                            }) => (
                    <>
                      <FxFileUpload
                        register={{ ...register('fileId') }}
                        name="fileId"
                        passData={passData}
                        id="create-send-account-book-form-card-file-id-file-upload"
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        value={
                          fileUploadData ? fileUploadData : value
                        }
                        acceptedFiles={[
                          '.pdf',
                          '.docx',
                          '.jpg',
                          '.txt',
                          '.png',
                          '.jpeg',
                          '.xls',
                          '.wav',
                          '.xlsx',
                          '.tiff',
                          '.doc',
                          '.rtf',
                          '.bmp',
                          '.efx',
                          '.csv',
                          '.kswps',
                          '.wps'
                        ]}
                        formData={{ type: 'CONTRACT' }}
                        setValue={setValue}
                        maxFileSize={10485760}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FxLabelView
                className="fx-label-small"
                id="create-send-account-book-form-card-file-types-supported"
              >
                (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff,
                .doc, .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv,
                .wps)
              </FxLabelView>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={'error-message'}>
                {errors.fileError &&
                  renderError(errors.fileError)}
              </div>
            </Grid>
            {fileUploadData && (
              <Grid item container>
                {fileUploadData.map((key: any) => {
                  return (
                    <Chip
                      icon={<InsertDriveFileTwoToneIcon />}
                      label={key['name']}
                      onDelete={() => removeFile(key['id'])}
                      color="primary"
                      variant="outlined"
                      deleteIcon={<DeleteIcon />}
                    />
                  );
                })}
              </Grid>
            )}
          </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register('id') }}
          className={'fx-hidden'}
          control={control}
          id="create-send-account-transfer-form-card-hidden-id-textbox"
          name="id"
          variant="outlined"
          defaultValue={transferInfo?.id}
          type="hidden"
        />
      </Grid>
      </>
      )}
    </ScheduleLayout>
    </>
  );
});
