import React, { ReactNode } from 'react';
import { FxBackToMXM } from '../../../components/Data/FxBackToMXM';
import MxmBannerContent from '../Banners/MxmBannerContent';
import SmbBannerContent from '../Banners/SmbBannerContent';
import DefaultBannerContent from '../Banners/DefaultBannerContent';
import DefaultContent from '../DebitCardContent/DefaultContent';
import SmbSourceContent from '../DebitCardContent/SmbSourceContent';
import MxmFastFundingDialogContent from '../FastFundingModal/MxmFastFundingDialogContent';
import DefaultFastFundingDialogContent from '../FastFundingModal/DefaultFastFundingDialogContent';

/**
 * class related with Banner
 */


export class FeatureBanner {
    private readonly ismxmUser: boolean;
    private readonly issmbUser: boolean;
    private readonly isDefault: boolean;
    private readonly isSSOUser: boolean;


    constructor(
        ismxmUser: boolean,
        issmbUser: boolean,
        isDefault: boolean,
        isSSOUser: boolean
    ) {
        this.ismxmUser = ismxmUser;
        this.issmbUser = issmbUser;
        this.isDefault = isDefault;
        this.isSSOUser = isSSOUser;
    }
    /**
     * Method to return fast funding banner content
     * @returns :jsx
     */
    public getFasterFundBannerContent(data: any): ReactNode {
        let bannerType: any = '3';
        if (data) {
            bannerType = data;
        }
        else {
            bannerType = this.ismxmUser ? '1' : this.issmbUser ? '2' : '3'
        }
        switch (bannerType) {
            case '1':
                return <MxmBannerContent />;
            case '2':
                return <SmbBannerContent />;
            default:
                return <DefaultBannerContent />;
        }
    }

    /**
     * Method to return debit card content
     * @returns :jsx
     */
    public getAppyDebitCardContent(data:any): ReactNode {
        let debitCardType: any = '3';
        if (data) {
            debitCardType = data;
        }
        else {
            debitCardType = this.ismxmUser ? '1' : this.issmbUser ? '2' : '3'
        }
        switch (debitCardType) {
            case '1':
                return <SmbSourceContent />;
            case '2':
                return <SmbSourceContent />;
            default:
                return <DefaultContent />;
        }
    }

    /**
     * Method to return fast fund banner content
     * @returns :jsx
     */
    public getFastFundingBannerContent(data:any): ReactNode {
        let bannerTextType: any = '3';
        if (data) {
            bannerTextType = data;
        }
        else {
            bannerTextType = this.ismxmUser ? '1' : this.issmbUser ? '2' : '3'
        }
        switch (bannerTextType) {
            case '1':
                return <MxmFastFundingDialogContent />
            case '2':
                return <MxmFastFundingDialogContent />
            default:
                return <DefaultFastFundingDialogContent />
        }
    }

     /**
     * Method to return back to button
     * @returns :jsx
     */
     public backToButton(): ReactNode {
         switch (true) {
             case this.ismxmUser:
                 return <FxBackToMXM ssoUser={this.isSSOUser}/>
             default:
                 return ;
         }
    }
}

