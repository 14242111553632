import React from 'react';
import { Grid } from '@mui/material';
import { FxCongratulationCard } from '../../Data/FxCongratulationCard';
import { FxFastFundingCard } from '../../Data/FxFasterFundingCard';
import { FxInfoLayout } from '../DrawerLayaouts/FxInfoLayout';
import { getCustomerUrl } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import { getKey, setKey } from '../../../libs/utils/storageManager';
import { Logger } from '../../../libs/utils/logger';
import { useDispatch } from 'react-redux';
import FxTopBanner from '../../Utils/fx-top-banner';
import { Logo } from '../../Utils/Logo';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { updateComponentParams } from '../../Utils/CommonBaseClass';

Logger.debug("FxFastFunding.tsx", "fast funding initializing")

/**
 * Component used inside the right drawer to enable fast funding
 */

export interface IFastFundingPayload {
    isFastFundingEnabled: boolean;
}

interface IFxFastFunding {
    onDrawerClose: (event: any, payload?: IFastFundingPayload) => void;
    merchantId?:string
}

export const FxFastFunding: React.FC<IFxFastFunding> = ({ onDrawerClose ,merchantId}) => {

    const [isEnabled, setIsEnabled] = React.useState(false);
    const httpClient = HttpClient.getClient();

    const dispatch = useDispatch()
    const id = merchantId || getKey('mxmMerchant')?.id || getKey('customerInitialMerchantId');
    const uiFeat = UIFeatureImpl.getInstance();
    const isSSOLoggedIn = uiFeat.getSSOUserFeatures().ssoUser;
    const isMerchantLoggedIn = isSSOLoggedIn && getKey('merchantMID') && getKey('customerId') === getKey('midEnabledCustomerID') && getKey('selectedAccount')?.id === getKey('merchantMIDAccount')

    
    /**
     * async function to enable merchant fast funding
     */
    const enable = async () => {
        const req:any={
            "configuration": {
                "passportFunding": {
                    "status": "INITIATED"
                },
                "quickSettle": false
            },
        }
        try {
                await httpClient.post(getCustomerUrl(`merchant/id/${id}`), req).then(() => {
                    if (id === getKey('mxmMerchant')?.id) {
                        FxTopBanner.closeForce('faster_funding_banner');    
                    }
                updateComponentParams(dispatch, 'customer-basic-info', { 'topBannerData': [] })
                setKey('mxmFastFunding', true );
                setIsEnabled(true);
                onDrawerClose({}, { isFastFundingEnabled: true });
                if(getKey('goPriorityCapitalUrl')){
                    setKey('fundingViaPassport', true );
                    updateComponentParams(dispatch, 'customer-home-passport', { 'openPipeRedirectionModal': true })
                }
            });
        } catch (e) { }
    }

    const fundingLayoutHeader = <>
          <h2>Get Corporate Debit card &nbsp;</h2>
        <div>
            <h2>with &nbsp;</h2>
            <Logo />
        </div>
    </>

    const goToDashboard = (event: any) => {
        onDrawerClose(event, { isFastFundingEnabled: true });
        if(merchantId){
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'merchant-details-info-card' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'merchant-details-faster-funding' } });
        }
    }
    /**
     * Setting the button text
     * @returns 
     */
    function getBackButtonText() {
        return merchantId ? 'Continue' : 'Continue to dashboard'
    }
    return (
        <Grid container id="fast-funding" className="fx-fast-funding">
            <Grid item className={'fx-drawer-left-content'}>
                    <FxInfoLayout layoutHeader={fundingLayoutHeader}/>
            </Grid>
            <Grid item xs justifyContent={"center"} className={'fx-drawer-right-content'}>
                {!isEnabled ?
                    <FxFastFundingCard onButtonClick={enable} />
                    :
                    <FxCongratulationCard message={'Funding via Passport enabled successfully.'} backButtonText={getBackButtonText()}  onButtonClick={goToDashboard} iconName="fastFunding"/>
                }
            </Grid>
        </Grid>
    )
}