import React from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { checkPermission } from '../../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../../Index/Passport/FxAccessDenied';
import { RecurringCards } from './RecurringCards';
/**
 * This component handles the rendering of the ScheduleCards
 */
export const RecurringDetails = () => {
        interface ScheduleParams {
            id: string
            type: string;
        }

        const params = useParams<ScheduleParams>();
        let id = params.id

        let schedules_source = {
            url: "transaction/recurring/id/" + id,
            method: "GET"
        }

        /**
        * function to check permission 
        */
        function checkViewPermission(data: any) {
            let flag: any;
            let permissionObject: any = {
                'recurring_wire_send': {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "View"
                },
                'recurring_check_send': {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "View"
                },
                'recurring_bood_send': {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "View"
                },
                'recurring_ach_send': {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "View"
                },
                'recurring_internationalWire_send': {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "View"
                },
                'recurring_virtual_card_send': {
                    entity: "Recurring Transaction",
                    name: "Virtual Card Send",
                    operation: "View"
                }
            }
            flag = checkPermission(permissionObject[data])
            return flag
        }
        return (
            <>
                {(checkViewPermission('recurring_wire_send') || checkViewPermission('recurring_ach_send') || checkViewPermission('recurring_check_send') || checkViewPermission('recurring_book_send') || checkViewPermission('recurring_virtual_card_send') || checkViewPermission('recurring_internationalWire_send')) ?
                    <RecurringCards id={"recurring-details-recurring-card1" + id} source={schedules_source}></RecurringCards>
                    : <FxAccessDenied id={'recurring-details-access-denied'} title={'You are not authorized to access this page'} />
                }
            </>
        )
    }
