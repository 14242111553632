import React, { useMemo } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { useForm, useFieldArray, FieldValues } from 'react-hook-form';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';

// Component used to draw dynamic fields

export const FxDynamicField: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const { register, getValues, control } = useForm({
            // defaultValues: {}; you can populate the fields by this attribute
            defaultValues: {
                dynamicField: props?.data
            } as FieldValues

        });

        const datasNew = getValues()

        //return data to parent component
        if (datasNew['dynamicField'].length) {
            props.fieldData(datasNew)
        }

        //Add new Field
        const addData: any = useMemo(() => {
            if (props.data && Array.isArray(props.data)) {
                let firstObj = {...props.data[0]};
                for (const key in firstObj) {
                    if (Object.prototype.hasOwnProperty.call(firstObj, key)) {
                        firstObj[key] = ''
                    }
                }
                return firstObj;
            }
        }, [props.data])

        const { fields, append, remove } = useFieldArray({
            control,
            name: "dynamicField"
        });
        return <><Grid container className='fx-dynamic-field-div' direction="row" spacing={1}  >
            <Grid item >
                {fields.map((field: any, index: any) => {
                    return (
                        <Grid direction="row" container xs={12} spacing={1} key={field.id} >
                            <Grid item container xs={11} spacing={1} >
                                {Object.entries(field).map((val: any) => {
                                    let regId: any = `dynamicField.${index}.${val}`.split(',')[0];
                                    if (val[0] !== 'id') {
                                        return (
                                            <Grid item xs={3} >
                                                <FxTextEdit register={{ ...register(regId) }} className={"fx-input-edit"} control={control} rules={{ required: false }} id={regId} name={regId} label={val[0]} variant="outlined" defaultValue={val[1] ? val[1] : ''} />
                                            </Grid>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <Grid item container xs={1}>
                                <Grid item ><IconButton
                                    id={"delete-row-check-remitence-" + index}
                                    className="fx-button"
                                    onClick={() => { remove(index) }}
                                    size="large"><DeleteIcon /></IconButton>
                                </Grid>
                            </Grid>
                            <hr className="MuiDivider-root" />
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
            <Grid item>
                <br />
                {datasNew['dynamicField'].length < (props.limit ? props.limit : 2) && <Button id="Add-new-template-button" title="Edit" className="fx-button fx-button-passport-border fx-svg-theme" variant="contained"
                    onClick={() => { append(addData) }} startIcon={<AddIcon />} >
                    {props.buttonName ? props.buttonName : 'Add New'}
                </Button>}
            </Grid></>;
    })
