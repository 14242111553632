import React, { useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { AccountInfoCard } from './AccountInfoCard';
import { updateComponentData, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { setKey } from '../../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useHistory } from 'react-router';
import { FxSkeltonList } from '../../Cards/FxSkelton';

/**
 * The is a Customer Account Listing Page
 */
export const AccountListCard: React.FC<any> = React.memo((props) => {

    let context:any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory();
    const accountsData = context?.data?.body;

    const customerAccountList = useMemo(() => {
      if(accountsData?.resources?.length > 0){
        return accountsData.resources.filter((item:any) => item.status !== 'DELETED');
      }
      return [];
    },[accountsData]);

    /**
     * useEffect to set title of page
     */
    useEffect(() => {
      updateComponentData(dispatch, 'page-title-details', { title: 'Home' })
      if(accountsData) {
        updateComponentParams(dispatch, 'customer-basic-info', { 'accountList': accountsData })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsData])

    /*
    *  my account click action
    */
    const myAccountClick = (event: any) => {
        if(event.type === 'CASH_BUILDER_PLUS'){
            history.push('/cashbuilder')
        }else if(event.type === 'CASH_BUILDER'){
            history.push('/portfolio')
        }else{
            history.push('/')
        }    
    }

    /**
    * Method handles the onchange event of selecting account number by setting the selected account object, updating component parameters for recent transactions card, resetting the component data for recent transactions list
    * @param item
    */
    const handleAccountChange = (item: any, type: any) => {
        const selectedItem = customerAccountList.find((x:any)=>x.id === item);
        customerAccountList.forEach((optionData: any) => {
            if (type === 'Account') {
                myAccountClick(selectedItem);
            }
            if (optionData.id === item) {
                if(optionData.status === 'INACTIVE'){
                    setKey("accountStatus", 'false');
                }
                updateComponentParams(dispatch, props?.id, { 'selectedOption': optionData })
                setKey("selectedAccount", optionData);
                if(type !== 'Download'){
                    updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': true })
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-home-passport' } });
                    updateComponentParams(dispatch, "customer-basic-info", { 'accountDetails':{}});
                    updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true  ,'showSkeleton':true})
                    updateComponentData(dispatch, 'switch-customer-data', { selectedAccount: optionData })
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'home-my-account-balance-chart' } });
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { "id": 'home-my-account-card-account-info-card'} });
                }
            }
        })
    }
    

    return (
        <Grid id="customer-account-list-card-Home-Grid" item xs={12} >
            <FxCard id="customer-account-list-card-Home-Card" className="fx-card-general fx-theme-passport fx-customer-account-list">
                <FxCardHeader id="customer-account-list-card-Home-Card-Header">
                    <Grid item container xs={12} spacing={1} alignItems='center'>
                        <Grid item className="fx-flex-grow">
                            <Grid id="passport-account-list-title" item xs justifyContent="flex-start" className=''>
                                <Typography variant="h3" >Passport Accounts</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="customer-account-list-card-Home-Card-Body" className="fx-card-body">
                    {customerAccountList.length > 0 ? customerAccountList.map((item:any,index:any)=>(
                        <AccountInfoCard data={item} id={`'account-info-card' + ${index}`} handleAccountChange={handleAccountChange}/>
                    )) : 
                        <FxSkeltonList width="100%" height="25rem" />
                    }
                </FxCardBody>
            </FxCard>
        </Grid>
    )
})