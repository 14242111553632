/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
  checkPermission,
  clean,
  convertEndData,
  convertToOnDayFormat,
  convertToTitleCaseAndAppendWithS,
  fetchLabelFromId,
  getEAOrAddressDetailsFromContact,
  removeUnderScoreConvertTitleCase,
  renderError,
  setPayeeInformation,
  transformRecurringRequestGlobal,
  truncateChars,
  updateComponentParams,
  customErrorMessage
} from "../../Utils/CommonBaseClass";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
  accountLabelTransformation,
  createTransactionUrl,
  getEAList,
  getLocationData,
} from "./ScheduleGlobalFunctions";
import { FxSuccess } from "../../Data/FxSuccess";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import FxModalGeneric from "../../Utils/FxModalGeneric";
import { AuthenticationVerificationModal } from "../AuthenticationModal/AuthenticationVerificationModal";
import HttpClient from "../../../libs/utils/httpClient";
import { isAchControlAllowed } from "../../Utils/ThemeChecker";
import { FxFasterFundingSwitch } from "../../Utils/FxFasterFundingSwitch";
import UIFeatureImpl from "../../../libs/services/uiFeatures";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";

/**
 * This component handles the collect via contact thorugh ACH method and edit of ACH transaction
 */

Logger.debug(
  "CreateCollectContactACH.tsx",
  "create collect to Contact ACH initializing"
);

// src to fetch account
const acc_src = {
  url: "account/list",
  method: "POST",
  data: {

    "pageSize": 25,
    "pageNumber": 1,
    "sortOptions": {
      "sortOrder": "DESC",
      "sortBy": "createdOn"
    },
    "criteria": {
      "filters": [
        {
          "key": "status",
          "operator": "nin",
          "values": [
            "INACTIVE"
          ]
        }
      ]
    }
  }
};

// src for contact list
const contact_list_src = {
  url: "contact/list",
  method: "POST",
  data: {
    pageNumber: 1,
    pageSize: 5,
    criteria: {
      filters: [
        {
          key: "allowedMethod",
          operator: "eq",
          values: ["ACH"],
        },
      ],
    },
  },
};

const processingModeData: any = [
  { label: "Same Day", value: "SAME_DAY" },
  { label: "Next Day", value: "FORWARD" },
];

export const CreateCollectContactACH: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const [eaLoad, setEALoad] = useState(false);
  const [contacEA, setContacEA] = useState<any>([]); // external account array
  const [source, setSource] = useState<any>(); // to set value in search contact field
  const [isSource, setIsSource] = useState(true);

  const [isPpiContact, setIsPpiContact] = useState(false);
  const [selectedPPI, setSelectedPPI] = useState("");

  const [allowDuplicate, setAllowDuplicate] = useState(false);
  
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();

  const [sendEnabled, setSendEnabled] = useState(true);
  const [externalEAId, setExternalEAId] = React.useState();

  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available

  const pmLevelQuickSettle = getKey('isAchQuickSettleEnabled');
  const showInstantFunding = pmLevelQuickSettle || context?.data?.body?.processingDetail?.quickSettle;
  const accountLevelQuickSettle = getKey('accountLevelQuickSettle')?.ach;
  const [instantFunding, setInstantFunding] = useState(false);
  const [location, setLocation] = useState<any>();

  const dispatch = useDispatch();
  const history = useHistory();

  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

  // Params For edit option
  const params = useParams<any>();
  const urlContactId = params?.contactId;
  const scheduleId = params.id;

  const httpClient = HttpClient.getClient();

  const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, setError, watch, } = useForm();

  const method_Tab = useMemo(() => {
    const tabs = [
      {
        className: "",
        title: "CARD",
        disabled: !!scheduleId,
        link: "/transaction/collect/contact/add/card",
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Card Collect",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "CARD Collect",
          operation: "CREATE"
        },
        {
          entity: "Accounts",
          name: "Card Collect",
          operation: "EDIT"
        }]
      },
    ];

    if(isAchControlAllowed(getKey('collectAchControl'))){
      tabs.unshift({
        className: "fx-link-sel",
        title: "ACH",
        disabled: !!scheduleId,
        link: "/transaction/collect/contact/add/ach",
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "ACH Collect",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "ACH Collect",
          operation: "CREATE"
        },
        {
          entity: "Accounts",
          name: "ACH Collect",
          operation: "EDIT"
        }]
      })
    }

    return tabs;
  }, [scheduleId]);

  const [isloading, setIsloading] = React.useState(false);

  // Function to be called before loading the component
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });

    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-collect-ach-from-contact-file-id-file-upload",
        files: [],
      },
    });

    // need to check
    if (urlContactId) {
      setExternalAccount(urlContactId);
    }

    if (scheduleId) { // need to update the code for edit
      const src = {
        url: "/transaction/id/" + scheduleId,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-collect-via-contact-ach", source: src },
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Collect Money", backButton: "/dashboard" },
        },
      });
    }

    const verifyProps = {
      open: false
    }
    updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  // useEffect to set edit data details
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues(context?.data?.body);
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  /**
   * // getting the source data
   * @param contactId
   */
  function setExternalAccount(contactId: any) {
    setContacEA([]);
    setIsSource(false);

    getEAList(contactId).then((data: any) => {
      const ea = getEAOrAddressDetailsFromContact(data, "EA", "", false, true);

      if (ea && ea.length > 0) {
        setSource(setPayeeInformation(data)); //to set search contact data
        setIsSource(true);
        if(scheduleId && context?.data?.body) {
          let selectedEAId = context?.data?.body?.source?.contact?.externalAccount.id;
          setValue("source", contactId);
          setExternalEAId(ea.find((eaDetail: any) => eaDetail.value === selectedEAId).value);
        } else {
          setExternalEAId(ea[0].value);
        }
      }
      if (data && data.ppi) {
        setEALoad(false);
        setIsPpiContact(true);
        setSelectedPPI(data.ppi);

        // need to check for contact having PPI
        setSendEnabled(false);
        FxSnackBar.show({
          text: 'Selected payment method is not supported for collecting money from contact.',
        });
      } else {
        setContacEA(ea);
        setIsPpiContact(false);
        setEALoad(true);
        setSelectedPPI("");
        setSendEnabled(true);
        FxSnackBar.closeForce();
      }
    });
  }

  /**
   * Method setting the default values for the text edit component from the context data
   * @param data form data
   */
  function setFormValues(data: any) {
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue("achCompanyDescription", data?.processingDetail?.companyDescription);
    setInstantFunding(data?.processingDetail?.quickSettle);
    setValue("authType", 'ONLINE');
    setAllowDuplicate(data?.allowDuplicate);

    if (data?.source?.contact) {
      setExternalAccount(data?.source?.contact?.id);
    }
  }

  /**
   * Function handles transformation of source data
   * @param data : source data array
   * @returns : array
   */
  function contactNameTransformer(data: any) {
    return data?.map((item: any) => ({
      label: item.name + " (ID: " + item.id + ")",
      value: item.id,
    })) || [];
  }

  const sourceFilterOptions: any = [
    { label: "Contact Name", value: "name", operator: "like" },
  ];

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passFileUploaddata(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors["fileError"]) {
        clearErrors("fileError");
      }
    } catch (e) { }
  }
  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removeFile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-collect-ach-from-contact-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  const handleClose = () => {
    if (scheduleId) {
      history.push("/transactions/send/" + scheduleId);
    } else {
      history.push("/dashboard");
    }
  };

  // function to call on change of contact from dropdown
  const handleChange = (event: any) => {
    if (event && event?.value) {
      setExternalAccount(event?.value);
      setIsSource(true);
    } else {
      setContacEA([]);
      setEALoad(false);
      setIsSource(true);
      setIsPpiContact(false);
    }
    setSendEnabled(true);
    FxSnackBar.closeForce();
  };

  /**
   * Method enables and disables the allow duplicate
   * @param event : allow duplicate status
   */
  const handleAllowDuplicate = (event: any) => {
    setAllowDuplicate(event);
  };
  /**
   * Method enables and disables the repeat functionality
   * @param event : repeat status
   */
  const handleRepeat = (event: any) => {
    setRepeatStatus(event);
  };
  /**
   * Method triggers if the recurring updates
   */
  const handleUpdateRecurring = (data: any) => {
    setRecurringFormData(data);
  };

  // summary data for source either for PPI or extrnal account
  const externalAccount = watch("externalAccount");
  const viaToLable = useMemo(() => {
    let ppi = {
      'To PPI': {
        id: 'ppi',
        value: selectedPPI,
      }
    }

    let address = {
      'From': {
        id: 'from',
        value: externalAccount
          ? fetchLabelFromId(contacEA, externalAccount)
          : fetchLabelFromId(contacEA, externalEAId),
      }
    }

    if (isPpiContact) {
      return ppi;
    } else {
      return address
    }
  }, [isPpiContact, selectedPPI, externalAccount, contacEA, externalEAId]);

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Collect Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    ...viaToLable,
    'To': {
      id: 'to',
      value: 'Passport ' + accountLabelTransformation(getKey("selectedAccount")),
    },

    ...(businessCustomer
      ? { Purpose: { id: "purpose", value: watch("purpose") } }
      : { Memo: { id: "purpose", value: watch("purpose") } }),
    
    'Via': {
      id: 'method',
      value: 'ACH',
    },

    'Processing Mode': {
      id: 'processingMode',
      value: watch("processingMode")
        ? watch("processingMode") === 'FORWARD' ? removeUnderScoreConvertTitleCase('NEXT_DAY') : removeUnderScoreConvertTitleCase(watch("processingMode"))
        : "",
    }
  };

  if (uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) {
    summaryData['Faster Funding'] = {
        id: 'quickSettle',
        value: instantFunding ? 'Yes' : 'No',
    }
  }

  if (businessCustomer) {
    summaryData['Statement Descriptor'] = {
      id: 'statementDescriptor',
      value: watch("achCompanyDescription"),
    }
  }

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
    if (!repeatStatus || !recurringFormData) {
      return {}
    }
    const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
    const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
      ? convertToTitleCaseAndAppendWithS(
        recurringFormData?.repeatEveryFormData?.repeatEverySelect
      )
      : '';
    const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
      'Repeat every': {
        id: 'repeat-every',
        value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
      },
    }
    if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
      result['On'] = {
        id: 'repeat-on',
        value: convertToOnDayFormat(recurringFormData),
      };
    }
    const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
    const ends = recurringFormData?.endFormData
      ? convertEndData(recurringFormData?.endFormData)
      : '';
    if (ends || endOn) {

    }
    result['Ends'] = endOn ? {
      id: 'repeat-ends',
      value: ends,
      ValueComponent: ({ value }) => (
        <>
          On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
        </>
      )
    } : {
      id: 'repeat-ends',
      value: ends,
    };
    return result;
  }, [recurringFormData, repeatStatus]);

  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    source: context?.data?.body?.source?.id,
    purpose: context?.data?.body?.purpose
  }

  /**
  * Function handles transformation of Destination account (Passport Account)
  * @param data : Destination Account data array
  * @returns : array
  */
  const destinationAccountTransformation = useCallback((data: any) => data?.map((item: any) => ({
    value: item.id,
    label: accountLabelTransformation(item),
  })) || [], []);

  /**
  * Function to handle the closing of the modal.
  */
  const handleCloseModal = () => {
    let verifyProps = { ...props.verifyProps }
    verifyProps['open'] = false;
    updateComponentParams(dispatch, props.id, { verifyProps });
  };

  /** 
  *This function is called upon successful completion of a post operation.
  *It dispatches an action to update component parameters related to file uploads.
  */
  const handlePostSuccessEvent = () => {
    dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-collect-ach-from-contact-file-id-file-upload', 'files': [] } });
  }
  
  

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    let recurringRequest: any = {};
    if (urlContactId) {
      data.source = urlContactId;
    }
    if (!data.source) {
      setError("source", {
        type: "manual",
        message: "Source is required",
      });
      return;
    }
    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: (recurringRequest["transactionDetail"]),
        action: [],
      };
      req["transactionDetail"]["startDate"] = data?.startDate;
      request = {
        type: 'TRANSACTION',
        transaction: {
          destination: {
            account: {
              id: data.depositAccount,
            },
          },
          method: "ACH",
          type: "REGULAR",
          amount: data.amount,
          purpose: data.purpose,
          allowDuplicate: allowDuplicate,
        },
      };
      if (data.source) {
        request["transaction"]["source"] = {
          contact: {
            id: data.source,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }

      if (data["processingMode"]) {
        request["transaction"]["processingDetail"] = {};
        request["transaction"]["processingDetail"]["processingMode"] = data.processingMode;

        request['transaction']['processingDetail']['authType'] = 'ONLINE';
        
      }

      if (data.achCompanyDescription) {
        request['transaction']['processingDetail']['companyDescription'] = data.achCompanyDescription
      }

      if (uiFeat.getTransactionFeatures().fastFunding) {
        request["transaction"]["processingDetail"]['quickSettle'] = instantFunding;
      }

      if(data['location']){
        request['transaction']['processingDetail']['location'] = {
          'id' : data.location
        }
      }

      req["action"].push(request);

      if (fileUploadData?.length) {
        req["linkedDocument"] = fileUploadData.map((item: any) => ({
          purpose: "Authorization",
          document: { id: item.id }
        }));
      }
    } else {
      req = {
        id: params?.id,
        destination: {
          account: {
            id: data.depositAccount,
          },
        },
        method: "ACH",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      };

      if (data.source) {
        req["source"] = {
          contact: {
            id: data.source,
            externalAccount: {
              id: data.externalAccount,
            },
          },
        };
      }

      if (data["processingMode"]) {
        req["processingDetail"] = {};
        req["processingDetail"]["processingMode"] = data.processingMode;
        req['processingDetail']['authType'] = 'ONLINE'
      }
      if (data.achCompanyDescription) {
        req['processingDetail']['companyDescription'] = data.achCompanyDescription
      }

      if (uiFeat.getTransactionFeatures().fastFunding) {
        req['processingDetail']['quickSettle'] = instantFunding;
      }
      if(data['location']){
        req['processingDetail']['location'] = {
          'id' : data.location
        }
      }
    }
    
    if (!eaLoad && isPpiContact) {
      delete req?.processingDetail //in case of ppi we are deleting ea node
      if (req?.source?.contact?.externalAccount) {
        delete req["source"]['contact']['externalAccount'];
      }
      else if (req?.action && req['action'][0] && req['action'][0] && req['action'][0]['transaction']?.source?.contact?.externalAccount) {
        delete req['action'][0]['transaction'].source.contact.externalAccount;
      }

    }
    req = clean(req);
    
    setIsloading(true);
    let status: any;
    status = await createCollectViaAch(req);
    const sendId = status?.headers?.url?.split("/").pop() || '';

    status = processAPIResponse(status);
    setIsloading(false);


    if (status.status) {
      const successMessage = scheduleId ? 'Collect Updated Successfully!' : repeatStatus ? 'Recurring Created Successfully!' : 'Collect Created Successfully';
      const redirectUrl = scheduleId ? `/transactions/send/${scheduleId['id']}` : repeatStatus ? '/thanks/transactions/collect-contact-ach-recurring/' : '/thanks/transactions/collect-contact-ach/';
      const verifyMessage = status.responseData?.email ? `email ${status.responseData?.email}` : status.responseData?.phone ? `phone ${status.responseData?.phone}` : '';
      if (status.responseData?.sessionId) {
        const verifyProps = {
          open: true,
          channelTitle: `To verify ${repeatStatus ? 'recurring send' : 'send'} for $${req.amount}, Passport will send you a one-time code. Choose how to receive your code.`,
          subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to complete verification.`,
          url: createTransactionUrl(req), // need to update
          req: req,
          initiationDetails: status.responseData,
          resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
          successMessage: successMessage,
          redirectUrl: redirectUrl,
          redirectUrlFromHeader: params?.id ? false : true,
          postSuccessEvent: handlePostSuccessEvent
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
      }

      else {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: repeatStatus
            ? 'Recurring Created Successfully!'
            : scheduleId
              ? 'Collect Updated Successfully'
              : 'Collect Created Successfully!',
        });
        if (repeatStatus) {
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
              id: "create-collect-ach-from-contact-file-id-file-upload",
              files: [],
            },
          });
        }
        if (repeatStatus) {
          history.push(
            "/thanks/transactions/collect-contact-ach-recurring/" + sendId
          );
        } else if (scheduleId) {
          history.push("/transactions/collect/" + scheduleId);
        } else {
          history.push("/thanks/transactions/collect-contact-ach/" + sendId);
        }
      }
    } else {
      //api  failed(
      FxSnackBar.show({
        text: customErrorMessage(status)
      });

    }
  }

  /**
  * Async Function handles api call for create and update send
  * @param paylaoddata : form request
  * @returns : response object
  */
  async function createCollectViaAch(paylaoddata: any) {
    try {
      let url = '/transaction';
      if (paylaoddata.action !== undefined) {
        url += '/recurring';
      }
      if (paylaoddata.id && paylaoddata.id !== undefined) {
        url += '/id/' + paylaoddata.id;
      }

      const data: any = await httpClient.post(getCustomerUrl(url, false), paylaoddata);
      return data;
    } catch (err) {
      Logger.error("CreateSendAccountCHECK.tsx", "error", err);
      return err;
    }

  }
  
/**
 * Method to handle Tab click
 */
  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: 'collect',
        type: 'contact',
        formData: { amount: watch('amount'), purpose: watch('purpose') }
      }
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
    }
    catch (e) { }
  }

  const isInstantFundingDisabled = !accountLevelQuickSettle || (accountLevelQuickSettle && !pmLevelQuickSettle);
  /**
  * Method enables and disables the instant fund functionality
  * @param event : instant fund status
  */
  const handleInstantFund = (event: any) => {
    setInstantFunding(event);
  };

  return (
    <>
      <ScheduleLayout
        id="create-collect-contact-ach-"
        type='collect'
        loading={isloading}
        summaryData={{ ...summaryData, ...summaryRepeatData }}
        submitTitle={repeatStatus ? 'Create' : scheduleId ? 'Save Changes' : 'Collect Money'}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleClose}
        saveEnabled={sendEnabled}
      >
        {urlContactId && (<FxSuccess id="sucess-external-account" header={"Contact added successfully"} description={"CONTACT ID - " + urlContactId} />)}
        <ScheduleTopSection
          id="create-collect-contact-ach-schedule-top-section"
          schedule="collect"
          type="contact"
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          defaultValue={topSectionDefaultValues}
          acc_src={acc_src}
          dataTransformation={destinationAccountTransformation}
          amountId="create-collect-contact-amount-textbox"
          destinationId="create-collect-contact-ach-destination-account"
        />
        {((location || !scheduleId) && businessCustomer)&& <Grid item xs={12} sm={12}>
          <FxLocationSelectComponent
              label='Location'
              register={{ ...register("location") }}
              control={control}
              rules={{ required: false }}
              id="create-collect-contact-ach-form-card-location"
              name="location"
              className="fx-input-edit"
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              isEditable={!scheduleId}
              value={location ? {
                label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",
                value: location?.id,
            }: null}
          />
        </Grid>}
        {!scheduleId && (
          <Grid item xs={12} sm={12}>
            {isSource && (
              <>
                <FxLabelView className="fx-label-top">Contact Name*</FxLabelView>
                <FxAutoComplete
                  register={{ ...register("source") }}
                  control={control}
                  rules={{ required: !urlContactId ? true : false }}
                  id="create-collect-contact-ach-form-card-source"
                  name="source"
                  source={contact_list_src}
                  searchBy={'name'}
                  className={
                    errors.source
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  dataTransformer={contactNameTransformer}
                  setError={setError}
                  clearError={clearErrors}
                  setValue={setValue}
                  resetField={resetField}
                  value={source}
                  filterOptions={sourceFilterOptions}
                  placeholder="Search Contact..."
                  isEditable={true}
                  setParam={handleChange}
                />
              </>
            )}
          </Grid>
        )}
        {scheduleId && (
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("source") }}
              value={source && source[0]?.label}
              type="text"
              className={
                errors.source
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="edit-send-contact-ach-form-card-source-textbox"
              label="Contact"
              name="source"
              isEditable={false}
            />
          </Grid>
        )}
        <Grid item>
          <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-contact-ach-tab" tabClick={tabClickFunction} />
        </Grid>
        {isPpiContact && (
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              control={control}
              rules={{ required: false }}
              id="create-send-contact-ach-form-card-ppi-textbox"
              label="Payment ID"
              name="passportPaymentId"
              variant="outlined"
              value={truncateChars(selectedPPI, 60)}
              isEditable={false}
              className={"fx-input-edit fx-input-edit-disabled"}
            />
          </Grid>
        )}

        {((eaLoad && !params?.id) ||
          (externalEAId && scheduleId)) && (
            <Grid item xs={12} sm={12}>
              <FxMaterialSelect
                register={{ ...register("externalAccount") }}
                id="create-send-contact-ach-form-card-ea"
                name="externalAccount"
                data={contacEA}
                control={control}
                label="Contact External Account"
                rules={{ required: true }}
                value={externalEAId ? externalEAId : ""}
                clearError={clearErrors}
                className={
                  errors.externalAccount
                    ? "border-error-input fx-input-edit"
                    : "fx-input-edit"
                }
                readOnly={scheduleId ? true : false}
                setValue={setValue}
              />
            </Grid>
          )}
        {!isPpiContact && <Grid item xs={12} sm={12}>
          <FxMaterialSelect
            register={{ ...register("processingMode") }}
            control={control}
            rules={{ required: true }}
            id="create-send-contact-ach-form-card-processing-mode"
            name="processingMode"
            data={processingModeData}
            value={"SAME_DAY"}
            label="Processing Mode"
            setValue={setValue}
          />
        </Grid>}
        
        <Grid item sm={12} xs={12}>
          <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-onetime-card-form-card-statement-descriptor-textbox" label="Statement Descriptor" name="achCompanyDescription" variant="outlined" setValue={setValue} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="create-send-contact-ach-form-card-allow-duplicate"
            value={allowDuplicate}
            onClick={handleAllowDuplicate}
          ></FxSwitch>{" "}
          <FxLabel value="Allow Duplicate"></FxLabel>
        </Grid>
        {(!scheduleId && checkPermission({ entity: "Recurring Transaction", name: "ACH Collect",operation: "CREATE" })) && (
          <Grid item xs={12} sm={12}>
            <FxSwitch
              id="repeat-switch"
              value={repeatStatus}
              onClick={handleRepeat}
            ></FxSwitch>{" "}
            <FxLabel value="Repeat"></FxLabel>
          </Grid>
        )}
        {repeatStatus && (
          <>
            <Grid item xs={12} sm={12}>
              <FxDateEdit
                register={{ ...register("startDate") }}
                className={
                  errors.startDate
                    ? "border-error-input fx-input-edit"
                    : "fx-input-edit"
                }
                control={control}
                id="create-send-contact-ach-form-card-startDate"
                name="startDate"
                type="date"
                variant="outlined"
                label={"Start Date *"}
                setValue={setValue}
                disablePast={true}
                resetField={resetField}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FxTextEdit
                register={{ ...register("name") }}
                className={
                  errors.name
                    ? "border-error-input fx-input-edit"
                    : "fx-input-edit"
                }
                control={control}
                rules={{ required: true }}
                id="create-send-account-ach-form-card-name-textbox"
                label="Name *"
                name="name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FxRecurring
                id="create-send-contact-ach-form-card-fx-recurring"
                updateTrigger={handleUpdateRecurring}
                startDate={watch("startDate")}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
              ></FxRecurring>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={12}>
                  <Typography className="filter-popover-label">
                    LINKED DOCUMENTS
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  &nbsp;
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={8}>
                    <FxMaterialSelect
                      register={{ ...register("docType") }}
                      id="create-send-contact-ach-form-card-doc-type"
                      control={control}
                      rules={{ required: false }}
                      name="docType"
                      data={docTypeData}
                      value={"CONTRACT"}
                      readOnly={true}
                      label="Select a document type"
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className="fx-choose-file-button">
                    <Controller
                      name={"fileId"}
                      control={control}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <FxFileUpload
                            register={{ ...register("fileId") }}
                            name="fileId"
                            passData={passFileUploaddata}
                            id="create-collect-ach-from-contact-file-id-file-upload"
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={
                              fileUploadData ? fileUploadData : value
                            }
                            acceptedFiles={[
                              ".pdf",
                              ".docx",
                              ".jpg",
                              ".txt",
                              ".png",
                              ".jpeg",
                              ".xls",
                              ".wav",
                              ".xlsx",
                              ".tiff",
                              ".doc",
                              ".rtf",
                              ".bmp",
                              ".efx",
                              ".csv",
                              ".kswps",
                              ".wps",
                            ]}
                            formData={{ type: "CONTRACT" }}
                            setValue={setValue}
                            maxFileSize={10485760}
                          />
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FxLabelView
                    className="fx-label-small"
                    id="create-send-contact-ach-form-card-file-types-supported"
                  >
                    (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                    .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                  </FxLabelView>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className={"error-message"}>
                    {errors.fileError && renderError(errors.fileError)}
                  </div>
                </Grid>
                {fileUploadData && (
                  <Grid item container>
                    {fileUploadData.map((key: any) => {
                      return (
                        <Chip
                          icon={<InsertDriveFileTwoToneIcon />}
                          label={key["name"]}
                          onDelete={() => removeFile(key["id"])}
                          color="primary"
                          variant="outlined"
                          deleteIcon={<DeleteIcon />}
                        />
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {(uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) &&
          <FxFasterFundingSwitch instantFunding={instantFunding} handleInstantFund={handleInstantFund} disabled={isInstantFundingDisabled}/>
        }
      </ScheduleLayout>
      <FxModalGeneric
        id="creat-send-contact-ach-otp"
        open={props.verifyProps?.open}
        onClose={handleCloseModal}
        componentProps={{ ...props.verifyProps }}
        className={'fx-modal-small2medium'}
        component={AuthenticationVerificationModal}
      >
      </FxModalGeneric>
    </>
  )

});