import React, { useState } from 'react'
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { deeplyClean, toTitleCase } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import { useHistory } from 'react-router-dom';
const httpClient = HttpClient.getClient();

Logger.debug("StopCheck.tsx", "Stop Check initializing")
/**
 * This component handles the stop check
 */
export const StopCheck: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, control,setValue } = useForm();
        const [reasonOtherFlag, setreasonOtherFlag] = useState(false);
        const history = useHistory();

        /**
         * Async function handles the api call for Stop Check
         * @param req : form request
         * @returns : response object
         */
        async function stopCheck(req: any) {
            try {
                const url: any = 'transaction/id/' + props.scheduleId + '/stop';
                const data: any = await httpClient.post(getCustomerUrl(url, false), req).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("StopCheck.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles to stop check
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                comment: data.comment,
                reason:data.reason
            }
            let status: any = await stopCheck(deeplyClean(req));
            status = processAPIResponse(status)

            const successMessage = toTitleCase(props.schedule) + 'Stop Check Successfully';
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                handleClose();
                history.push(`/transactionsHistory`);
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

         /**
         * On close handler
         */
        function handleClose() {
            props?.close();
        }

        const reasons= [
            { "value": "ON_USER_REQUEST", "label": "On user Request" },
            { "value": "INCORRECTLY_CREATED", "label": "Incorrectly created" },
            { "value": "FRAUDULENT", "label": "Fraudulent" },
            { "value": "INCORRECT_DESTINATION", "label": "Incorrect Destination" },
            { "value": "INCORRECT_AMOUNT", "label": "Incorrect Amount" },
            { "value": "LOST_CHECK", "label": "Lost Check" },
            { "value": "OTHERS", "label": "Others" }];

            const handleTypeChange = (event: any) => {
                if(event?.value==='OTHERS'){
                    setreasonOtherFlag(true)
                }else {
                    setreasonOtherFlag(false)
                }
            }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="stop-check-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-stop-check-modal" variant="h4">
                                            Are you sure you want to Stop this transaction?
                                        </Typography>
                                        <Typography id="sub-title-stop-check-modal" variant="h6">
                                            You cannot undo this action
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='stop-check-modal-close-icon'>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="stop-check-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <FxSelectAutoSearch register={{ ...register("reason") }} rules={{ required: true }} className={errors.reason ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="reason" name="reason" data={reasons} label="Reason*" value={'reason'} setValue={setValue} onChange={handleTypeChange} readOnly={false} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("comment") }} className={reasonOtherFlag ? errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit" : 'fx-hidden'} control={control} rules={{ required: reasonOtherFlag }} id="comment" label={reasonOtherFlag ? "Comment*" :"Comment"} name="comment" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="stop-check-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <Grid className='fx-padding-right-15'>
                                        <FxButton
                                            variant="contained"
                                            className="fx-button"
                                            id="stop-check-button"
                                            onClick={handleClose}
                                        > Cancel
                                        </FxButton>
                                    </Grid>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id={"stop-check-button"}
                                        type="submit"
                                    >
                                       STOP SEND
                                    </FxButton>


                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        )
    });
