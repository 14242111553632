import { put, takeEvery, select } from "redux-saga/effects";
import HttpClient from "../utils/httpClient";
import { getDataFromStorage, createUniqueId, saveDataInStorage } from "../utils/cacheHandler";
import { State } from "../reducer/rootReducer";
import { Logger } from "../utils/logger";

/**
 * interface for the response 
 */
const httpClient = HttpClient.getClient();

interface ApiResponse {
    isError: boolean;
    error?: any;
    errorCode?: string;
    body?: any;
    status?: number;
    errorData?: any;
}
const getContext: any = (state: State) => state.context;

const getErrorMessage = (error: any) => {
    if (error.data?.errorMessage) {
        return error.data.errorMessage;
    }
    if (error?.response?.data?.errorMessage) {
        return error.response.data.errorMessage
    }

    if (error?.response?.data?.errorDescription) {
        return error.response.data.errorDescription
    }

    if (error?.data?.errorDescription) {
        return error.data.errorDescription;
    }

    return null;
}


/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * This is the basic function for the calling api by get method
 * it will return the data or error message
 */
export function* getAPI(url: string, header: any): any {
    if (url.includes(".json")) {
        return {
            body: yield fetch(url).then(response => response.json()),
            isError: false
        }
    }

    const response: ApiResponse = yield httpClient.get(url, header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error) {
        return {
            status: error.status,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.data?.errorCode ? error.data.errorCode : null,
            errorData: error.data?.errorData ? error.data.errorData : null
        }
    });
    return response;
}

/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* postAPI(url: string, data: any, header: any): any {
    if (header && header['Content-Type'] && header['Content-Type'] === 'multipart/form-data') {
        let formData = new FormData();
        formData.append("file", data);
        data = formData;
    }
    const response: ApiResponse = yield httpClient.post(url, data, header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.response && error.response.data.errorCode,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.response && error.response.data && error.response.data.errorCode ? error.response.data.errorCode : null,
            errorData: error.response && error.response.data.errorData ? error.response.data.errorData : null
        }
    });
    return response;
}

/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* putAPI(url: string, data: any, header: any): any {
    const response: ApiResponse = yield httpClient.put(url, data, header).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.response && error.response.data.errorCode,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.response && error.response.data && error.response.data.errorCode ? error.response.data.errorCode : null,
            errorData: error.response && error.response.data.errorData ? error.response.data.errorData : null
        }
    });
    return response;
}


/**
 * 
 * @param url url of the api
 * @param header if header need to be changed
 * @param data the body for the api
 * This is the basic function for the calling api by post method
 * it will return the data or error message
 */
export function* deleteAPI(url: string, data: any, header: any): any {
    const response: ApiResponse = yield httpClient.delete(url, data).then((response: any) => {
        return {
            body: response.data,
            isError: false,
            status: response.status
        }
    }).catch(function (error: any) {
        return {
            status: error.status,
            error: getErrorMessage(error),
            isError: true,
            errorCode: error.data?.errorCode ? error.data.errorCode : null,
            errorData: error.data?.errorData ? error.data.errorData : null
        }
    });
    return response;
}

/**
 * 
 * @param action action will contain payload with,
 * method :- method of the api eg: post,get,put default will be put
 * type :- which redux function to be called, default will be CONTEXT_COMPONENT_UPDATE_DATA
 * transformer :- if any data need to be changed, it will be a function
 * node :- can add separate node under the data node in store
 * Based on the method it will call the specific api method and wait for its response
 */
export function* apiSaga(action: any) {
    Logger.debug("ApiSaga", "Starting the apiSaga", action)
    let apiResponse: ApiResponse = {
        isError: false
    };

    let sourceData = {
        url: action.payload.url,
        method: action.payload.method,
        data: action.payload.data ? action.payload.data : null,
        header: action.payload.header
    };
    let key = createUniqueId(sourceData);
    let cacheStatus = 0;
    if (action.payload.expiry) {
        apiResponse = getDataFromStorage(key, action.payload.expiry);
        if (apiResponse != null && apiResponse.body != null) {
            cacheStatus = 1;
        }
    };
    if (cacheStatus === 0) {
        switch (action.payload.method) {
            case "POST":
                apiResponse = yield postAPI(action.payload.url, action.payload.data, action.payload.header);
                break;
            case "PUT":
                apiResponse = yield putAPI(action.payload.url, action.payload.data, action.payload.header);
                break;
            case "GET":
            default:
                apiResponse = yield getAPI(action.payload.url, action.payload.header);
                break;

        }
    }


    if (apiResponse['status'] === 401 || apiResponse['errorCode'] === 'EC-AUTH-0001') {
        window.location.href = '/login';
        return;
    }

    if (action.payload.expiry && cacheStatus === 0 && apiResponse.isError === false) {
        saveDataInStorage(key, apiResponse);
    }
    Logger.debug("ApiSaga", "Ending the apiSaga", action, apiResponse)
    return apiResponse;
}
/**
 * 
 * @param action action with payload
 * @param apiResponse response from api
 * @param status by default 1 setting as data available
 ** This is calling component reducer function 
 * it will update the component config
 */
export function* updateComponentDataSaga(action: any, apiResponse: any, status: number = 1): any {
    let type = "CONTEXT_COMPONENT_UPDATE_DATA";
    if (action.payload.type) {
        type = action.payload.type;
    }
    if (action.payload.transformer) {
        try {
            apiResponse = action.payload.transformer(action.payload.id, apiResponse);
        } catch (err) {
        }

    }
    
    const target = action.payload.target ? action.payload.target : null;
    if (action.payload.merger) {
        let oldData = yield select(getContext);
        oldData = oldData[action.payload.id].data
        apiResponse = action.payload.merger((target ? oldData[target] : oldData), apiResponse)
    }

    yield put({ type: type, payload: { data: apiResponse, node: target, id: action.payload.id, status: status, key: action.payload.key } });
}

function* apiAsyncUpdate(action: any): any {
    const apiResponse: any = yield apiSaga(action);
    yield updateComponentDataSaga(action, apiResponse);
}


export function* apiRegisterSaga() {
    yield takeEvery("CALL_API", apiAsyncUpdate);
}


