/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import {
  clean,
  convertEndData,
  convertToOnDayFormat,
  convertToTitleCaseAndAppendWithS,
  fetchLabelFromId,
  getEAOrAddressDetailsFromContact,
  removeUnderScoreConvertTitleCase,
  renderError,
  setPayeeInformation,
  transformRecurringRequestGlobal,
  updateComponentParams,
  customErrorMessage,
  maskAccountNumber
} from "../../../Utils/CommonBaseClass";
import { getCustomerUrl, processAPIResponse } from "../../../../libs/utils/utils";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { getKey } from "../../../../libs/utils/storageManager";

import { FxDateEdit } from "../../../Input/FxDate/FxDateEdit";
import { FxSwitch } from "../../../Action/FxSwitch";
import FxLabel from "../../../Input/FxLabel/FxLabelView";
import FxLabelView from "../../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import FxSnackBar from "../../../Utils/fx-snack-bar";
import ScheduleLayout from "../../../Layout/ScheduleLayout";
import { FxSummaryCurrency } from "../../../Input/FxCurrency/FxSummaryCurrency";
import { FxDateTimeView } from "../../../Input/FxDate/FxDateView";
import FxModalGeneric from "../../../Utils/FxModalGeneric";
import { AuthenticationVerificationModal } from "../../AuthenticationModal/AuthenticationVerificationModal";
import HttpClient from "../../../../libs/utils/httpClient";
import { ScheduleCreateMethodTab } from "../../Schedules/ScheduleCreateMethodTab";
import { accountLabelTransformation, getEAList } from "../../Schedules/ScheduleGlobalFunctions";
import { FxRecurring } from "../../Schedules/Recurring/FxRecurring";
import { ScheduleTopSection } from "../../Schedules/ScheduleTopSection";
import { TransactionSummaryFieldType } from "../../Schedules/TransactionSummaryTemplate";
import { FxFasterFundingSwitch } from "../../../Utils/FxFasterFundingSwitch";
import UIFeatureImpl from "../../../../libs/services/uiFeatures";

Logger.debug(
  "EditCollectContactRecurring.tsx",
  "Component to handle the edit recurring of collect via contact"
);

// src to fetch account
const acc_src = {
  url: "account/list",
  method: "POST",
  data: {

    "pageSize": 25,
    "pageNumber": 1,
    "sortOptions": {
      "sortOrder": "DESC",
      "sortBy": "createdOn"
    },
    "criteria": {
      "filters": [
        {
          "key": "status",
          "operator": "nin",
          "values": [
            "INACTIVE"
          ]
        }
      ]
    }
  }
};

const processingModeData: any = [
  { label: "Same Day", value: "SAME_DAY" },
  { label: "Next Day", value: "FORWARD" },
];

/**
 * This component handles the collect via contact thorugh ACH method
 */
export const EditCollectContactRecurring: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  let recurringInfo: any = context?.data?.body?.action[0]?.transaction;

  const [eaLoad, setEALoad] = useState(false);
  const [contacEA, setContacEA] = useState<any>([]); // external account array
  const [source, setSource] = useState<any>(); // to set value in search contact field

  const [allowDuplicate, setAllowDuplicate] = useState(false);
  
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();

  const [sendEnabled, setSendEnabled] = useState(true);
  const [externalEAId, setExternalEAId] = React.useState();
  const [externalContactCardId, setContactCardId] = React.useState();
  const [contacCard, setContacCard] = useState<any>([]);
  const [merchantList, setMerchantList] = useState<any>([]);

  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available
  const individualCustomer = uiFeat.getIndividualCustomerFeature().available
  const pmLevelQuickSettle = recurringInfo?.source?.contact?.externalAccount ? getKey('isAchQuickSettleEnabled') : getKey('isCardQuickSettleEnabled');
  const showInstantFunding = pmLevelQuickSettle || context?.data?.body?.processingDetail?.quickSettle;
  const [instantFunding, setInstantFunding] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

  // Params For edit option
  const params = useParams<any>();

  const httpClient = HttpClient.getClient();

  const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, setError, watch, } = useForm();

  const method_Tab = useMemo(() => {
    const tabs = [
      {
        className: recurringInfo?.source?.contact?.externalAccount ? "fx-link-sel" : "",
        title: "ACH",
        disabled: true,
        link: "/transaction/collect/contact/add/ach",
        multiplePermission: true,
        permission: [{
          entity: "Recurring Transaction",
          name: "ACH Collect",
          operation: "EDIT"
        }]
      },
      {
        className: recurringInfo?.source?.contact?.card ? "fx-link-sel" : "",
        title: "CARD",
        disabled: true,
        link: "/transaction/collect/contact/add/card",
        multiplePermission: true,
        permission: [{
          entity: "Recurring Transaction",
          name: "CARD Collect",
          operation: "EDIT"
        }]
      },
    ];

    return tabs;
  }, [recurringInfo]);

  const [isloading, setIsloading] = React.useState(false);

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({type: "DATA_UPDATE_COMPONENT_PARAM",payload: {id: "create-collect-ach-from-contact-file-id-file-upload",files: []},});

    const verifyProps = {
      open: false
    }
    updateComponentParams(dispatch, props.id, { verifyProps });
    handleMerchantListData();

  }, []);

  /**
   * useEffect to set edit data details
   */
  useEffect(() => {
    if (recurringInfo) {
      setFormValues(recurringInfo);
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Recurring', backButton:'/recurring/details/' + context?.data?.body?.id} } });
    }
  }, [recurringInfo]);

  /**
   * Method to get the merchant List
   */
  async function getMerchantList() {
    const paylaoddata = {
        "criteria": {
            "filters": [
                  {
                    "operator": "eq",
                    "key": "account.id",
                    "values": [getKey("selectedAccount")?.id]
                  }
              ]
          }
    }
    const data = await httpClient.post(getCustomerUrl('/merchant/list', false), paylaoddata);
    return data;
  }

  /**
   * Method to transform Merchant List Data
   */
  async function handleMerchantListData() {
    const data = await getMerchantList();
    if(data?.data?.resources) {
        const transformedMerchant =  data?.data?.resources?.map((mList: any) => {
            return ({label: mList.categoryType, value: mList.id})
        })
        setMerchantList(transformedMerchant);
    }
  }

    /**
     * getting the source data
     * @param contactId
     */
    function setSourceDetail(contactId: any) {
        setContacEA([]);
        setContacCard([]);

        getEAList(contactId).then((data: any) => {
            const ea = getEAOrAddressDetailsFromContact(data, "EA", "", false, true);
            const contactCards = data?.card?.map((cardDetail: any) => {
                return (
                    {label: (cardDetail?.holderName || '') + maskAccountNumber(cardDetail?.cardNumberLast4, false), value: cardDetail.id}
                )
            }) || [];

            setSource(setPayeeInformation(data)); //to set search contact data
            setValue("source", contactId);

            if (ea && ea.length > 0 && recurringInfo?.source?.contact?.externalAccount) {
                if (context?.data?.body) {
                    let selectedEAId = context?.data?.body?.action[0]?.transaction?.source?.contact?.externalAccount.id;
                    setExternalEAId(ea.find((eaDetail: any) => eaDetail.value === selectedEAId).value);
                }
            }
            if (contactCards && contactCards.length > 0 && recurringInfo?.source?.contact?.card) {
                if (context?.data?.body) {
                    let selectedCardId = context?.data?.body?.action[0]?.transaction?.source?.contact?.card.id;;
                    setContactCardId(contactCards.find((cardDetail: any) => cardDetail.value === selectedCardId).value);
                }
            }

            setContacEA(ea);
            setContacCard(contactCards);
            setEALoad(true);
            setSendEnabled(true);
            FxSnackBar.closeForce();
        });
    }

  /**
   * Method setting the default values for the text edit component from the context data
   * @param data form data
   */  
  function setFormValues(data: any) {
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue("achCompanyDescription", (recurringInfo?.source?.contact?.card ? data?.processingDetail?.statementDescriptor : data?.processingDetail?.companyDescription));
    setInstantFunding(data?.processingDetail?.quickSettle);
    setValue("authType", 'ONLINE');
    setAllowDuplicate(data?.allowDuplicate);

    if(recurringInfo?.source?.contact?.externalAccount) {
        setValue('processingMode', recurringInfo?.processingDetail?.processingMode);
    }

    if (data?.source?.contact) {
      setSourceDetail((data?.source?.contact.url).split('/contact/id/')[1]);
    }
  }

  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removeFile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-collect-ach-from-contact-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  const handleClose = () => {
    history.push(`/recurring/details/${context?.data?.body?.id}`);
  };

  /**
   * Method enables and disables the allow duplicate
   * @param event : allow duplicate status
   */
  const handleAllowDuplicate = (event: any) => {
    setAllowDuplicate(event);
  };

  /**
   * Method triggers if the recurring updates
   */
  const handleUpdateRecurring = (data: any) => {
    setRecurringFormData(data);
  };

  // summary data for source either for PPI or extrnal account
  const externalAccount = watch("externalAccount");
  const externalCard = watch("card");
  const viaToLable = useMemo(() => {
    let address = {}

    if(recurringInfo?.source?.contact?.externalAccount) {
        address = {
            'From': {
              id: 'from',
              value: externalAccount
                ? fetchLabelFromId(contacEA, externalAccount)
                : fetchLabelFromId(contacEA, externalEAId),
            }
          }
    } else {
        address = {
            'From': {
              id: 'from',
              value: externalCard
                ? fetchLabelFromId(contacCard, externalCard)
                : fetchLabelFromId(contacCard, externalContactCardId),
            }
        }
    }
    return address
    
  }, [externalAccount, contacEA, externalEAId, externalCard]);

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Collect Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    ...viaToLable,
    'To': {
      id: 'to',
      value: 'Passport ' + accountLabelTransformation(getKey("selectedAccount")),
    },

    ...(individualCustomer
      ? { Memo: { id: "purpose", value: watch("purpose") } }
      : { Purpose: { id: "purpose", value: watch("purpose") } }),
    
    'Via': {
      id: 'method',
      value: recurringInfo?.source?.contact?.externalAccount ? 'ACH' : 'CARD',
    },
  };

  if (uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) {
    summaryData['Faster Funding'] = {
        id: 'quickSettle',
        value: instantFunding ? 'Yes' : 'No',
    }
  }

  if(recurringInfo?.source?.contact?.externalAccount) {
    summaryData['Processing Mode'] = {
        id: 'processingMode',
        value: watch("processingMode")
          ? watch("processingMode") === 'FORWARD' ? removeUnderScoreConvertTitleCase('NEXT_DAY') : removeUnderScoreConvertTitleCase(watch("processingMode"))
          : "",
    }
  }

  if (businessCustomer) {
    summaryData['Statement Descriptor'] = {
      id: 'statementDescriptor',
      value: watch("achCompanyDescription"),
    }
  }

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
    if(!recurringFormData) {
        return {}
    }
    const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
    const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
        ? convertToTitleCaseAndAppendWithS(
            recurringFormData?.repeatEveryFormData?.repeatEverySelect
        )
      : '';
    const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
        'Repeat every': {
            id: 'repeat-every',
            value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
        },
    }
    if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
        result['On'] = {
            id: 'repeat-on',
            value: convertToOnDayFormat(recurringFormData),
        };
    }
    const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
    const ends = recurringFormData?.endFormData
        ? convertEndData(recurringFormData?.endFormData)
        : '';
    if (ends || endOn) {

    }
    result['Ends'] = endOn ? {
        id: 'repeat-ends',
        value: ends,
        ValueComponent: ({ value }) => (
            <>
                On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
            </>
        )
    } : {
        id: 'repeat-ends',
        value: ends,
    };
    return result;
}, [recurringFormData]);

  /**
  * Function handles transformation of Destination account (Passport Account)
  * @param data : Destination Account data array
  * @returns : array
  */
  const destinationAccountTransformation = useCallback((data: any) => data?.map((item: any) => ({
    value: item.id,
    label: accountLabelTransformation(item),
  })) || [], []);

  /**
  * Function to handle the closing of the modal.
  */
  const handleCloseModal = () => {
    let verifyProps = { ...props.verifyProps }
    verifyProps['open'] = false;
    updateComponentParams(dispatch, props.id, { verifyProps });
  };


  /**
  * Method to set file upload data
  * @param event :event object
  */
  function passdata(event: any) {
    try {
        setFileUploadData(event)
        if (event.length > 0 && errors['fileError']) {
            clearErrors('fileError');
        }
    }
    catch (e) { }
}

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    
    if (!data.source) {
      setError("source", {
        type: "manual",
        message: "Source is required",
      });
      return;
    }
    req = {
      id: context?.data?.body?.id,
      name: data?.name,
      action: [],
    };

    request = {
      type: 'TRANSACTION',
      transaction: {
        destination: {
          account: {
            id: data.depositAccount,
          },
        },
        method: recurringInfo?.method,
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      },
    };
    
    if (data.source) {
      request["transaction"]["source"] = {
        contact: {
          id: data.source,
          externalAccount: recurringInfo?.method === 'ACH' ? {id: data.externalAccount} : undefined,
          card: recurringInfo?.method === 'CARD' ? {id: data.card} : undefined,
        },
      };
    }

    if (data["processingMode"] && recurringInfo?.method === 'ACH') {
      request["transaction"]["processingDetail"] = {};
      request["transaction"]["processingDetail"]["processingMode"] = data.processingMode;

      request['transaction']['processingDetail']['authType'] = 'ONLINE';
      
    }

    if (data.achCompanyDescription) {
      request['transaction']['processingDetail'] = {
        ...request['transaction']['processingDetail'],
        companyDescription: recurringInfo?.method === 'ACH' ? data.achCompanyDescription : undefined,
        statementDescriptor: recurringInfo?.method === 'CARD' ? data.achCompanyDescription : undefined
      }
    }

    if (uiFeat.getTransactionFeatures().fastFunding) {
      (request["transaction"]["processingDetail"]) ? 
        request["transaction"]["processingDetail"]['quickSettle'] = instantFunding : 
        request["transaction"]["processingDetail"] = {
          quickSettle: instantFunding
        };
    }

    req["action"].push(request);

    req = {
        ...req,
        transactionDetail: {
            ...transformRecurringRequestGlobal(context?.data?.body?.transactionDetail, _.cloneDeep(recurringFormData)).transactionDetail,
            startDate: data.startDate,
        },
        linkedDocument: fileUploadData?.map((file: any) => ({
            purpose: "Authorization",
            document: {
                id: file.id,
            }
        })),
    }
    
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await editCollectViaContactRecurring(req);

    status = processAPIResponse(status);
    setIsloading(false);


    if (status?.status) {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: 'Recurring Updated Successfully!',
        });
        if (true) {
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
              id: "create-collect-ach-from-contact-file-id-file-upload",
              files: [],
            },
          });
        }
        history.push('/recurring');
    } else {
      FxSnackBar.show({
        text: customErrorMessage(status)
      });

    }
  }

  /**
  * Async Function handles api call for create and update send
  * @param paylaoddata : form request
  * @returns : response object
  */
  async function editCollectViaContactRecurring(paylaoddata: any) {
    try {
      let url = '/transaction/recurring';
      if (paylaoddata.id && paylaoddata.id !== undefined) {
        url += '/id/' + paylaoddata.id;
      }

      const data: any = await httpClient.post(getCustomerUrl(url, false), paylaoddata).then((response: any) => {
        return response
      })
        .catch((error: any) => {
          return { ...error };
        })
      return data;
    } catch (err) {
      Logger.error("CreateSendAccountCHECK.tsx", "error", err);
      return err;
    }

  }

  /**
  * Method enables and disables the instant fund functionality
  * @param event : instant fund status
  */
  const handleInstantFund = (event: any) => {
    setInstantFunding(event);
  };

  return (
    <>
      <ScheduleLayout
        id="create-collect-via-contact-ach-"
        type='collect'
        loading={isloading}
        summaryData={{ ...summaryData, ...summaryRepeatData }}
        submitTitle={'Save Changes'}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleClose}
        saveEnabled={sendEnabled}
      >
        <ScheduleTopSection
          id="create-collect-contact-ach-schedule-top-section"
          schedule="collect"
          type="contact"
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          acc_src={acc_src}
          dataTransformation={destinationAccountTransformation}
          amountId="create-collect-contact-amount-textbox"
          destinationId="create-collect-contact-ach-destination-account"
        />

        {recurringInfo?.source?.contact?.card && <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("merchantList") }}
              value={merchantList?.find((mList: any) => mList.value === recurringInfo?.processingDetail?.merchant?.id)?.label}
              type="text"
              className={
                errors.source
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="create-contact-collect-from-card-merchant-list"
              label="Merchant"
              name="source"
              isEditable={false}
            />
          </Grid>
        </Grid>}
        
        {(
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("source") }}
              value={source && source[0]?.label}
              type="text"
              className={
                errors.source
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="edit-send-contact-ach-form-card-source-textbox"
              label="Contact"
              name="source"
              isEditable={false}
            />
          </Grid>
        )}
        <Grid item>
          <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-contact-ach-tab" />
        </Grid>

        {((eaLoad && !params?.id) ||
          (externalEAId && recurringInfo?.source?.contact?.externalAccount)) && (
            <Grid item xs={12} sm={12}>
              <FxMaterialSelect
                register={{ ...register("externalAccount") }}
                id="create-send-contact-ach-form-card-ea"
                name="externalAccount"
                data={contacEA}
                control={control}
                label="Contact External Account"
                rules={{ required: true }}
                value={externalEAId ? externalEAId : ""}
                clearError={clearErrors}
                className={
                  errors.externalAccount
                    ? "border-error-input fx-input-edit"
                    : "fx-input-edit"
                }
                readOnly={true}
                setValue={setValue}
              />
            </Grid>
        )}
        {((externalContactCardId && recurringInfo?.source?.contact?.card)) && (
            <Grid item xs={12} sm={12}>
              <FxMaterialSelect
                register={{ ...register("card") }}
                id="create-send-contact-ach-form-card"
                name="card"
                data={contacCard}
                control={control}
                label="Contact Card"
                rules={{ required: true }}
                value={externalContactCardId || ""}
                clearError={clearErrors}
                className={
                  errors.card
                    ? "border-error-input fx-input-edit"
                    : "fx-input-edit"
                }
                readOnly={true}
                setValue={setValue}
              />
            </Grid>
        )}
        {recurringInfo?.source?.contact?.externalAccount && <Grid item xs={12} sm={12}>
          <FxMaterialSelect
            register={{ ...register("processingMode") }}
            control={control}
            rules={{ required: true }}
            id="create-send-contact-ach-form-card-processing-mode"
            name="processingMode"
            data={processingModeData}
            value={"SAME_DAY"}
            label="Processing Mode"
            setValue={setValue}
          />
        </Grid>}
        
        <Grid item sm={12} xs={12}>
          <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-onetime-card-form-card-statement-descriptor-textbox" label="Statement Descriptor" name="achCompanyDescription" variant="outlined" setValue={setValue} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="create-send-contact-ach-form-card-allow-duplicate"
            value={allowDuplicate}
            onClick={handleAllowDuplicate}
          ></FxSwitch>{" "}
          <FxLabel value="Allow Duplicate"></FxLabel>
        </Grid>
        {context?.data?.body?.transactionDetail && (
                    <>
                        <Grid item xs={12} sm={12} >
                            <FxDateEdit register={{ ...register("startDate") }} className={errors.startDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-recurring-form-card-startDate" name="startDate" type="date" variant="outlined" defaultValue={context?.data?.body?.transactionDetail?.startDate} label={'Start Date *'} setValue={setValue} disablePast={new Date(context?.data?.body?.transactionDetail?.startDate) < new Date() ? false : true} resetField={resetField} rules={{ required: true }} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FxTextEdit register={{ ...register("name") }} className={errors.name ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-recurring-form-card-name-textbox" defaultValue={context?.data?.body?.name} label="Name" name="name" variant="outlined" setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FxRecurring register={register} control={control} errors={errors} id='edit-recurring' updateTrigger={handleUpdateRecurring} defaultValue={context?.data?.body?.transactionDetail} setValue={setValue} clearErrors={clearErrors} startDate={watch("startDate")}></FxRecurring>
                        </Grid>
                        <Grid item container >
                            <Grid item>
                                <Typography className="filter-popover-label" >LINKED DOCUMENTS</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>&nbsp;</Grid>
                            <Grid item container xs={12} spacing={1} alignItems='center'>
                                <Grid item xs={12} sm={8}>
                                    <FxMaterialSelect register={{ ...register("docType") }} id="edit-recurring-form-card-doc-type" control={control} rules={{ required: false }} name="docType" data={docTypeData} value={'CONTRACT'} readOnly={true} label="Select a document type" setValue={setValue} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name={"fileId"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <FxFileUpload register={{ ...register("fileId") }} name='fileId' passData={passdata} id="edit-recurring-form-card-file-id-file-upload" onChange={(e: any) => { onChange(e); }} value={fileUploadData ? fileUploadData : value} acceptedFiles={[".pdf", ".docx", ".jpg", ".txt", ".png", ".jpeg", ".xls", ".wav", ".xlsx", ".tiff", ".doc", ".rtf", ".bmp", ".efx", ".csv", ".kswps", ".wps"]}
                                                    formData={{ "type": 'CONTRACT' }}
                                                    setValue={setValue}
                                                    maxFileSize={10485760} />
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FxLabelView className="fx-label-small" id="edit-recurring-form-card-file-types-supported">(.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc, .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={'error-message'}>
                                    {errors.fileError && renderError(errors.fileError)}
                                </div>
                            </Grid>
                            {fileUploadData && <Grid item container >
                                {fileUploadData.map((key: any) => {
                                    return <Chip icon={<InsertDriveFileTwoToneIcon />} label={key['name']} onDelete={() => removeFile(key['id'])} color="primary" variant="outlined" deleteIcon={<DeleteIcon />} />
                                })}
                            </Grid>}
                        </Grid>
                    </>
                )}
        {(uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) &&
          <FxFasterFundingSwitch instantFunding={instantFunding} handleInstantFund={handleInstantFund} disabled={true}/>
        }
      </ScheduleLayout>
      <FxModalGeneric
        id="creat-send-contact-ach-otp"
        open={props.verifyProps?.open}
        onClose={handleCloseModal}
        componentProps={{ ...props.verifyProps }}
        className={'fx-modal-small2medium'}
        component={AuthenticationVerificationModal}
      >
      </FxModalGeneric>
    </>
  )

});