import React, { useEffect, useState, useRef } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { Logger } from "../../../libs/utils/logger";
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { handleKeyDownChange } from "../../Utils/CommonBaseClass";

/**
 * Component used for edit text
 */
Logger.debug("FxTextEditSSN.tsx", "Fx Text Edit initializing")

interface FxTextEditSSNProps {
  id: string;
  name: string;
  value?: string;
  defaultValue?: String;
  className?: string;
  error?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  rules?: any,
  register: any,
  control: any,
  variant?: "standard" | "filled" | "outlined" | undefined;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

/**
 * @param props FxTextEditSSNProps
 * Component can be used for edit ssn.
 */
export const FxTextEditSSN: React.FC<FxTextEditSSNProps> =
  (props) => {
    const { id, name, error, setValue, clearErrors, setError, control, rules, register, defaultValue, placeholder, label, variant } = props;

    const className = (props.className ? props.className : 'fx-input-edit')

    const [editing, setEditing] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [last4DigitSSN, setLast4DigitSSN] = useState<any>(defaultValue ? '*****' + defaultValue.substr(defaultValue?.length - 4) : '');

    const textBoxRef = useRef<any>();

    const ssnDefaultValue = defaultValue?.length === 4 ? '' : defaultValue;

    function onMouseOver() {
      setShowIcon(true)
    }

    function onMouseOut() {
      setShowIcon(false)
    }

    useEffect(() => {
      if (setValue) {
        setValue(name, ssnDefaultValue);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    function onBlur(e: any) {
      try {
        const ssn = e.target.value;
        if (!error) {
          setEditing(false);
          setValue(name, ssn);
          if (ssn) {
            const last4DigitSSN = '*****' + ssn.substr(ssn.length - 4);
            setLast4DigitSSN(last4DigitSSN);
          } else {
            setLast4DigitSSN('');
          }
        }
      }
      catch (e) { }
    }

    /**
    * On edit click handler
    */
    const handleClickEditIcon = (value: any) => {
      setEditing(true);

      setTimeout(() => {
        textBoxRef?.current?.focus();
      }, 300)

    };

    /**
    * Method to handle changes on event for ssn
    */
    const onChangeHandler = (event: any) => {
      try {
        event.persist();

        const targetName = event.target.name;
        const targetValue = event.target.value;
        if (targetName === name) {
          if (event.target.value.includes('*')) {
            setValue(name, '')
          } else {
            let val = targetValue.replace(/\D/g, '');
            val = val.replace(/^(\d{3})/, '$1-');
            val = val.replace(/-(\d{2})/, '-$1-');
            val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
            if (val.length === 11) {
              clearErrors(name);
            }
            else {
              if (targetValue) {
                setError(name, {
                  type: "manual",
                  message: "Invalid SSN",
                });
              }
            }
            setValue(name, val)
          }
        }
      }
      catch (e) { }

    }

    return <>
      {!editing && last4DigitSSN !== '' ?
        <TextField
          variant={variant}
          id={`${id}-view`}
          name={`${name}-input`}
          disabled={true}
          defaultValue={last4DigitSSN}
          placeholder={placeholder ? placeholder : ""}
          className="fx-input-edit"
          type={'text'}
          onMouseEnter={onMouseOver}
          onMouseLeave={onMouseOut}
          label={label ? label : ''}
          InputProps={
            {
              endAdornment: showIcon && (
                <InputAdornment position="end">
                  <IconButton
                    className="fx-icon-button"
                    onClick={handleClickEditIcon}
                    id={`${id}-view-icon`}
                    size="large">
                    {<EditIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        /> :
        <Controller
          name={name}
          control={control ? control : ''}
          rules={rules ? rules : {}}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              {...register}
              variant={variant}
              id={id}
              name={name}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={ssnDefaultValue}
              placeholder={placeholder ? placeholder : ""}
              className={className}
              type={'text'}
              onBlur={onBlur}
              label={label ? label : ''}
              value={(props.value === '' ? props.value : (props.value ? props.value : value))}
              onChange={onChangeHandler}
              onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }}
              inputRef={textBoxRef}
              error={error ? error : false}
            />
          )}
        />
      }
      <TextField
            className="fx-hidden"
            id={"react-" + props.id + "-ssn-input"}
            name={props.name}
            value={defaultValue ? defaultValue : ""}
            inputRef={textBoxRef}
            type={'hidden'} />
    </>;
  }