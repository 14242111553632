import React from 'react'
import { FxInfoLayout } from '../../../components/DrawerPages/DrawerLayaouts/FxInfoLayout';
import { Logo } from '../../../components//Utils/Logo';

/**
 * Component used to draw mxm debit card content for aply debit card
 * @returns 
 */
export default function SmbSourceContent() {
    const applyCardLayoutHeader = <>
        <h2>Get Corporate Debit card&nbsp;</h2>
        <div>
            <h2>with &nbsp;</h2>
            <Logo />
        </div>
    </>
    return (
        <FxInfoLayout layoutHeader={applyCardLayoutHeader} />
    )
}
