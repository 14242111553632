/**
 * @param key
 * @param value
 * set the session storage item with key and value
 */
export const setSessionStorage = (key: string, value: any): void => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * @param key
 * get session storage item using the key
 */

export const getSessionStorage = (key: string): any => {
    const value: any = sessionStorage.getItem(key);
    if (value !== 'undefined') {
        return JSON.parse(value);
    }
    return null;
};

/**
 * remove specified session storage item
 * @param key 
 */
export const removeSessionStorage = (key: string): void => {
    sessionStorage.removeItem(key);
};

/**
 * remove all session storage items, for example during logout
 */
export const removeAllSessionStorage = (): void => {
    sessionStorage.clear();
};
