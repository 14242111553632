import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success-icon.svg";
import { useHistory } from "react-router";
import HttpClient from "../../../libs/utils/httpClient";
import { Logger } from "../../../libs/utils/logger";
import { getCustomerUrl } from "../../../libs/utils/utils";
import { FxButton } from "../../Action/FxButton";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import FxCardHeader from "../../Container/FxCardHeader";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { FxInfoCard } from "../Cards/FxInfoCard";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import { useDispatch } from "react-redux";

const httpClient = HttpClient.getClient();


Logger.debug("PolicyQueueDetailModal.tsx", "Policy Queue Detail Modal initializing");
/**
 * This component handles the Approval, Reject and Pending detail view 
 */
export const PolicyQueueDetailModal: React.FC<any> = React.memo((props) => {
  const {data} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [transactionInfo, setTransactionInfo] = useState<any>();

  useEffect(() => {
    handleTransactionInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
  * Api call for get the Transaction Info
  */
  async function getTransactionInfo() {
    try {
      const data = await httpClient.get(getCustomerUrl('/transaction/id/' + props.data.transactionId))
      return data;
    } catch (err) {
      return err;
    }
  }

  /**
  * to handle transaction info response
  */
  async function handleTransactionInfo() {
    let status = await getTransactionInfo();
    if (status.data) {
      const trnsactionInfo = {...status.data, createdBy:status.data?.createdBy?.username, memo: (status.data?.processingDetail?.memo || '')};
      setTransactionInfo(trnsactionInfo);
    }
  }

  const processing_details_section: any = [
    {
      label: "Via",
      value: "method",
    },
    {
      label: "Purpose",
      value: "purpose",
    },
    {
      label: "Memo",
      value: "memo",
    },
    {
      label: "Created On",
      value: "createdOn",
      type: "fri://fieldtype/date",
      format:"MMM DD, YYYY",
    },
    {
      label: "Created By",
      value: "createdBy"     
    }
  ];

  /**
  * to redirect to transaction detail page
  */
  function handleTransactionDetail() {
    history.push(`/transactions/send/${props.data.transactionId}`,{backUrl:'/pendingApprovals'});
  }

  /**
  * to handle the button click 
  */
  function handleButtonClick() {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'approvals-queue-list' } });
    history.push(`pendingApprovals`);
    props.close();
  }
 

  return (
    <div className="modal-content">
      <div className="fx-policy-approval-popup modal-body">
        {
          <FxCard className="policy-confirmation-data-card">
            <FxCardHeader
              id="policy-queue-detail-header"
              className="fx-card-header"
            >
                <Grid className="fx-modal-close-icon">
                  <IconButton
                    onClick={props.close}
                    id="policy-queue-detail-modal-close-icon"
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
            </FxCardHeader>
            <FxCardBody
              id="policy-queue-detail-modal-body"
              className="fx-info-card"
            >
              <Grid
                container
                direction="row"
                className="fx-modal-form flex column"
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  margin='auto'
                  className={"fx-card-circle"}
                >
                  <SuccessIcon />
                </Grid>
                <Grid container justifyContent="center" item xs={12}>
                  <Typography
                    id="title-policy-confirmation-modal"
                    className="fx-approval-confirmation-title"
                  >
                    {(props.data?.approvalStatus === 'REJECTED' || props.approvalStatus === 'REJECTED') ? 'Rejected' : 'Approved'} Successfully!
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={12}
                  className="fx-approval-confirmation-description"
                >
                  {(props.approvalStatus === 'REJECTED' || props.approvalStatus === 'APPROVED') ? 
                    <Typography textAlign='center'> You have {props.approvalStatus === 'REJECTED' ? 'rejected':'approved'} send {data?.entity?.method?.toLowerCase()} request from {data?.entity?.createdBy?.username} </Typography> :
                    <Typography textAlign='center'>
                    <span>{data?.approvalStatus === 'REJECTED' ? 'Rejected':'Approved'} by </span>
                    <span>{props.data?.lastUpdatedByValue} on </span>
                    <FxDateTimeView value={props.data?.lastUpdatedOnValue} format={'MMM DD, YYYY'}/>
                   </Typography>
                  }               
                  
                </Grid>
              </Grid>
              {
                !props?.isPendingApproved && <>
                  <Grid container xs={12} className="fx-approval-confirmation-popup-grid2">
                    <Grid item className="fx-digest-grid">
                      <FxCurrencyView
                        className="balance-amount"
                        id="policy-queue-detail-currencyview"
                        value={props.data.amount?.props.value}
                        prefix={"$"}
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography
                        id="reference-title-policy-confirmation-modal"
                        variant="subtitle1"
                        className="fx-approval-confirmation-reference-number"
                        onClick={handleTransactionDetail}
                      >
                        REF #{props.data.transactionId}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className="fx-transaction-summery-details"
                  >
                    <Grid item xs={12}>
                      <div className="schedule-detail-summary-card-divider"></div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        id="policy-queue-detail-card#processing-header"
                        className="fx-transaction-summery-details-title"
                      >
                        Transaction Summary
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      key="schedule-processing-info-container"
                      direction="row"
                      xs={12}
                    >
                      <FxInfoCard
                        notiltle={true}
                        id={"policy-queue-detail-info-card-processing-details"}
                        column={processing_details_section}
                        data={transactionInfo}
                      ></FxInfoCard>
                    </Grid>
                  </Grid>
                </>
              }

            </FxCardBody>
            <FxCardFooter
              id="policy-approval-modal-Footer"
              className="fx-footer"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                className="fx-modal-footer"
              >
                <FxButton
                  disableRipple={false}
                  className="fx-button fx-button-theme"
                  id={"approval-accept-reject-button"}
                  type="submit"
                  onClick={handleButtonClick}
                >
                  {`Go To APPROVAL Queue`}
                </FxButton>
              </Grid>
            </FxCardFooter>
          </FxCard>
        }
      </div>
    </div>
  );
});
