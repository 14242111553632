import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import usa_state from '../../../Utils/usa_states.json'
import { AUTH_STRINGS } from '../../../../constants/strings';
import {
  clean,
  convertToPhoneRequestFormat,
  deleteUnwantedRequest,
  handleAddressChange,
  handleKeyDownChange,
  handleZipChange,
  renderError,
  setPhoneValidationOptions,
  setZipValidationOptions,
  updateComponentParams,
  setCityNameValidation,
  setAddressLine2Validation,
  setAddressLine1Validation
} from '../../../Utils/CommonBaseClass';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxDateEdit } from '../../../Input/FxDate/FxDateEdit';
import { ReactComponent as EditIcon } from '../../../../assets/svg/business-signup-edit-icon.svg';
import FxPhoneEdit from '../../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../../Input/FxSelect/FxSelectAutoSearch';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import {  useIsMobileScreen } from '../../../../libs/utils/utils';

/**
 * Component: BusinessSignUp
 */


Logger.debug("BusinessDetailsSignup.tsx", "Business Details Signup initializing")

export const BusinessDetailsSignup: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control } = useForm();       
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const history = useHistory();
        const isSmallScreen = useIsMobileScreen()

        useEffect(() => {
            updateComponentParams(dispatch, props.id, {isLoading: false})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        const categoryData = [
            { value: "SOLE_PROPRIETOR", label: "Sole Proprietor" },
            { value: "PARTNERSHIP", label: " Partnership" },
            { value: "LLC", label: "LLC" },
            { value: "LLP", label: "LLP" },
            { value: "C_CORP", label: "C Corp" },
            { value: "S_CORP", label: "S Corp" },
            { value: "SINGLE_MEMBER_LLC", label: "Single member LLC" },
            { value: "GOVERNMENT_ORGANISATION", label: "Govt. (LocalState/Federal)" },
            { value: "TAX_EXEMPTION", label: "501 c/Tax Exempt" },
            { value: "NON_PROFIT", label: "Non Profit" }]


        // website validation constant
        // const setwebisteValidation = {
        //     required: false,
        //     pattern: {
        //         value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        //         message: AUTH_STRINGS.ERRORS.URL_INVALID_REGEXP,
        //     },
        // }

        // ein validation constant
        const setEinValidationOptions = {
            required: true,
            maxLength: 11,
            pattern: {
                value: /^\d{2}-?\d{7}$/,
                message: AUTH_STRINGS.ERRORS.EIN_INVALID_REGEXP,
            },
        };

         // Email validation constant
         const setEmailValidationOptions = {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };

        // function handles the form submit
        async function onSubmit(data: any) {
            let req: any = { ...data }
            const address :any = {
                "addressLine1": req['addressLine1'],
                "addressLine2": req['addressLine2'],
                "city": req['city'],
                "state": req['state'],
                "zip": req['zip'],
                "isPrimary": true,
                "usage": {
                                "isPayorAddress": false
                            }
            }
            if(data['address_id']){
                address['id'] = data['address_id'];
                delete req['address_id'];
            }
            
            req['mailingAddress'] = [address]
            req['phone'] = convertToPhoneRequestFormat(req.phone)
            req = clean(req);
            let unwantedRequestArray = ['state', 'addressLine1', 'city', 'zip', 'addressLine2','phone-select'];
            deleteUnwantedRequest(req, unwantedRequestArray)            

            if (props.onSubmit) {
                updateComponentParams(dispatch, props.id, { isLoading: true })
                props.onSubmit(data, req,()=>{
                    updateComponentParams(dispatch, props.id, { isLoading: false })
                })
            }
        }

        // Method handles the cancel operation
        const handleClose = () => {
            history.push('/')
        }
        // Method handles the onchange event
        const handleChange = (event: any) => {
            if (event.target.name === 'ein') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{2})/, '$1-');
                val = val.replace(/(\d)-(\d{7}).*/, '$1-$2');
                if (val.length === 10) {
                    clearErrors('ein');
                }
                else {
                    setError("ein", {
                        type: "manual",
                        message: "Invalid EIN",
                    });
                }
                setValue('ein', val)
            }
        }

        if (getKey('CustomerName')) {
            setValue('legalName', getKey('CustomerName'))
        }

        return (
            <Grid container id="business-details-main-grid" xs={12} justifyContent='center' >
                <Grid item xs={12} sm={isSmallScreen?undefined:11} md={isSmallScreen?undefined:7}>
                    <FxCard id="business-details-card"  className={isSmallScreen?"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-signup-formkyc-mobile fx-business-details":"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-business-details"}>
                        <div className="fx-form-edit-profile ">
                            <form id="business-details-form" onSubmit={handleSubmit(onSubmit)}>
                                <FxCardHeader id="business-details-card-header">
                                  <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                                    <Grid item xs={12} container flexWrap={'nowrap'}>
                                      <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                                        <EditIcon />
                                      </Grid>
                                      <Grid item>
                                        <Typography id="business-details-card-title" variant="h3">
                                          Tell us about your business
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} marginTop={2.5}>
                                      <Typography variant="h6" id='business-details-card-form-card-label' className={isSmallScreen?"fx-word-wrap":''}>
                                        To get started, we’ll need to verify your business
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </FxCardHeader>
                                <FxCardBody id="business-details-card-body" className="fx-info-card fx-margin-top-reducer" >
                                    <Grid container direction="row" spacing={2} className="fx-info-card-form " >
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxLabelView id="business-details-label" className="" >MORE ABOUT YOUR BUSINESS</FxLabelView>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("legalName") }} className={errors.legalName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="legalName" label="Legal Business Name *" name="legalName" variant="outlined" defaultValue={getKey('CustomerName')} isEditable={false} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FxMaterialSelect register={{ ...register("businessCategory") }} className={errors.businessCategory ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} control={control} id="businessCategory" name="businessCategory" data={categoryData} label="Business Type *" setValue={setValue} value={props.data?.businessCategory} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FxTextEdit register={{ ...register("ein") }} className={errors.ein ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEinValidationOptions} onChange={handleChange} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} id="ein-textbox" label="Employer Identification Number (EIN) *" name="ein" variant="outlined" placeholder="XX-XXXXXXX" defaultValue={props.data?.ein} />
                                            <div className={'error-message'}>
                                                {errors.ein && renderError(errors.ein)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("doingBusinessAs") }} className={errors.doingBusinessAs ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="doingBusinessAs" label="Doing Business as *" name="doingBusinessAs" variant="outlined" defaultValue={props.data?.doingBusinessAs} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <FxDateEdit register={{ ...register("dateOfIncorporation") }} className={errors.dateOfIncorporation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="dateOfIncorporation" label="Business Date of Incorporation *" name="dateOfIncorporation" setValue={setValue} variant="outlined" defaultValue={props.data?.dateOfIncorporation} disableFuture={true} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FxSelectAutoSearch register={{ ...register("stateOfIncorporation") }} rules={{ required: true }} className={errors.stateOfIncorporation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="stateOfIncorporation" name="stateOfIncorporation" data={usa_state} label="State of Incorporation *" setValue={setValue} value={props.data?.stateOfIncorporation} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("website") }} control={control} className={errors.website ? "border-error-input fx-input-edit" : "fx-input-edit"} id="website" name="website" label="Website" defaultValue={props.data?.website} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} >
                                            <FxTextEdit register={{ ...register("description") }} className={errors.description ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="description" label="Business Description" name="description" variant="outlined" defaultValue={props.data?.description} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxLabelView id="business-details-label2" className="" >BUSINESS CONTACT INFO</FxLabelView>
                                        </Grid>
                                        <FxTextEdit register={{ ...register("address_id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-address-id"  name="address_id" variant="outlined" defaultValue={props.data?.address_id} />
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("addressLine1") }} control={control} rules={setAddressLine1Validation(true)} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="business-details-address-line1-texbox" name="addressLine1" label="Address Line 1 *" defaultValue={props.data?.addressLine1} onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                            <div className={'error-message'}>
                                                {errors.addressLine1 && renderError(errors.addressLine1)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("addressLine2") }} control={control} rules={setAddressLine2Validation(false)} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="business-details-address-line2-texbox" name="addressLine2" label="Address Line 2" defaultValue={props.data?.addressLine2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(true)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="business-details-city-texbox" name="city" label="City *" defaultValue={props.data?.city} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="business-details-state" name="state" data={usa_state} label="State *" setValue={setValue} value={props.data?.state} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(true)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="business-details-zip-textbox" name="zip" label="ZIP *" defaultValue={props.data?.zip} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                            <div className={'error-message'}>
                                                {errors.zip && renderError(errors.zip)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                        <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="phone" name="phone" label="Phone Number *" defaultValue={props.data?.phone} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                            <div className={'error-message'}>
                                                {errors.phone && renderError(errors.phone)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FxTextEdit register={{ ...register("email") }} control={control} rules={setEmailValidationOptions} className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} id="email" name="email" label="Email *" defaultValue={props?.data?.email}/>
                                            <div className={'error-message'}>
                                                {errors.email && renderError(errors.email)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="business-details-card-footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer fx-footer-kyc">
                                        <FxButton variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="ea-modal"
                                            onClick={handleClose}>
                                            Cancel
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id={"business-details-card-save-button"}
                                            type="submit"
                                            isSubmitting={props?.isLoading}
                                        >
                                                Continue
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </form>
                        </div>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })