/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Grid } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { ISource } from '../../../types/common.interfaces';
import { useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import usa_state from '../../Utils/usa_states.json';

import {
  getPPISupportedPaymentMethods,
  customErrorMessage,
  updateComponentParams,
  deleteUnwantedRequest,
  createCommonVerifyProps,
  renderError,
  setPhoneValidationOptions,
  convertToPhoneDisplayFormat,
  renderCityNameError,
  setZipValidationOptions,
  setCityNameValidation,
  convertToPhoneRequestFormat,
  findAddressValidations,
  handleZipChange,
  handleKeyDownChange,
  cleanWithEmptyValue
} from "../../Utils/CommonBaseClass";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import { clean } from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import { accountLabelTransformation, getLocationData } from "./ScheduleGlobalFunctions";
import {
  createSendMyAccount,
  dataSourceTransformation,
} from "./ScheduleGlobalFunctions";
import { AUTH_STRINGS } from "../../../constants/strings";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import FxPhoneEdit from "../../Input/FxPhone/FxPhoneEdit";
import { FxSelectAutoSearch } from "../../Input/FxSelect/FxSelectAutoSearch";

interface InputErrType {
  type: string;
  message: string;
}

Logger.debug(
  "CreateSendOneTimeWIRE.tsx",
  "create send Account Wire initializing"
);

const acc_src: ISource = {
  url: "account/list",
  method: "POST",
  data: {
    pageSize: 25,
    pageNumber: 1,
    sortOptions: {
      sortOrder: "DESC",
      sortBy: "createdOn",
    },
    criteria: {
      filters: [
        {
          key: "status",
          operator: "nin",
          values: ["INACTIVE"],
        },
      ],
    },
  },
};

const accountType = [
  { label: "Savings", value: "SAVINGS" },
  { label: "Checking", value: "CHECKING" },
];

const holderTypeData = [
  { label: "Consumer", value: "CONSUMER" },
  { label: "Corporate", value: "CORPORATE" },
];

const bank_src = {
  url: "list/bank",
  method: "POST",
  expiry: 300,
  baseUrl: true,
  data: {
    pageSize: 25,
    pageNumber: 1,
    criteria: {
      filters: [],
    },
  },
};

// routing number validation constant
const setRoutingNumberValidation = {
  required: false,
  minLength: 9,
  maxLength: 9,
  pattern: {
    value: /^[0-9-_&,.]*$/,
    message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
  },
};

/**
 * This component handles the send creation through my account for one time wire method
 */
export const CreateSendOneTimeWire: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  const params = useParams<any>();
  const withdrawalInfo: any = context?.data?.body;
  let sendId: any = "";
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available
  const scheduleId = params?.id;
  const method_Tab = useMemo(() => {
    const tabs = [
      {
        title: "ACH",
        link: "/transaction/send/onetime/add/ach",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Ach Send",
          operation: "CREATE"
      }
      },
      {
        className: "fx-link-sel",
        title: "WIRE",
        link: "/transaction/send/onetime/add/wire",
        disabled: !!scheduleId,
        permission: {
            entity: "Accounts",
            name: "Wire Send",
            operation: "CREATE"
        }
      },
      {
        title: 'INTERNATIONAL WIRE',
        link: '/transaction/send/onetime/add/internationalWire',
        disabled: !!scheduleId,
        permission: {
            entity: "Accounts",
            name: "International Wire Send",
            operation: "CREATE"
        }
      },
      {
        title: "BOOK",
        link: "/transaction/send/onetime/add/book",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Book Send",
          operation: "CREATE"
      }
      }
    ];
    // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
    if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
      tabs.push({
        title: "VIRTUAL CARD",
        link: "/transaction/send/onetime/add/virtualcard",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "CREATE"
        }
      })
    }
    return tabs;
  }, [scheduleId]);

  const [isloading, setIsloading] = useState(false);
  const [location, setLocation] = useState<any>();

  // routing number/wire routing number validation error messages
  const renderRoutingNumberError = useCallback((err: InputErrType): string => {
    if (err.type === "minLength" || err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
    }
    return err.message;
  }, []);

  // account number validation constant
  const setAccountNumberValidation = {
    required: true,
    maxLength: 17,
  };

  /**
   * Method to render account number error
   * @param err
   */
     const renderAccountNumberError = (err: InputErrType): string => {
      if (err.type === "maxLength") {
        return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_NOT_VALID_LENGTH;
      }
      return err.message;
    };

  /**
   * Method to transform routing number data as dropdown options
   * @param data : routing number data
   */
  const routingNumberTransformer = useCallback((data: any) => data?.map((item: any) => ({
    label: item.routingNumber + " (" + item.name + ")",
    value: item.routingNumber,
  })) || [], []);

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-onetime-wire-form-card-destination" },
    });
    if (scheduleId) {
      const src = {
        url: "/transaction/id/" + scheduleId,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-to-one-time-WIRE", source: src },
      });
      updateComponentParams(dispatch, "edit-send-to-one-time-WIRE", {
        isEdit: true,
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    setValue("type", "REGULAR");
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  /**
   * useEffect to set edit data details
   */
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues(context?.data?.body);
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  /**
   * Method setting the default values for the text edit component from the context data
   */
  function setFormValues(data: any) {
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue("memo",data?.processingDetail?.memo);
    if(data?.destination?.externalAccount) {
      setValue("holderType", data?.destination?.externalAccount?.holderType)
      setValue("accountHolderName", data?.destination?.externalAccount?.holderName)
      setValue("accountNumber", '****'+data?.destination?.externalAccount?.accountNumberLast4)
    }
  }

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any;
    req = {
      source: {
        account: {
          id: data.source,
        },
      },
      method: "WIRE",
      type: "REGULAR",
      amount: data.amount,
      purpose: data.purpose,
    };
    if (!scheduleId) {
        req["destination"] = {
          externalAccount: {
            type: data.accountType,
            routingNumber: data.routingNumber,
            wireRoutingNumber: data.wireRoutingNumber,
            holderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            purpose: data.accountPurpose,
            holderType: data.holderType,
          },
        };
        if(data['holderPhone']){
          req["destination"]['externalAccount']['holderPhone'] = convertToPhoneRequestFormat(data['holderPhone']);
        }
        // creating address object for request
        const addressObj = cleanWithEmptyValue({
          addressLine1: data['addressLine1'],
          addressLine2: data['addressLine2'],
          city: data['city'],
          state: data['state'],
          zip: data['zip']
        })
        if(Object.keys(addressObj).length > 0 && Object.values(addressObj).some(value => value !== null)){
          req["destination"]['externalAccount']['holderAddress'] = addressObj;
        }
    }
    if (data.memo) {
      req["processingDetail"] = {};
      req["processingDetail"]["memo"] = data.memo;
    }
    if(data['location']){
      req['processingDetail']['location'] = {
          'id' : data['location']
      }
    }
    
    if (props.isEdit) {
      req["id"] = context?.data?.body?.id;
      const unwantedRequestArray = ["source", "destination", "method", "type"];
      deleteUnwantedRequest(req, unwantedRequestArray);
  }
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    if (status?.headers?.url) {
      sendId = status.headers.url.split("/").pop();
    }

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
      //api success
      const successMessage = withdrawalInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
      const redirectUrl = withdrawalInfo? `/transactions/send/${withdrawalInfo['id']}`: '/thanks/transactions/send-onetime-virtual-card/';
      const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
      if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{

   
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: withdrawalInfo ? 'Send Updated Successfully!' : 'Send Created Successfully!',
      });
      if (withdrawalInfo) {
        history.push("/transactions/send/" + withdrawalInfo["id"]);
      } else {
        history.push("/thanks/transactions/send-onetime-wire/" + sendId);
      }
    } 
   }
     else {
      //api  failed
      FxSnackBar.show({
        text: customErrorMessage(status),
      });
    }
  }

  const amount = watch("amount");
  const summaryData: Record<
    string,
    TransactionSummaryFieldType | string | undefined
  > = {
    "Send Amount": {
      id: "amount",
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : "",
    },
    From: {
      id: "from",
      value: accountLabelTransformation(getKey("selectedAccount")),
    },
    To: {
      id: "to",
      value: watch("accountHolderName"),
    },
    ...(businessCustomer
    ? { Purpose: { id: "purpose", value: watch("purpose") } }
    : { Memo: { id: "purpose", value: watch("purpose") } }),
    Via: {
      id: "method",
      value: "WIRE",
    },
    ...({
      "To External Account": {
        id: "accountNumber",
        value: watch("accountNumber"),
      },
    })
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: "memo",
      value: watch("memo"),
    }
}
  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: "send",
        type: "one-time",
        formData: { amount: watch("amount"), purpose: watch("purpose") },
      };
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: { id: "schedule-top-section", data: data },
      });
    } catch (e) {}
  };

  const handleClose = () => {
    if (scheduleId) {
      history.push("/transactions/send/" + scheduleId);
    } else {
      history.push("/dashboard");
    }
  };
        /**
       * Function to handle the closing of the modal.
       */
      const handleCloseModal = () => {
        let verifyProps = {...props.verifyProps}
        verifyProps['open'] = false;
        updateComponentParams(dispatch, props.id, { verifyProps });
    };
  return (
    <>
    <ScheduleLayout
      id={'create-send-onetime-wire'}
      type='send'
      loading={isloading}
      submitTitle={props.isEdit ? 'Save': 'Send Money'}
      summaryData={summaryData}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => handleClose()}
      saveEnabled={true}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection
        id="create-send-one-time-wire-schedule-top-section"
        schedule="send"
        type="one-time"
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        acc_src={acc_src}
        dataSourceTransformation={dataSourceTransformation}
        amountId="create-send-onetime-wire-form-card-amount-textbox"
        sourceId="create-send-onetime-wire-form-card-source"
        purposeId="create-send-onetime-wire-form-card-purpose-textbox"
      />
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
        <FxLocationSelectComponent
          label='Location'
          register={{ ...register("location") }}
          control={control}
          rules={{ required: false }}
          id="create-send-one-time-wire-form-location"
          name="location"
          className="fx-input-edit"
          setError={setError}
          clearError={clearErrors}
          setValue={setValue}
          resetField={resetField}
          isEditable={!scheduleId}
          value={location ? {
            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
            value: location?.id,
          }: null}
        />
      </Grid>}
      <ScheduleCreateMethodTab
        tabItems={
          getPPISupportedPaymentMethods(
          method_Tab,
          "onetime"
        )
      }
        id="create-send-onetime-ach-form-card-tab"
        tabClick={tabClickFunction}
      />
      <Grid item>
        <fieldset className="fx-container-create-schdule-summery">
          <legend>Destination Details</legend>
          <Grid item xs={12} sm={12} container spacing={2}> 
            {(
              <>
              <Grid item xs={12} sm={6}>
                  <FxMaterialSelect
                    register={{ ...register("holderType") }}
                    control={control}
                    rules={{
                      required: !props.isEdit,
                    }}
                    className={
                      errors.accountPurpose
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    id="create-send-one-time-wire-form-card-account-purpose"
                    data={holderTypeData}
                    name="holderType"
                    readOnly={!!props.isEdit}
                    label="Holder Type *"
                    setValue={setValue}
                    value={context?.data?.body?.destination?.externalAccount?.holderType || "CORPORATE"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FxTextEdit
                    register={{ ...register("accountHolderName") }}
                    className={
                      errors.accountHolderName
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    rules={{ required: props.isEdit ? false : true }}
                    id="create-send-one-time-wire-form-card-amount-textbox"
                    label="Holder Name *"
                    name="accountHolderName"
                    type="text"
                    variant="outlined"
                    defaultValue={
                      context?.data?.body?.destination?.externalAccount?.holderName
                        ? context?.data?.body?.destination?.externalAccount?.holderName
                        : ""
                    }
                    isEditable={!props.isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(false)} className={errors.holderPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-send-one-time-wire-form-card-holder-phone-textbox" name="holderPhone" label="Holder Phone"  defaultValue={convertToPhoneDisplayFormat(context?.data?.body?.destination?.externalAccount?.holderPhone)} isEditable={!props.isEdit} setValue={setValue} setError={setError} clearErrors={clearErrors} disableFlag={true}/>
                    <div className={'error-message'}>
                      {errors.holderPhone && renderError(errors.holderPhone)}
                    </div>
                </Grid>
                  <Grid item container xs={12} sm={12}>
                    <fieldset className="fx-add-user-address-container">
                      <legend>Holder Address</legend>
                      <Grid item container xs={12} sm={12} spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: findAddressValidations(watch), maxLength: 35 }} id={'create-send-one-time-wire-form-card-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} inputProps={{ maxLength: 30 }}  defaultValue={context?.data?.body?.destination?.externalAccount?.holderAddress?.addressLine1} isEditable={!props.isEdit}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 35 }} id={'create-send-one-time-wire-form-card-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{ maxLength: 30 }}  defaultValue={context?.data?.body?.destination?.externalAccount?.holderAddress?.addressLine2} isEditable={!props.isEdit}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(findAddressValidations(watch))} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-send-one-time-wire-form-card-city-textbox" name="city" label="City" isEditable={!props.isEdit} defaultValue={context?.data?.body?.destination?.externalAccount?.holderAddress?.city} />
                          <div className={'error-message'}>
                            {errors.city && renderCityNameError(errors.city)}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: findAddressValidations(watch) }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-send-one-time-wire-form-card-state-textbox" name="state" data={usa_state} label="State" 
                            setValue={setValue} readOnly={props.isEdit} value={context?.data?.body?.destination?.externalAccount?.holderAddress?.state} placeholder="Select"/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(findAddressValidations(watch))} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-send-one-time-wire-form-card-zip-textbox" name="zip" label="Zip" isEditable={!props.isEdit} defaultValue={context?.data?.body?.destination?.externalAccount?.holderAddress?.zip} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors); }} onKeyDown={(e: any) => {  handleKeyDownChange(e, setValue);}}/>
                          <div className={'error-message'}>
                            {errors.zip && renderError(errors.zip)}
                          </div>
                        </Grid>
                      </Grid>
                    </fieldset>
                  </Grid>
                <Grid item xs={12} sm={12}>
                  <FxLabel>BANK ACCOUNT INFORMATION</FxLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FxMaterialSelect
                    register={{ ...register("accountType") }}
                    control={control}
                    rules={{ required: true }}
                    id="create-send-one-time-wire-form-card-account-type"
                    name="accountType"
                    data={accountType}
                    value={context?.data?.body?.destination?.externalAccount?.type || "SAVINGS"}
                    label="Account Type *"
                    setValue={setValue}
                    readOnly={!!props.isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FxTextEdit
                    register={{ ...register("accountNumber") }}
                    className={
                      errors.accountNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    rules={!props.isEdit ? setAccountNumberValidation : {}}
                    id="create-send-one-time-wire-form-card-amount-textbox"
                    label="Account Number *"
                    name="accountNumber"
                    type="text"
                    variant="outlined"
                    defaultValue={
                      context?.data?.body?.destination?.externalAccount?.accountNumberLast4
                        ? ('****'+context?.data?.body?.destination?.externalAccount?.accountNumberLast4)
                        : ""
                    }
                    isEditable={props.isEdit ? false : true}
                  />
                   <div className={"error-message"}>
                    {errors.accountNumber &&
                    renderAccountNumberError(errors.accountNumber)}
                  </div>
                </Grid>
                {(context?.data?.body?.destination || !scheduleId) && <Grid item xs={12} sm={6}>
                  <FxLabelView className="fx-label-top" id="create-send-one-time-wire-form-card-routing-number-label">Routing Number*</FxLabelView>
                  <FxAutoComplete
                    register={{ ...register("routingNumber") }}
                    control={control}
                    rules={setRoutingNumberValidation}
                    id="create-send-one-time-wire-form-card-routing-number"
                    name="routingNumber"
                    source={bank_src}
                    searchBy={"searchPattern"}
                    label="Routing Number *"
                    className={
                      errors.routingNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    value={
                      context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber
                        ? {
                          label:
                          context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber + " (" + context?.data?.body?.destination?.externalAccount?.bankInfo?.name + ")",
                          value:
                          context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber,
                        }
                        : null
                    }
                    dataTransformer={routingNumberTransformer}
                    setValue={setValue}
                    resetField={resetField}
                    setError={setError}
                    clearError={clearErrors}
                    defaultOperator={"eq"}
                    placeholder={"Routing Number"}
                    isEditable={(context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber) ? false : true}
                  />
                  <div className={"error-message"}>
                    {errors.routingNumber &&
                      renderRoutingNumberError(
                        errors.routingNumber
                      )}
                  </div>
                </Grid>}
                {(context?.data?.body?.destination || !scheduleId) && <Grid item xs={12} sm={6}>
                  <FxLabelView className="fx-label-top" id="create-send-one-time-wire-form-card-wire-routing-number-label">Wire Routing Number</FxLabelView>
                  <FxAutoComplete
                    register={{ ...register("wireRoutingNumber") }}
                    control={control}
                    rules={setRoutingNumberValidation}
                    id="create-send-one-time-wire-form-card-wire-routing-number"
                    name="wireRoutingNumber"
                    source={bank_src}
                    searchBy={"searchPattern"}
                    label="Wire Routing Number *"
                    className={
                      errors.wireRoutingNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    value={
                      context?.data?.body?.destination?.externalAccount?.wireRoutingNumber
                        ? {
                          label:
                          context?.data?.body?.destination?.externalAccount?.wireRoutingNumber  + " (" + context?.data?.body?.destination?.externalAccount?.wireBankInfo?.name + ")",
                          value:
                          context?.data?.body?.destination?.externalAccount?.wireRoutingNumber,
                        }
                        : null
                    }
                    dataTransformer={routingNumberTransformer}
                    setValue={setValue}
                    resetField={resetField}
                    setError={setError}
                    clearError={clearErrors}
                    defaultOperator={"eq"}
                    placeholder={"Wire Routing Number"}
                    isEditable={props.isEdit ? false : true}
                  />
                  <div className={"error-message"}>
                    {errors.wireRoutingNumber &&
                      renderRoutingNumberError(
                        errors.wireRoutingNumber
                      )}
                  </div>
                </Grid>}
              </>
            )}
          </Grid>
        </fieldset>
      </Grid>
      {businessCustomer && <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("memo") }}
          className={
            errors.memo
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          rules={{ required: false }}
          id="create-send-one-time-wire-form-card-memo-textbox"
          label="Memo"
          name="memo"
          variant="outlined"
          defaultValue={
            context?.data?.body?.processingDetail
              ?.memo
              ? context.data.body.processingDetail
                  .memo
              : ""
          }
        />
      </Grid>}
    </ScheduleLayout>
    </>
  );
});
