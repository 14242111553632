import React, { useEffect } from "react";
import { Grid, Typography, Checkbox, Menu } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxButton } from '../../Action/FxButton';
import {checkViewShouldBeDisabled, parseJsonData, updateComponentParams} from "../../Utils/CommonBaseClass";
import { RolesAccordion } from "./roles-accordion/RolesAccordian";

Logger.debug("RolePermissions.tsx", "Role information initializing");

export const RolePermissions: React.FC<any> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    /**
    * Method called before component loading
    * */
    useEffect(() => {
     dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        updateComponentParams(dispatch, props?.id, { RoleType: "Entity" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const params = useParams<any>();

    const id = params.id
  
     /**
     * Updating when source loaded
     */
    useEffect(() => {
        if(context?.data?.body){
            let permissionIds:any = [];
           context?.data?.body.restrictedEntity?.forEach((item: any) => {
                if(item.data){
                    let permIds = getPermissionIds(item.data,'value','true')
                    if(permIds && permIds.length > 0){
                        permissionIds= [...permissionIds, [...permIds]]
                    }
                }
             })
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "role-permissions-default-checked", data: permissionIds } });
            updateComponentParams(dispatch,"role-information", {
                roleName:context?.data?.body?.name, 
                roleDescription:context?.data?.body?.description,
                parentRole:context?.data?.body?.parentRole,
                rolesData:id === undefined ? context?.data?.body : props?.data
            })
        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.data?.body]);
    /**
     * Method to move the View element to first of the list
     * @param data 
     * @returns 
     */
    function moveArrayElement(data:Array<any>)
    {   try{
        const index= data.indexOf('VIEW')
        if(index !== -1)
        {
            const element = data.splice(index, 1)[0];
            data.splice(0,0,element);
        }
        return data;
    }
    catch(e){}
       
    }
    
    
    const [activeView, setActiveView] = React.useState('ENTITY');
    const [anchorEls, setAnchorFilterEl] = React.useState(null);
    const [isFilterDataLoading, setIsFilterDataLoading] = React.useState(false);
    const openFilter = Boolean(anchorEls);
    const buttonGroupOptions: any = [
        { id: 'entity', value: 'ENTITY', label: 'Entity', selected: activeView === 'ENTITY' },
    ]

    /**
     * handle button group click
     * @param item :data
     */
    const handleButtonGroupClick = (item: any) => {
        setActiveView(item?.value)
    }
     /**
     * handle menu close
     * @param item :data
     */
    const handleFilterClose = () => {
        setAnchorFilterEl(null);
    };

     /**
     * handle select all event
     * @param item :data
     */
    const handleSelectAllClick = () => {
        setIsFilterDataLoading(!isFilterDataLoading);
    };
    /**
     * Method to get the permission ids
     * @param data 
     * @param checkParam 
     * @param checkValue 
     * @returns 
     */
    function getPermissionIds(data: any, checkParam: any, checkValue: any) {
        const permissionIds: any = [];
        if (data) {
            data?.forEach((item: any) => {
                item.permissions.forEach((permission: any) => {
                    if (permission[checkParam] === checkValue) {
                        permissionIds.push(permission.id)
                    }
                })
            })
        }
        return permissionIds
    }
   
    /**
     * Method disable the view based on any other operation selected 
     * @param data : array contains the permissions
     * @returns : the array after disabling the view
     */
    const disableViewByDefault = (data: any) => {
        try{
            data?.forEach((item: any)=>{
                item.permissions?.forEach((permItem: any)=>{
                    if(permItem.operation === 'VIEW'){
                        permItem.disabled = checkViewShouldBeDisabled(item.permissions);
                    }
                })
            })
            return data;
        }
        catch(e){}
    }

    /**
     * Method for creating accordion
     * @returns 
     */
    function accordion()
    {
        const data:any=[]
          if(context?.data?.body)
            {
                let dataArray = props?.data === undefined ? parseJsonData(context.data.body.restrictedEntity) : parseJsonData(props?.data.restrictedEntity)
                dataArray=dataArray.filter((ele:any)=>ele.entity && ele.entity.toLowerCase() !== 'individual customer')
                if(id)
                {
                    let permissionIds:any = [];
                    context?.data?.body.restrictedEntity?.forEach((item: any) => {
                        if(item.data){
                            let permIds = getPermissionIds(item.data,'value','true')
                            if(permIds && permIds.length>0){
                                permissionIds= [...permissionIds, [...permIds]]
                            }
                        }
                     })
                    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "role-permissions-default-checked", data: permissionIds } });
                    
                }
                dataArray.forEach((option: any) => {
            if(option && option.operations)
            {
                option.operations=moveArrayElement(option.operations)
            }
            if(option?.entity)
            {
                updateComponentParams(dispatch, option.entity, { 'tableData': disableViewByDefault(option.data) })
            
            data.push( <div>
                {
                    <RolesAccordion valueChanged={props?.valueChanged} readOnly={props?.readOnly}
                        title={option.entity}
                        content={option.data}
                        columnName={option.operations}
                        id={option.entity}
                        isEdit={!props?.readOnly}
                    />

                }
            </div>)
            }

            })
        }
        return data
    }

    return (
        <>
            {<Grid item sm={12} xs={12}>
                <Grid >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography id="title-rolePermission" variant="h6">
                            Role Permission
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <div className="fx-roles-permission-buttons">
                                <Grid item className="fx-roles-permission-tabs">
                                    {buttonGroupOptions.map((item: any) => {
                                        return (
                                            <Grid id="roles-permission-buttons">
                                                <FxButton
                                                    className={item.selected ? 'fx-link-sel' : 'fx-link'}
                                                    id={item.label + '-roles-permission-button'}
                                                    onClick={() => handleButtonGroupClick(item)}
                                                >
                                                    {item.label}
                                                </FxButton>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <Menu
                                    id={props.id + '-menu'}
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button'
                                    }}
                                    anchorEl={anchorEls}
                                    open={openFilter}
                                    onClose={handleFilterClose}
                                    className="fx-menu-dotted"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                >
                                    {activeView === 'ENTITY' && context?.data?.body?.restrictedEntity?.map((option: any) => (
                                        <div id="checkbox-option">
                                            <Checkbox
                                                className={`fx-permissions-checkbox ${option?.show ? 'green' : ''}`}
                                                id="checkboxHead"
                                                value={option?.entity}
                                                color="primary"
                                                checked={option?.show}
                                                indeterminate={props.indeterminate}
                                                onChange={() =>
                                                    handleSelectAllClick()
                                                }
                                                inputProps={option}
                                            />
                                            {option?.entity}
                                        </div>
                                    ))}
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    className="fx-form-edit-profile flex column"
                >
                    {activeView === 'ENTITY' && (
                            <div className="accordion">
                                {accordion()}
                             </div>
                        )}
                </Grid>
            </Grid>}
        </>
    );
	
});
