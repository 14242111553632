/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxBreadCrumbs from '../../Navigation/FxBreadCrumbs';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { handleKeyDownChange, handleZipChange, renderError, setZipValidationOptions } from '../../Utils/CommonBaseClass';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("EditMailingAddress.tsx", "edit mailing address initializing")

/**
 * Component used to edit mailing address of the customer
 */
export const EditMailingAddress: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();

        const params = useParams<any>();
        let id = params.id;
        let context: any;
        ({ context, props } = RegisterComponent(props));

        const page_menu = [
            {
                title: 'Dashboard',
                link: '/'
            },
            {
                title: 'My Mailing Address',
                link: '/mailingaddresses',
            },
            {
                title: 'Edit Address',
                link: '#',
            }
        ]



        const [primaryFlag, setPrimaryFlag] = useState(false);
        const [primaryMailingAddress, setPrimaryMailingAddress] = useState(false);
        /**
         * Getting data from context
         */
        if (context && context.data && context.data.body && !primaryFlag) {
            setValue('addressLine1',context.data.body.addressLine1);
            setValue('addressLine2',context.data.body.addressLine2);
            setValue('city',context.data.body.city);
            setValue('zip',context.data.body.zip);
            setPrimaryMailingAddress(context.data.body.isPrimary)
            setPrimaryFlag(true)
        }
        const dispatch = useDispatch()

        const primaryAddressData = [
            { label: 'Set as Primary Address', value: 'primaryAddress', checked: primaryMailingAddress },
        ];

        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        /**
         * Async method used to call update API
         * @param payloadData :request
         * @returns
         */
        async function updateMailingAddress(payloadData: any,id:any) {
            try {
                const url = '/mailingAddress/id/'+id
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CustomerEditProfile.tsx", "error", err);
                return err;
            }
        }
        /**
         * Method used to clear form data
         * @param obj :form data
         * @returns
         */
        function clean(obj: any) {
            for (const propName in obj) {
                if (obj[propName] == null || obj[propName] === undefined || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }
        /**
         * Method used to format request and call API
         * @param data :form data
         */
        async function onSubmit(data: any) {

            data = clean(data);
            const request: any = {
                'addressLine1': data.addressLine1,
                'addressLine2': data.addressLine2,
                'city': data.city,
                'state': data.state,
                'zip': data.zip,
                'isPrimary': primaryMailingAddress,
                'usage' : {
                    'isPayorAddress': true
                }
            }
            let status: any = await updateMailingAddress(request,id);
            status = processAPIResponse(status)
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Mailing Address Updated Successfully!',
                });
                history.push("/mailingaddresses");
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        const history = useHistory();

        /**
         * Method to handle checkbox change event
         * @param event :event object
         */
         const handleSettings = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target?.type == 'checkbox') {
                if (event.target.value == 'primaryAddress') {
                    setPrimaryMailingAddress(event.target.checked)
                }
            }
        };

        return (
            <>
            {primaryFlag&&<Grid container xs={8} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <FxBreadCrumbs menuItems={page_menu} id="edit-mailing-address-breadcrumbs" />
                <Grid id="my-mailing-address-Grid" item xs={12}>
                    <Grid container spacing={1} >
                        <FxCard id="my-mailing-address-card" className="fx-theme-passport">
                            {context?.data?.body && <form onSubmit={handleSubmit(onSubmit)}>
                                <FxCardHeader id={'my-mailing-address-card#card-header'}>
                                    <Grid id="my-mailing-address-Grid2" item container justifyContent="space-between" xs={12} >
                                        <Typography id="title-customer-profile-edit-info" variant="h4" >Edit Mailing Address</Typography>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody id="my-mailing-address-card#card-body" className="fx-info-card" >
                                    <Grid container direction="row" spacing={2} className="fx-form-edit-profile flex column" >
                                        <Grid item xs={12}>
                                            <Typography variant="h5" title="MAILING ADDRESS" id="customer-profile-edit-contact-info" className="">MAILING ADDRESS</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-address-addressLine1" label="Address Line 1*" name="addressLine1" variant="outlined" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-address-addressLine2" label="Address Line 2" name="addressLine2" variant="outlined" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-address-city" label="City*" name="city" variant="outlined"/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FxSelectAutoSearch register={{ ...register("state") }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} control={control} id="state" name="state" data={usa_state} label="State*" value={context?.data?.body?.state} setValue={setValue} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="edit-address-zip" label="ZIP*" name="zip" variant="outlined" onChange={(e: any) => { handleZipChange(e,setValue,setError,clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e,setValue) }} />
                                            <div className={'error-message'}>
                                                {errors.zip && renderError(errors.zip)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                    <FxCheckEdit register={{ ...register("setPrimary") }} control={control} onChange={handleSettings} data={primaryAddressData} id="set-primary-mailing-address" name="setPrimary" label="Set as Primary Address" row="vertical" setValue={setValue} />
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="my-mailing-address-card-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                        <FxButton
                                            variant="contained"
                                            className="fx-button"
                                            id="cancel-edit-mailing-address"
                                            onClick={() => history.push('/mailingaddresses')}
                                        > Cancel
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id="save-edit-mailing-address-btn"
                                            type="submit"
                                        > Save Changes
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>

                            </form>
                            }
                        </FxCard>
                    </Grid>
                </Grid >
            </Grid >}
            </>

        )
    })

let usa_state: any = [
    { "value": "AL", "label": "Alabama" },
    { "value": "AK", "label": "Alaska" },
    { "value": "AS", "label": "American Samoa" },
    { "value": "AZ", "label": "Arizona" },
    { "value": "AR", "label": "Arkansas" },
    { "value": "CA", "label": "California" },
    { "value": "CO", "label": "Colorado" },
    { "value": "CT", "label": "Connecticut" },
    { "value": "DE", "label": "Delaware" },
    { "value": "DC", "label": "District Of Columbia" },
    { "value": "FM", "label": "Federated States Of Micronesia" },
    { "value": "FL", "label": "Florida" },
    { "value": "GA", "label": "Georgia" },
    { "value": "GU", "label": "Guam" },
    { "value": "HI", "label": "Hawaii" },
    { "value": "value", "label": "Idaho" },
    { "value": "IL", "label": "Illinois" },
    { "value": "IN", "label": "Indiana" },
    { "value": "IA", "label": "Iowa" },
    { "value": "KS", "label": "Kansas" },
    { "value": "KY", "label": "Kentucky" },
    { "value": "LA", "label": "Louisiana" },
    { "value": "ME", "label": "Maine" },
    { "value": "MH", "label": "Marshall Islands" },
    { "value": "MD", "label": "Maryland" },
    { "value": "MA", "label": "Massachusetts" },
    { "value": "MI", "label": "Michigan" },
    { "value": "MN", "label": "Minnesota" },
    { "value": "UM", "label": "Minor Outlying Islands" },
    { "value": "MS", "label": "Mississippi" },
    { "value": "MO", "label": "Missouri" },
    { "value": "MT", "label": "Montana" },
    { "value": "NE", "label": "Nebraska" },
    { "value": "NV", "label": "Nevada" },
    { "value": "NH", "label": "New Hampshire" },
    { "value": "NJ", "label": "New Jersey" },
    { "value": "NM", "label": "New Mexico" },
    { "value": "NY", "label": "New York" },
    { "value": "NC", "label": "North Carolina" },
    { "value": "ND", "label": "North Dakota" },
    { "value": "MP", "label": "Northern Mariana Islands" },
    { "value": "OH", "label": "Ohio" },
    { "value": "OK", "label": "Oklahoma" },
    { "value": "OR", "label": "Oregon" },
    { "value": "PW", "label": "Palau" },
    { "value": "PA", "label": "Pennsylvania" },
    { "value": "PR", "label": "Puerto Rico" },
    { "value": "RI", "label": "Rhode Island" },
    { "value": "SC", "label": "South Carolina" },
    { "value": "SD", "label": "South Dakota" },
    { "value": "TN", "label": "Tennessee" },
    { "value": "TX", "label": "Texas" },
    { "value": "UT", "label": "Utah" },
    { "value": "VT", "label": "Vermont" },
    { "value": "VA", "label": "Virginia" },
    { "value": "VI", "label": "Virgin Islands" },
    { "value": "WA", "label": "Washington" },
    { "value": "WV", "label": "West Virginia" },
    { "value": "WI", "label": "Wisconsin" },
    { "value": "WY", "label": "Wyoming" }
]
