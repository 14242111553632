
const initialState: any = {

};

/**
 * Component reducer from dynamic page creation
 * Not required for static defined projects
 */

const componentReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "COMPONENT_CREATE":
      state[action.value.payload.id] = {
        config: action.value.payload.props,
        data: {}
      };
      return state;

    case "COMPONENT_CONTEXT_RESET":
      if (!(state.page) || (state.page.id !== action.payload.id)) {
        state = initialState;
      }
      return state;

    case "COMPONENT_UPDATE_CONFIG":
      //update the config of component at root level;
      state[action.payload.id] = { ...state[action.payload.id], ...action.payload.config, ...action.payload.data }
      return state;

    case "COMPONENT_UPDATE_SOURCE_STATUS":
      state[action.payload.id].config.source.status = 0;
      //update the config of component at root level;
      state[action.payload.id] = { ...state[action.payload.id] }
      return state;

    case "COMPONENT_UPDATE_CONFIG_SOURCE":
      //update the source
      let finalData = state[action.payload.id]

      //source object
      let compSource = state[action.payload.id].config.source
      compSource = { ...compSource, ...action.payload.source }
      state[action.payload.id].config.source = compSource
      state[action.payload.id].data.source = null
      finalData = state[action.payload.id]

      //clone property to reflect the change
      state[action.payload.id] = { ...finalData }
      return state;


    case "COMPONENT_CONTEXT_INITIALIZE":
      state = { ...action.payload.context, page: { id: action.payload.id, status: 2 } };

      return state;

    //this will update api callback data to components data node.
    case "CONTEXT_COMPONENT_UPDATE_DATA":
      state[action.payload.id].config.source.status = action.payload.status ? action.payload.status : 1; // should only call once.
      state[action.payload.id].config.source.key = action.payload.key ? action.payload.key : null;
      if (!action.payload.node) {
        action.payload.node = 'source';
      }
      if (action.payload.node) {
        let nodeData = {
          [action.payload.node]: action.payload.data
        }
        action.payload.data = nodeData;
      }
      state[action.payload.id] = {
        data: action.payload.data,
        config: state[action.payload.id].config
      };
      return state;
    case "CONTEXT_COMPONENT_UPDATE_PARAM":
      const updatedState: any = { ...state[action.payload.id] };
      updatedState.config.params = {
        ...updatedState.config.params,
        ...action.payload.data
      };
      state[action.payload.id] = updatedState;
      return state;
    case "SET_MANUAL_CONTEXT":
      const newState = { config: action.value, data: {} };
      return { ...state, [action.value.id]: newState };
    case "SET_MANUAL_CONTEXT_HARD":
      delete (state[action.value.id]);
      return state;
    case "APPEND_DATA":
      state[action.payload.id].data = [...state[action.payload.id].data, ...action.payload.data];

      return state;
    default:
      return state;
  }
};

export default componentReducer;

/**
 *  [load_page]  (saga)
 *      [load_page_json] (saga)
 *        [load_page_json_ready]  (if json is avaiable)
 *          [API_CALL] (request of page-json) , "load_page_json_api_done", "page-fri"
 *          [load_page_json_api_done] (api data and set for right page fri)
 *              <PAGE_JSON_SET>
 *          [API_CALL] (request of layout-json) , "load_page_json_api_done", "layout-fri" , "page-fri"
 *              <LAYOUT_JSON_SET>
 *          [load_page_json_api_done] (api data and set for right layout fri)  will merge both JSON and update page JSON
 *        [load_page_json_ready]
 *
 *  [load_page_json_ready]
 *    [page_load_generate_context] fri,
 *      <PAGE_CONTEXT_INITIALIZE> pagestatus = READY
 *
 */
