import React from 'react'; // we need this to make JSX compile
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { Grid, Typography } from '@mui/material';
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { addressFormatter, maskAccountNumber, truncateChars } from '../../../Utils/CommonBaseClass';
import { getKey } from '../../../../libs/utils/storageManager';
import { LogoAccount } from '../../../Utils/Logo';
import { ViewVirtualCardImage } from '../ViewVirtualCardImage';

Logger.debug("ScheduleDetailSummaryCard.tsx", "Schedule Detail Summary Card initializing");

/**
 * Component: ScheduleDetailSummaryCard
 * Usage: To show schedule processing details
 */

export const ScheduleDetailSummaryCard: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const destinationInfo: any = props?.destinationInfo?.data?.body;
        let schedule_info: any = {};
        if (props && props.data) {
            schedule_info = { ...props.data };
        }

        /**
         * function to handle Processing Summary data
         */
        function processingSummaryDataTranformer(data: any) {
            try {
              return {
                ...data,
                pendingCaptureAmount: data?.pendingCaptureAmount ? '$' + data?.pendingCaptureAmount : '$0.00',
                traceNumber: data?.processingDetail?.traceNumber || '-'
              };
            } catch (e) {
              return data;
            }
          }

        const scheduleId = props?.data?.id;
        const processing_details_section: any = [
            {
                label: 'Transaction Date',
                value: 'scheduleDate',
                type: 'fri://fieldtype/date'
            },
            {
                label: 'Processing Date',
                value: 'processDate',
                type: "fri://fieldtype/date"
            },
            {
                label: 'Status Date',
                value: 'statusDate',
                type: 'fri://fieldtype/date'
            },
            {
                label: 'Trace Number',
                value: 'traceNumber',
            }

        ];
        const processing_details_card_section: any = [
            {
                label: 'Pending Capture Amount',
                value: 'pendingCaptureAmount',
            }
        ];

        
        // remove trace number node in case of crad transaction (Collect via contact)
        if(schedule_info?.source?.contact?.card) {
            processing_details_section.pop();
        }

        if (schedule_info && schedule_info.method === 'CHECK' && schedule_info?.source?.account?.id !== getKey('selectedAccount')?.id) {
            schedule_info.sourceInfo = schedule_info?.source?.externalAccount;
        }
        if (props.data.method === 'CHECK' && schedule_info?.source?.account?.id === getKey('selectedAccount')?.id) {
            processing_details_section.push({ label: 'Check Number', value: 'processingDetail.checkNumber' }, { label: 'Tracking ID', value: 'processingDetail.trackingId' }, { label: 'Shipped by', value: 'processingDetail.shippedBy' })
        }


        /**
        // to get external account detail (name and acocunt number) for collect via contact ACH method
        * @param externalAccountId : contact externalAccount ID in transaction
        * @param contactExternalAccounts : contact externalAccount Accounts Array
        */
        function externalAccountDetail(externalAccountId: number, contactExternalAccounts: any[]) {
            if((schedule_info?.sourceInfo)) {
                let contactSelectedExternalAccount = contactExternalAccounts?.find(eaDetail => eaDetail.id === externalAccountId);
                if(contactSelectedExternalAccount) {
                    return <>
                            {contactSelectedExternalAccount.bankInfo.name}
                            <br/> 
                            {maskAccountNumber(contactSelectedExternalAccount.accountNumberLast4, false)}
                        </>
                }
                return '';
            }
            return '';
        }

        /**
        // to get external account detail (name and card number) for collect via contact CARD method
        * @param cardId : contact card ID in transaction
        * @param contactCards : contact Cards Array
        */
        function getContactCardDetail(cardId: number, contactCards: any[]) {
            if((schedule_info?.sourceInfo)) {
                let selectedContactCardDetail = contactCards?.find(eaDetail => eaDetail.id === cardId);
                if(selectedContactCardDetail) {
                    return <>
                            {selectedContactCardDetail?.holderName || ' '}
                            <br/> 
                            {maskAccountNumber(selectedContactCardDetail.cardNumberLast4, false)}
                        </>
                }
                return '';
            }
            return '';
        }

        
        return (
            <Grid id="schedule-detail-summary-card-Grid" item xs={12}>
                <FxCard id="schedule-detail-summary-card" className="fx-info-card fx-theme-passport">
                    <FxCardHeader id={'schedule-detail-summary-card#card-header'}></FxCardHeader>
                    <FxCardBody id="schedule-detail-summary-card#source-body" className="fx-margin-top-reducer fx-transaction-summery">
                        <Grid container direction="row" spacing={2} className="fx-transaction-summery-currency">
                            <FxCurrencyView tooltip={true} id={'schedule-detail-summary-card-header-amount'} value={schedule_info?.amount} prefix={"$"}></FxCurrencyView>
                            <Grid item xs={12}>
                                <Typography id="linked-reference" className="fx-transaction-summery-ref" >{schedule_info?.externalReference}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container sm={12} xs={12} className="fx-transaction-summery-dir">
                            <Grid item sm={1} xs={1} className={'fx-transaction-summery-progress-line'}>
                            </Grid>
                            <Grid item sm={11} xs={11}>
                                <Grid item xs={12}>
                                    <Typography id="schedule-detail-summary-card#source-header" variant="h5">
                                        FROM
                                    </Typography>
                                    <Grid item sm={12} xs={12} >
                                        {schedule_info?.source?.account?.id === getKey('selectedAccount')?.id ?
                                            <Grid item container alignItems='center'>
                                                <Grid item className={'fx-transaction-summery-passport-logo'}>
                                                    <LogoAccount /> &nbsp;
                                                </Grid>
                                                <Grid item>
                                                    <Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                        {(getKey('selectedAccount')?.nickName ? getKey('selectedAccount').nickName : '') + ' A/c ' + maskAccountNumber(schedule_info?.sourceInfo?.accountNumber, false)}
                                                    </Typography>
                                                </Grid>
                                            </Grid> : schedule_info?.sourceInfo?.resourceName === "account" ?
                                                <Grid item container alignItems='center'>
                                                    <Grid item className={'fx-transaction-summery-passport-logo'}>
                                                        <LogoAccount /> &nbsp;
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                            {(schedule_info?.sourceInfo?.nickName ? schedule_info?.sourceInfo?.nickName : '') + ' A/c ' + maskAccountNumber(schedule_info?.sourceInfo?.accountNumber, false)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid> :
                                                <>
                                                    <Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                        {schedule_info?.sourceInfo === undefined ? (schedule_info?.source?.card ? schedule_info?.source?.card.holderName : schedule_info?.source?.externalAccount?.bankInfo ? schedule_info?.source?.externalAccount?.bankInfo?.name : schedule_info?.processingDetail?.checkVerification ? 'CHECK' : schedule_info?.source?.externalAccount?.bankName ? schedule_info?.source?.externalAccount?.bankName : (schedule_info?.method === 'CARD' && schedule_info.type === 'EXTERNAL') ?'External':'') : schedule_info?.sourceInfo?.bankName ? schedule_info?.sourceInfo?.bankName : schedule_info?.sourceInfo?.cardNumberLast4 ? 'CARD' : (schedule_info?.sourceInfo?.bankInfo?.name)}
                                                    </Typography>
                                                    <Typography id="schedule-detail-summary-card-bank-name" variant="h4">
                                                        {schedule_info?.sourceInfo === undefined ? (schedule_info?.source?.card ? maskAccountNumber(schedule_info?.source?.card?.cardNumberLast4, false) : schedule_info?.source?.externalAccount?.bankInfo ? maskAccountNumber(schedule_info?.source?.externalAccount?.accountNumberLast4, false) : schedule_info?.processingDetail?.checkVerification ? maskAccountNumber(schedule_info?.processingDetail?.checkVerification?.checkDetail?.checkNumber,false) : schedule_info?.source?.externalAccount?.bankName ? maskAccountNumber(schedule_info?.source?.externalAccount?.accountNumberLast4, false) : '') : schedule_info?.sourceInfo?.cardNumberLast4 ? maskAccountNumber(schedule_info?.sourceInfo?.cardNumberLast4,false) : maskAccountNumber(schedule_info?.sourceInfo?.accountNumberLast4, false)}
                                                    </Typography>
                                                    {schedule_info?.source?.contact?.externalAccount && <Typography id="schedule-detail-summary-card-bank-name" variant="h4">
                                                        {externalAccountDetail(schedule_info?.source?.contact?.externalAccount.id, schedule_info?.sourceInfo?.externalAccount)}
                                                    </Typography>}

                                                    {schedule_info?.source?.contact?.card && <Typography id="schedule-detail-summary-card-bank-name" variant="h4">
                                                        {getContactCardDetail(schedule_info?.source?.contact?.card.id, schedule_info?.sourceInfo?.card)}
                                                    </Typography>}
                                                </>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>&nbsp;</Grid>
                                <Grid item xs={12}>
                                    <Typography id="schedule-detail-summary-card#source-header" variant="h5" >
                                        TO
                                    </Typography>

                                    {schedule_info?.destinationInfo?.resourceName === "account" ?
                                        <Grid item container alignItems='center'>
                                            <Grid item className={'fx-transaction-summery-passport-logo'}>
                                                <LogoAccount /> &nbsp;
                                            </Grid>
                                            <Grid item>
                                                <Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                    {(schedule_info?.destinationInfo?.nickName ? schedule_info?.destinationInfo?.nickName : '') + ' A/c ' + maskAccountNumber(schedule_info?.destinationInfo?.accountNumber, false)}
                                                </Typography>
                                            </Grid>
                                        </Grid> : schedule_info?.source?.account?.id === getKey('selectedAccount')?.id ? <>
                                            {schedule_info?.destination?.contact?.card ? <Typography id="refund-card-detail" variant="h4">{getContactCardDetail(schedule_info?.destination?.contact?.card?.id, schedule_info?.destinationInfo?.card)}</Typography> : 
                                                (schedule_info?.destination?.ppi || schedule_info?.destination?.contact?.ppi) ? <Typography id="schedule-detail-summary-card-ppi" variant="h4" title={schedule_info?.destination?.ppi ? schedule_info?.destination?.ppi : schedule_info?.destination?.contact?.ppi}>
                                                {schedule_info?.destination?.ppi ? truncateChars(schedule_info?.destination?.ppi, 40) : truncateChars(schedule_info?.destination?.contact?.ppi, 40)}
                                            </Typography> :
                                                <><Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                    {schedule_info?.destinationInfo?.bankInfo ? schedule_info?.destinationInfo?.bankInfo?.name : ((schedule_info?.destinationInfo === undefined && schedule_info?.destination?.externalAccount?.bankInfo) ? schedule_info?.destination?.externalAccount?.bankInfo?.name : (schedule_info?.destinationInfo === undefined && schedule_info?.destination?.internationalExternalAccount)? schedule_info?.destination?.internationalExternalAccount?.accountNumberLast4 : (schedule_info?.method === 'CARD' && schedule_info.type === 'REFUND' && !schedule_info?.destinationInfo) ?'External':'')}
                                                </Typography>
                                                    <Typography id="schedule-detail-summary-card-bank-name-account-number" variant="h4">
                                                        {(schedule_info?.method === 'CHECK' && schedule_info?.destinationInfo) ? addressFormatter("", schedule_info?.destinationInfo) :
                                                            (schedule_info?.method !== 'BOOK' && schedule_info?.method !== 'CHECK') ? ((schedule_info?.destinationInfo === undefined && schedule_info?.destination?.externalAccount?.accountNumberLast4) ? maskAccountNumber(schedule_info?.destination?.externalAccount?.accountNumberLast4, false) : schedule_info?.destinationInfo?.accountNumberLast4 ? maskAccountNumber(schedule_info?.destinationInfo?.accountNumberLast4, false) :
                                                                maskAccountNumber(schedule_info?.destinationInfo?.accountNumber, false)) :
                                                                maskAccountNumber(schedule_info?.destinationInfo?.accountNumber, false)}

                                                        {schedule_info?.method === 'VIRTUAL_CARD' && schedule_info?.destination?.email &&
                                                            schedule_info?.destination?.email
                                                        }

                                                        {schedule_info?.method === 'VIRTUAL_CARD' && !schedule_info?.destination?.email &&
                                                            destinationInfo?.name
                                                        }

                                                        {
                                                            schedule_info?.method === 'BOOK' && schedule_info?.feeAgainstTransaction && <Typography id="schedule-detail-summary-book-bank-name" variant="h4">Passport</Typography>
                                                        }
                                                    </Typography>
                                                    {

                                                    }
                                                </>}
                                        </> : <>
                                            <Grid item container alignItems='center'>
                                                <Grid item className={'fx-transaction-summery-passport-logo'}>
                                                    <LogoAccount /> &nbsp;
                                                </Grid>
                                                <Grid item>
                                                    <Typography id="schedule-detail-summary-card-bank-name" variant="h4" >
                                                        {(getKey('selectedAccount')?.nickName ? getKey('selectedAccount').nickName : '') + ' A/c ' + maskAccountNumber(schedule_info?.destinationInfo?.accountNumber, false)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        { schedule_info?.method === 'VIRTUAL_CARD' && <Grid container direction="row" >
                            <ViewVirtualCardImage id="view-virtual-card-image" data={schedule_info} />
                        </Grid>
                        }
                        {schedule_info?.method === 'CARD' && schedule_info?.isAutoCapture === false && <Grid container direction="row" spacing={2} className="fx-transaction-summery-details">
                            <Grid item xs={12}>
                                <div className="schedule-detail-summary-card-divider"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id="schedule-detail-summary-card#processing-header" className="fx-transaction-summery-details-title" >
                                    Summary
                                </Typography>
                            </Grid>
                            <Grid item key="schedule-processing-info-container" direction="row" xs={12}>
                                <FxInfoCard notiltle={true} id={"section-info-regular-card-processing-details" + scheduleId} column={processing_details_card_section} data={processingSummaryDataTranformer(props?.data)} ></FxInfoCard>
                            </Grid>
                        </Grid>}

                        {(schedule_info?.method !== 'CARD' || (schedule_info?.method === 'CARD' && schedule_info?.type==='EXTERNAL' ))&& <Grid container direction="row" spacing={2} className="fx-transaction-summery-details">
                            <Grid item xs={12}>
                                <div className="schedule-detail-summary-card-divider"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id="schedule-detail-summary-card#processing-header" className="fx-transaction-summery-details-title" >
                                    Processing Summary
                                </Typography>
                            </Grid>
                            <Grid item key="schedule-processing-info-container" direction="row" xs={12}>
                                <FxInfoCard notiltle={true} id={"section-info-card-processing-details" + scheduleId} column={processing_details_section} data={processingSummaryDataTranformer(props?.data)} ></FxInfoCard>
                            </Grid>
                        </Grid>}
                      
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    })
