import React, { useState } from "react"; // we need this to make JSX compile
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import FxCard from "../../../Container/FxCard";
import FxCardHeader from "../../../Container/FxCardHeader";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardFooter from "../../../Container/FxCardFooter";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as ThreeDotMenu } from "../../../../assets/svg/threedot-menu-icon.svg";
import { VoidCollect } from "../../Schedules/VoidCollect";
import FxModalGeneric from "../../../Utils/FxModalGeneric";
import { RefundCollect } from "../../Schedules/RefundCollect";
import { FxStatus } from "../../../Input/FxStatus/FxStatus";
import moment from "moment";
import { ReactComponent as DividerIcon } from "../../../../assets/svg/divider-icon.svg";


interface FxSalesCardProps {
  id: string;
  salesData?: any;
  scheduleId?: any;
  transactionType?: any
}


export const SalesInfo: React.FC<FxSalesCardProps> = React.memo((props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRefund, setOpenRefund] = useState(false);
  const [menuOptions, setMenuOptions] = useState<any>();
  const open = Boolean(anchorEl);
  const [voidOpen, setVoidOpen] = useState(false);
  const [captureData, setCaptureData] = useState({});
/**
 * Method handles the onclick event in the 3-dot icon options
 * @param event : event parameter
 */
  const handleOptionClick = async (event: any) => {
    if (event === "Void") {
      setVoidOpen(true);
    } else if (event === "Refund") {
      setOpenRefund(true);
    }

    handleClose();
  };
  /**
   * Method handles the menu options
   */
  const getMenuOptions = (data: any) => {
    const options = [];
    if (data?.status === "COMPLETED") {
      options.push({ id: "refund-sale", title: "Refund" });
    } else  {
      options.push({ id: "void-sale", title: "Void" });
    }
    setMenuOptions(options);
  };

  /**
   * Method handles the closing of the menus
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Method handles the onclick event in the 3-dot icon
   * @param event : event parameter
   * @param data :  corresponding menu data
   */
  const handleMenuButtonClick = (event: any, data: any) => {
    setCaptureData(data);
    setAnchorEl(event.currentTarget);
    getMenuOptions(data);
  };
 /**
  * this is used to show the listing of sales capture per transactions
  */
  const renderCaptureListingNode = (item:any, index:number) => {
    return <Grid container xs={12} id={"capture-list-card-item-"+index} marginBottom={'0.8rem'}>
      <Grid container xs={12} alignItems={'center'} className={"fx-capture-list-card-header"}>
          Capture ID : {item?.id}
      </Grid>

      <Grid container xs={12} alignItems={'center'} className={"fx-capture-list-card-body"}>
            {/** Amount  */}
            <Grid item xs={4} justifyContent={'flex-start'} className={"fx-capture-list-card-body-amount"}>
              Amount: ${item?.amount}
            </Grid>

            {/** Status + Date + Actions */}
            <Grid item xs={8} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={'0.5rem'}>
              {/** Status */}
              <Grid>
                <FxStatus id="capture-list-card-body-status-tab" value={item?.status} fill={true} className="fx-status"/>
              </Grid>

              {/** Date */} 
              <Grid className="fx-capture-list-card-body-date-text">
                On {moment(new Date(item?.transactionDate)).format('MMM DD, YYYY')}
              </Grid>

              {/** Action Buttons */}
              <Grid marginLeft={'0.5rem'}>
               <Grid item>
                  { item?.status!=='VOIDED' && <FxButton
                        id={item["id"] + "-list-actions"}
                        title=""
                        className="fx-button fx-button-dotted fx-button-dotted-icons"
                        aria-label="more"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e: any) => handleMenuButtonClick(e, item)}
                      >
                        <ThreeDotMenu />
                      </FxButton>
                  }
                    </Grid>
                    <Grid item>
                      <Menu
                        MenuListProps={{
                            'aria-labelledby': 'account-list-menu',
                        }}
                        className="fx-menu-dotted"
                        id={item["id"] + "-list-actions-menu"}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                      >
                        {(menuOptions?.length > 0) && menuOptions?.map((option: any) => (
                            <>
                              <MenuItem id={option.id} key={option} onClick={() => { handleOptionClick(option.title) }}>
                                  <div className={option.className}>{option.title}</div>
                              </MenuItem>
                            </>
                        ))}
                      </Menu>
                    </Grid>
                </Grid>
            </Grid>
      </Grid>
    </Grid>
    
  }

  return (
    <Grid id="sales-info-capture-grid" item xs={12} >
      <FxCard id="sales-info-capture-card" className="fx-theme-passport">
        <FxModalGeneric
          id="sales-refund-popup"
          open={openRefund}
          onClose={() => { setOpenRefund(false); }}
          componentProps={{ scheduleId: props?.scheduleId, captureData: captureData, transactionType: props?.transactionType,   close: () => { setOpenRefund(false); } }}
          className={'fx-modal-small2medium'}
          title={'Are you sure you want to Refund?'}
          component={RefundCollect}>
        </FxModalGeneric>
        <FxModalGeneric
          id="sales-void-popup"
          open={voidOpen}
          onClose={() => { setVoidOpen(false); }}
          componentProps={{ scheduleId: props?.scheduleId, captureData: captureData, transactionType: props?.transactionType , close: () => { setVoidOpen(false); } }}
          className={'fx-modal-small2medium'}
          component={VoidCollect}>
        </FxModalGeneric>
        <FxCardHeader id={"sales-info-capture-card-header"}>
          <Grid
            id="sales-info-capture-card-header-grid"
            item
            container
            justifyContent="space-between"
            xs={12}
          >
            <Typography id="sales-info-capture-card-title" variant="h4">
              Captures
            </Typography>
            <span className="fx-capture-list-card-divider">
                <DividerIcon />
            </span>
          </Grid>
        </FxCardHeader>
        <FxCardBody
          id="sales-info-capture-card-body"
          className="fx-card-body-no-border-no-shadow fx-capture-listing-main-grid"
        >
          {
          props?.salesData?.map((object:any, index:number) => {
            return renderCaptureListingNode(object, index);
          })
          }
        </FxCardBody>
        <FxCardFooter id="sales-info-capture-card-footer" className="fx-footer">
          <Grid></Grid>
        </FxCardFooter>
      </FxCard>
    </Grid>
  );
});
