import React from 'react';
import { IFxTopBanner } from './FxTopBannerController';
import { FxTopBannerContentType, FxTopBannerController } from './FxTopBannerController';

/**
 * Component for showing top banner with content
 * Usage
 * Render only once in the root component
 * Call show() or closeForce() static methods from everywhere
 */
export default class FxTopBanner extends React.Component {
    private static _fxTopBannerRef: IFxTopBanner | null = null;

    public static show = (Content: FxTopBannerContentType) => {
        this._fxTopBannerRef?.show(Content);
    }

    public static closeForce = (id:string) => {
        this._fxTopBannerRef?.closeForce(id);
    }

    public static storeData = (data: any) => {
        this._fxTopBannerRef?.bannerData(data);
    }

    render = () => {
        return (
            <FxTopBannerController
                ref={(ref) => FxTopBanner._fxTopBannerRef = ref}
            />
        );
    }
}
