import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardHeader from '../../../Container/FxCardHeader';
import { AccountInfo } from './AccountInfo';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { getKey } from '../../../../libs/utils/storageManager';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { FxButton } from '../../../Action/FxButton';
import { AccountStatementDownload } from './AccountStatementDownload';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/download-home.svg';
import { useIsMobileOrTabletScreen } from '../../../../libs/utils/utils';
import { AvailableBalanceChart } from './AvailableBalanceChart';
import clsx from "clsx";

const accountStatementPermission = {
  entity: "Accounts",
  name: "Statement",
  operation: "Download"
}
/**
 * This component manages the rendering of the account info card, account insight card and debit card.
 */

export const MyAccount: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context } = RegisterComponent(props));
  const isMobileScreen = useMediaQuery({ query: '(max-width: 900px)' })
  let currentAccountId: any;
  let currentAccountNumber: any;
  let accountData: any;
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const isSmallScreen = useIsMobileOrTabletScreen();

  /**
   * checking account number in the context data
   */
  if (context && context.data && context.data.accountNumber) {
    accountData = context.data;
    currentAccountId = context.data.id
    currentAccountNumber = context.data.accountNumber
    dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'home-ledger-list', 'account': currentAccountId } });
  }

  /**
  * Function to reset the component
  */
  useEffect(() => () => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-my-account-card-account-info-card' } });
    updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true })
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-quick-transfer-card' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'debit-card-link-passport' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-ledger-list' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-transaction-activity-list' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-passport-payment-id' } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const account: any = currentAccountId ? { id: currentAccountId, accountNumber: currentAccountNumber } : null;

   /**
   * Method handles the onclick event for opening modal
   */
   const handleOpen = () => {
    setOpen(true);
  };
   /**
   * Method handles the closing for account statement
   */
  const handleAccountStatementClose = () => {
    setOpen(false);
  }

  return (
    <>
    <Grid id="home-my-account-grid" item xs={12}  container className="fx-account-insight">
      <FxCard className={clsx("fx-theme-passport fx-account-summary-card", !isSmallScreen ? 'fx-account-summary-card-accountgraph':'')}>
        <FxCardHeader>
          <Grid item container xs={12} justifyContent="space-between" className='fx-account-summary-card-accountgraph-header'>
            <Grid item className='fx-account-insight-title'>
              <Typography variant='h3'>Overview</Typography>
            </Grid>
            <Grid item>
              {!isMobileScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status!=='INACTIVE' &&
              <FxButton
                          permissions={accountStatementPermission}
                          variant="contained"
                          className="fx-account-insight-ac-statement-btn "
                          title='Download Statement'
                          id="account-statement"
                          onClick={handleOpen}
              > <DownloadIcon/> <span className='fx-account-insight-ac-statement'> A/C STATEMENT</span></FxButton>}
            </Grid>
          </Grid>
        </FxCardHeader>
        <FxCardBody>
          <Grid item container xs={12} sm={12} spacing={!isSmallScreen ? 0: 4}>
            <Grid item xs={isMobileScreen ? 12 : 5} className={clsx(!isSmallScreen ? 'fx-account-summary-card-accountgraph-body fx-dashboard-section-one-cards-left' : '')}>
                <AccountInfo account={account} id="home-my-account-card-account-info-card" accountData={accountData}></AccountInfo>
            </Grid>
            <Grid item container xs={isMobileScreen ? 12 : 7} className={clsx(!isSmallScreen ? 'fx-account-summary-card-accountgraph-body fx-dashboard-section-one-cards-right' : '')}>
                <AvailableBalanceChart id="available-balance-chart-card" account={account}/>
            </Grid>
            {/* <Grid item container xs={12} className="fx-account-balance-cards-overlay">
                <BalanceOverlay id="home-my-account-balance-cards-overlay" accountData={accountData}/>
            </Grid> */}
          </Grid>
        </FxCardBody>
      </FxCard>
    </Grid>
    {open && <AccountStatementDownload id="account-statement-download" open={open} onClose={handleAccountStatementClose} />}
    </>
  )
})
