import React, { useState } from 'react'; // we need this to make JSX
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive'
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxImage from '../../Data/FxImage';
import { Logo } from '../../Utils/Logo';
import ViewVirtualCardSummary from './VirtualCardSummary';
import Copyright from '../Auth/Copyright';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { VirtualCardPaymentHistoryList } from './VirtualCardPaymentHistoryList';

Logger.debug("VirtualCardDetails.tsx", "View virtual card details initializing");

/**
 * Component: VirtualCardDetails
 * Usage: view virtual card info public url.
 */
const VirtualCardDetails = (props: any): JSX.Element => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const history = useHistory();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
    const [isLoading, setIsLoading] = useState(false);
    const [virtualCardDetails, setVirtualCardDetails] = useState<any>(null);

    if(context && context.data_state === 3 && context.data && !isLoading){
        setIsLoading(true);
        extractVirtualCardDetails(context.data)
    }

    /**
     * Function to extract virtual card information
     */
    function extractVirtualCardDetails(data:any){

        const{isError, body} =  data;

        if(isError || !body){
            history.push('/not-found');
            return ;
        }

        let image =  body?.image;
        if(image){
            image = 'data:image/png;base64,'+image;
        }

        setVirtualCardDetails({...body, image});
    }

    return (
        <Grid item xs={12} container direction="row" className='fx-virtual-card-detail'>
            <Grid item xs={12} container direction="row" alignContent="flex-start" sm={isSmallScreen ? undefined : 12}  >
                <div className="login-page">
                    <Grid xs={12} sm={11} container justifyContent="center">
                        <Grid xs={12} sm={10} className="login-page-content">
                        {isLoading ?
                            <Grid container direction="row" className='login-page-content-card' >
                                <Grid container direction="row" item>
                                    <div className="login-logo">
                                        <Logo showFull />
                                    </div>
                                    <div className='fx-login-page-title'> <Typography className='' variant={'h3'}>Virtual Card</Typography></div>
                                </Grid>
                                <Grid container direction="row" item className="fx-login-text">
                                    {" Payment Info"}
                                </Grid>
                                <Grid container direction="row">
                                    {isSmallScreen ?
                                        <Grid container xs={12} md={12} alignContent="center" alignItems='center' spacing={2}>
                                            <Grid className='fx-virtual-card-view' item xs={12}>
                                                <FxImage id="virtual-card-image" src={virtualCardDetails?.image} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ViewVirtualCardSummary id="virtual-card-summary" data={virtualCardDetails} ></ViewVirtualCardSummary>
                                            </Grid>
                                        </Grid>:
                                        <Grid container xs={12} md={12} alignContent="center" className='fx-virtual-card-view' alignItems='center' spacing={2}>
                                            <Grid item xs={5}>
                                                <FxImage id="virtual-card-image" src={virtualCardDetails?.image} />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <ViewVirtualCardSummary id="virtual-card-summary" data={virtualCardDetails} ></ViewVirtualCardSummary>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12}>
                                    </Grid>
                                    <Grid item xs={12}><VirtualCardPaymentHistoryList id="payment-history-list" data={virtualCardDetails?.paymentHistory}></VirtualCardPaymentHistoryList></Grid>
                                </Grid>
                            </Grid>:
                             <div className={'fx-margin-bottom-2 fx-width-100'}>
                                <FxSkeltonList height="15rem" />
                            </div>
                            }
                            <Grid item xs={12} className={"login-page-bottom"}>
                                <Copyright />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

        </Grid>
    );
};

export default VirtualCardDetails;
