/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import HttpClient from '../../../libs/utils/httpClient';
import { ViewProfile } from '../Index/Passport/ViewProfile';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxBreadCrumbs from '../../Navigation/FxBreadCrumbs';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { breadCrumbGlobal, handleKeyDownChange, handleZipChange, renderError, setZipValidationOptions } from '../../Utils/CommonBaseClass';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("AddMailingAddress.tsx", "add mailing address initializing")

/**
 * Component used to add mailing address for the customer
 */
export const AddMailingAddress: React.FC<any> = React.memo((props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();

        const params = useParams<any>();
        const createType = params.type;
        let mailingAddressId: any;

         /**
         * Method to set breadcrumbs data
         */
        function page_menuData(){
            const pageMenu:any = []
            if (!createType) {
                pageMenu.push(
                {
                    title: 'My Mailing Address',
                    link: '/mailingaddresses',
                })
            }
            else{
                let data = breadCrumbGlobal(createType)
                data?.forEach((item: any) => {
                    pageMenu.push({ title: item.title, link: item.link })
                })
            }
            pageMenu.splice(0, 0, {
                title: 'Dashboard',
                link: '/'
            })
            pageMenu.push({
                title: 'Add Address',
                link: '/mailingaddress/add',
            })
            return pageMenu
        }

        const defStateData: any = {};
        defStateData['state#1'] = '';

        const [primaryMailingAddress, setPrimaryMailingAddress] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const addressArray = ['1'];
        const [stateData, setStateData] = useState(defStateData);
        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const primaryAddressData = [
            { label: 'Set as Primary Address', value: 'primaryAddress', checked: primaryMailingAddress },
        ];

        /**
         * Method used to call add mailing address API
         * @param payloadData :form data
         * @returns
         */
        async function addedMailingAddress(payloadData: any) {
            try {

                const url = '/mailingAddress'
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("AddMailingAddress.tsx", "error", err);
                return false;
            }
        }
        /**
         * Method used to clear form data
         * @param obj :form data
         * @returns
         */
        function clean(obj: any) {
            for (let propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }

        /**
         * Method to handle checkbox change event
         * @param event :event object
         */
        const handleSettings = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target?.type === 'checkbox') {
                if (event.target.value === 'primaryAddress') {
                    setPrimaryMailingAddress(event.target.checked)
                }
            }
        };
        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            if (createType === 'send-check') {
                history.push('/transaction/send/add/check')
            } else{
                history.push('/mailingaddresses')
            }
        }

        /**
         * Method used to format request to add mailing address
         * @param data :form data
         */
        async function onSubmit(data: any) {
            setIsLoading(true);
            const mailingArray: any = [];
            for (let key2 in stateData) {
                data[key2] = stateData[key2];
            }
            addressArray?.forEach((item: any, i: any) => {
                let addressLine1Obj: any;
                let addressLine2Obj: any;
                let cityObj: any;
                let stateObj: any;
                let zipObj: any;
                for (let key in data) {
                    if (i == key.split('#')[1]) {
                        if (key.split('#')[0] === 'addressLine1') {
                            addressLine1Obj = data[key];
                        }
                        if (key.split('#')[0] === 'addressLine2') {
                            addressLine2Obj = data[key];
                        }
                        if (key.split('#')[0] === 'city') {
                            cityObj = data[key];
                        }
                        if (key.split('#')[0] === 'state') {
                            stateObj = data[key];
                        }
                        if (key.split('#')[0] === 'zip') {
                            zipObj = data[key];
                        }
                    }
                }
                let Obj: any = { addressLine1: addressLine1Obj, addressLine2: addressLine2Obj, city: cityObj, state: stateObj, zip: zipObj, };
                Obj = clean(Obj);
                mailingArray.push(Obj)

            });

            data = clean(data);
            const request: any = {
                'addressLine1': data.addressLine1,
                'addressLine2': data.addressLine2,
                'city': data.city,
                'state': data.state,
                'zip': data.zip,
                'isPrimary': primaryMailingAddress,
                'usage': {
                    'isPayorAddress': true
                }
            }
            let status: any = await addedMailingAddress(request);
            if (status?.headers?.url) {
                mailingAddressId = (status.headers.url).split('/').pop();
            }
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Mailing address added Successfully!',
                });
                history.push(createType === 'send-check' ? ('/transaction/send/add/check/' + mailingAddressId) : '/mailingaddresses');
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        const history = useHistory();

        const handleChange = (event: any, i: number) => {
            setStateData({
                ...stateData,
                ['state#' + i]: event?.target?.value
            });

        }

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <FxBreadCrumbs menuItems={page_menuData()} id="add-mailing-address-breadcrumbs" />
                <Grid container className="fx-top-header" id="customer-top-grid" item justifyContent="space-between" alignItems="center" xs={12} >
                    <Grid item><h3 >Add Mailing Address</h3></Grid>
                    <ViewProfile />
                </Grid>
                <Grid id="customer-profile-info-Grid" container item xs={12}>
                    <Grid container spacing={1} >
                        <FxCard id="customer-profile-info-card" className="fx-theme-passport">
                            <div className="fx-form-edit-profile flex column">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FxCardHeader id={'customer-profile-info-card#card-header'}>
                                        <Grid id="customer-profile-info-Grid2" item container justifyContent="space-between" xs={12} >
                                            <Typography id="title-customer-profile-edit-info" variant="h3" >Add Mailing Address</Typography>
                                        </Grid>
                                    </FxCardHeader>
                                    <FxCardBody id="customer-profile-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                                        {addressArray?.map((item: any, i: any) => {
                                            return <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'addressLine1'} label="Address Line 1*" name={'addressLine1'} variant="outlined" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxTextEdit register={{ ...register('addressLine2') }} control={control} className="fx-input-edit" id={'addressLine2'} label="Address Line 2" name={'addressLine2'} variant="outlined" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxTextEdit register={{ ...register('city') }} control={control} rules={{ required: true }} className={errors['city'] ? "border-error-input fx-input-edit" : "fx-input-edit"} id={'city'} label="City*" name={'city'} variant="outlined" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <>
                                                        <FxMaterialSelect register={{ ...register('state') }} control={control} className={errors['state'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'state'} name={'state'} data={usa_state}
                                                            onChange={(e: any) => handleChange(e, i)} label="State*" setValue={setValue} />
                                                    </>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxTextEdit register={{ ...register('zip') }} control={control} rules={setZipValidationOptions(true)} className={errors['zip'] ? "border-error-input fx-input-edit" : "fx-input-edit"} id={'zip'} label="ZIP*" name={'zip'} variant="outlined" onChange={(e: any) => { handleZipChange(e,setValue,setError,clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e,setValue) }}/>
                                                    <div className={'error-message'}>
                                                        {errors.zip && renderError(errors.zip)}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FxCheckEdit register={{ ...register("setPrimary") }} control={control} onChange={handleSettings} data={primaryAddressData} id="set-primary-mailing-address" name="setPrimary" label="Set as Primary Address" row="vertical" setValue={setValue} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        })}
                                    </FxCardBody>
                                    <FxCardFooter id="customer-edit-profile-Card-Footer" className="fx-footer">

                                        <Grid container item xs={12} sm={12} justifyContent="space-between">
                                            <Grid item>
                                            </Grid>
                                            <Grid item >
                                                <Grid item container>
                                                    <Grid item className="fx-padding-right-15">
                                                        <FxButton
                                                            variant="contained"
                                                            className="fx-button"
                                                            id="cancel-add-mailing-address"
                                                            onClick={handleClose}
                                                        > Cancel
                                                        </FxButton>
                                                    </Grid><Grid item>
                                                        <FxButton
                                                            disableRipple={false}
                                                            className="fx-button fx-button-theme"
                                                            id="add-mailing-address-save-changes"
                                                            type="submit"
                                                        >
                                                            {isLoading ? (
                                                                <CircularProgress
                                                                    size={20}
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            ) : (
                                                                'Add Address'
                                                            )}
                                                        </FxButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FxCardFooter>
                                </form>
                            </div>
                        </FxCard>
                    </Grid>
                </Grid >
            </Grid>
        )
    })

let usa_state: any = [
    { "value": "", "label": "State" },
    { "value": "AL", "label": "Alabama" },
    { "value": "AK", "label": "Alaska" },
    { "value": "AS", "label": "American Samoa" },
    { "value": "AZ", "label": "Arizona" },
    { "value": "AR", "label": "Arkansas" },
    { "value": "CA", "label": "California" },
    { "value": "CO", "label": "Colorado" },
    { "value": "CT", "label": "Connecticut" },
    { "value": "DE", "label": "Delaware" },
    { "value": "DC", "label": "District Of Columbia" },
    { "value": "FM", "label": "Federated States Of Micronesia" },
    { "value": "FL", "label": "Florida" },
    { "value": "GA", "label": "Georgia" },
    { "value": "GU", "label": "Guam" },
    { "value": "HI", "label": "Hawaii" },
    { "value": "value", "label": "Idaho" },
    { "value": "IL", "label": "Illinois" },
    { "value": "IN", "label": "Indiana" },
    { "value": "IA", "label": "Iowa" },
    { "value": "KS", "label": "Kansas" },
    { "value": "KY", "label": "Kentucky" },
    { "value": "LA", "label": "Louisiana" },
    { "value": "ME", "label": "Maine" },
    { "value": "MH", "label": "Marshall Islands" },
    { "value": "MD", "label": "Maryland" },
    { "value": "MA", "label": "Massachusetts" },
    { "value": "MI", "label": "Michigan" },
    { "value": "MN", "label": "Minnesota" },
    { "value": "UM", "label": "Minor Outlying Islands" },
    { "value": "MS", "label": "Mississippi" },
    { "value": "MO", "label": "Missouri" },
    { "value": "MT", "label": "Montana" },
    { "value": "NE", "label": "Nebraska" },
    { "value": "NV", "label": "Nevada" },
    { "value": "NH", "label": "New Hampshire" },
    { "value": "NJ", "label": "New Jersey" },
    { "value": "NM", "label": "New Mexico" },
    { "value": "NY", "label": "New York" },
    { "value": "NC", "label": "North Carolina" },
    { "value": "ND", "label": "North Dakota" },
    { "value": "MP", "label": "Northern Mariana Islands" },
    { "value": "OH", "label": "Ohio" },
    { "value": "OK", "label": "Oklahoma" },
    { "value": "OR", "label": "Oregon" },
    { "value": "PW", "label": "Palau" },
    { "value": "PA", "label": "Pennsylvania" },
    { "value": "PR", "label": "Puerto Rico" },
    { "value": "RI", "label": "Rhode Island" },
    { "value": "SC", "label": "South Carolina" },
    { "value": "SD", "label": "South Dakota" },
    { "value": "TN", "label": "Tennessee" },
    { "value": "TX", "label": "Texas" },
    { "value": "UT", "label": "Utah" },
    { "value": "VT", "label": "Vermont" },
    { "value": "VA", "label": "Virginia" },
    { "value": "VI", "label": "Virgin Islands" },
    { "value": "WA", "label": "Washington" },
    { "value": "WV", "label": "West Virginia" },
    { "value": "WI", "label": "Wisconsin" },
    { "value": "WY", "label": "Wyoming" }
]
