import React from 'react';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReactComponent as OneTimeIcon } from '../../../assets/svg/one_time.svg';
import { ReactComponent as ManageExternalAccountIcon } from '../../../assets/svg/manage-ea.svg';
import { useLocation } from 'react-router-dom';
import { IScheduleMenuItem } from '../../../types/common.interfaces';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleTabView } from './ScheduleTabView';
import { ReactComponent as ManageContactIcon } from '../../../assets/svg/contact-icon.svg';
import { isAchControlAllowed, isAnyAchControl } from '../../Utils/ThemeChecker';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("ScheduleCollectMenu.tsx", "Schedule Collect Menu Card initializing");

const allLinks = {
    one_time: [
        '/transaction/collect/add/onetime-card',
        '/transaction/collect/add/mobile-check-deposit',
        '/transaction/collect/add/onetime-ach',
        '/transaction/collect/edit/onetime-card',
        '/transaction/collect/edit/onetime-ach',
    ],
    my_account: [
        '/transaction/collect/add/my-account-ach',
        '/transaction/collect/edit/my-account-ach',
        '/recurring/collect/edit',
    ],
    contact: [
        '/transaction/collect/contact/add/ach',
        '/transaction/collect/contact/edit/ach',
        '/transaction/collect/contact/add/card',
        '/transaction/collect/contact/edit/card',
        '/contact-recurring/collect/edit'
    ]
};

const permissionData = {
    COLLECT_ACH: {
        entity: "Accounts",
        name: "ACH Collect",
        operation: "CREATE"
    },
    RECURRING_COLLECT_ACH: {
        entity: "Recurring Transaction",
        name: "ACH Collect",
        operation: "CREATE"
    },
    RECURRING_COLLECT_ACH_EDIT: {
        entity: "Recurring Transaction",
        name: "ACH Collect",
        operation: "EDIT"
    },
    COLLECT_Card: {
        entity: "Accounts",
        name: "Card Collect",
        operation: "CREATE"
    },
    RECURRING_COLLECT_CARD: {
        entity: "Recurring Transaction",
        name: "CARD Collect",
        operation: "CREATE"
    },
    RECURRING_COLLECT_CARD_EDIT: {
        entity: "Recurring Transaction",
        name: "CARD Collect",
        operation: "EDIT"
    },
    COLLECT_Check: {
        entity: "Accounts",
        name: "Check Collect",
        operation: "CREATE"
    },
    COLLECT_ACH_EDIT: {
        entity: "Accounts",
        name: "ACH Collect",
        operation: "EDIT"
    },
    COLLECT_Card_EDIT: {
        entity: "Accounts",
        name: "Card Collect",
        operation: "EDIT"
    },
    COLLECT_Check_EDIT: {
        entity: "Accounts",
        name: "Check Collect",
        operation: "EDIT"
    }
}

/**
 * Component: ScheduleCollectMenu
 * Usage: To show links of schedule methods for collect
 */
export const ScheduleCollectMenu: React.FC<any> = React.memo(
    (props) => {
        const location = useLocation();
        ({ props } = RegisterComponent(props));

        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        const scheduleMenuItems: IScheduleMenuItem[] = [];
        let locationPath = location.pathname;
        if(locationPath.includes('edit/')){
            const locationPathArr = locationPath.split('/');
            locationPathArr.pop();
            locationPath = locationPathArr.join('/');
        }
        if(businessCustomer && (
            (!props?.edit && (checkPermission(permissionData.COLLECT_ACH) || checkPermission(permissionData.RECURRING_COLLECT_ACH) || checkPermission(permissionData.COLLECT_Card) || checkPermission(permissionData.RECURRING_COLLECT_CARD))) || 
                (props?.edit && (checkPermission(permissionData.COLLECT_ACH_EDIT) || checkPermission(permissionData.RECURRING_COLLECT_ACH_EDIT) || checkPermission(permissionData.COLLECT_Card_EDIT) || checkPermission(permissionData.RECURRING_COLLECT_CARD_EDIT)))
            )){
            scheduleMenuItems.push({
                title: "Contact",
                icon: <ManageContactIcon />,
                link: (checkPermission(permissionData.COLLECT_ACH) || checkPermission(permissionData.RECURRING_COLLECT_ACH)) && isAchControlAllowed(getKey('collectAchControl')) ? "/transaction/collect/contact/add/ach" : "/transaction/collect/contact/add/card",
                name: "contact",
                narration:"Collect funds from your linked contacts to your passport account.",
                selected: !!allLinks.contact.includes(locationPath),
            });
        }
        if(((!props?.edit && (checkPermission(permissionData.COLLECT_Card) || checkPermission(permissionData.COLLECT_Check) || checkPermission(permissionData.COLLECT_ACH) )) || (props?.edit && (checkPermission(permissionData.COLLECT_Card_EDIT) || checkPermission(permissionData.COLLECT_Check_EDIT) || checkPermission(permissionData.COLLECT_ACH_EDIT))))){
            scheduleMenuItems.push({
                title:businessCustomer ? "One Time" : "Check Deposit",
                icon: <OneTimeIcon />,
                link: individualCustomer ? "/transaction/collect/add/mobile-check-deposit" : isAchControlAllowed(getKey('collectAchControl')) && checkPermission(permissionData.COLLECT_ACH) ? "/transaction/collect/add/onetime-ach" : checkPermission(permissionData.COLLECT_Check) ? "/transaction/collect/add/mobile-check-deposit" : "/transaction/collect/add/onetime-card",
                name: "one_time",
                narration: businessCustomer ? "Collect funds from your payer without saving the account information." : "Deposit a check directly into your passport account with just a few taps.",
                selected: !!allLinks.one_time.includes(locationPath),
            })
        }
        if((isAnyAchControl(getKey('collectAchControl'))) && ((!props?.edit && (checkPermission(permissionData.COLLECT_ACH) || checkPermission(permissionData.RECURRING_COLLECT_ACH))) || (props?.edit && (checkPermission(permissionData.COLLECT_ACH_EDIT) || checkPermission(permissionData.RECURRING_COLLECT_ACH_EDIT))))){
            scheduleMenuItems.push({
                title: "My Account",
                icon: <ManageExternalAccountIcon />,
                link: "/transaction/collect/add/my-account-ach",
                name: "my_account",
                narration: businessCustomer ? "Collect funds from your own account to your passport account." : "Deposit Money from your external account to your Passport account through ACH.",
                selected: !!allLinks.my_account.includes(locationPath),
            });
        }

        return (
            <ScheduleTabView
                isEdit={props?.edit}
                menuItems={scheduleMenuItems}
            />
        )
    })
