/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import {
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
  clean,
  renderError,
  transformRecurringRequestGlobal,
  convertToTitleCaseAndAppendWithS,
  convertEndData,
  convertToOnDayFormat,
  deleteUnwantedRequest,
  updateComponentParams,
  checkPermission,
  createCommonVerifyProps} from "../../Utils/CommonBaseClass";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
  createSendMyAccount,
  dataSourceTransformation,
  getEAList,
  accountLabelTransformation,
  getDestinationValue,
  dataCardProgramTransformation,
  dataSourceVirtualCardTransformation,
  getLocationData
} from "./ScheduleGlobalFunctions";
import { FxSuccess } from "../../Data/FxSuccess";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxMaterialCreatableSelect from "../../Input/FxSelect/FxMaterialCreatableSelect";
import { ScheduleVirtualCardHolderDetails } from "./ScheduleVirtualCardHolderDetails";
import HttpClient from "../../../libs/utils/httpClient";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";
const httpClient = HttpClient.getClient();

Logger.debug(
  "CreateSendContactVirtualCard.tsx",
  "create send Contact Virtaul Card initializing"
);

/**
 * This component handles the send creation to linked contacts through wire method
 */
export const CreateSendContactVirtualCard: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const params = useParams<any>();
  const dispatch = useDispatch();
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individual = uiFeat.getIndividualCustomerFeature().available;
  /**
   * Function to be called when context data body changes
   */
  useEffect(() => {
    if (!context?.data?.body) {
      return;
    }
    setFormValues();
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  let sendId: any = "";
  const [contactInfo, setContactInfo] = useState<any>({name:'', email:''});
  const [cardHolderValue, setCardHolderValue] = useState<any>();
  const [isDestination, setIsDestination] = useState(true);
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const [sendEnabled, setSendEnabled] = useState(true);
  const [virtualCardOptions, setVirtualCardOptions] = useState<any>([]);
  const [newVirtualCard, setNewVirtualCard] = useState(false);
  //const params = useParams<any>();
  const urlContactId = params?.contactId;
  const scheduleId = params?.id;
  const [location, setLocation] = useState<any>();
  const acc_src = {
    url: "account/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: "DESC",
        sortBy: "createdOn",
      },
      criteria: {
        filters: [
          {
            key: "status",
            operator: "nin",
            values: ["INACTIVE"],
          },
        ],
      },
    },
  };

  const destination_src = {
    url: "contact/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 5,
      criteria: {
        filters: [],
      },
    },
  };

  const card_program_src = {
    url: "account/id/" + getKey("selectedAccount")?.id + "/virtualCard/cardProgram/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      criteria: {
        filters: [],
      },
    },
  };
   /**
    * Api used to fetch authorized user list
    */
  const auth_src = {
    url: "/authorizedUser/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "criteria": {
        "filters": [

        ]
    }
  }
  }

  const method_Tab = useMemo(() => {
    const tabs = [
      {
        title: "ACH",
        link: "/transaction/send/contact/add/ach",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Ach Send",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "Ach Send",
          operation: "CREATE"
        }]
      },
      {
        title: "WIRE",
        link: "/transaction/send/contact/add/wire",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Wire Send",
          operation: "CREATE"
        }]
      },
      {
        title: "INTERNATIONAL WIRE",
        link: "/transaction/send/contact/add/internationalWire",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "International Wire Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "International Wire Send",
          operation: "CREATE"
        }]
      },
      ...(!individual ? [{
        title: "CHECK",
        link: "/transaction/send/contact/add/check",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Check Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Check Send",
          operation: "CREATE"
        }]
      }] : []),
      {
        title: "BOOK",
        link: "/transaction/send/contact/add/book",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Book Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Book Send",
          operation: "CREATE"
        }]
      },
      {
        className: "fx-link-sel",
        title: "VIRTUAL CARD",
        link: "/transaction/send/contact/add/virtualcard",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Virtual Card Send",
          operation: "CREATE"
        }]
      }
    ];

    return tabs;
  }, [props.isEdit]);

  const [isloading, setIsloading] = useState(false);
  const destination_filter = 'name';

  /**
   * On change handler
   */
  const handleClose = () => {
    if (context?.data?.body) {
      history.push("/transactions/send/" + context?.data?.body?.id);
    } else {
      history.push("/dashboard");
    }
  };

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-contact-virtual-card-form-card-destination" },
    });
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-virtual-card-form-card-file-id-file-upload",
        files: [],
      },
    });
    setValue("type", "REGULAR");

    // checking whether edit or not.
    if (params.id) {
      const src = {
        url: "/transaction/id/" + params.id,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-contact-virtual-card", source: src },
      });
      updateComponentParams(dispatch, "edit-send-contact-virtual-card", {
        isEdit: true,
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);
  /**
   * setting form values for edit form
   */
  const setFormValues = () => {
    setValue("amount", context?.data?.body?.amount);
    setValue("purpose", context?.data?.body?.purpose);
    getDestinationData();
  };

  /**
   * Method handles the api call for getting contact name from the contact api and set the value
   */
  async function getDestinationData() {
    const destinationValue: any = await getDestinationValue(
      context?.data?.body?.destination
    );
    setValue("destination", destinationValue);
  }

  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passdata(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors["fileError"]) {
        clearErrors("fileError");
      }
    } catch (e) {}
  }

  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removefile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-virtual-card-form-card-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    let recurringRequest: any = {};
    if (urlContactId) {
      data.destination = urlContactId;
    }
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }

    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: recurringRequest["transactionDetail"],
        action: [],
      };
      req["transactionDetail"]["startDate"] = data?.startDate;
      request = {
        transaction: {
          source: {
            account: {
              id: data.source,
            },
          },
          method: "VIRTUAL_CARD",
          type: "REGULAR",
          amount: data.amount,
          purpose: data.purpose
        },
      };

      if (data.destination) {
        request["transaction"]["destination"] = {
          contact: {
            id: data.destination
          }
        };
      }
      request["transaction"]["processingDetail"] = getProcessingDetail(data);

      req["action"].push(request);

      if (fileUploadData?.length) {
        req["linkedDocument"] = fileUploadData.map((item: any) => ({
          purpose: "Authorization",
          document: { id: item.id }
        }));
      }
    } else {
      req = {
        source: {
          account: {
            id: data.source,
          },
        },
        method: "VIRTUAL_CARD",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose
      };

      if (data.destination) {
        req["destination"] = {
          contact: {
            id: data.destination
          },
        };
      }

      req["processingDetail"] = getProcessingDetail(data);
    }

    if (props.isEdit) {
      req["id"] = context?.data?.body?.id;
      const unwantedRequestArray = ["source", "destination", "method", "type"];
      deleteUnwantedRequest(req, unwantedRequestArray);
    }

    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    if (status?.headers?.url) {
      sendId = status.headers.url.split("/").pop();
    }

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
      const successMessage = props.isEdit? 'Send Updated Successfully!': repeatStatus? 'Recurring Created Successfully!': 'Send Created Successfully';
        const redirectUrl = props.isEdit? `/transactions/send/${context?.data?.body?.id}`: repeatStatus? '/thanks/transactions/send-contact-virtual-card-recurring/': '/thanks/transactions/send-contact-virtual-card/';
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true,
            postSuccessEvent:handlePostSuccessEvent
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      if (props.isEdit) {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: 'Send Updated Successfully!',
        });
        history.push("/transactions/send/" + context?.data?.body?.id);
      } else {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: repeatStatus
            ? 'Recurring Created Successfully!'
            : 'Send Created Successfully!',
        });
        if (repeatStatus) {
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
              id: "create-send-contact-virtual-card-form-card-file-id-file-upload",
              files: [],
            },
          });
        }

        dispatch({
          type: "DATA_UPDATE_COMPONENT_PARAM",
          payload: {
            id: "create-send-contact-virtual-card-form-card-linked-file-id-file-upload",
            files: [],
          },
        });
        if (repeatStatus) {
          history.push(
            "/thanks/transactions/send-contact-virtual-card-recurring/" + sendId
          );
        } else {
          history.push("/thanks/transactions/send-contact-virtual-card/" + sendId);
        }
      }
    } 
  }
    else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    
    }
  }

   /**
    * On change handler for search contact.
    *  @param contactId
  */
  const handleChange = (event: any) => {
    if (event && event?.value) {
      setIsDestination(true);
      setExternalAccount(event?.value);
      setVirtualCardList(event?.value);
    }

    setIsDestination(true);
    setSendEnabled(true);
    FxSnackBar.closeForce();
  };

  /**
    * On change handler for Card Holder type.
    *  @param contactId
  */
  const onCardHolderChange=(value: any)=>{
    setCardHolderValue(value)
  }

  /**
  * Async function handles the api call for getting virtual card list
  *  @param contactId
  */
 async function setVirtualCardList(contactId: any) {
      const virtualCardData = await getVirtualCardData(contactId);
      if (virtualCardData) {
         const virtualCardOptions = dataSourceVirtualCardTransformation(virtualCardData?.resources)
         setVirtualCardOptions(virtualCardOptions);
      }
  }

    /**
     * // getting the virtual card data
     * @param contactId
     */
   async function getVirtualCardData(contactId: any) {
    try {
        const url = "account/id/" + getKey("selectedAccount")?.id + "/virtualCard/list";
        const payload ={
          pageSize: 25,
          pageNumber: 1,
          criteria: {
            filters: [{
              "operator": "eq",
              "key": 'contactId',
              "values": [contactId]
          }],
          }
        };
        const data: any = await httpClient
            .post(getCustomerUrl(url,false), payload)
            .then((response) => {
                return response.data;
            });
        return data;
    } catch (err) {
        Logger.error("CreateSendAccountACH.tsx", "error", err);
        return false;
    }
}

    /**
     * // getting the destination data
     * @param contactId
     */
    function setExternalAccount(contactId: any) {
      setIsDestination(false);
      getEAList(contactId).then((data: any) => {
        const info ={
          name:data?.name,
          email:data?.email
        }
          setContactInfo(info);
      });
  }

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  function destinationTransformer(data: any) {
      return data?.map((item: any) => {
        return {
          label: item.name + " (ID: " + item.id + ")",
          value: item.id,
        }
      }) || [];
  }


  const destinationFilterOptions: any = [
    { label: "Contact Name", value: "name", operator: "like" },
  ];

  /**
   * Method enables and disables the repeat functionality
   * @param event : repeat status
   */

  const handleRepeat = (event: any) => {
    setRepeatStatus(event);
  };

  /**
   * Method triggers if the recurring updates
   */
  const handleUpdateRecurring = (data: any) => {
    setRecurringFormData(data);
  };

  const getProcessingDetail= (data: any) => {
    let processingDetail:any= null;

    if(newVirtualCard){
      processingDetail = {
        virtualCard: {
          cardProgram:{
            id:data.cardProgram
          },
          nickname: data.virtualCardNickName
        }
      };
    }else{
      processingDetail = {
        virtualCard: {
         id:data.contactVirtualCard
        }
      };
    }

    if(cardHolderValue){
      processingDetail.virtualCard.entity = cardHolderValue
    }
    if(data['location']){
      processingDetail['location'] = {
          'id' : data['location']
      }
    }

    return processingDetail;
  }

  /**
    * The main summary data
    */
  const amount = watch('amount');
  const destinationId = watch('destination');  
  const destinationLabel = useMemo(() => {
      return destinationId && contactInfo?.name ? contactInfo?.name :''
  }, [destinationId, contactInfo?.name]);
  
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
      'Send Amount': {
          id: 'amount',
          ValueComponent: FxSummaryCurrency,
          value: amount && !isNaN(amount) ? amount : '',
      },
      'From': {
          id: 'from',
          value: accountLabelTransformation(getKey('selectedAccount'))
      },
      'To': {
          id: 'to',
          value: destinationLabel,
      },
      ...(businessCustomer
        ? { Purpose: { id: "purpose", value: watch("purpose") } }
        : { Memo: { id: "purpose", value: watch("purpose") } }),
      'Via': {
          id: 'method',
          value: 'VIRTUAL CARD',
      },
  };

  /**
     * Recurring related summary data
     */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
    if(!repeatStatus || !recurringFormData) {
        return {}
    }
    const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
    const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
        ? convertToTitleCaseAndAppendWithS(
            recurringFormData?.repeatEveryFormData?.repeatEverySelect
        ): '';
        const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
            'Repeat every': {
                id: 'repeat-every',
                value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
            },
        }
        if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
            result['On'] = {
                id: 'repeat-on',
                value: convertToOnDayFormat(recurringFormData),
            };
        }
        const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
        const ends = recurringFormData?.endFormData
            ? convertEndData(recurringFormData?.endFormData): '';
            if (ends || endOn) {
            }
            result['Ends'] = endOn ? {
                id: 'repeat-ends',
                value: ends,
                ValueComponent: ({ value }) => (
                    <>
                        On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                    </>
                )
            } : {
                id: 'repeat-ends',
                value: ends,
            };
            return result;
        }, [recurringFormData, repeatStatus]);

  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    source: context?.data?.body?.source?.id,
    purpose: context?.data?.body?.purpose,
  };


  /**
   * On tab click handler.
   */
  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: "send",
        type: "contact",
        formData: { amount: watch("amount"), purpose: watch("purpose") },
      };
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: { id: "schedule-top-section", data: data },
      });
    } catch (e) {}
  };

  /**
   * On change virtual card handler.
   */
  const onContactVirtualCardChange =(event:any)=>{
    const value = event.target.value;
    if(value === 'New'){
      setNewVirtualCard(true);
    }else{
      setNewVirtualCard(false);
    }
  }
        /**
         * Function to handle the closing of the modal.
         */
      
      const handleCloseModal = () => {
        let verifyProps = {...props.verifyProps}
        verifyProps['open'] = false;
        updateComponentParams(dispatch, props.id, { verifyProps });
    };
      /** 
      *This function is called upon successful completion of a post operation.
      *It dispatches an action to update component parameters related to file uploads.
      */
      const handlePostSuccessEvent=()=>{
        dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-contact-virtual-card-form-card-linked-file-id-file-upload', 'files': [] } });
    }
  return (
    <>
    <ScheduleLayout
      id="create-send-contact-virtual-card"
      type="send"
      loading={isloading}
      submitTitle={
        repeatStatus ? "Create" : props.isEdit ? "Save Changes" : "Send Money"
      }
      summaryData={{ ...summaryData, ...summaryRepeatData }}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleClose}
      saveEnabled={sendEnabled}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      {urlContactId && (
        <FxSuccess
          id="sucess-external-account"
          header={"Contact added successfully"}
          description={"CONTACT ID - " + urlContactId}
        />
      )}
      <ScheduleTopSection
        id="create-send-contact-virtual-card-schedule-top-section"
        schedule="send"
        type="contact"
        register={register}
        method="virtualcard"
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={topSectionDefaultValues}
        acc_src={acc_src}
        dataSourceTransformation={dataSourceTransformation}
        amountId="create-send-contact-virtual-card-form-card-amount-textbox"
        sourceId="create-send-contact-virtual-card-form-card-source"
        purposeId="create-send-contact-virtual-card-form-card-purpose-textbox"
      />
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
          <FxLocationSelectComponent
              label='Location'
              register={{ ...register("location") }}
              control={control}
              rules={{ required: false }}
              id="create-send-contact-virtual-card-form-location"
              name="location"
              className="fx-input-edit"
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              isEditable={!scheduleId}
              value={location ? {
                label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                value: location?.id,
              }: null}
          />
      </Grid>}

      {!props.isEdit && (
        <Grid item xs={12} sm={12}>
          {isDestination && (
            <FxAutoComplete
              register={{ ...register("destination") }}
              control={control}
              rules={{ required: !urlContactId ? true : false }}
              id="create-send-contact-virtual-card-form-card-destination"
              name="destination"
              source={destination_src}
              searchBy={destination_filter}
              className={
                errors.destination
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              dataTransformer={destinationTransformer}
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              value={""}
              filterOptions={destinationFilterOptions}
              placeholder={"Search Contact..."}
              isEditable={true}
              setParam={handleChange}
            />
          )}
        </Grid>
      )}
      {props.isEdit && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            register={{ ...register("destination") }}
            className={
              errors.destination
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            control={control}
            id="create-send-contact-virtual-card-form-card-destination-textbox"
            label=""
            name="destination"
            variant="outlined"
            isEditable={false}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="create-send-contact-virtual-card-form-card-tab"
          tabClick={tabClickFunction}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FxMaterialCreatableSelect
          register={{ ...register("contactVirtualCard") }}
          id="create-send-contact-virtual-card-form-card-ea1"
          name="contactVirtualCard"
          data={virtualCardOptions}
          control={control}
          label="Select Virtual Card"
          rules={{ required: true }}
          clearError={clearErrors}
          className={
            errors.contactVirtualCard
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          readOnly={props.isEdit ? true : false}
          setValue={setValue}
          creatable={{ value: "New", label: "New Virtual Card" }}
          onChange={onContactVirtualCardChange}
        />
      </Grid>
      {!newVirtualCard && (
        <Grid item xs={12} sm={12}>
          <FxMaterialSelect
            register={{ ...register("funding_account") }}
            id="create-send-contact-ach-form-card-source"
            name="funding_account"
            source={acc_src}
            control={control}
            rules={{ required: true }}
            dataTransformation={dataSourceTransformation}
            value={getKey("selectedAccount")?.id}
            readOnly={true}
            label="Funding Account"
            setValue={setValue}
          />
        </Grid>
      )}

      {newVirtualCard && (
        <>
          <Grid id={props.id} item container xs={12} sm={12}>
            <Grid xs={12} sm={12}>
              <Typography
                id="contact-virtual-card-detail"
                className="fx-create-schedule-subheading"
              >
                VIRTUAL CARD DETAILS
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <fieldset
                id="contact-virtual-card-detail-field-set"
                className="fx-container-create-schdule-summery fx-container-create-schdule-middle-scroll"
              >
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <FxMaterialSelect
                      register={{ ...register("funding_account") }}
                      id="create-send-contact-ach-form-card-source"
                      name="funding_account"
                      source={acc_src}
                      control={control}
                      rules={{ required: true }}
                      dataTransformation={dataSourceTransformation}
                      value={getKey("selectedAccount")?.id}
                      readOnly={true}
                      label="Funding Account"
                      setValue={setValue}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FxTextEdit
                    register={{ ...register("virtualCardNickName") }}
                    className={
                      errors.nick_name
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    id="create-send-contact-wire-form-card-memo-textbox"
                    label="Nick Name"
                    name="virtualCardNickName"
                    variant="outlined"
                    defaultValue={
                      context?.data?.body?.virtualCardNickName
                        ? context?.data?.body["virtualCardNickName"]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FxMaterialSelect
                    register={{ ...register("cardProgram") }}
                    id="create-send-contact-virtual-card-program"
                    name="cardProgram"
                    source={card_program_src}
                    control={control}
                    label="Select Card Program"
                    rules={{ required: true }}
                    dataTransformation={dataCardProgramTransformation}
                    value={""}
                    clearError={clearErrors}
                    className={
                      errors.cardProgram
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    setValue={setValue}
                  />
                </Grid>
              </fieldset>
            </Grid>
          </Grid>
          <ScheduleVirtualCardHolderDetails
            id="create-contact-virtual-card-schedule-virtual-card-holder-details"
            source={auth_src}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            onCardHolderChange={onCardHolderChange}
          />
        </>
      )}

      {!props.isEdit &&
        checkPermission({
          entity: "Recurring Transaction",
          name: "Virtual Card Send",
          operation: "Create",
        }) && (
          <Grid item xs={12} sm={12}>
            <FxSwitch
              id="repeat-switch"
              value={repeatStatus}
              onClick={handleRepeat}
            ></FxSwitch>{" "}
            <FxLabel value="Repeat"></FxLabel>
          </Grid>
        )}

      {repeatStatus && (
        <>
          <Grid item xs={12} sm={12}>
            <FxDateEdit
              register={{ ...register("startDate") }}
              className={
                errors.startDate
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="create-send-contact-virtual-card-form-card-startDate"
              name="startDate"
              type="date"
              variant="outlined"
              label={"Start Date *"}
              setValue={setValue}
              disablePast={true}
              resetField={resetField}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("name") }}
              className={
                errors.name
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              rules={{ required: true }}
              id="create-send-account-virtual-card-form-card-name-textbox"
              label="Name *"
              name="name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {/*This section handles the recurring*/}
            <FxRecurring
              id="create-send-contact-virtual-card-form-card-fx-recurring"
              updateTrigger={handleUpdateRecurring}
              startDate={watch("startDate")}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
            ></FxRecurring>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Typography className="filter-popover-label">
                  LINKED DOCUMENTS
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                &nbsp;
              </Grid>
              <Grid item container xs={12} spacing={1} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <FxMaterialSelect
                    register={{ ...register("docType") }}
                    id="create-send-contact-virtual-card-form-card-doc-type"
                    control={control}
                    rules={{ required: false }}
                    name="docType"
                    data={docTypeData}
                    value={"CONTRACT"}
                    readOnly={true}
                    label="Select a document type"
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="fx-choose-file-button">
                  <Controller
                    name={"fileId"}
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FxFileUpload
                          register={{ ...register("fileId") }}
                          name="fileId"
                          passData={passdata}
                          id="create-send-contact-virtual-card-form-card-file-id-file-upload"
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          value={fileUploadData ? fileUploadData : value}
                          acceptedFiles={[
                            ".pdf",
                            ".docx",
                            ".jpg",
                            ".txt",
                            ".png",
                            ".jpeg",
                            ".xls",
                            ".wav",
                            ".xlsx",
                            ".tiff",
                            ".doc",
                            ".rtf",
                            ".bmp",
                            ".efx",
                            ".csv",
                            ".kswps",
                            ".wps",
                          ]}
                          formData={{ type: "CONTRACT" }}
                          setValue={setValue}
                          maxFileSize={10485760}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FxLabelView
                  className="fx-label-small"
                  id="create-send-contact-virtual-card-form-card-file-types-supported"
                >
                  (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc, .docx,
                  .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                </FxLabelView>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={"error-message"}>
                  {errors.fileError && renderError(errors.fileError)}
                </div>
              </Grid>
              {fileUploadData && (
                <Grid item container>
                  {fileUploadData.map((key: any) => {
                    return (
                      <Chip
                        icon={<InsertDriveFileTwoToneIcon />}
                        label={key["name"]}
                        onDelete={() => removefile(key["id"])}
                        color="primary"
                        variant="outlined"
                        deleteIcon={<DeleteIcon />}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </ScheduleLayout>
    </>
  );
});
