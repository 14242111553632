import React from 'react';
import { Divider, Grid } from '@mui/material';
import { FxButton } from '../Action/FxButton';

interface IFxDialogCard {
    messageContent: React.ReactElement;
    title: string;
    buttonText?: string;
    onButtonClick?: (event: any) => void;
    Icon: React.ComponentType;
    disabled?:any;
    className?:any;
    noteContent?: React.ReactElement;
}

export const FxDialogCard: React.FC<IFxDialogCard> = React.memo(({messageContent, Icon, title, buttonText, onButtonClick ,disabled, noteContent}) => {

    return (
        <Grid xs={12} md={10} className={'fx-dialog-card'}>
            <div className={'fx-dialog-card-icon'}>
                <Icon/>
            </div>
            <p className={'fx-dialog-card-title'}>{title}</p>
            <div className={'fx-dialog-card-message'}>
                {messageContent}
            </div>
            <Divider />
            <FxButton variant={'contained'} onClick={onButtonClick} disabled={disabled} className={`${disabled ? 'fx-button-disabled' : 'fx-button-theme'} fx-dialog-card-button`}>
                {buttonText}
            </FxButton>
            {noteContent && <div className={'fx-dialog-card-note'}>
                {noteContent}
            </div>}
        </Grid>
    )
})