import { getCookie, setCookie, removeCookie, removeAllCookie } from "./cookieManager";
import { removeAllLocalStorage } from "./cacheHandler";
import md5 from 'crypto-js/md5';
import { appStorage } from "./AppStorage";
import { getSessionStorage, removeAllSessionStorage, removeSessionStorage, setSessionStorage } from "./sessionStorageManager";


/**
 * set cookie with md5 key
 * @param key
 * @param value
 */
export const setCookieCustom = (key: any, value: any): void => {
    setCookie(md5(key).toString(), value);
}

/**
 * get md5 encrypted cookie
 * @param key
 */
export const getCookieCustom = (key: any): any => {
    return getCookie(md5(key).toString());
}

/**
 * Remove the cookie of the user
 */
export const removeCookieCustom= (key: any): void => {
    removeCookie(md5(key).toString());
};

/**
 * set the token for the user
 * @param token
 */
export const setUserToken = (token: string): void => {
    appStorage.set('token', token);
    // setCookieCustom('token', token);
}
/**
 * user logged in
 */
export const setUserLoggedIn = (): void => {
    setCookieCustom('userloggedin', true);
}
/**
 * store user info
 * @param name
 */
export const setUserInfo = (name: string): void => {
    appStorage.set('username', name);
}

/**
 * retrieve the token for the user
 */
export const getUserToken = (): any => {
    const value = appStorage.get('token');
    return value;
};
/**
 * check user logged in or not
 */
export const isUserLoggedIn = () => {
    return getCookieCustom("userloggedin");
}

/**
 * get user info
 * @param name
 */
export const getUserInfo = (): any => {
    const value = appStorage.get('username');
    return value;
};
/**
 * Remove the token for the user
 */
export const removeUserToken = (): void => {
    removeCookie(md5('token').toString());
};

//logout
export const logout = () => {
    removeAllLocalStorage();
    appStorage.clear();
    removeAllCookie();
    removeAllSessionStorage();
}

/**
 * method clear session
 */
export const clearSession = () => {
    removeAllLocalStorage();
    appStorage.clear();
    removeAllCookie();
}
/**
 * set the key
 * @param key
 * @param value
 */
export const setKey = (key: string, value: any) => {
    appStorage.set(key, value);
}

/**
 * get the key
 * @param key
 */
export const getKey = (key: string) => {
    return appStorage.get(key);
}

/**
 * remove the key
 * @param key
 */
export const removeKey = (key: string) => {
    return appStorage.removeKey(key);
}

/**
 * Set session storage item with MD5 encrypted key
 * @param key
 * @param value
 */
export const setSessionStorageCustom = (key: any, value: any): void => {
    setSessionStorage(md5(key).toString(), value);
};

/**
 * Get session storage item with MD5 encrypted key
 * @param key
 */
export const getSessionStorageCustom = (key: any): any => {
    return getSessionStorage(md5(key).toString());
};

/**
 * Remove session storage item with MD5 encrypted key
 * @param key
 */
export const removeSessionStorageCustom = (key: any): void => {
    removeSessionStorage(md5(key).toString());
};