import { useMediaQuery } from "react-responsive";
import { getKey } from "./storageManager";
import { toTitleCase } from "./string"
declare global {
    interface Window {
        dataLayer: any;
    }
}

export interface IDeferredPromise<T> extends Promise<T> {
    resolve?(token: T): void;
    reject?(err: any): void;
}
/**
 * check and process API response
 * @param response
 */
export function processAPIResponse(response: any) {
    let status = false
    let message: any = "Please try again"
    let errorCode:any;
    let responseData: any;
    try {
        if (response.status < 300) {
            status = true
            message = "Data saved "
            responseData = response?.data;
        }
        else if (response.status === 401 || response.response.status === 401) {
            window.location.href = '/logout';
            return { status, message }
        }
        else {
            if (response.response.data) {
                let resData = response.response.data
                if (resData['errors']) {
                    message = [];
                    resData['errors'].forEach((item: any) => {
                        message.push(toTitleCase(item.field) + ': ' + item.message);
                        errorCode = response?.response?.data?.errorCode
                    });
                }
                else {
                    message = resData.errorMessage ? resData.errorMessage : "Please try again"
                    errorCode = response?.response?.data?.errorCode
                }
            }
        }
    } catch (e) {

    }
    return { status, message, errorCode, responseData }
}

/**
 * url redirect
 * @param history
 * @param url
 */
export function redirect(history: any, url: any) {
    history.push(url);
}

/**
 *
 * @param data
 * push to gtn
 */
export function pushGTM(data:any){
    try{
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }catch(e){}
}

export function formatPhone(phoneNumber: any) {
    let f_val = phoneNumber;
    try {
        // eslint-disable-next-line no-useless-escape
        f_val = phoneNumber.replace(/\D[^\.]/g, "");
        f_val = f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);

    } catch (e) { }
    return f_val;
}


/**
 *
 * @param base
 * @param url
 * return url based on the type
 */
export function getCustomerUrl(url:string,base?:boolean){
    let final_url = url;
    if(!base){
        //require customer specific
        final_url = "customer/id/" + getKey('customerId')
        //check the first char of url
        const first_char = url.slice(0,1)
        if(first_char === "/"){
            final_url += url
        }
        else{
            final_url +=  "/" + url
        }

    }
    return final_url
}

export interface IDeferredPromise<T> extends Promise<T> {
    resolve?(token: T): void;
    reject?(err: any): void;
}

/**
 * get element's offset top when position is absolute or fixed
 * @param element
 * return offsetTop
 */
export const getOffsetTop = (element: any) => {
    let offsetTop = 0;
    while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

/**
 * Returns deferred promise object
 * which gives opportunity to declare it one place and resolve/reject from another place
 */
export function createDeferredPromise<T>(): IDeferredPromise<T> {
    let resolve;
    let reject;

    const promise = new Promise<T>((thisResolve, thisReject) => {
        resolve = thisResolve;
        reject = thisReject;
    });

    return Object.assign(promise, {resolve, reject});
}

/**
 * Hook for checking mobile screen
 */
export const useIsMobileScreen = () => useMediaQuery({ query: '(max-width: 600px)' });

export const useIsMobileOrTabletScreen = () => useMediaQuery({ query: '(max-width: 900px)' });
