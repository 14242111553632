/* eslint-disable eqeqeq */
import { Divider, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useCallback } from 'react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NavigateArrow } from '../../../../assets/svg/greater-navigate-arrow.svg';
import { ReactComponent as CircleIcon } from '../../../../assets/svg/link-circle.svg';
import { ReactComponent as VisaImage } from '../../../../assets/svg/visa-image.svg';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxLink } from '../../../Action/FxLink';
import { FxApplyDebitCardButton } from '../../../Data/FxApplyDebitCardButton';
import { FxApplyDebitCard } from '../../../DrawerPages/FxApplyDebitCard/FxApplyDebitCard';
import { IApplyDebitCardPayload } from '../../../DrawerPages/FxApplyDebitCard/FxApplyDebitCard';
import { FxDateTimeView } from '../../../Input/FxDate/FxDateView';
import { calculateAge, checkPermission, fetchDataFromState, maskAccountNumber, updateComponentParams } from '../../../Utils/CommonBaseClass';
import FxRightDrawer from '../../../Utils/fx-right-drawer';
import { FxConsumerDebitCardApply } from '../../../DrawerPages/FxApplyDebitCard/FxConsumerDebitCardApply';

/**
 * This component handles the debit card list
 */
export const DebitCardList: React.FC<any> = React.memo((props) => {
    const activeCard: any = [];
    ({ props } = RegisterComponent(props));

    const history = useHistory();
    const dispatch = useDispatch();
    const uiFeat = UIFeatureImpl.getInstance();
    const jointTenancy = uiFeat.getJointTenancyFeature().available;

    const [isDebitCardLinked, setIsDebitCardLinked] = useState<any>();

    /**
     * checking props data
     */
    useEffect(() => {
        if (props?.data) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'debit-card-object-data', data: props?.data } })
            let cards = props?.data?.resources || [];
            if(jointTenancy && cards.length) {
              cards = cards.filter((item: any) => item?.cardHolder?.id === getKey('authUserId'));
            }

          if (cards?.length > 0) {
                setIsDebitCardLinked(true)
                // eslint-disable-next-line array-callback-return
                cards.map((data: any) => {
                    if (data?.status !== 'CANCELLED' && data?.status !== 'CANCELLATION_INITIATED') {
                        activeCard.push(data)
                    }
                    if (data?.status == 'PENDING' || data?.status == 'SHIPPED') {
                        updateComponentParams(dispatch, props?.id, { 'status': data?.status })
                    }
                })
            } else {
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props?.id } });
                setIsDebitCardLinked(false)
            }
            updateComponentParams(dispatch, props?.id, { 'activeCard': activeCard })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data])

    /**
     * Method used to open right drawer to apply for debit card
     */
    const handleApplyDebitCardButtonClick = useCallback(async () => {
        const data = await FxRightDrawer.show<IApplyDebitCardPayload>(({ onClose }) => {
            return <FxApplyDebitCard onDrawerClose={onClose} />
        })
        data && setIsDebitCardLinked(data.isDebitCardApplied)
    },[]);

     /**
     * Method used to open right drawer to apply for debit card for Consumers
     */
     const handleApplyConsumerDebitCardButtonClick = useCallback(async () => {
        const data = await FxRightDrawer.show<IApplyDebitCardPayload>(({ onClose }) => {
            return <FxConsumerDebitCardApply onDrawerClose={onClose} />
        })
        data && setIsDebitCardLinked(data.isDebitCardApplied)
    },[]);

    /**
    * function to check permission 
    */
    function checkViewPermission(data: any) {
        const permissionObject: any = {
            'debit_card_view': {
                entity: "Accounts",
                name: "Debit Card",
                operation: "View"
            },
            'debit_card_issue': {
                entity: "Accounts",
                name: "Debit Card",
                operation: "Issue"
            }
        }
        return checkPermission(permissionObject[data])
    }
   /**
    * Method to get switch customer details from state
    */
   const switchData = useSelector((state: any) =>
   fetchDataFromState(state, 'switch-customer-data')?.data
);
    /**
    * to get customer details
    */
    const customerDetails = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']?.params?.customerDetails
        }
    });

    /**
    * to check joint tenancy owner should adult
    */
    const jointTenancyOwnerShouldAdult = useMemo(()=>{
        if(jointTenancy){ // Check minor owner only for joint tenancy customer
            const holders = customerDetails?.owners;    
            const currentOwner = holders?.find((holder:any) => holder.id === getKey('LoggedInOwnerId'));
           
            return calculateAge(currentOwner?.dob) >= 18;
        }else{
            return true; 
        }    

    },[jointTenancy, customerDetails])

    return (
       <>{switchData?.selectedAccount?.isCustomerOwned === true && <Grid id="debit-card-list-grid" item xs={12} >
            <Grid id="debit-card-list-card" >
                {checkViewPermission("debit_card_view") && <Grid id="debit-card-list-card-body">
                    <Grid id="debit-card-list-card-grid" item container xs={12} className="home-debit-card">
                        <Divider className="fx-divider-account fx-debit-card-section-divider" />

                        {props?.activeCard?.length > 0 && <><Grid item xs={12} >
                            {
                                props?.activeCard && props?.activeCard.map((item: any, index: any) => {
                                    return (
                                        <>
                                            {index <= 0 && <>
                                                <Grid container xs={12} >
                                                    <Grid item className="fx-debit-card">
                                                        <VisaImage />
                                                    </Grid>
                                                    <Grid container xs>
                                                        <Grid item container xs={12} className="fx-debit-card-left fx-debit-card-left-padding">
                                                            <Grid item xs={12}>
                                                                <Typography id="debit-card-number" variant="h5" >Linked Debit Card &nbsp; <CircleIcon /> <CircleIcon /> {item?.cardNumber}</Typography>
                                                            </Grid>
                                                            <Grid item container xs={12} className="fx-debit-card-left-issued-date">
                                                                <Grid item>
                                                                    Issued on : 
                                                                </Grid>
                                                                <Grid item>
                                                                    &nbsp;
                                                                </Grid>
                                                                <Grid item>
                                                                    <FxDateTimeView value={item?.createdOn} format=" MMM DD, yyyy" />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={1} alignItems='center' justifyContent={'center'}>
                                                    <FxLink id="debit-card-link" className={'fx-debit-card-link'} onClick={() => { history.push('/debit-card/details/' + item?.id) }}>
                                                    <Grid item> <NavigateArrow/></Grid>
                                                    </FxLink>
                                                    </Grid>
                                                </Grid>
                                            </>}
                                        </>
                                    )
                                })
                            }
                        </Grid>
                        </>}

                        {checkViewPermission("debit_card_issue") && !isDebitCardLinked && getKey("accountStatus") !== 'false' && (!props?.activeCard || props?.activeCard?.length === 0) && jointTenancyOwnerShouldAdult && ( getKey("selectedAccount")?.status !== 'INACTIVE' && getKey("selectedAccount")?.status !== 'CLOSURE_INITIATED' && getKey("selectedAccount")?.status !== 'CLOSED') &&
                            <FxApplyDebitCardButton onClick={handleApplyConsumerDebitCardButtonClick} />}

                        {((getKey("accountStatus") === 'false') || ( getKey("selectedAccount")?.status === 'INACTIVE'  || getKey("selectedAccount")?.status === 'CLOSURE_INITIATED' || getKey("selectedAccount")?.status === 'CLOSED'))  && <FxApplyDebitCardButton onClick={handleApplyDebitCardButtonClick} disabled={true } />}

                        {(isDebitCardLinked && (props?.status == 'PENDING' || props?.status == 'SHIPPED') && props?.activeCard.length == 0) &&
                            <Grid container item xs={12} justifyContent="center" className="fx-debit-card-left">
                                <Grid item xs={12} >
                                    <Typography id="no-debit-card-msg" variant="h5" >We are processing your Debit Card for the Passport Account {'(' + maskAccountNumber(getKey("selectedAccount")?.accountNumber, false) + '). '}Usually takes 2 to 7 working days.</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
 }</> )
})
