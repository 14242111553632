import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { AUTH_STRINGS } from '../../../../../constants/strings';
import FxMaterialSelect from '../../../../Input/FxSelect/FxMaterialSelect';
import FxCard from '../../../../Container/FxCard';
import FxCardBody from '../../../../Container/FxCardBody';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxTextEdit } from '../../../../Input/FxText/FxTextEdit';
import FxLabelView from '../../../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../../../Action/FxButton';
import { ReactComponent as InternalIcon } from '../../../../../assets/svg/Internal-circle.svg';
import usa_state from '../../../../Utils/usa_states.json'
import {
  ageLimit,
  deleteUnwantedRequest,
  handleKeyDownChange,
  handleZipChange,
  handleAddressChange,
  renderError,
  setZipValidationOptions,
  renderOwnershipPercentageError,
  setPhoneValidationOptions,
  convertToPhoneRequestFormat,
  renderPhoneError,
  handleSecIdChange,
  secondaryIdentificationIdValidation,
  convertToPhoneDisplayFormat,
  setAddressLine1Validation
} from '../../../../Utils/CommonBaseClass';
import issuance_countries from '../../../../../libs/utils/country/country.json'
import { FxDateEdit } from '../../../../Input/FxDate/FxDateEdit';
import FxCheckEdit from '../../../../Input/FxCheck/FxCheckEdit';
import FxPhoneEdit from '../../../../Input/FxPhone/FxPhoneEdit';
import { FxTextEditSSN } from '../../../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../../../Input/FxSelect/FxSelectAutoSearch';
import { getKey } from '../../../../../libs/utils/storageManager';
import HttpClient from '../../../../../libs/utils/httpClient';

const httpClient = HttpClient.getClient()
//component used to edit business owner

export const BusinessOwnerEdit: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();
        const [secondaryIdType, setSecondaryIdType] = useState<any>('');
        const [checkBoxValue, setCheckBoxValues] = useState<any>({ authorized: false, creditReport: false })
        const [businessOwnerDetails, setBusinessOwnerDetails] = useState<any>();
        /**
        * Function to be called before loading the component
        */
        useEffect(() => {
            if (props.data) {
                setBusinessOwnerDetails(props?.data);
                setSecondaryIdType(props?.data?.secondaryIdentificationType)
                setCheckBoxValues({ authorized: props?.data?.authSignatory === undefined ? false : props?.data?.authSignatory?.[0] === 'authorized' ? true : props?.data?.authSignatory ? props?.data?.authSignatory : false, creditReport: props?.data?.creditReport === undefined ? false : props?.data?.creditReport === 'creditReport' ? true : props?.data?.creditReport ? props?.data?.creditReport : false })
            }
        }, [props?.data]);

        const usCitizenDropdown:any =[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
        ]

        const secondaryIDTypeData: any = [
            { value: 'DRIVER_LICENSE', label: 'Driver License' },
            { value: 'PASSPORT', label: 'Passport' },
            { value: 'ALIEN_REGISTRATION_CARD', label: 'Alien registration Card' }
        ];

        //Set Validation condition for Ownership Percentage
        const authSignDate: any = useMemo(() => {
            let requiredField: boolean;
            if (checkBoxValue?.authorized === true && (props.businessDetails?.['businessCategory'] === 'GOVERNMENT_ORGANISATION' || props.businessDetails?.['businessCategory'] === 'NON_PROFIT' || props.businessDetails?.['businessCategory'] === 'TAX_EXEMPTION' || props.businessDetails?.['businessCategory'] === 'LLC' || props.businessDetails?.['businessCategory'] === 'LLP' || props.businessDetails?.['businessCategory'] === 'S-CORP ' || props.businessDetails?.['businessCategory'] === 'C-CORP')) {
                delete errors['ownerShipPercentage']
                requiredField = true
            }
            else {
                requiredField = false
            }
            return requiredField
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checkBoxValue])

        const authorizedCheckBoxData = [
            { label: 'Is an authorised signatory?', value: 'authorized', checked: checkBoxValue?.authorized }
        ]

        // let creditReportCheckBoxData = [
        //     { label: 'Pull Credit Report', value: 'creditReport', checked: checkBoxValue?.creditReport }
        // ]

        // Email validation constant
        const setEmailValidationOptions = {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };

        // Ssn validation constant
        const setSsnValidationOptions = {
            required: true,
            maxLength: 11,
            pattern: {
                value: /^\d{3}-?\d{2}-?\d{4}$/,
                message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
            },
        };

        // Ownership percentage validation constant
        const setOwnerShipPercentageRule = {
            required: authSignDate === true ? false : true,
            min: 0,
            max: 100
        };

        const isAuthUser = props?.data?.email === getKey('email');

        if (getKey('email') && isAuthUser && getKey('phone')) {
            setValue('email', getKey('email'))
            setValue('mobilePhone', convertToPhoneDisplayFormat(getKey('phone')))
        }

        // Method handles the handle change of ssn and mobile number fields
        const handleChange = (event: any) => {
            if (event.target.name === 'secondaryIdentificationType') {
                setSecondaryIdType(event.target.value)
            }

            if (event.target.name === 'ssn') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (val.length === 11) {
                    clearErrors('ssn');
                }
                else {
                    setError("ssn", {
                        type: "manual",
                        message: "Invalid SSN",
                    });
                }
                setValue('ssn', val)
            }
        }

        // Method handles the submit for edit beneficial owner form
       async function onSubmit(data: any) {
            data.ssn = data?.ssn || businessOwnerDetails?.ssn;
                const beneficial: any = await callCustomerAPI()
                if (beneficial && beneficial.business && beneficial.business.beneficialOwner) {
                    let updateBeneficialOwner: any = beneficial.business.beneficialOwner.find((item: any) => item.email === data.email);
                    if (updateBeneficialOwner) {
                        data.id = updateBeneficialOwner.id;
                    }
                }
            const req: any = { ...data };
            if( data['workPhone'] === "" &&  data['homePhone'] === "" && data['mobilePhone'] === ""){
                setError("homePhone", {
                    type: "required",
                    message: "At least one of home phone, work phone or mobile phone is required.",
                  });
                return;
            }
           if(data['id'] === ''){
                delete data['id'];
                delete req['id'];
            }
            if (data['dob']) {
                req['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            const address :any = {
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip'],
                "isPrimary": true,
            }
            if(data['address_id']){
                address['id'] = data['address_id'];
            }
            req['mailingAddress'] = [address]
            req['businessDetails'] = {
                'ownershipPercentage': data['ownerShipPercentage'],
                'title': data['jobtitle']
            }
            req["secondaryIdentification"] = data['secondaryIdentificationType'] === 'DRIVER_LICENSE' ? {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'stateOfIssuance': data['secondaryIdentificationIssuanceState']
            } : {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'countryOfIssuance': data['secondaryIdentificationType'] === 'ALIEN_REGISTRATION_CARD' ? 'US' : data['secondaryIdentificationIssuance']
            }
            req['mobilePhone'] = convertToPhoneRequestFormat(req.mobilePhone) 
            if(req['mobilePhone'] === ""){
                delete req['mobilePhone'];
            }
            req['homePhone'] = convertToPhoneRequestFormat(req.homePhone) 
            if(req['homePhone'] === ""){
                delete req['homePhone'];
            }
            req['workPhone'] = convertToPhoneRequestFormat(req.workPhone)
            if(req['workPhone'] === ""){
                delete req['workPhone'];
            }
            req['isUSCitizen'] = data['usCitizen']==='yes' ? true : false
            req['actAsAuthorizedSignatory'] = checkBoxValue.authorized
            if(req['actAsAuthorizedSignatory'] === ' checked'){
                req['actAsAuthorizedSignatory'] = true;
            }
            // req['pullCreditReport'] = checkBoxValue.creditReport
            if (!req['businessDetails']['ownershipPercentage']) {
                delete req['businessDetails']['ownershipPercentage'];
            }
            if (data) {
                data['authSignatory'] = checkBoxValue.authorized
                data['creditReport'] = checkBoxValue.creditReport
            }
            // req = clean(req);
            const unwantedRequestArray = ['state', 'addressLine1', 'city', 'zip', 'addressLine2', 'jobtitle', 'authSignatory', 'ownerShipPercentage', 'creditReport', 'secondaryIdentificationType', 'secondaryIdentificationId', 'secondaryIdentificationIssuance', 'secondaryIdentificationIssuanceState','usCitizen','mobilePhone-select','homePhone-select','workPhone-select'];
            deleteUnwantedRequest(req, unwantedRequestArray)
           if (props.onSubmit) {
                props.onSubmit(data, req, 'edit', props?.index)
            }
            if (props?.cancel) {
                props.cancel()
            }
        }
        /**
              * Call the Customer API to get customer details
              * @returns 
              */
        async function callCustomerAPI() {
            const response = await httpClient.get('/customer/id/' + getKey('customerId'))
            return response?.data;
        }
        // Method handles the click operation for going back to business owner list screen
        const handleGoBackClick = (data: any) => {
            if (props?.cancel) {
                props.cancel()
            }
        }

        /**
         * handle change for check box
         * @param event :click event
         */
        const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target?.type === 'checkbox') {
                if (event.target.value === 'authorized') {
                    setCheckBoxValues({ authorized: event.target.checked, creditReport: checkBoxValue?.creditReport === undefined ? false : checkBoxValue?.creditReport })
                }
                else {
                    setCheckBoxValues({ authorized: checkBoxValue?.authorized === undefined ? false : checkBoxValue?.authorized, creditReport: event.target.checked })
                }
            }
        }
        return (
            <Grid container id='signUp-business-owner' xs={12}>
                <FxCard id="signUp-business-owner-form-card" className="fx-info-kyc-edit">
                    <div className="fx-form-edit-profile">
                        {businessOwnerDetails !== undefined && <form id="signUp-business-owner-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCardBody id="signUp-business-owner-form-card-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                     <FxTextEdit register={{ ...register("id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-first-name"  name="id" variant="outlined" defaultValue={businessOwnerDetails?.id} />
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-first-name" label="First Name*" name="firstName" variant="outlined" defaultValue={businessOwnerDetails?.firstName} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("lastName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-last-name" label="Last Name" name="lastName" variant="outlined" defaultValue={businessOwnerDetails?.lastName} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions} id="signUp-business-owner-form-card-email" label="Email*" name="email" variant="outlined" defaultValue={isAuthUser ? getKey('email') : businessOwnerDetails?.email} isEditable={isAuthUser ? false : true} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("jobtitle") }} className={errors.jobtitle ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-jobTitle" label="Job Title*" name="jobtitle" defaultValue={businessOwnerDetails?.jobtitle} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                    <FxTextEdit register={{ ...register("ownerShipPercentage") }} className={errors.ownerShipPercentage ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setOwnerShipPercentageRule} suffix="%" id="signUp-business-owner-form-card-ownerShipPercentage" type="number" label={authSignDate === true ? "Ownership" : "Ownership*"} name="ownerShipPercentage" variant="outlined" defaultValue={businessOwnerDetails?.ownerShipPercentage} min='0'/>
                                        <div className={'error-message'}>
                                            {errors.ownerShipPercentage && renderOwnershipPercentageError(errors.ownerShipPercentage)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >ABOUT OWNER</FxLabelView>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-dob" label="Date of Birth*" name="dob" variant="outlined" defaultValue={businessOwnerDetails?.dob} maxDate={ageLimit(18)} setValue={setValue} defaultCalendarMonth={ageLimit(18)}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEditSSN
                                            register={{ ...register("ssn") }}
                                            className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            error={errors?.ssn}
                                            control={control}
                                            rules={setSsnValidationOptions}
                                            id="signUp-business-owner-form-card-ssn"
                                            label="Social Security Number *"
                                            placeholder="XXX-XX-XXXX"
                                            name="ssn"
                                            variant="outlined"
                                            defaultValue={businessOwnerDetails?.ssn}
                                            setValue={setValue}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />
                                        <div className={'error-message'}>
                                            {errors.ssn && renderError(errors.ssn)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id="signUp-business-owner-form-card-mobilePhone" label="Mobile Phone *" name="mobilePhone" placeholder={'XXX-XXX-XXXX'} defaultValue={isAuthUser ? convertToPhoneDisplayFormat(getKey('phone')) : businessOwnerDetails?.mobilePhone} setValue={setValue} setError={setError} clearErrors={clearErrors} isEditable={isAuthUser? false: true} />
                                        <div className={'error-message'}>
                                            {errors.mobilePhone && renderPhoneError(errors.mobilePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.homePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id="signUp-business-owner-form-card-homePhone" label="Home Phone" name="homePhone" placeholder={'XXX-XXX-XXXX'} defaultValue={businessOwnerDetails?.homePhone} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.homePhone && renderPhoneError(errors.homePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.workPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id="signUp-business-owner-form-card-workPhone" label="Work Phone" name="workPhone" placeholder={'XXX-XXX-XXXX'} defaultValue={businessOwnerDetails?.workPhone} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.workPhone && renderPhoneError(errors.workPhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxMaterialSelect register={{ ...register("usCitizen") }} rules={{ required: true }} className={errors.usCitizen ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-business-owner-select-usCitizen" name="usCitizen" data={usCitizenDropdown} label="Is US Citizen*" setValue={setValue} value={businessOwnerDetails?.usCitizen} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >SECONDARY IDENTIFICATION</FxLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FxMaterialSelect register={{ ...register('secondaryIdentificationType') }} control={control} className={errors['secondaryIdentificationType'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'secondaryIdentificationType'} name={'secondaryIdentificationType'} data={secondaryIDTypeData}
                                            onChange={handleChange} label="Type *" setValue={setValue} value={businessOwnerDetails?.secondaryIdentificationType} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FxTextEdit register={{ ...register("secondaryIdentificationId") }} className={errors.secondaryIdentificationId ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={secondaryIdentificationIdValidation(secondaryIdType)} id="signup-secondaryIdentificationId" label="ID *" name="secondaryIdentificationId" defaultValue={businessOwnerDetails?.secondaryIdentificationId} onChange={(e: any) => { handleSecIdChange(e, setValue, setError, clearErrors, secondaryIdType) }} />
                                        <div className={'error-message'}>
                                            {errors.secondaryIdentificationId && renderError(errors.secondaryIdentificationId)}
                                        </div>
                                    </Grid>
                                    {secondaryIdType === 'DRIVER_LICENSE' &&
                                        <Grid item xs={12} sm={4}>
                                            <FxMaterialSelect register={{ ...register('secondaryIdentificationIssuanceState') }} control={control} className={errors['secondaryIdentificationIssuanceState'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'secondaryIdentificationIssuanceState'} name={'secondaryIdentificationIssuanceState'} data={usa_state}
                                                onChange={handleChange} label="State of Issuance *" setValue={setValue} value={businessOwnerDetails?.secondaryIdentificationIssuanceState} />
                                        </Grid>}
                                    {secondaryIdType !== 'DRIVER_LICENSE' && secondaryIdType !== 'ALIEN_REGISTRATION_CARD' && <Grid item xs={12} sm={4}>
                                        <FxMaterialSelect register={{ ...register('secondaryIdentificationIssuance') }} control={control} className={errors['secondaryIdentificationIssuance'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: secondaryIdType !== '' ? true : false }} id={'secondaryIdentificationIssuance'} name={'secondaryIdentificationIssuance'} data={issuance_countries}
                                            onChange={handleChange} value={businessOwnerDetails?.secondaryIdentificationIssuance} readOnly={secondaryIdType === 'ALIEN_REGISTRATION_CARD' ? true : false} label="Country of Issuance *" setValue={setValue} />
                                    </Grid>}
                                    {secondaryIdType === 'ALIEN_REGISTRATION_CARD' &&
                                        <Grid item xs={12} sm={4}>
                                            <FxTextEdit register={{ ...register("secondaryIdentificationIssuanceAlien") }} className={errors.secondaryIdentificationIssuanceAlien ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                control={control} rules={{ required: false }} id="signUp-business-owner-secondaryIdentificationIssuanceAlien" label="Country of Issuance *" name="secondaryIdentificationIssuanceAlien" defaultValue="US" isEditable={false} />
                                            <div className={'error-message'}>
                                                {errors.secondaryIdentificationIssuanceAlien && renderError(errors.secondaryIdentificationIssuanceAlien)}
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >PRIMARY ADDRESS</FxLabelView>
                                    </Grid>
                                    <FxTextEdit register={{ ...register("address_id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-first-name"  name="address_id" variant="outlined" defaultValue={businessOwnerDetails?.address_id} />
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAddressLine1Validation(true)}  id="signUp-business-owner-addressLine1" label="Address Line 1 *" name="addressLine1" defaultValue={businessOwnerDetails?.addressLine1}   onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }}/>
                                        <div className={'error-message'}>
                                            {errors.addressLine1 && renderError(errors.addressLine1)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-addressLine2" label="Address Line 2" name="addressLine2" defaultValue={businessOwnerDetails?.addressLine2} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-city" label="City *" name="city" defaultValue={businessOwnerDetails?.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-business-owner-select-state" name="state" data={usa_state} label="State *" setValue={setValue} value={businessOwnerDetails?.state} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="signUp-business-owner-zip" label="ZIP *" name="zip" defaultValue={businessOwnerDetails?.zip} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                        <div className={'error-message'}>
                                            {errors.zip && renderError(errors.zip)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container alignItems="center" >
                                            <Grid item >
                                                <FxCheckEdit register={{ ...register("authSignatory") }} onChange={handleChangeCheckBox} control={control} data={authorizedCheckBoxData} id="signUp-business-owner-form-card-authSignatory" name="authSignatory" row="vertical" setValue={setValue} />
                                            </Grid>
                                            <Grid item >
                                                <Tooltip title="Authorised Signatory will have authority to appoint the authorized signers on your Passport Account, to add or delete authorized signers on your Passport Account, to open and manage accounts, and to give us any other instructions on your behalf with respect to your Passport Accounts"><InternalIcon /></Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="signUp-business-owner-Card-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton
                                        disableRipple={false}
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="back-edit-business-owner-btn"
                                        onClick={handleGoBackClick}
                                    > GO BACK
                                    </FxButton>
                                    <Grid className='fx-padding-right-15'></Grid>
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-edit-business-owner-btn"
                                        type="submit"
                                    > SAVE CHANGES
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>}
                    </div>
                </FxCard>
            </Grid>
        )
    }
)
