import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { checkIsBase64, clean, convertToPhoneDisplayFormat, getBrowserType, getOperatingSystem } from '../../Utils/CommonBaseClass';
import { SignUpFlow } from './SignUpFlow';
import { isUserLoggedIn } from '../../../libs/utils/storageManager';
import { pushGTM } from '../../../libs/utils/utils';


/**
 * This component loads the Signup Flow Home component
 */
export const SignUpFlowHome: React.FC = () => {

        // declaring params
        const params = useParams<any>();
        let searchParams = useLocation().search;
        // remove the gtm parameters if any
        if(searchParams.includes('&gtm_debug')){
            searchParams = searchParams.split('&gtm_debug')[0]
        }

        const queryParams = checkIsBase64(searchParams.substring(1))? window.atob(searchParams.substring(1)):searchParams.substring(1);
        const qParams = (new URLSearchParams(queryParams));

        const phoneNumber = (qParams.get('phone'));
        const cType = qParams.get('type');
        let email = qParams.get('email')+'';;

        //backend not encoded the params properly.
        //convert space to + for email
        email = email.replace(" ","+")

        //if already logged in do  not show this page
        const history = useHistory();

        if (isUserLoggedIn()) {
        history.push("/");
        return <></>;
        }

        
        const inviteCode = params.token;
        // declaring source variables

        const details:any = {
            inviteCode: inviteCode,
            email: (email !== 'null' && email !== "")? email :'',
            phoneNumber: phoneNumber? convertToPhoneDisplayFormat(phoneNumber) :'',
        };
        if(cType &&  cType !== 'null') {details.type = cType }
        // configuring gtm for user signup launch page
            const gtmData ={
                event:"userInvitationTrigger",
                "userInvitationCode": inviteCode
            }
            pushGTM(gtmData)
            
            /**
             * Session meta data are collected here
             */
            const navigatorDetails:any = navigator;
            const utmParam = {
                utmSource: qParams.get('utm_source'),
                utmMedium: qParams.get('utm_medium'),
                utmCampaign: qParams.get('utm_campaign'),
                utmContent: qParams.get('utm_content')
            }
            const sessionMetadata = {
                utm: utmParam,
                refererUrl: document.referrer,
                browser: getBrowserType(navigatorDetails['userAgent']),
                os: getOperatingSystem(navigatorDetails['userAgent']),
                browserCodeName: navigatorDetails['appCodeName'],
                browserName: navigatorDetails['appName'],
                browserVersion: navigatorDetails['appVersion'],
                cookiesEnabled: navigatorDetails['cookieEnabled'],
                browserLanguage: navigatorDetails['language'],
                browserOnline: navigatorDetails['onLine'],
                platform: navigatorDetails['platform'],
                userAgent: navigatorDetails['userAgent'],
            }
            
            
            return (
            <Grid item container xs={12}>
                <SignUpFlow key="signupflowhome" id="signup-flow-home-component" details={clean(details)} sessionMetadata={sessionMetadata}></SignUpFlow>
            </Grid>

        )
    }