import React from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../../../libs/utils/logger";
import FxCardHeader from "../../../../Container/FxCardHeader";
import FxCard from "../../../../Container/FxCard";
import FxCardBody from "../../../../Container/FxCardBody";
import FxCardFooter from "../../../../Container/FxCardFooter";
import { FxButton } from "../../../../Action/FxButton";
import { clean } from "../../../../Utils/CommonBaseClass";
import HttpClient from "../../../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../../../libs/utils/utils";
import { ReactComponent as EnableIcon } from '../../../../../assets/svg/verify-check-icon-with-circle.svg';
import FxSnackBar from "../../../../Utils/fx-snack-bar";
const httpClient = HttpClient.getClient();

Logger.debug("enableWebhook.tsx", "Enable Webhook initializing");

interface IEnableWebhook{
  id: string;
  open: boolean;
  onClose: () => any;
  className: string;
  metadata?: {
    webhookId: any;
    setSuccessPopupConfigurations: (setSuccessCardOpen: any, setTitle: any, setDescription: any, setButtonText: any) => any;
  }
}

/**
 * This component handles the Enable action of webhook
 */
export const EnableWebhook: React.FC<IEnableWebhook> = React.memo((props) => {
  const { handleSubmit, formState: { isSubmitting } } = useForm();

  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {
    let status: any;
    let request: any;

    request = {};

    status = await enableWebhook(clean(request));
    status = processAPIResponse(status)

    if(status.status){
        props?.metadata?.setSuccessPopupConfigurations(true, 'Webhook Updated Successfully',`Webhook has been enabled successfully.`, 'Go to Webhook Details')
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
    props.onClose();
  }

  /**
   * Method to call api for enabling webhook
   * @param payloadData : request payload
   */
  async function enableWebhook(payloadData: any) {
    try {
      const url =
        "/webhookSubscription/id/" +
        props?.metadata?.webhookId + 
        "/enable";
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("enableWebhook.tsx", "error", err);
      return err;
    }
  }

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className="fx-freeze-debit-card-modal">
          <FxCardHeader id="enable-webhook-header" className="fx-card-header">
            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  <EnableIcon/>
                </Grid>
                <Grid item xs={12}>
                  <Typography id="fx-enable-webhook-modal-title" align="center" className="fx-webhook-action-title " >
                  Are you sure you want to enable webhook?
                  </Typography>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="enable-webhook-modal-body" className="fx-info-card">
            <Grid item xs={12} className="fx-modal-form flex column">
              <Typography id="title-delete-modal" variant="h5" className="fx-freeze-debit-card-modal-subtext">
                By proceeding with this action, you are confirming that you are enabling the webhook.
              </Typography>
            </Grid>
          </FxCardBody>
          <FxCardFooter
            id="enable-webhook-modal-Footer"
            className="fx-footer"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"enable-webhook-submit-button"}
                type="submit"
                isSubmitting={isSubmitting}
              >
                ENABLE NOW
              </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>
    </Grid>
  );
});
