import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { FxTextEdit } from "../../Input";

Logger.debug("InvoiceAmountsTable.tsx", "Invoice Accounts Table card initializing");

/**
 * This component is created to handle the Invoice Card Details along with the AVS Verification.This is part of create collect via card.
 */

interface IInvoiceAmountsTable {
  id: string;
  register: any;
  control: any;
  setValue: any;
  watch: any;
  errors: any;
  data: {
    subtotal: number;
    taxAmount: number;
    discountAmount: number;
    freightAmount: number;
    dutyAmount: number;
    additionalCharges: number;
    invoiceTotal: number;
  };
  taxExemptValue?: boolean;
  invoiceItemsData: { totalAmount: number }[];
  isAmountChanged?: boolean;
}
export const InvoiceAmountsTable: React.FC<IInvoiceAmountsTable> = (props) => {
  const { id, register, control, setValue, errors, data, watch,  taxExemptValue, invoiceItemsData, isAmountChanged } = props;

  const subtotal = Number(watch('invoice-amounts.subtotal') || 0);
  const taxAmount = Number(watch('invoice-amounts.taxAmount') || 0);
  const discountAmount = Number(watch('invoice-amounts.discountAmount') || 0);
  const freightAmount = Number(watch('invoice-amounts.freightAmount') || 0);
  const dutyAmount = Number(watch('invoice-amounts.dutyAmount') || 0);
  const additionalCharges = Number(watch('invoice-amounts.additionalCharges') || 0);
  const invoiceTotal = Number(watch('invoice-amounts.invoiceTotal') || 0);

  /**
   * set the taxAmount value to null when the taxExempt toggle is enabled
   */
  useEffect(() => {
    if(taxExemptValue === true){
      const totalAmount = invoiceTotal - taxAmount;
      setValue(`invoice-amounts.invoiceTotal`, totalAmount);
      setValue('invoice-amounts.taxAmount', null);
    }
  },[taxExemptValue, setValue, invoiceTotal, taxAmount]);

  //Method to update the subtotal and total amount based on invoice items
  useEffect(() => {
    if (invoiceItemsData) {
      const subAmount = invoiceItemsData.reduce((acc: any, item) => acc + item?.totalAmount, 0);
      setValue(`invoice-amounts.subtotal`, subAmount);
    }
    const totalAmount = subtotal + taxAmount - discountAmount + freightAmount + dutyAmount + additionalCharges;
    setValue(`invoice-amounts.invoiceTotal`, totalAmount);
  }, [invoiceItemsData, setValue, isAmountChanged, subtotal, taxAmount, discountAmount, freightAmount, dutyAmount, additionalCharges]);

  
  return (
      <fieldset id={id} className="fx-container-create-schdule-summery fx-invoice-details-amounts-table">
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>SUBTOTAL</Grid>
          </Grid>
          <Grid sm={4}>
            <FxTextEdit
              register={{
                ...register(`invoice-amounts.subtotal`),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.subtotal
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              id={`invoice-amounts.subtotal`}
              name={`invoice-amounts.subtotal`}
              setValue={setValue}
              isEditable={false}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
        {!taxExemptValue && (
          <Grid
            item
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={"2rem"}
            marginBottom={"1rem"}
            paddingRight={"4rem"}
          >
            <Grid>
              <Grid>TAX AMOUNT</Grid>
            </Grid>
            <Grid sm={4}>
              <FxTextEdit
                register={{
                  ...register(
                    `invoice-amounts.taxAmount`
                  ),
                }}
                control={control}
                rules={{ required: false }}
                className={
                  errors.taxAmount
                    ? "border-error-input fx-input-edit clear-number-field-arrow"
                    : "fx-input-edit clear-number-field-arrow"
                }
                id={`invoice-amounts.taxAmount`}
                name={`invoice-amounts.taxAmount`}
                setValue={setValue}
                defaultValue={data?.taxAmount || ''}
                isEditable={true}
                valuePattern={/-|\+|e|E/}
                type="number"
                showDecimal={true}
                onWheel={(e: any) => e.target.blur()}
                prefix="$"
              />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>DISCOUNT</Grid>
          </Grid>
          <Grid sm={4}>
            <FxTextEdit
              register={{
                ...register(
                  `invoice-amounts.discountAmount`
                ),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.discountAmount
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              id={`invoice-amounts.discountAmount`}
              name={`invoice-amounts.discountAmount`}
              setValue={setValue}
              defaultValue={data?.discountAmount || ''}
              isEditable={true}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>FREIGHT AMOUNT</Grid>
          </Grid>
          <Grid sm={4}>
            <FxTextEdit
              register={{
                ...register(
                  `invoice-amounts.freightAmount`
                ),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.freightAmount
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              defaultValue={data?.freightAmount || ''}
              id={`invoice-amounts.freightAmount`}
              name={`invoice-amounts.freightAmount`}
              setValue={setValue}
              isEditable={true}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>DUTY AMOUNT</Grid>
          </Grid>
          <Grid sm={4}>
            <FxTextEdit
              register={{
                ...register(
                  `invoice-amounts.dutyAmount`
                ),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.dutyAmount
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              id={`invoice-amounts.dutyAmount`}
              name={`invoice-amounts.dutyAmount`}
              defaultValue={data?.dutyAmount || ''}
              setValue={setValue}
              isEditable={true}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>ADDITIONAL CHARGES</Grid>
          </Grid>
          <Grid xs={4}>
            <FxTextEdit
              register={{
                ...register(
                  `invoice-amounts.additionalCharges`
                ),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.additionalCharges
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              defaultValue={data?.additionalCharges || ''}
              id={`invoice-amounts.additionalCharges`}
              name={`invoice-amounts.additionalCharges`}
              setValue={setValue}
              isEditable={true}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"2rem"}
          marginBottom={"1rem"}
          paddingRight={"4rem"}
        >
          <Grid>
            <Grid>INVOICE TOTAL</Grid>
          </Grid>
          <Grid xs={4}>
            <FxTextEdit
              register={{
                ...register(
                  `invoice-amounts.invoiceTotal`
                ),
              }}
              control={control}
              rules={{ required: false }}
              className={
                errors.invoiceTotal
                  ? "border-error-input fx-input-edit clear-number-field-arrow"
                  : "fx-input-edit clear-number-field-arrow"
              }
              id={`invoice-amounts.invoiceTotal`}
              name={`invoice-amounts.invoiceTotal`}
              setValue={setValue}
              isEditable={false}
              valuePattern={/-|\+|e|E/}
              type="number"
              showDecimal={true}
              onWheel={(e: any) => e.target.blur()}
              prefix="$"
            />
          </Grid>
        </Grid>
      </fieldset>
  );
}
