import React , {useEffect, useState }from 'react'; // we need this to make JSX compile
import { useHistory, useParams } from 'react-router-dom';
import FxCard from "../../Container/FxCard";
import { Grid, Typography } from '@mui/material';
import { LedgerBatchSummary } from './LedgerBatchSummary';
import { getKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import { CardTransactionListing } from './FxCardTransactionListing';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
const httpClient = HttpClient.getClient();

/**
 * This component handles the rendering of the Ledger Batch details
 */
export const LedgerBatchDetails = () => {
        interface LedgerBatchParams {
            id: string
            number:any;
            type: string;
        }

        const params = useParams<LedgerBatchParams>();
        const dispatch = useDispatch();
        const batchId = params.id;

        const accountId = getKey("selectedAccount")?.id;
        const [batchData, setBatchData] = useState<any>();

        const history = useHistory<any>()
        const stateObj= history.location.state;

        
    useEffect(() => {
        getBatchData();
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: {title: 'Batch ID - ' + batchId, backButton: stateObj?.url} } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * This Method handles the calling of the batch summary api
     */
    async function getBatchData(){
        try {
            let url = 'batch/batchId/' + batchId + '/summary?accountId=' + accountId;
            if(stateObj?.batchHash && stateObj?.batchHash !== ''){
                url = 'batch/batchId/' + batchId + '/summary?accountId=' + accountId + '?hash=' + stateObj?.batchHash;
            }
            const batchSummaryData = await httpClient.get(getCustomerUrl(url, true))
            if(batchSummaryData){
                setBatchData(batchSummaryData);
            }
        } catch (err) {
            Logger.error("LedgerBatchDetails.tsx", "error", err);
            return false;
        }
    }
        return (
            <>
            <Grid id="customer-profile-password-grid"   container  xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container">
                    {batchData && <LedgerBatchSummary batchId={batchId} data={batchData}/>}
              <Grid id="ledger-batch-details-card-Home-Grid" item xs={12} >
            <FxCard id="ledger-batch-details-card-Home-Card" className="fx-card-general fx-theme-passport" >
                <FxCardHeader id="ledger-batch-details-card-Home-Card-Header" className="fx-card-header"> 
                    <Grid item container xs={12} direction='row' spacing={1}>
                        <Grid item xs={12} className="fx-flex-grow">
                            <Grid className='fx-tab-border'>
                            <Typography variant='h5'>Card Transactions</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </FxCardHeader>
             <CardTransactionListing key="cardTransactionListing"  batchId={batchId}  />
                </FxCard>
            </Grid>

              </Grid>
            </>
        )

    }
