import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { Filter } from '../../Data/Filter';
import { getKey } from '../../../libs/utils/storageManager';
import { useHistory } from 'react-router';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { IFilterColumns, IDataGridProps, IFilterFields } from '../../../types/common.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { removeUnderScoreConvertTitleCase } from '../../Utils/CommonBaseClass';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import moment from 'moment';
import { truncateChars } from '../../Utils/CommonBaseClass';
import ShowFasterFundingAsPerTheme from '../../Utils/ThemeChecker';
import { FxButtonGroup } from '../../Action/FxButtonGroup';
import { getMobileViewTransactionAmountJSX, getMobileViewTransactionsArrowJSX, getTransactionAmountJSX, getTransactionsArrowJSX } from '../../../libs/utils/TransactionService';
import { FxPrimaryFilter, IPrimaryFilterData } from '../../Data/FxPrimaryFilter';

/**
 * This component handles the list of Transaction List
 */
export const TransactionList: React.FC<any> = React.memo(
    (props) => {
        const history = useHistory()
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const isNewCardStatusLifecycleApplied = getKey('isNewCardStatusLifecycleApplied');

        /**
        * useeffect to load title while loading page
        */
        useEffect(() => {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Transactions' } } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        /**
        * useEffect to reset the filter while unmount the page
        */
        useEffect(() => () => {
            updateComponentParams(dispatch, 'transactions-list-card-filter', { resetFilter: true });
        }, [dispatch]);

        let src: any = {
            url: "transaction/list",
            method: "POST",
            data: {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                    "sortBy": "lastUpdatedOn",
                    "sortOrder": "desc"
                },
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "accountId",
                            "values": [getKey("selectedAccount")?.id]
                        },
                        {
                            "operator": "IN",
                            "key": "status",
                            "values": ['SCHEDULED', 'PROCESSING', 'IN_DELIVERY', 'PENDING', 'INITIATED', 'UNCAPTURED', 'CAPTURED', 'PARTIALLY_CAPTURED', 'DELIVERED']
                        }
                    ]
                }
            }
        }

        if(!getKey("selectedAccount")?.id){
            src = {
                url: "",
                method: "POST",
                data: {}
            }
        }

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "icon",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem", "paddingLeft": "0 !important" },
                    "dataStyle": { "textAlign": "left !important" }
                },
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "scheduleDate",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": { "width": "15rem" }
                },
                {
                    "label": "Txn ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "15rem" }
                },
                {
                    "label": "Auth Code",
                    "enable": true,
                    "fieldName": "authCode",
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "10%" }
                },
                {
                    "label": "PURPOSE",
                    "enable": true,
                    "fieldName": "purpose",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "30rem" }
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true,
                    "style": { "width": "10rem" }
                },
                {
                    "label": "STATUS REASON",
                    "enable": true,
                    "fieldName": "statusReason",
                    "type": "fri://fieldtype/default",
                    "truncateChars": 35,
                    "default": true,
                    "valueTransformer": removeUnderScoreConvertTitleCase,
                    "style": { "width": "30rem" }
                },
                {
                    "label": "AMOUNT",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"text-align": "end","width": "20rem"}
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem"}
                },
                {
                    "label": "LAST UPDATED ON",
                    "enable": true,
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": { "width": "12rem"}
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem"}
                }
            ]
        }

        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        //primary filters data
        const primaryFilterData: IPrimaryFilterData[] = [
            { label: 'Txn ID', value: 'id', operator: 'eq' },
            { label: 'Auth Code', value: 'authCode', operator: 'eq' }
        ];

        /**
         * Method handles the transformation of Transaction List
         * @param data : Transaction List
         * @returns : transformed data
         */
        function transactionList(data: any) {
            let newData: any = [];
            // eslint-disable-next-line array-callback-return
            data?.map((item: any, i: number) => {
                newData[i] = { ...data[i] }

                if(item?.method === 'VIRTUAL_CARD'){
                    const processingDetail = item?.processingDetail;
                    newData[i].method = 'VIRTUAL CARD';
                    newData[i].cardId = processingDetail?.virtualCard?.id;
                }
                if(item?.method === 'INTERNATIONAL_WIRE'){
                    newData[i].method = 'INTERNATIONAL WIRE';
                }

                newData[i].scheduleDate = item.scheduleDate;
                newData[i].status = item.status;
                if ((item.type === 'EXTERNAL' || item.type === "REFUND") && item.method === 'CARD' ) {
                    newData[i].purpose = <><span>{item.purpose ? (item.source?.account?.id ? 'REFUND - Ref: ' : item.destination?.account?.id ? 'SALE - Ref: ' : '') + truncateChars(item?.purpose, 35) : ''} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'recurring-method'} value={newData[i]?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>{item?.processingDetail?.quickSettle && item?.status !== 'VOIDED' && <ShowFasterFundingAsPerTheme/>}</>
                }
                else {
                    newData[i].purpose = <><span title={item?.purpose}>{` ${item.purpose ? truncateChars(item?.purpose, 35) : ''}`} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'recurring-method'} value={newData[i]?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>{item.processingDetail?.quickSettle && item?.status !== 'VOIDED' && <ShowFasterFundingAsPerTheme/>}</>
                }
                newData[i].amount = getMobileViewTransactionAmountJSX(item, getKey("selectedAccount")?.id);
                newData[i].icon = getMobileViewTransactionsArrowJSX(item, getKey("selectedAccount")?.id);


            });
            return newData;
        }

         /**
         * @param data : Transaction List
         * @returns : transformed data
         */
        const transactionListGridTransformer = (data: any) => {
            let newData: any = [];
            let cur_date: any = '';
             data?.forEach((item: any,i:number) => {
                newData[i] = { ...data[i] }
                let title: any
                if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                 newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                     <Grid item container xs={2} alignItems='center' justifyContent={'flex-start'}>
                        {getTransactionsArrowJSX(item, getKey("selectedAccount")?.id)}
                     </Grid>
                     <Grid item container xs={10} alignItems='center'>
                         <Grid item container xs={12}>
                             <Grid item>
                                 <Grid title={item?.purpose}><Typography variant='h3'>{truncateChars(item?.purpose, 25)}&nbsp;</Typography></Grid>
                             </Grid>
                             <Grid item className="fx-grid-list-method">
                                 &nbsp;<FxPaymentModeStatus tooltip={true} id={'transaction-method'} value={item?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>
                             </Grid>
                             {item.processingDetail?.quickSettle && <ShowFasterFundingAsPerTheme/>}
                             <Grid item container xs justifyContent='end'>
                                {getTransactionAmountJSX(item, getKey("selectedAccount")?.id)}
                             </Grid>
                         </Grid>
                         <Grid item container xs={12} spacing={1} alignItems='center'>
                             <Grid item>
                                 <Grid><Typography variant='h5'>Txn ID: {item?.id}</Typography></Grid>
                             </Grid>
                             <Grid item className="fx-grid-list-status">
                                 <FxStatus id="transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                             </Grid>
                         </Grid>
                     </Grid>
                 </Grid>
            })
            return newData;
        }

        const datagridProps: IDataGridProps = {
            id: "transaction-list-schedule",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
        };

        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "Date",
                    "toLabel": "To",
                    "fieldName": "scheduleDate",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Amount",
                    "fieldName": "amount",
                    "type": "fri://fieldtype/amountrange",
                    "data": [],
                },
                {
                    "label": "Status",
                    "fieldName": "status",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "Scheduled", "value": "SCHEDULED", "checked": false },
                        { "label": "Pending", "value": "PENDING", "checked": false },
                        { "label": "Processing", "value": "PROCESSING", "checked": false },
                        { "label": "In Delivery", "value": "IN_DELIVERY", "checked": false },
                        ...(isNewCardStatusLifecycleApplied === 'true' ? 
                        [{ "label": "Captured", "value": "CAPTURED", "checked": false },
                         { "label": "Initiated", "value": "INITIATED", "checked": false }]
                         : [{ "label": "Approved", "value": "APPROVED", "checked": false }]),
                        { "label": "Uncaptured", "value": "UNCAPTURED", "checked": false },
                        { "label": "Partially Captured", "value": "PARTIALLY_CAPTURED", "checked": false },
                        { "label": "Delivered", "value": "DELIVERED", "checked": false }
                    ]
                },
                {
                    "label": "Quick Settle",
                    "fieldName": "quickSettle",
                    "type": "fri://fieldtype/radio",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "Yes", "value": "true" },
                        { "label": "No", "value": "false" }
                    ]
                },
                {
                    "label": "Created On",
                    "toLabel": "To",
                    "fieldName": "createdOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Last Updated On",
                    "toLabel": "To",
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Method",
                    "fieldName": "method",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "ACH", "value": "ACH", "checked": false },
                        { "label": "Card", "value": "CARD", "checked": false },
                        { "label": "Check", "value": "CHECK", "checked": false },
                        { "label": "Book", "value": "BOOK", "checked": false },
                        { "label": "Wire", "value": "WIRE", "checked": false },
                        { "label": "International Wire", "value": "INTERNATIONAL_WIRE", "checked": false },
                        { "label": "Virtual Card", "value": "VIRTUAL_CARD", "checked": false },
                    ]
                }
            ]
        }

        /**
        * Method handles the redirection to details page
        * @param event : event parameter
        * @param data : row data
        */
        function redirectDetailsPage(event: any, data: any) {
            if (data?.source?.account?.id) {
                history.push(`/transactions/send/${data.id}`);
            }
            else if (data?.destination?.account?.id) {
                history.push(`/transactions/collect/${data.id}`);
            }
        }

        const permissionArray: any = [
            {
                entity: "Accounts",
                name: "Card Collect",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Check Collect",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Ach Collect",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Wire Send",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Book Send",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Check Send",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Ach Send",
                operation: "View"
            },
            {
                entity: "Accounts",
                name: "Virtual Card Send",
                operation: "View"
            }
        ]

        const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
        const selectedAccount = accountListInfo?.params?.selectedOption;
        //type of Transactions
        const transactionTypeData = [
            { id: 'IN_PROCESS_TRANSACTIONS', value: 'IN_PROCESS_TRANSACTIONS', label: 'IN PROCESS TRANSACTIONS', selected: true},
            { id: 'TRANSACTION_HISTORY', value: 'TRANSACTION_HISTORY', label: 'TRANSACTION HISTORY', selected: false }
        ];
        
        /**
         * Method handles the change event of the transaction Type
         */
        const handleTypeChange = (item: any) => {
            try {
                if (item.value && item.value === 'TRANSACTION_HISTORY') {
                      history.push('/transactionsHistory');
                }
            }
            catch (e) { }
        }

        return (

            <Grid id="transactions-list-first-grid" item xs={12}>
                <Grid item xs={12} className="fx-flex-grow fx-ea-type-button-mobile" >
                    <FxButtonGroup id="all-transaction-card-button-group" options={transactionTypeData} onClick={handleTypeChange} />
                </Grid>
                <Grid item xs={12} >&nbsp;</Grid>
                <Grid container xs={12} className="fx-layout-list" >
                    <FxCard id="transactions-list-card" className="fx-theme-passport">
                        <FxCardHeader>
                            <Grid id="transactions-list-card-header-first-grid" item container justifyContent="space-between" xs={12}>
                            <Grid>
                                <Grid item>
                                <FxPrimaryFilter id="transactions-list-primary-filter" name="transactions-list-primary-filter" data={primaryFilterData} dataGridProps={datagridProps} />
                                </Grid>
                            </Grid>
                            <Grid id="transactions-list-header-first-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                                <Grid item>
                                <Filter id="transactions-list-card-filter" multiplePermission={true} permissions={permissionArray} field={filterFields} dataGridProps={datagridProps} hiddenParameter={['accountId','status']}/>
                                </Grid>
                            </Grid>
                            </Grid>
                        </FxCardHeader>

                        <FxCardBody id="transactions-list-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="transactions-list-card-body-grid" item xs={12}>
                            {!isSmallScreen ?<DataGrid id="transaction-list-schedule" multiplePermission={true} permissions={permissionArray} tableClass="fx-table-passport" dataTransformer={transactionList} source={src} column={column} rowClick={selectedAccount?.type !== 'DEPOSIT' && redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            :<FxGridList id="transaction-list-schedule" source={src} permissions={permissionArray} dataTransformer={transactionListGridTransformer} column={gridColumn} rowClick={selectedAccount?.type !== 'DEPOSIT' && redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} /> }
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="transactions-list-card-footer">
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })
