import React from 'react'
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { toTitleCase } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("ResendEmail.tsx", "Resend Email initializing")
/**
 * This component handles the resend email
 */
export const ResendEmail: React.FC<any> = React.memo(
    (props) => {

        const { register, formState: { errors }, handleSubmit, control } = useForm();

        /**
         * Async function handles the api call for Resend Email
         * @param req : form request
         * @returns : response object
         */
        async function resendEmail(req: any) {

            try {
                const url: any = 'transaction/id/' + props.scheduleId + '/notify';
                const data: any = await httpClient.post(getCustomerUrl(url, false), req).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("ResendEmail.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles to resend email
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                comment: data.comment,
                communicationChannel: ["email"]
            }

            let status: any = await resendEmail(req);
            status = processAPIResponse(status)

            const successMessage = toTitleCase(props.schedule) + 'Resend Email Successfully';
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                handleClose();
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

         /**
         * On close handler
         */
        function handleClose() {
            props?.close();
        }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="resend-email-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-resend-email-modal" variant="h4">
                                            Are you sure to Resend the Email ?
                                        </Typography>
                                        <Typography id="sub-title-resend-email-modal" variant="h6">
                                            You cannot undo this action
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='resend-email-modal-close-icon'>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="resend-email-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("comment") }} className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="comment" label="Comment" name="comment" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="resend-email-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <Grid className='fx-padding-right-15'>
                                        <FxButton
                                            variant="contained"
                                            className="fx-button"
                                            id="resend-email-button"
                                            onClick={handleClose}
                                        > Cancel
                                        </FxButton>
                                    </Grid>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id={"resend-email-button"}
                                        type="submit"
                                    >
                                       YES, RESEND IT
                                    </FxButton>


                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        )
    });
