/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"; // we need this to make JSX compile
import { Logger } from "../../../../libs/utils/logger";
import { useForm } from "react-hook-form";
import { FxTextEdit } from "../../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getCustomerUrl, processAPIResponse } from "../../../../libs/utils/utils";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import FxSnackBar from "../../../Utils/fx-snack-bar";
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import { FxSkeltonList } from "../../Cards/FxSkelton";
import HttpClient from "../../../../libs/utils/httpClient";
import { Divider, Grid, Typography } from "@mui/material";
import { clean, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { WebhookEvents } from "./WebhookEvents";
import { removeValues, updateEventListPerCustomerType } from "../WebhookUtility";

Logger.debug("CreateWebhook.tsx","Create Webhook Form");

/**
 * This component handles the creation of Webhook
 */
export const CreateWebhook: React.FC<any> = React.memo(
    (props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory<any>();
    const params = useParams<any>();
    const id = params.id;
    const stateObj = history?.location?.state;
    const httpClient = HttpClient.getClient();
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(props.source ? true : false);
    const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control, resetField, watch, getValues } = useForm();
    let webhookId:any;
    

    /**
     * Setting the context data into the component state
     */
    if (context?.data?.body && !isDataLoaded) {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Webhook', backButton: '/webhook/view/'+context?.data?.body?.id } } });
        setIsDataLoaded(true);
        setIsEdit(true);
        updateComponentParams(dispatch, props?.id, { selectedAuthenticationType: context?.data?.body?.secret?.type, mandateAuthDetails: context?.data?.body?.secret ? true : false })

    }

    /**
     * useEffect to be called at the time of first render to get the list of the Events available
     */
    useEffect(()=> {
        getAllAvailableEventList();
    },[]);

    /**
     * Function to be called before loading the component
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        if(!props?.source){
            dispatch({
                type: "DATA_UPDATE_COMPONENT_REDUCER",
                payload: {
                    id: "page-title-details",
                    data: { title: "Add New Webhook", backButton: "/webhooks" },
                },
            });
            updateComponentParams(dispatch, props?.id, { selectedAuthenticationType: '', mandateAuthDetails: false })
        }
        return () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });  
        }
    }, []);

    /**
     * Method to call api for webhook creation
     * */
    async function createWebhook(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl('webhookSubscription',false), paylaoddata)
            return data;
        } catch (err) {
            Logger.error("createWebhook.tsx", "error", err);
            return err;
        }
    }
    
    /**
     * Method to call api for edit approval policy
     * */
    async function updateWebhook(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl('webhookSubscription/id/' + context?.data?.body?.id,false), paylaoddata)
            return data;
        } catch (err) {
            Logger.error("updateWebhook.tsx", "error", err);
            return err;
        }
    }

    /**
     * This method flattens the array values of the eventName node and returns that array
     * @param data 
     * @returns 
     */
    function transformWebhookEventsData(data:any) {
        let modifiedData = data.flatMap((item:any) => item.eventName);
        const filteredArray = modifiedData.filter((element:any) => element !== undefined);
        return filteredArray;
      }

    /**
     * Method returns the generated request
     * @param data 
     * @param tag 
     * @returns 
     */
    const generateUpdateRequest = (data:any, apiData:any, tag:any) => {
        if(tag === 'basic_info'){
            let req:any = {
                name: data.name,
                description: data.description,
                url: data.url,
            }
            if((data?.authenticationDetails !== '' && (apiData?.secret?.value !== data?.value))){
                req.secret = {
                    type: data?.authenticationDetails,
                    key: data?.key,
                    value: data?.value
                }
            } 

            if(apiData?.secret && data?.authenticationDetails === ''){
                req.secret = null;
            }

            return req;
        }
        if(tag === 'event_info'){
            let req:any = {
                name: apiData.name,
                description: apiData.description,
                url: apiData.url,
                subscribedEventType: transformWebhookEventsData(data['webhook-events'])
            }
            return req;
        }
    }

    /** 
     * Source for the Webhook Detail API
     */
    const webhook_source = {
        url: "/webhookSubscription/id/" + context?.data?.body?.id,
        method: "GET"
    }

    /**
     * Async function handles the on submit of form
     * @param data : form request
     */
    async function handleSaveWebhook(data: any) {
        let createReq: any = {};
        let updateReq: any = {};
        let status: any;

        if(context?.data?.body){
            updateReq = generateUpdateRequest(data, context?.data?.body, stateObj?.tag)
        }else{
            createReq = {
                name: data.name,
                description: data.description,
                url: data.url,
                subscribedEventType:  transformWebhookEventsData(data['webhook-events'])
            }
            if(data?.authenticationDetails !== ''){
                createReq.secret = {
                    type: data.authenticationDetails,
                    key: data?.key,
                    value: data?.value
                }
            } 
            createReq = clean(createReq);
        }

        if (context?.data?.body) {
            status = await updateWebhook(updateReq);
        } else {
            status = await createWebhook(createReq);
        }
        if (status?.headers?.url) {
            webhookId = (status.headers.url).split('/').pop();
        }

        status = processAPIResponse(status)

        if (status.status) {
            // api success
            if (context?.data?.body) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Webhook Editted successfully!',
                });
                updateComponentParams(dispatch,'webhook-detail-info-card-'+context?.data?.body?.id,{});
                dispatch({
                    type: "DATA_API_PARAM_CHANGED",
                    payload: { id: "webhook-detail-info-card-"+context?.data?.body?.id, source: webhook_source},
                });
                history.push('/webhook/view/' + context.data.body.id);
            } else {
                setTimeout(() => {
                    history.push("/thanks/manage/webhooks/" + webhookId);
                }, 400)
            }
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    /**
     * Function to handle Close Button of Create/Edit Screen 
     */
    const handleClose = () => {
        if (context?.data?.body) {
            history.push("/webhook/view/" + context.data.body.id);
        } else {
            history.push("/webhooks");
        }
    };

    /**
     * Authentication Details Dropdown Data
     */
    const authenticationDetailDropdownData = [
        { label: 'Select', value: '' },
        { label: 'Header', value: 'header' },
    ];

    /**
     * Method handles the updation of the state when Authentication Detail Dropdown value is changed
     * @param event 
     */
    const handleSelectHolderChange = (event: any) => {
        let selectedValue = event.target.value;
        if(selectedValue === ''){
            setValue('key', '');
            setValue('value', '');
        }
        updateComponentParams(dispatch, props?.id, { mandateAuthDetails : selectedValue !== '' ? true : false, selectedAuthenticationType: selectedValue })
    }

    /**
     * This method handles the retrievel of the list of the events that the user can subscriber to
     */
    const getAllAvailableEventList = async () => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                  "sortBy": "lastUpdatedOn",
                  "sortOrder": "desc"
                },
                "criteria": {
                  "filters": []
                }
            }
            await httpClient.post(getCustomerUrl('/webhookSubscription/event/list'),payloadData).then(response => {
                const eventListData = response?.data?.resources['INSTANT'];
                const trimmedData = removeValues(eventListData, ["customer.individual.create", "customer.business.create","customer.jointtenancy.create"]);
                const updatedData = updateEventListPerCustomerType(trimmedData);
                updateComponentParams(dispatch, props?.id, { eventList: updatedData })
            });
        } catch (e) { }
    }
 
    return (
        <Grid item xs={12} className='fx-create-approval-policy'>
            <Grid id="create-webhook-sub-grid" container justifyContent="center" alignItems="center" className="flex column" >
            <Grid id="create-webhook-second-grid" item xs={12} sm={6}>
                <FxCard className="fx-schedule-card-general fx-theme-passport fx-no-box-shadow">
                    {(isEdit && !isDataLoaded) && <FxSkeltonList height="20rem" />}
                    {((isEdit && isDataLoaded) || !isEdit) && <div className="fx-form-edit-profile flex column">
                        <form id="create-webhook" onSubmit={handleSubmit(handleSaveWebhook)}>
                            <FxCardBody className="fx-info-card">
                                <Grid container className="flex column fx-container-create-schdule" >
                                    <Grid item xs={12} className='fx-container-create-schdule-middle-scroll fx-container-create-schdule-left-layout'>
                                        <FxCard id="create-webhook-form-card" className="create-webhook-form">
                                            <FxCardBody id="create-webhook-form-card-body">
                                                <Grid container>
                                                    {/** Webhook Info Section */}
                                                    {((isEdit && stateObj?.tag === 'basic_info') || (!isEdit)) && <Grid container xs={12} id='create-webhook-form-webhook-info-grid'>
                                                        <Grid item xs={12} id='create-webhook-form-webhook-info-grid-title' className="create-webhook-form-basic-info-title">
                                                            <Typography variant="h4">Webhook Info</Typography>
                                                        </Grid>
                                                        <Grid container item id="create-webhook-form-webhook-info-form-grid" xs={12} className="fx-form-edit-profile" display={"flex"} gap={"1.5rem"}>
                                                            <Grid container item spacing={1.5} xs={12} sm={12} display={"flex"}>
                                                                <Grid container item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("name") }} className={errors.name ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-webhook-name-textbox" label="Name*" name="name" variant="outlined" defaultValue={context?.data?.body?.name ? context.data.body.name : ''} />
                                                                </Grid>
                                                                <Grid container item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("description") }} className={errors.description ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-webhook-description-textbox" label="Description" name="description" variant="outlined" defaultValue={context?.data?.body?.description ? context.data.body.description : ''} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={12} sm={12}>
                                                                <FxTextEdit register={{ ...register("url") }} className={errors.url ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-webhook-url-textbox" label="URL*" name="url" variant="outlined" defaultValue={context?.data?.body?.url ? context.data.body.url : ''} isEditable={context?.data?.body?.status === 'ENABLED' ? false : true}/>
                                                            </Grid>

                                                            <Grid container item spacing={1.5} xs={12} sm={12} display={"flex"}>
                                                                <Grid container item xs={12} sm={4}>
                                                                    <FxMaterialSelect register={{ ...register("authenticationDetails") }} control={control} rules={{ required: false }} className={errors.authenticationDetails ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-webhook-authentication-details-textbox" data={authenticationDetailDropdownData} name="authenticationDetails" label="Authentication Details" onChange={handleSelectHolderChange} setValue={setValue} value={context?.data?.body?.secret?.type ?? ''} />
                                                                </Grid>
                                                                <Grid container item xs={12} sm={4}>
                                                                    <FxTextEdit register={{ ...register("key") }} className={errors.key ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: props?.mandateAuthDetails ? true : false }} id="create-webhook-key-textbox" label={props?.mandateAuthDetails ? "Key*" : "Key"} name="key" variant="outlined" defaultValue={context?.data?.body?.secret?.key ? context.data.body.secret.key : ''} isEditable={(props?.selectedAuthenticationType && props?.selectedAuthenticationType !== '') ? true : false}/>
                                                                </Grid>
                                                                <Grid container item xs={12} sm={4}>
                                                                    <FxTextEdit register={{ ...register("value") }} className={errors.value ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: props?.mandateAuthDetails ? true : false }} id="create-webhook-value-textbox" label={props?.mandateAuthDetails ? "Value*" : "Value"} name="value" variant="outlined" defaultValue={context?.data?.body?.secret?.value ? context.data.body.secret.value : ''} isEditable={props?.selectedAuthenticationType && props?.selectedAuthenticationType !== ''  ? true : false}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>}

                                                    {/** Webhook Event Selection Section */}
                                                    {((isEdit && stateObj?.tag === 'event_info') || (!isEdit)) && <Grid container xs={12} id='create-webhook-form-event-selection-grid' marginTop={'2.625rem'}>
                                                        <Grid item xs={12} id='create-webhook-form-event-selection-grid-title' className="create-webhook-form-basic-info-title">
                                                            <Typography variant="h4">Trigger this Webhook for *</Typography>
                                                        </Grid>
                                                        <Grid container item id="create-webhook-form-event-selection-grid" xs={12} className="fx-form-edit-profile">
                                                                    <WebhookEvents 
                                                                    data={props?.eventList}
                                                                    register={register}
                                                                    setValue={setValue}
                                                                    control={control}
                                                                    resetField={resetField}
                                                                    errors={errors}
                                                                    getValues={getValues}
                                                                    watch={watch} 
                                                                    id={"create-webhook-form-event-form-grid"}
                                                                    treeData={context?.data?.body?.subscribedEventType ?? []}                                                          
                                                                    />  
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                            </FxCardBody>
                                            <FxCardFooter id="create-approval-form-card-footer" className="fx-footer">
                                                <Grid container direction="row" justifyContent="flex-end" className="" paddingTop={'2rem'}>
                                                    <Divider/>
                                                    <FxButton variant="contained"
                                                        className="fx-button fx-button-cancel"
                                                        id="create-approval-form-card-cancel-button"
                                                        onClick={handleClose}>
                                                        Cancel
                                                    </FxButton>
                                                    <span className="fx-padding-right-16" />
                                                    <FxButton
                                                        disableRipple={false}
                                                        className="fx-button fx-button-theme"
                                                        id={"create-approval-policy-btn"}
                                                        type="submit"
                                                        isSubmitting={isSubmitting}
                                                    >
                                                            SAVE WEBHOOK
                                                    </FxButton>
                                                </Grid>
                                            </FxCardFooter>
                                        </FxCard>
                                    </Grid>
                                </Grid>
                            </FxCardBody>

                            <Grid
                                container
                                xs={12}
                                id={`form-card-footer`}
                                className='fx-card-footer-mobile'
                                justifyContent='space-around'
                            >

                            </Grid>
                        </form>
                    </div>}
                </FxCard>
            </Grid>
            </Grid>
        </Grid>
    );
});
