import React, { useEffect } from 'react'; // we need this to make JSX
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive'
import moment from 'moment';
import { Logger } from '../../../../libs/utils/logger';
import HttpClient from '../../../../libs/utils/httpClient';
import { IFileInfo } from '../../../Data/FxAgreementLinkCard';
import { convertToPhoneRequestFormat, convertToPhoneDisplayFormat, updateComponentData, fetchDataFromState, updateComponentParams, callAPI, Method, getLastFourDigits } from '../../../Utils/CommonBaseClass';
import { AccountIdentifierSignup } from '../Common/AccountIdentifierSignup';
import { ReviewAndSubmitSignup } from '../Common/ReviewAndSubmitSignup';
import { ICardData } from '../Common/ReviewInfoCard';
import { SignUpLeftBar } from './SignUpLeftBar';
import { SignUpIndividualDetails } from './SignUpIndividualDetails';
import { getCustomerUrl, processAPIResponse, pushGTM } from '../../../../libs/utils/utils';
import { getKey, removeCookieCustom, setKey } from '../../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { useDispatch, useSelector } from 'react-redux';
const httpClient = HttpClient.getClient();

/**
 * Component: Signup individual
 * Usage: User signup page
 */

Logger.debug("SignUp.tsx", "SignUp initializing")

export const SignUpIndividual: React.FC<any> = React.memo(
    (props) => {
        let context : any;
        ({ context, props } = RegisterComponent(props));
        const { isLoading, step, paymentIdDetailData, paymentIdDetailReq, isUpdateContactRequest, initialIndData   } = props;
        const history = useHistory();
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })

        
        /**
         * Useeffect hook resets the parameters
         */
        useEffect(() => {
            updateComponentParams(dispatch, props.id, { isLoading: false, step: 1, formReq: undefined, individualData: undefined, paymentIdDetailData: undefined, paymentIdDetailReq: undefined,  isUpdateContactRequest: false,  initialIndData: false })
        },[dispatch,props.id,])


        /**
         * Method fetches data from the state
         */
        const individualState = useSelector((state: any) =>
            fetchDataFromState(state, 'customer-kyc-individual-data')
        );

        /**
        checking customer info is present on the customer get api or not.If data is present then calling method 'setInitialData' to prepolutae the information
         */
        useEffect(() => {
            if(context?.data?.body){
                setInitialData();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[context?.data?.body])
        /**
         * Deep copy function to create a copy of the object and its nested properties
         * @param obj : object
         * @returns 
         */
        function deepCopy(obj: any) {
            if (typeof obj !== 'object' || obj === null) {
                return obj; // Return the value if it's not an object
            }

            const newObj: any = Array.isArray(obj) ? [] : {}; // Determine if it's an array or object

            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = deepCopy(obj[key]); // Recursively copy nested properties
                }
            }

            return newObj;
            }
        /**
         * method used to set data on form while page loading
         */
        const setInitialData= ()=> {
            let individualDetailsData: any;
            const data = getIndividualDetailsDataFromApi(context?.data?.body?.individual ? context.data.body.individual:[]);
            individualDetailsData = individualState?.data?.individualData || data;
            let formReqCopy = signUpIndividual({ details: individualState?.data?.individualData || data })
            let individualStateCopy = deepCopy(individualState?.data);
            let individualObj;
            if(individualStateCopy && individualDetailsData){
                individualStateCopy['individualDetailsData'] = individualDetailsData;
            }
            else{
                individualObj = { individualData: individualDetailsData  }
            }
            updateComponentData(dispatch, 'customer-kyc-individual-data', { ...individualStateCopy || individualObj })

            updateComponentParams(dispatch, props.id, { individualData: individualDetailsData, initialIndData: true, formReq: formReqCopy  })

        }
       
        /**
         * This method used to set individual customer details as the format of form data
         */
        function getIndividualDetailsDataFromApi(data: any) {
            let _mailingAddress: any = data.mailingAddress;
            _mailingAddress = _mailingAddress ? _mailingAddress[0] : {};
            return  {
                "email": data['email'],
                "mobilePhone": convertToPhoneDisplayFormat(data['mobilePhone']),
                "firstName": data['firstName'],
                "lastName": data['lastName'],
                "middleName": data['middleName'] ? data['middleName'] : '',
                "dob": data['dob'],
                "ssn": data['ssn'],
                "address_id": _mailingAddress.id ? _mailingAddress.id : '',
                "addressLine1": _mailingAddress['addressLine1'],
                "addressLine2": _mailingAddress['addressLine2'] ? _mailingAddress['addressLine2'] : '',
                "city": _mailingAddress['city'],
                "state": _mailingAddress['state'],
                "zip": _mailingAddress['zip'],
                "mobilePhone-select": "+91"
            }
        }

        // Method handles the onsubmit event of the individual details form
        const getIndividualDetails = async (data: any, callback:any) => {
            const individualStateCopy = {...individualState?.data}
            individualStateCopy['individualData'] = data;
            let formReqCopy = signUpIndividual({ details: data })           

            const req ={...formReqCopy.customer};
            let url:any="customer/id/" + getKey('customerId');
            let status = await callAPI(url, Method.POST, req)
            status = processAPIResponse(status);

            callback()
            if (status.status) {
                if(getKey('isPPIEnabled') === 'TRUE'){
                    let paymentIdDetailDataCopy = {...paymentIdDetailData}
                    paymentIdDetailDataCopy['phone'] = data?.mobilePhone;
                    individualStateCopy['individualData']['phone'] = data?.mobilePhone;
                    updateComponentParams(dispatch, props.id, { individualData: data, step: 2, paymentIdDetailData: paymentIdDetailDataCopy, formReq: formReqCopy })
                }
                else{
                    updateComponentParams(dispatch, props.id, { individualData: data, step: 3, formReq: formReqCopy })
                }
    
                updateComponentData(dispatch, 'customer-kyc-individual-data', { ...individualStateCopy })
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        
        

        // Method transforming request
        function signUpIndividual(data: any) {
            const details: any = data.details ? data.details : {};
            const address: any = {
                addressLine1: details['addressLine1'],
                addressLine2: details['addressLine2'] ? details['addressLine2'] : '',
                city: details['city'],
                state: details['state'],
                zip: details['zip'],
                isPrimary: true
            };
            if(details.addressLine2 === undefined || details.addressLine2 === ''){
                delete address['addressLine2']
            }
            if(details['address_id']){
                address['id'] = details['address_id'];
            }
            const req:any = {
                customer: {
                    "type": "INDIVIDUAL",
                    "individual": {
                        "firstName": details.firstName,
                        "middleName": details.middleName,
                        "lastName": details.lastName,
                        "ssn": details.ssn,
                        "dob": moment(details.dob).format('MM/DD/YYYY'),
                        "email": details.email,
                        "mobilePhone": convertToPhoneRequestFormat(details.mobilePhone),
                        "mailingAddress": [address]
                    },
                }
            }

            if(details.middleName === undefined || details.middleName === ''){
                delete req['customer']['individual']['middleName']
            }
            return req;
        }
        // Method handles the onsubmit event of the review & submit page
        const handleReviewIndividualSubmit = (req:any) => {
            const customerType ={
                customer:{
                    type: "INDIVIDUAL",
                }
            };

          const linkedDocuments = req?.fileData?.map((file: IFileInfo) => {
            return {
              purpose: 'AUTHORIZATION',
              document: {
                type: file?.fileType,
                name: file?.fileType === 'RATE_CARD' ? 'RATE_CARD.pdf' : 'KYC_AGREEMENT.pdf',
                base64encodedContent: file?.base64
              }
            }
          })

            let request = {...customerType, ...req};
            let accountData: any = {
                "isCustomerOwned": true,
                "linkedDocument": linkedDocuments
            }
            if(paymentIdDetailReq?.nickname){
                accountData = {
                    ...accountData,
                    "nickName" : paymentIdDetailReq?.nickname
                }
            }

            request['account'] = accountData
            reviewIndividualSubmitRequest(request)
        }

        async function reviewIndividualSubmitRequest(req:any){
            let status:any = false;
            if(isUpdateContactRequest){
                status = true;
            }

            if(!status){
                status = await transformRequest(req)
            }

           if(status  && getKey('isPPIEnabled') === 'TRUE'){
                const payload = {
                    ppi:paymentIdDetailReq.ppi?.trim()
                };

                let ppIdStatus = await addPaymentId(payload);
                ppIdStatus = processAPIResponse(ppIdStatus)
                if(ppIdStatus.status){
                    setTimeout(() => {
                        history.push('/')
                    }, 1000);
                }
                else{
                    //api  failed
                    FxSnackBar.show({
                        text: ppIdStatus.message,
                    });
                }
                updateComponentParams(dispatch, props.id, { isLoading: false })
           }
        }

        // Method transforms the request from various pages for api call
        async function transformRequest(req: any) {
            updateComponentParams(dispatch, props.id, { isLoading: true })
            delete req['privacyPolicyAccepted'];
            delete req['fileData'];
            delete req['accountNickname'];
            let status: any;
            status = await updateCustomer(req);
            status = processAPIResponse(status)
            if (status.status) {
                removeCookieCustom('customerKycData')
                // configuring gtm for kyc successful submit
                    let gtmData ={
                        event:"userKycSuccessTrigger",
                        "userEmail": getKey('email'),
                        "userType": "INDIVIDUAL"
                    }
                    pushGTM(gtmData)
                setKey('kycPending','true');
                if(getKey('isPPIEnabled') === 'TRUE'){
                    updateComponentParams(dispatch, props.id, { isUpdateContactRequest: true })
                    return true;
                }
                else{
                    updateComponentParams(dispatch, props.id, { isLoading: false, isUpdateContactRequest: true })
                    setTimeout(() => {
                        history.push('/')
                    }, 1000);
                }
            }
            else {
                //api  failed
                updateComponentParams(dispatch, props.id, { isLoading: false })
                FxSnackBar.show({
                    text: status.message,
                });
                return false;
            }
        }

        /**
        * API call function
        * @param req :request
        * @returns
        */
        async function updateCustomer(req: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('/onboard',false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        /**
         * API call function for create ppi
         * @param req :request
         * @returns
         */
        async function addPaymentId(req: any) {
            try {
                const url = "/ppi"
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        // Method handles the going back from review screen to payment ppi screen
        const handleReview = (step?: number) => {
          if(step){
            updateComponentParams(dispatch, props.id, { step })
          } else {
            let _step: any;
            if(getKey('isPPIEnabled') === 'TRUE'){
              _step = 2;
            }
            else{
              _step = 1;
            }
            updateComponentParams(dispatch, props.id, { step: _step })
          }
        }

        // Method handles the click event of menu bar
        const handleMenuClick = (step: any) => {
            updateComponentParams(dispatch, props.id, { step })

        }

         // Method handles the going back from review screen to individual details screen
         const handlePaymentId = () => {
            updateComponentParams(dispatch, props.id, { step: 1 })

        }

        // Method handles to going for review screen to individual details screen
        const handlePaymentDetailsSubmit = (req: any) => {
            const stepCopy: number = step;
            let newStep = stepCopy + 1;
            updateComponentParams(dispatch, props.id, { step: newStep, paymentIdDetailReq: req })
        }

      const individualData = individualState?.data?.individualData || {};

        // Individual details for the review screen
        const ssn = getLastFourDigits(individualData.ssn);
      const basicIndividualInfo: ICardData = {
        header: 'Individual Customer Info',
        items: [{
          name: `${individualData.firstName ?? ''} ${individualData.middleName ?? ''} ${individualData.lastName ?? ''}`,
          data: {
            ein: {
              title: 'EIN:',
              value: individualData.ein || ''
            },
            ssn: {
                title: 'SSN',
                value: `${ssn ? '*' + ssn : ''}`
            },
            dob: {
                title: 'DOB',
                value: `${individualData.dob ? individualData.dob : ''}`
            },
            address: {
              value: `${individualData.addressLine1 || ''} ${individualData.addressLine2 || ''}${individualData.city ? ', ' + individualData.city : ''}${individualData.state ? ', ' + individualData.state : ''}${individualData.zip ? ', ZIP - ' + individualData.zip : ''}`
            },
            email: {
              value: individualData.email || ''
            }
          }
        }]
      }

      // Account identifier details for the review screen
      const { phone, email } = individualData || {};
      const { value, ppi } = paymentIdDetailReq?.paymentType || {};


      const accountIdentifierData = {
        'Phone': {
          title: 'Phone:',
          value: phone || ''
        },
        'Email': {
          title: 'Email:',
          value: email || ''
        },
        'Custom': {
          title: 'PPI ID:',
          value: ppi || ''
        }
      }


      const accountIdentifierInfo: ICardData = {
        header: 'Account Identifier',
        items: [{
          name: paymentIdDetailReq?.nickname || '',
          data: {
            ppi: accountIdentifierData[value as keyof typeof accountIdentifierData] || {},
          }
        }]
      }



      // Review details for the review screen
      const reviewDetails = [
        { info: basicIndividualInfo, step: 1 },
        { info: accountIdentifierInfo, step: 2 }
      ]

        return (
            <Grid item xs={12} container direction="row"  className='fx-form-grid'>
                {!isSmallScreen && <Grid item xs={3} sm={4} md={3} container direction="row" alignContent="flex-start"    className ="fx-login-promo-parent">
                    <SignUpLeftBar activeMenu={step} onClick={handleMenuClick} data={individualState?.data?.individualData} />
                </Grid>}
                {isSmallScreen && <Grid item xs={12} container direction="row" alignContent="flex-start" sm={12}   className ="fx-login-promo-parent">
                    <SignUpLeftBar activeMenu={step} onClick={handleMenuClick} data={individualState?.data?.individualData} />
                </Grid>}
                {step === 1 && initialIndData && <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row">
                    <SignUpIndividualDetails id='fx-signup-individual-details' onSubmit={getIndividualDetails} data={individualState?.data?.individualData} />
                </Grid >}

                {step === 2 && (getKey('isPPIEnabled') === 'TRUE') &&  <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row" alignContent="flex-start">
                    <AccountIdentifierSignup details={individualState?.data?.individualData} onSubmit={handlePaymentDetailsSubmit} data={paymentIdDetailReq} goBackClick={handlePaymentId} />
                </Grid >}

                {step === 3 && <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row" alignContent="flex-start">
                    <ReviewAndSubmitSignup reviewDetails={reviewDetails} goBackClick={handleReview} onSubmit={handleReviewIndividualSubmit} loading={isLoading} />
                </Grid >}
            </Grid >
        );
    });
