/**
 * Component renders the themes for partner user
 */
import { Grid, Typography } from "@mui/material";
import { getKey } from "../../libs/utils/storageManager";
import { checkPermission, fetchDataFromState } from "./CommonBaseClass";
import { toUpper } from "lodash";
import React from "react";
import { SwitchPortalMenu } from "../Page/Index/Passport/SwitchPortalMenu";


/**
 * Check if the user is of partner
 */
export function checkIsPartner(){
    return getKey('loginType') === 'PARTNER';
  }

  /**
   * Method fetches data from the portal-selected state
   * @param state : state data
   * @returns 
   */
export const fetchPortalData = (state: any) => {
    return checkIsPartner() ? fetchDataFromState(state, 'portal-selected'): null
}  
/**
 * Method displays title for partner
 * @param props : props 
 * @returns 
 */
export const portalTitle = (props: any) => {
    return checkIsPartner() ? (<Grid item container justifyContent="space-between" className='fx-menu-passport-sel-selected-portal-small'>
    <Grid item xs container alignItems={'center'}>
      <Typography className='fx-menu-passport-sel-selected-portal-small-collapse-label'  > {toUpper(props?.selectedPortal === 'Lead' ? 'Leads' : props?.selectedPortal)}</Typography>
    </Grid>
  </Grid>):null
}  
/**
 * Method displays title for partner
 * @param props : props 
 * @returns 
 */
export const portalSecondTitle = (props: any) => {
    return checkIsPartner() ? (<Grid item container style={{ marginLeft: '1rem' }} justifyContent="space-between" className='fx-menu-passport-sel-selected-portal'>
    <Grid item xs container style={{ marginLeft: '.5rem' }}>
        <Typography className='fx-menu-passport-sel-selected-portal-label'  > {toUpper(props?.selectedPortal === 'Lead' ? 'Leads' : props?.selectedPortal)}</Typography>
    </Grid>
</Grid>):null
}  
/**
 * Method renders switch portal
 * @param props : props 
 * @returns 
 */
export const swtichPortalMenu = (props: any) => {
    return checkIsPartner() ? checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" })  &&  <SwitchPortalMenu drawerExpand={true} onMenuSelect={props?.toggleDrawer} userName={props?.userName} id="switch-portal-menu-partner-pexp" ></SwitchPortalMenu>: null
}
/**
 * Method renders switch portal
 * @param props : props 
 * @returns 
 */
export const swtichPortalSecondMenu = (props: any) => {
    return checkIsPartner() ? checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" }) && <SwitchPortalMenu id="switch-portal-menu-partner-dr" userName={props?.userName} drawerExpand={props.drawerExpand} ></SwitchPortalMenu>: null
}
/**
 * Method displays title for partner
 * @param props : props 
 * @returns 
 */
export const drawerExpandTitle = (props: any) => {
    return checkIsPartner() ? (<Grid item container justifyContent="space-between" className='fx-menu-passport-sel-selected-portal'>
    <Grid item xs container >
      <Typography className='fx-menu-passport-sel-selected-portal-label'  > {toUpper(props?.selectedPortal === 'Lead' ? 'Leads' : props?.selectedPortal)}</Typography>
    </Grid>
  </Grid>):null
} 
/**
 * Method displays title for partner
 * @param props : props 
 * @returns 
 */
export const drawerCollapseTitle = (props: any) => {
    return checkIsPartner() ? (<Grid item container justifyContent="space-between" className='fx-menu-passport-sel-selected-portal-small'>
    <Grid item xs container className='fx-menu-passport-sel-selected-portal-smallgrid'>
      <Typography className='fx-menu-passport-sel-selected-portal-small-collapse-label'  > {toUpper(props?.selectedPortal === 'Lead' ? 'Leads' : props?.selectedPortal)}</Typography>
    </Grid>
  </Grid>):null
} 