/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useForm } from 'react-hook-form';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clean, getPPISupportedPaymentMethods, updateComponentParams, deleteUnwantedRequest, createCommonVerifyProps} from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createSendMyAccount, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { FxSuccess } from '../../Data/FxSuccess';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import FxVerifyPPI from '../../Data/FxVerifyPPI';
import { ScheduleTopSection } from './ScheduleTopSection';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("CreateSendOneTimeCheck.tsx", "create send One Time Check initializing");

const acc_src = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

/**
 * This component handles the send creation through one time for check method
 */
export const CreateSendOneTimeCheck: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const history = useHistory()
        const { register, formState: { errors }, handleSubmit, setValue, watch, control, setError, resetField, clearErrors } = useForm();
        const sendMyAccountInfo: any = context?.data?.body;
        const params = useParams<any>();
        const passedOtherSendId = params.othersId;
        const scheduleId = params?.id;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const [location, setLocation] = useState<any>();
        const method_Tab = useMemo(() => {
            const methodTabs = [
            {
                title: 'ACH',
                link: '/transaction/send/onetime/add/ach',
                disabled: !!scheduleId,
                permission: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CREATE"
                }
            },
            {
                title: 'INTERNATIONAL WIRE',
                link: '/transaction/send/onetime/add/internationalWire',
                disabled: !!scheduleId,
                permission: {
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "CREATE"
                }
            },
            {
                className: 'fx-link-sel',
                title: 'CHECK',
                link: '/transaction/send/onetime/add/check',
                disabled: !!scheduleId,
                permission: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "CREATE"
                }
            },
            {
                title: 'BOOK',
                link: '/transaction/send/onetime/add/book',
                disabled: !!scheduleId,
                permission: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CREATE"
                }
            }];
              // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
            if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
                methodTabs.push({
                  title: "VIRTUAL CARD",
                  link: "/transaction/send/onetime/add/virtualcard",
                  disabled: !!scheduleId,
                  permission: {
                    entity: "Accounts",
                    name: "Virtual Card Send",
                    operation: "CREATE"
                  }
                })
              }
            return methodTabs;
        }, [scheduleId]);

        const [isloading, setIsloading] = useState(false);
        const [saveEnabled, setSaveEnabled] = useState<boolean | undefined>(false);

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if (scheduleId) {
                const src = {
                  url: "/transaction/id/" + scheduleId,
                  method: "GET",
                };
                dispatch({
                  type: "DATA_API_PARAM_CHANGED",
                  payload: { id: "edit-send-to-one-time-CHECK", source: src },
                });
                updateComponentParams(dispatch, "edit-send-to-one-time-CHECK", {
                  isEdit: true,
                });
            } else {
                dispatch({
                  type: "DATA_UPDATE_COMPONENT_REDUCER",
                  payload: {
                    id: "page-title-details",
                    data: { title: "Send Money", backButton: "/dashboard" },
                  },
                });
              }
              const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
        }, []);

        /**
        * useEffect to set edit data details
        */
        useEffect(() => {
            if (context?.data?.body) {
                setFormValues(context?.data?.body);
            }
            if(context?.data?.body?.processingDetail?.location){
                getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
            }
        }, [context?.data?.body]);

        /**
        * Method setting the default values for the text edit component from the context data
        */
        function setFormValues(data: any) {
            setValue("amount", data?.amount);
            setValue("purpose", data?.purpose);
            setValue("ppi", data?.destination?.ppi.replace("@ppi", ""));
            setValue("memo", data?.processingDetail?.memo);
            setSaveEnabled(true)
        }

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {
                source: {
                    account: {
                        id: data.source
                    }
                },
                method: 'CHECK',
                type: 'REGULAR',
                amount: data.amount,
                purpose: data.purpose
            };
            req['destination'] = {
                ppi: `${data.ppi}@ppi`,
            }
            if(data.memo){
                req['processingDetail'] = {};
                req['processingDetail']['memo'] = data.memo;
            }
            if(data['location']){
                req['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }
            if (props.isEdit) {
                req["id"] = context?.data?.body?.id;
                const unwantedRequestArray = ["source", "destination", "method", "type"];
                deleteUnwantedRequest(req, unwantedRequestArray);
            }
            req = clean(req);
            setIsloading(true);
            let status: any;
            status = await createSendMyAccount(req);
            const sendMyAccountId = status?.headers?.url?.split('/').pop() || '';
            status = processAPIResponse(status);
            setIsloading(false);
            if (status.status) {
                const successMessage = sendMyAccountInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
                const redirectUrl = sendMyAccountInfo? `/transactions/send/${sendMyAccountInfo["id"]}`: '/thanks/transactions/send-check/';
                const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                if(status.responseData?.sessionId){
                    const verifyProps = {
                        ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
                        successMessage : successMessage,
                        redirectUrl: redirectUrl,
                        redirectUrlFromHeader:params?.id?false: true
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
               else{

               
                if (sendMyAccountInfo) {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Send Updated Successfully!',
                    });
                    history.push('/transactions/send/' + sendMyAccountInfo['id'])
                }
                else {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Send Created Successfully!',
                    });
                    history.push('/thanks/transactions/send-check/' + sendMyAccountId)
                }
            }
        }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }

        }

        const handleClose = () => {
            if (scheduleId) {
              history.push("/transactions/send/" + scheduleId);
            } else {
              history.push("/dashboard");
            }
          };


        /**
         * Transaction summary object
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: accountLabelTransformation(getKey('selectedAccount'))
            },
            'To':  {
                id: 'to',
                value: watch('ppi') + "@ppi",
            },
            ...(businessCustomer
            ? { Purpose: { id: "purpose", value: watch("purpose") } }
            : { Memo: { id: "purpose", value: watch("purpose") } }),
            'Via': {
                id: 'method',
                value: 'CHECK',
            }
        };

        if(businessCustomer) {
            summaryData['Memo'] = {
                id: 'memo',
                value: watch('memo'),
            }
        }

        const tabClickFunction = () => {
            try{
              const data:any = {
                schedule: 'send',
                type: 'one-time',
                formData: { amount: watch('amount'), purpose: watch('purpose')}
              }
              dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch(e){}
          }
         /**
         * Function to handle the closing of the modal.
         */
	
            const handleCloseModal = () => {
                let verifyProps = {...props.verifyProps}
                verifyProps['open'] = false;
                updateComponentParams(dispatch, props.id, { verifyProps });
            };
        return (
          <>
            <ScheduleLayout
                id={'create-send-onetime-check'}
                type='send'
                loading={isloading}
                submitTitle={props.isEdit ? 'Save': 'Send Money'}
                summaryData={summaryData}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => handleClose()}
                saveEnabled={saveEnabled}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                {passedOtherSendId && <FxSuccess id="sucess-to-others" header={"Send added successfully"} description={"Send ID - " + passedOtherSendId} />}
                <ScheduleTopSection id="create-send-one-time-check-schedule-top-section" schedule="send" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={{}} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-onetime-check-form-card-amount-textbox" sourceId="create-send-onetime-check-form-card-source" purposeId="create-send-onetime-check-form-card-purpose-textbox"/>
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-send-one-time-check-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                <ScheduleCreateMethodTab tabItems={getPPISupportedPaymentMethods(method_Tab, "onetime")} id="create-send-onetime-check-form-card-tab" tabClick={tabClickFunction}/>
                <Grid item>
                    <fieldset className="fx-container-create-schdule-summery">
                        <legend>Destination Details</legend>
                        {!props.isEdit ?
                        <FxVerifyPPI
                            id='create-send-onetime-check-ppi'
                            control={control}
                            register={register}
                            errors={errors}
                            fieldName='ppi'
                            watch={watch}
                            onVerificationFinished={(status) =>{
                                if(getKey("isPPIEnabled")?.toLowerCase() === 'true') {
                                    let ppiSupportedMethods = getKey("ppiSupportedMethods")?.split(",");
                                    let method = ppiSupportedMethods.find((element: string)=> element?.toLowerCase() === "CHECK".toLowerCase());
                                        if(method) {
                                            setSaveEnabled(status ? status : false);
                                        } else {
                                            setSaveEnabled(false);
                                        }
                                  } else {
                                    setSaveEnabled(false)
                                  }
                                }
                            }
                        /> :
                        <FxTextEdit
                            register={{ ...register("ppi") }}
                            type="text"
                            className={
                                errors.destination
                                ? "border-error-input fx-input-edit"
                                : "fx-input-edit"
                            }
                            control={control}
                            id="edit-send-to-one-time-ACH-ppi"
                            label="Payment ID"
                            name="ppi"
                            isEditable={false}
                            suffix={"@ppi"}
                        />}
                    </fieldset>
                </Grid>
                {businessCustomer && <Grid item>
                    <FxTextEdit  register={{ ...register("memo") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-send-onetime-check-form-card-memo-textbox" label="Memo" name="memo" variant="outlined" defaultValue={context?.data?.body?.memo ? context.data.body['memo'] : ''} />
                </Grid>}
            </ScheduleLayout>
     </>
    )});
