/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useCallback } from 'react'; // we need this to make JSX compile
import { Chip, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash";
import { Logger } from '../../../../libs/utils/logger';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import { clean, convertEndData, convertToOnDayFormat, convertToTitleCaseAndAppendWithS, removeUnderScoreConvertTitleCase, renderError, transformRecurringRequestGlobal,customErrorMessage, getScheduleType } from '../../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../../libs/utils/utils';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { getDestinationValue, getLocationData, setAmountValidationOptions, updateRecurring } from '../../Schedules/ScheduleGlobalFunctions';
import { FxDateEdit } from '../../../Input/FxDate/FxDateEdit';
import { FxRecurring } from '../../Schedules/Recurring/FxRecurring';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import { FxFileUpload } from '../../../Input/FxFile/FxFileUpload';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/delete-icon.svg';
import ScheduleLayout from '../../../Layout/ScheduleLayout';
import { FxDateTimeView } from '../../../Input/FxDate/FxDateView';
import { TransactionSummaryFieldType } from '../../Schedules/TransactionSummaryTemplate';
import { ScheduleCreateMethodTab } from '../../Schedules/ScheduleCreateMethodTab';
import { FxSwitch } from '../../../Action/FxSwitch';
import FxLabel from '../../../Input/FxLabel/FxLabelView';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { FxSummaryCurrency } from '../../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';

const  processingModeData = [
    { label: 'Same Day', value: 'SAME_DAY' },
    { label: 'Next Day', value: 'FORWARD' }
];

const  deliveryModeData = [
    { label: 'Two Day', value: 'TWO_DAY' },
    { label: 'Overnight', value: 'OVERNIGHT' },
    { label: 'Standard', value: 'STANDARD' }
];

Logger.debug("EditRecurring.tsx", "edit recurring initializing")
/**
 * This component handles the recurring update
 */
export const EditRecurring: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const history = useHistory()
        const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, watch, setError } = useForm();
        let recurringInfo: any = context?.data?.body?.action[0]?.transaction;
        const [isLoading, setIsLoading] = useState(false);
        const [recurringFormData, setRecurringFormData] = useState<any>();
        const [destinationInfoLoaded, setDestinationInfoLoaded] = useState(false);
        const [sourceInfoLoaded, setSourceInfoLoaded] = useState(false);
        const [fileUploadData, setFileUploadData] = useState<any>();
        const isCollect = recurringInfo?.source?.externalAccount?.id;
        const [isEditDataAvailable, setIsEditDataAvailable] = useState(false);
        const [location, setLocation] = useState<any>();
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const params = useParams<any>();
        const transactionType = params?.type;        

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'edit-recurring-form-card-file-id-file-upload', 'files': [] } });
        }, []);

        /**
         * use effect to get destination Info data
         */
        useEffect(() => {
            setFormValues();
            getDestinationData();
            getSourceData();
            if(recurringInfo?.processingDetail?.location){
                getLocationData(recurringInfo?.processingDetail?.location,setLocation,setValue)
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Recurring',backButton:'/recurring/details/' + context?.data?.body?.id} } });
        }, [recurringInfo]);

        async function getDestinationData() {
            if (recurringInfo !== undefined) {
                let destinationValue: any = await getDestinationValue(recurringInfo?.destination);
                setValue('destination', destinationValue)
                setDestinationInfoLoaded(true)
            }
        }

        const getSourceData = useCallback(async () => {
            if(!recurringInfo) {
                return;
            }
            const sourceValue = await getDestinationValue(recurringInfo?.source);
            setValue('source', sourceValue);
            setSourceInfoLoaded(true);
        }, [recurringInfo, setValue]);

        /**
         * setting isEditDataAvailable state after getting data from context and setting the default value for fields from api data
         */
        if (context?.data?.body && !isEditDataAvailable) {
            try {
                setIsEditDataAvailable(true);
            }
            catch (e) { }
        }

        /**
         * Method setting the default values for the text edit component from the context data
         */
        function setFormValues() {
            setValue("amount", recurringInfo?.amount)
            setValue("name", context?.data?.body?.name)
            setValue("purpose", recurringInfo?.purpose)
            setValue("achCompanyDescription", recurringInfo?.processingDetail?.companyDescription ? recurringInfo?.processingDetail?.companyDescription : recurringInfo?.processingDetail?.memo)
            setValue("memo", recurringInfo?.processingDetail?.memo);
            setValue('allowDuplicate', recurringInfo?.allowDuplicate);
        }
        /**
         * Async function handles the edit withdrawal form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            setIsLoading(true);
            let status: any;
            let req:any = {
                id: context?.data?.body?.id,
                name: data?.name,
                action: [
                    {
                        transaction: {
                            processingDetail: {
                                processingMode: data.processingMode,
                                memo: data.memo,
                            },
                            amount: data.amount,
                            purpose: data.purpose,
                            allowDuplicate: data.allowDuplicate,
                            method: recurringInfo?.method,
                        },
                    }
                ],
                transactionDetail: {
                    ...transformRecurringRequestGlobal(context?.data?.body?.transactionDetail, _.cloneDeep(recurringFormData)).transactionDetail,
                    startDate: data.startDate,
                },
                linkedDocument: fileUploadData?.map((file: any) => ({
                    purpose: "Authorization",
                    document: {
                        id: file.id,
                    }
                })),
            }
            if (data.achCompanyDescription) {
                req['action'][0]['transaction']['processingDetail']['companyDescription'] = data.achCompanyDescription
            }
            req = clean(req);
            status = await updateRecurring(req);
            status = processAPIResponse(status);
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Recurring Updated Successfully!',
                });
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'edit-recurring-form-card-file-id-file-upload', 'files': [] } });
                history.push('/recurring/details/' + context?.data?.body?.id)
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: customErrorMessage(status),
                });
            }
        }

        /**
         * Method handles the redirection to details page
         */
        function redirectToInfoPage() {
            history.push(`/recurring/details/${context?.data?.body?.id}`);
        }

        /**
        * Method triggers if the recurring updates
        */
        const handleUpdateRecurring = (data: any) => {
            setRecurringFormData(data);
        }

        let docTypeData: any = [
            { label: 'Contract', value: 'CONTRACT' }
        ];

        /**
        * Method to set file upload data
        * @param event :event object
        */
        function passdata(event: any) {
            try {
                setFileUploadData(event)
                if (event.length > 0 && errors['fileError']) {
                    clearErrors('fileError');
                }
            }
            catch (e) { }
        }

        function getMethodTab(){
            let method_Tab = [];
            if(isCollect){
                method_Tab.push({
                    className: recurringInfo?.method === 'ACH' ? "fx-link-sel" : '',
                    title: "ACH",
                    disabled: true,
                    permission:{
                      entity: "Recurring Transaction",
                      name: "Ach Collect",
                      operation: "Edit"
                    }
                  })
            }
            else{
                method_Tab.push({
                    className: recurringInfo?.method === 'ACH' ? "fx-link-sel" : '',
                    title: "ACH",
                    disabled: true,
                    permission:{
                      entity: "Recurring Transaction",
                      name: "Ach Send",
                      operation: "Edit"
                    }
                  },
                  {
                    className: recurringInfo?.method === 'WIRE' ? "fx-link-sel" : '',
                    title: "WIRE",
                    disabled: true,
                    permission:{
                      entity: "Recurring Transaction",
                      name: "Wire Send",
                      operation: "Edit"
                    }
                  },
                  {
                    className: recurringInfo?.method ==='INTERNATIONAL_WIRE'?"fx-link-sel":'',
                    title: "INTERNATIONAL WIRE",
                    disabled: true,
                    permission:{
                      entity: "Recurring Transaction",
                      name: "International Wire Send",
                      operation: "Edit"
                    }
                  },
                  {
                    className: recurringInfo?.method ==='CHECK'?"fx-link-sel":'',
                    title: "CHECK",
                    disabled: true,
                    permission:{
                      entity: "Recurring Transaction",
                      name: "Check Send",
                      operation: "Edit"
                    }
                  },
                  {
                      className: recurringInfo?.method === 'BOOK' ? "fx-link-sel" : '',
                      title: "BOOK",
                      disabled: true,
                      permission:{
                          entity: "Recurring Transaction",
                          name: "Book Send",
                          operation: "Edit"
                        }
                  })
            }
            return method_Tab
        }

        /**
         * Method to remove file upload data
         * @param id : file id
         */
        const removeFile = (id: any) => {
            const newVal = fileUploadData.filter((item: any) => item.id !== id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'edit-recurring-form-card-file-id-file-upload', 'files': newVal } });
            setFileUploadData(newVal)
        }

        /**
         * The main summary data
         */
        const amount = watch('amount');
        const destination = watch('destination');

        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            [isCollect ? getScheduleType(businessCustomer,'title') + ' Amount' : 'Send Amount']: {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: watch('source'),
            },
            'To': {
                id: 'to',
                value: destination,
            },
            'Purpose': {
                id: 'purpose',
                value: watch('purpose'),
            },
            'Via': {
                id: 'method',
                value: recurringInfo?.method,
            },
            'Processing Mode' : {
               id: 'processingMode',
               value: watch('processingMode') && watch('processingMode') ? (watch('processingMode') === 'FORWARD' ? 'Next Day' : removeUnderScoreConvertTitleCase(watch('processingMode'))) : '',
            },
        };
        /**
         * to remove Processing Mode node from template in method wire
         */
        if(recurringInfo?.method === 'WIRE' ||  recurringInfo?.method === 'BOOK' ||  recurringInfo?.method === 'CHECK'){
            delete summaryData?.['Processing Mode']
            summaryData['Memo'] = {
                id: 'memo',
                value: watch('memo')
            }
        }
        if (recurringInfo?.method === 'CHECK') {
            summaryData['Delivery Mode'] = {
                id: 'deliverymode',
                value: watch('deliveryMode')
            }
        }
        if(recurringInfo?.method === 'ACH'){
            summaryData['Memo'] = {
                id: 'memo',
                value: watch('achCompanyDescription')
            }
        }

        /**
         * Recurring related summary data
         */
        const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
            if(!recurringFormData) {
                return {}
            }
            const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
            const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
                ? convertToTitleCaseAndAppendWithS(
                    recurringFormData?.repeatEveryFormData?.repeatEverySelect
                )
              : '';
            const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
                'Repeat every': {
                    id: 'repeat-every',
                    value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
                },
            }
            if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
                result['On'] = {
                    id: 'repeat-on',
                    value: convertToOnDayFormat(recurringFormData),
                };
            }
            const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
            const ends = recurringFormData?.endFormData
                ? convertEndData(recurringFormData?.endFormData)
                : '';
            if (ends || endOn) {

            }
            result['Ends'] = endOn ? {
                id: 'repeat-ends',
                value: ends,
                ValueComponent: ({ value }) => (
                    <>
                        On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                    </>
                )
            } : {
                id: 'repeat-ends',
                value: ends,
            };
            return result;
        }, [recurringFormData]);

        return (
            <ScheduleLayout
                id={'edit-recurring'}
                loading={isLoading}
                submitTitle={'Save Changes'}
                summaryData={{...summaryData, ...summaryRepeatData}}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={redirectToInfoPage}
                saveEnabled
            >
                <Grid item  xs={12} sm={12}  className='fx-amount-wrapper'>
                    <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} prefix="$" id="edit-recurring-form-card-amount-textbox" label="Amount" name="amount" valuePattern={/-|\+|e|E/} type="number" variant="outlined" defaultValue={recurringInfo?.amount ? recurringInfo['amount'] : ''} showDecimal={true} setValue={setValue} />
                </Grid>
                {sourceInfoLoaded && (
                    <Grid item  xs={12} sm={12} >
                      <FxTextEdit register={{ ...register("source") }} type="text" className={errors.source ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-recurring-form-card-source-textbox" label={watch('source')?.includes('@ppi')?'Payment ID':"Source Account"} name="source" isEditable={false} />
                   </Grid>
                )}
                <Grid item  xs={12} sm={12} >
                    <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-recurring-form-card-purpose-textbox" label="Purpose" name="purpose" variant="outlined" defaultValue={recurringInfo?.purpose ? recurringInfo['purpose'] : ''} />
                </Grid>
                {(location && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                    label='Location'
                    register={{ ...register("location") }}
                    control={control}
                    rules={{ required: false }}
                    id="create-send-one-time-ach-form-location"
                    name="location"
                    className="fx-input-edit"
                    setError={setError}
                    clearError={clearErrors}
                    setValue={setValue}
                    resetField={resetField}
                    isEditable={false}
                    value={location ? {
                        label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                        value: location?.id,
                    }: null}
                    />
                </Grid>}
                {(transactionType !== 'collect' || businessCustomer) && <Grid item xs={12} sm={12} >
                    <ScheduleCreateMethodTab
                      tabItems={getMethodTab()}
                      id="edit-recurring-form-card-tab"
                    />
                  </Grid>}
                {destinationInfoLoaded && (
                    <Grid item xs={12} sm={12} >
                      <FxTextEdit register={{ ...register("destination") }} type="text" className={errors.destination ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-recurring-form-card-destination-textbox" label={watch('destination')?.includes('@ppi')?'Payment ID':"Destination Account"} name="destination" isEditable={false} />
                   </Grid>
                )}
                {recurringInfo?.method === 'ACH' && (
                    <Grid item xs={12} sm={12} >
                        <FxMaterialSelect register={{ ...register("processingMode") }} control={control} rules={{ required: true }} id="edit-recurring-form-card-processing-mode" name="processingMode" data={processingModeData} value={recurringInfo && recurringInfo['processingDetail'] && recurringInfo?.processingDetail?.processingMode ? recurringInfo['processingDetail']['processingMode'] : 'SAME_DAY'} label="Processing Mode" setValue={setValue} />
                    </Grid>
                )}
                {recurringInfo?.method === 'CHECK' && (
                    <Grid item xs={12} sm={12} >
                        <FxMaterialSelect register={{ ...register("deliveryMode") }} control={control} rules={{ required: true }} id="edit-recurring-form-card-delivery-mode" name="deliveryMode" data={deliveryModeData} value={recurringInfo?.processingDetail?.deliveryMode ? recurringInfo['processingDetail']['deliveryMode'] : 'TWO_DAY'} label="Delivery Mode" setValue={setValue} />
                    </Grid>
                )}
                {(recurringInfo?.method === 'CHECK' || recurringInfo?.method === 'WIRE' || recurringInfo?.method === 'BOOK') && (
                    <Grid item xs={12} sm={12} >
                        <FxTextEdit register={{ ...register("memo") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-recurring-form-card-memo-textbox" label="Memo" name="memo" variant="outlined" />
                    </Grid>
                )}
                {recurringInfo?.method === 'ACH' && <Grid item xs={12} sm={12} >
                    <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-recurring-form-card-company-description-textbox" label="Memo" name="achCompanyDescription" variant="outlined" defaultValue={recurringInfo?.processingDetail?.companyDescription ? recurringInfo.processingDetail['companyDescription'] : ''} />
                </Grid>}
                {!!recurringInfo && (
                    <Controller
                        name='allowDuplicate'
                        control={control}
                        defaultValue={recurringInfo.allowDuplicate}
                        render={({ field: { onChange, value }}) => (
                            <Grid item>
                                <FxSwitch
                                    id='edit-recurring-form-card-allow-duplicate'
                                    value={value}
                                    onClick={onChange}
                                >
                                </FxSwitch>
                                <FxLabel value="Allow Duplicate"></FxLabel>
                            </Grid>
                        )}
                    />
                )}
                {context?.data?.body?.transactionDetail && (
                    <>
                        <Grid item xs={12} sm={12} >
                            <FxDateEdit register={{ ...register("startDate") }} className={errors.startDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-recurring-form-card-startDate" name="startDate" type="date" variant="outlined" defaultValue={context?.data?.body?.transactionDetail?.startDate} label={'Start Date *'} setValue={setValue} disablePast={new Date(context?.data?.body?.transactionDetail?.startDate) < new Date() ? false : true} resetField={resetField} rules={{ required: true }} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FxTextEdit register={{ ...register("name") }} className={errors.name ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-recurring-form-card-name-textbox" defaultValue={context?.data?.body?.name} label="Name" name="name" variant="outlined" setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FxRecurring register={register} control={control} errors={errors} id='edit-recurring' updateTrigger={handleUpdateRecurring} defaultValue={context?.data?.body?.transactionDetail} setValue={setValue} clearErrors={clearErrors} startDate={watch("startDate")}></FxRecurring>
                        </Grid>
                        <Grid item container >
                            <Grid item>
                                <Typography className="filter-popover-label" >LINKED DOCUMENTS</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>&nbsp;</Grid>
                            <Grid item container xs={12} spacing={1} alignItems='center'>
                                <Grid item xs={12} sm={8}>
                                    <FxMaterialSelect register={{ ...register("docType") }} id="edit-recurring-form-card-doc-type" control={control} rules={{ required: false }} name="docType" data={docTypeData} value={'CONTRACT'} readOnly={true} label="Select a document type" setValue={setValue} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name={"fileId"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <FxFileUpload register={{ ...register("fileId") }} name='fileId' passData={passdata} id="edit-recurring-form-card-file-id-file-upload" onChange={(e: any) => { onChange(e); }} value={fileUploadData ? fileUploadData : value} acceptedFiles={[".pdf", ".docx", ".jpg", ".txt", ".png", ".jpeg", ".xls", ".wav", ".xlsx", ".tiff", ".doc", ".rtf", ".bmp", ".efx", ".csv", ".kswps", ".wps"]}
                                                    formData={{ "type": 'CONTRACT' }}
                                                    setValue={setValue}
                                                    maxFileSize={10485760} />
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FxLabelView className="fx-label-small" id="edit-recurring-form-card-file-types-supported">(.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc, .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={'error-message'}>
                                    {errors.fileError && renderError(errors.fileError)}
                                </div>
                            </Grid>
                            {fileUploadData && <Grid item container >
                                {fileUploadData.map((key: any) => {
                                    return <Chip icon={<InsertDriveFileTwoToneIcon />} label={key['name']} onDelete={() => removeFile(key['id'])} color="primary" variant="outlined" deleteIcon={<DeleteIcon />} />
                                })}
                            </Grid>}
                        </Grid>
                    </>
                )}
            </ScheduleLayout>
        );
    })
