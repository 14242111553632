import { useSelector, useDispatch } from "react-redux";
import { SagaHelper } from "../../libs/utils/sagaHelper";
import { getKey } from "../../libs/utils/storageManager";
import { get } from "lodash";

/**
 * init the componnet
 * and procvess the source
 * @param props
 */
export function InitializeComponent(props: any) {
    const state = useSelector((state: any) => {
        if (props.id && state.context[props.id]) {
            return state.context[props.id];
        }
        else {
            return null;
        }
    });
    const dispatch = useDispatch();
    if (!state) {
        return { context: null, props: props };
    }
    props = { ...props, ...state.config.params };
    let context = { ...state, request: props.request, config: { ...state.config, params: props } };

    if (state && props.disableSourceProcess === true) {
        return { context, props };
    }

    const source = state.config.source;
    if (source) {
        if (context.events && context.events.onSourceLoad) {
            source.onLoad = context.events.onSourceLoad;
        }
        //add session obj
        props.session = getKey;

        dispatch({ type: "SOURCE_PROCESS", payload: { "id": props.id, source: source, props: props, context: context, _dispatch: dispatch } });
    }
    context = setUpListeners(context);
    if (context.config?.params?.filterParams) {
        const actionF = compileFunction(context.config.params.filterParams);
        context.config.props = props = actionF(context, new SagaHelper(dispatch));
    }
    return { context, props };
}

/**
 * check listeners for this component
 * @param context
 */
function setUpListeners(context: any) {
    const listeners = context.config.listeners;
    if (!listeners || listeners.length < 1) {
        return context;
    }
    for (let i = 0; i < listeners.length; i++) {
        const listener = listeners[i];
        if (listener["component"] && listener["key"]) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            context['data'][listener['target']] = useSelector((state: any) => {
                return get(state.context[listener["component"]], listener['key']);
            });
        }
    };
    return context;
}

/**
 * eval the code string
 * @param code
 */
function compileFunction(code: any) {
    let functionName = function (data: any, sh: any) { };
    // eslint-disable-next-line no-eval
    eval("functionName = " + code);
    return functionName;
}