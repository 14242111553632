import { checkPermission } from "../../components/Utils/CommonBaseClass";
import { CustomerType, LoggedInUserTypeEnum } from "../../types/common.interfaces";
import { getLocalStorage } from "../utils/cacheHandler";
import { getKey } from "../utils/storageManager";
import { FeatureBanner } from "./FeatureComponents/FeatureBanner";

declare global {
    interface Window {
      README_CONFIG: any;
    }
  }

let readMeConfig: any = window.README_CONFIG;

interface UIFeature {

    getFasterFunding():{ available: boolean, banner: any, innerText: any } ;
    getActivateAccount():{ available: boolean, banner: any, bannerType: any };
    getTransactionFeatures(): { fastFunding: boolean, statementDescriptor: boolean };
    getSSOUserFeatures(): { available: boolean, ssoUser: boolean, button: any };
    getContactCardMethod(): { available: boolean }
    getDebitCardContent():{ available: boolean, info: any, congratulation: any } ;
    getBatchId():{ available: boolean } ;
    getReadMeInfo(): { url: any, project: any, apiKey: any }
    getPartnerFeature():{ available: boolean } ;
    getPmFeature():{ available: boolean } ;
    getBusinessCustomerFeature():{ available: boolean } ;
    getIndividualCustomerFeature():{ available: boolean } ;
    getJointTenancyFeature():{ available: boolean } ;
    getMerchantOnboardingFeature():{ available: boolean };
}

class UIFeatureImpl implements UIFeature{
    private static instance: UIFeatureImpl;

    // Private properties
    private fasterFunding: { available: boolean, banner: any, innerText: any } = { available: false, banner: null, innerText: null };
    private activateAccount: { available: boolean, banner: any, bannerType: any } = { available: false, banner: null, bannerType: null };
    private investmentAccount: { available: boolean, banner: any, bannerType: any } = { available: false, banner: null, bannerType: null };
    private transactionFeatures: { fastFunding: boolean , statementDescriptor: boolean} = { fastFunding: false, statementDescriptor: false };
    private backToOriginalUrl: string = "";
    private ssoUserFeatures: { available: boolean, ssoUser: boolean, button: any } = { available: false, ssoUser: false, button: null };
    private contactCard: { available: boolean } = { available: true }
    private debitCardContnet: { available: boolean, info: any, congratulation: any } = { available: true, info: null, congratulation: null };
    private batchId: { available: boolean } = { available: true }
    private readMe: { url: any, project: any, apiKey: any } = { url: null, project:null, apiKey: null }
    private pmFeature: { available: boolean } = { available: false }
    private partnerFeature: { available: boolean } = { available: false }
    private businessCustomerFeature: { available: boolean } = { available: false }
    private individalCustomerFeature: { available: boolean } = { available: false }
    private jointTenancyFeature: { available: boolean } = { available: false }
    private merchantOnboardingFeature: { available: boolean } = { available: false }

    private constructor() { }

    // Singleton instance creation
    public static getInstance(): UIFeature {
       return this.getImplementation();
    }   
    // Singleton instance creation
    public static getImplementation (): UIFeatureImpl {
        if (!UIFeatureImpl.instance) {
            UIFeatureImpl.instance = new UIFeatureImpl();
        }
        return UIFeatureImpl.instance;
    }

    // Getter and setter methods for faster funding feature
    public getFasterFunding(): { available: boolean, banner: any, innerText: any } {
        return this.fasterFunding;
    }

    public setFasterFunding(available: boolean, banner?: any, innerText?: any): void {
        this.fasterFunding = { available, banner, innerText };
    }

    // Getter and setter methods for activate account feature
    public getActivateAccount(): { available: boolean, banner: any, bannerType: any } {
        return this.activateAccount;
    }

    public setActivateAccount(available: boolean, banner: any, bannerType: any): void {
        this.activateAccount = { available, banner, bannerType };
    }

    // Getter and setter methods for investment account feature
    public getInvestmentAccount(): { available: boolean, banner: any, bannerType: any } {
        return this.investmentAccount;
    }

    public setInvestmentAccount(available: boolean, banner: any, bannerType: any): void {
        this.investmentAccount = { available, banner, bannerType };
    }
    
    // Getter and setter methods for sso user feature
    public getSSOUserFeatures(): { available: boolean, ssoUser: boolean, button: any } {
        return this.ssoUserFeatures;
    }

    public setSSOUserFeatures(available: boolean, ssoUser: boolean, button: any): void {
        this.ssoUserFeatures = { available, ssoUser, button };
    }
   
    // Getter and setter methods for transaction feature
    public getTransactionFeatures(): { fastFunding: boolean, statementDescriptor: boolean } {
        return this.transactionFeatures;
    }

    public setTransactionFeatures(fastFunding: boolean, statementDescriptor: boolean): void {
        this.transactionFeatures = { fastFunding, statementDescriptor };
    }
    //Method to get contact card availablility of users
    public getContactCardMethod(): { available: boolean } {
        return this.contactCard;
    }
    //Method to set contact card availablility of users
    public setContactCardMethod(available: boolean): void {
        this.contactCard = { available };
    }

    // Getter and setter methods for back to original URL
    public getBackToOriginalUrl(): string {
        return this.backToOriginalUrl;
    }

    public setBackToOriginalUrl(backToOriginalUrl: string): void {
        this.backToOriginalUrl = backToOriginalUrl;
    }

    //Method to get debit card content
    public getDebitCardContent(): { available: boolean, info: any, congratulation: any } {
        return this.debitCardContnet;
    }

    //Method to set debit card content
    public setDebitCardContent(available: boolean, info: any, congratulation: any): void {
        this.debitCardContnet = { available, info, congratulation };
    }

    //Method to get batch id availability
    public getBatchId(): { available: boolean} {
        return this.batchId;
    }

    //Method to set batch id availability
    public setBatchId(available: boolean): void {
        this.batchId = { available};
    }

    //Method to get readMe keys
    public getReadMeInfo(): { url: any, project: any, apiKey: any} {
        return this.readMe;
    }

    //Method to set readMe keys
    public setReadMeInfo(url: any, project: any, apiKey: any): void {
        this.readMe = { url, project, apiKey};
    }

    //Method to get pm login
    public getPmFeature(): { available: boolean } {
        return this.pmFeature;
    }

    //Method to set pm login
    public setPmFeature(available: boolean): void {
        this.pmFeature = { available };
    }

    //Method to get partner login
    public getPartnerFeature(): { available: boolean } {
        return this.partnerFeature;
    }

    //Method to set partner login
    public setPartnerFeature(available: boolean): void {
        this.partnerFeature = { available };
    }

    //Method to get Business Customer Feature
    public getBusinessCustomerFeature(): { available: boolean } {
        return this.businessCustomerFeature;
    }

    //Method to set Business Customer Feature
    public setBusinessCustomerFeature(available: boolean): void {
        this.businessCustomerFeature = { available };
    }

    //Method to get Individual Customer Feature
    public getIndividualCustomerFeature(): { available: boolean } {
        return this.individalCustomerFeature;
    }

    //Method to set Individual Customer Feature
    public setIndividualCustomerFeature(available: boolean): void {
        this.individalCustomerFeature = { available };
    }

    //Method to get joint_Tenancy Customer Feature
    public getJointTenancyFeature(): { available: boolean } {
        return this.jointTenancyFeature;
    }

    //Method to set joint_Tenancy Customer Feature
    public setJointTenancyFeature(available: boolean): void {
        this.jointTenancyFeature = { available };
    }

    //Method to get Direct Funded Merchant Onboarding Feature
    public getMerchantOnboardingFeature(): { available: boolean } {
        return this.merchantOnboardingFeature;
    }

    //Method to set Direct Funded Merchant Onboarding Feature
    public setMerchantOnboardingFeature(available: boolean): void {
        this.merchantOnboardingFeature = { available };
    }

    // Function to build features
    public buildFeatures(): void {

    }

}

/**
 * apply the custom ui feature including from back end values
 * non defined value will have a default fallback
 */


export function applyCustomFeatures(defFeature?: any): void {

    let defFeatures = getConfigurations(defFeature);
    //defFeatures will use once api delivers the required settings
    const uiFeat:UIFeatureImpl = UIFeatureImpl.getImplementation();
    // Implement feature building logic here
    const mxmUser = JSON.parse(getLocalStorage('mxmUser'));
    const smbUser = JSON.parse(getLocalStorage('smbUser'));
    const ssoUser = JSON.parse(getLocalStorage('MXMSsoLogin'));
    const backToSSo = (ssoUser || (!ssoUser && mxmUser));
    const isSMBSource = mxmUser || smbUser;
    const showBatch = getKey('showBatch');

    const isInternalPMSettingEnabled = getKey('isInternalPMSettingEnabled');
    const mxcWhitelistedUsersList = getKey('mxcWhitelistedUsersList');

    const availFundsPopupPermission = {
        entity: "Accounts",
        name: "Faster Funds",
        operation: "Avail"
      };
    
    // Implement UI feature blessing logic here
    
    uiFeat.setActivateAccount(isSMBSource, '', '')
    uiFeat.setInvestmentAccount(true, '', '')
    uiFeat.setTransactionFeatures(mxmUser,!mxmUser)
    uiFeat.setContactCardMethod('SHOW_CONTACT_CARD_METHOD' in defFeatures ? defFeatures['SHOW_CONTACT_CARD_METHOD'] === '1' : !isSMBSource)

    const compFeat = new FeatureBanner(
        mxmUser, // ismxmUser
        smbUser, // issmbUser
        (!isSMBSource), // isDefault
        ssoUser
    );
    
    uiFeat.setFasterFunding('COLLECT_CARD_QUICK_SETTLE' in defFeatures ? defFeatures['COLLECT_CARD_QUICK_SETTLE'] === '1' && checkPermission(availFundsPopupPermission) && getKey('userType') === CustomerType.BUSINESS : isSMBSource, compFeat.getFasterFundBannerContent('FAST_FUNDING_CONTENT_TYPE' in defFeatures && defFeatures['FAST_FUNDING_CONTENT_TYPE']), compFeat.getFastFundingBannerContent('FAST_FUNDING_CONTENT_TYPE' in defFeatures && defFeatures['FAST_FUNDING_CONTENT_TYPE']))
    uiFeat.setDebitCardContent(true, compFeat.getAppyDebitCardContent('DEBIT_CARD_CONTENT_TYPE' in defFeatures && defFeatures['DEBIT_CARD_CONTENT_TYPE'] === '1'), isSMBSource);
    uiFeat.setSSOUserFeatures(backToSSo, ssoUser, compFeat.backToButton());
    uiFeat.setBatchId(showBatch);
    uiFeat.setReadMeInfo(isSMBSource ? readMeConfig['smb']['url'] : readMeConfig['nonSmb']['url'], isSMBSource ? readMeConfig['smb']['projects'] : readMeConfig['nonSmb']['projects'], isSMBSource ? readMeConfig['smb']['api_key'] : readMeConfig['nonSmb']['api_key'])
    uiFeat.setPartnerFeature(getKey('loginType') === LoggedInUserTypeEnum.PARTNER)
    uiFeat.setPmFeature(getKey('loginType') === LoggedInUserTypeEnum.PM)
    uiFeat.setBusinessCustomerFeature(getKey('userType') === CustomerType.BUSINESS)
    uiFeat.setIndividualCustomerFeature(getKey('userType') === CustomerType.INDIVIDUAL || getKey('userType') === CustomerType.JOINT_TENANCY)
    uiFeat.setJointTenancyFeature(getKey('userType') === CustomerType.JOINT_TENANCY)
    uiFeat.setMerchantOnboardingFeature(isInternalPMSettingEnabled === 'TRUE' || mxcWhitelistedUsersList?.length > 0)
}

/**
* Function to get configuration key value
*/
const getConfigurations = (data: any) => {
    const obj = (data?.resources || []).reduce((acc: any, item: any) => {
        if (item?.name) {
            acc[item.name] = item?.value;
        }
        return acc;
    }, {});
    return obj
}

export default UIFeatureImpl;
