import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "../../Data/DataGrid";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import {
  IFilterColumns,
  IFilterFields,
  IDataGridProps,
} from "../../../types/common.interfaces";
import { Filter } from "../../Data/Filter";
import { Logger } from "../../../libs/utils/logger";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { useDispatch } from "react-redux";
import {
  FxPrimaryFilter,
  IPrimaryFilterData,
} from "../../Data/FxPrimaryFilter";
import { useHistory } from "react-router";

/**
 * Component: Webhook Event Detail List
 * Usage: To display the list of Webhooks Event logs recieved by the user
 */

export const WebhookEventDetailList: React.FC<any> = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const stateObj = history?.location?.state;

  /**
   * This Method takes the Event List data and transforms it into a dropdown component friendly code
   * @param eventListData 
   * @returns 
   */
  function getAllEventNames(eventListData:any) {
    const allEvents: any = [];
    const eventListLength = eventListData.length;

    for (let i = 0; i < eventListLength; i++) {
      const item = eventListData[i];
      const eventNames = item.eventName;
      eventNames.forEach((eventName: any) => allEvents.push({ label: eventName, value: eventName }));
    }
    return allEvents;
  }

  /**
   * Source data for the eventHistory/list API
   */
  const src = {
    url: "/webhookSubscription/eventHistory/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        sortBy: "createdOn",
        sortOrder: "desc",
      },
      criteria: {
        filters: [
          {
            "operator": "eq",
            "key": "webhookSubscription.id",
            "values": [
              stateObj?.webhook?.id
            ]
          },
          {
            "operator": "in",
            "key": "status",
            "values": [
              'SENT'
            ]
          }
        ],
      },
    },
  };

  /**
   * Columns for the listing
   */
  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "Event Entity ID",
        enable: true,
        fieldName: "eventEntityId",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "Event Name",
        enable: true,
        fieldName: "eventName",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "STATUS",
        enable: true,
        fieldName: "status",
        type: "fri://fieldtype/status",
        default: true,
      },
      {
        "label": "STATUS REASON",
        "enable": true,
        "fieldName": "statusReason",
        "type": "fri://fieldtype/default",
        "truncateChars": 35,
        "default": true,
      },
      {
        "label": "Status Date",
        "enable": true,
        "fieldName": "statusDate",
        "type": "fri://fieldtype/date",
        "default": true,
      },
      {
        label: "Created On",
        enable: true,
        fieldName: "createdOn",
        type: "fri://fieldtype/date",
        default: true,
      }
    ],
  };

  /**
   * useeffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: { id: "page-title-details", data: { title: "Webhook Events Details", backButton: '/webhook/view/' + stateObj?.webhook?.id } },
    });
  }, [dispatch, stateObj?.webhook?.id]);

  /**
   * useEffect to reset the filter while unmount the page
   */
  useEffect(
    () => () => {
      updateComponentParams(dispatch, "webhook-event-detail-list-filter", {
        resetFilter: true,
      });
    },
    [dispatch]
  );

  /**
   * DataGridProps declaration for the list
   */
  const datagridProps: IDataGridProps = {
    id: "webhook-event-detail-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  /**
   * Filter fields for the listing
   */
  const filterFields: IFilterFields = {
    fields: [
      {
        label: "Event",
        fieldName: "event",
        type: "fri://fieldtype/select",
        className: "filter-divider filter-tri-row-checkbox-divider",
        data: getAllEventNames(stateObj?.webhook?.eventList)
      },
      {
        label: "Created On",
        toLabel: "To",
        fieldName: "createdOn",
        type: "fri://fieldtype/daterange",
        data: [],
        defaultValue: false,
      }
    ],
  };

  /**
   * Method to transform data for list columns
   * */
  function transformWebhookListData(data: any) {
    try {
      return data?.map((item: any) => {
        return {
          ...item,
          eventEntityId : item?.eventEntity?.id,
        };
      });
    } catch (e) {
      Logger.error("WebhookList.tsx", "transformWebhookListData", "error", e);
      return false;
    }
  }

  //primary filters data
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: "Event Name", value: "event", operator: "eq" },
    { label: "Event Entity ID", value: "eventEntityId", operator: "eq" }
  ];

  return (
    <Grid id="webhook-event-detail-list-main-grid" item xs={12}>
      <Grid container xs={12} className="fx-layout-list">
        <FxCard id="webhook-event-detail-list-card" className="fx-theme-passport">
          <FxCardHeader
            id="webhook-event-detail-list-card-header"
            className="fx-card-header"
          >
            <Grid
              id="webhook-event-detail-list-card-header-first-grid"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid id="webhook-event-detail-list-card-header-second-grid">
                <Grid item className="fx-approval-primary-filter">
                  <FxPrimaryFilter
                    id="webhook-event-detail-list-primary-filter"
                    name="webhook-event-detail-list-primary-filter"
                    data={primaryFilterData}
                    dataGridProps={datagridProps}
                    defaultSelection="event"
                  />
                </Grid>
              </Grid>
              <Grid
                id="webhook-event-detail-list-filter-grid"
                item
                container
                spacing={2}
                className="fx-action-block fx-inline-flex"
              >
                <Grid item>
                  <Filter
                    id="webhook-event-detail-list-filter"
                    field={filterFields}
                    dataGridProps={datagridProps}
                  ></Filter>
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="user-list-Card-Body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="webhook-event-detail-list-card-body-grid" item xs={12}>
              <DataGrid
                id="webhook-event-detail-list"
                tableClass="fx-table-passport"
                dataTransformer={transformWebhookListData}
                source={src}
                column={column}
                pagination={true}
                pageNumber={1}
                pageSize={25}
                resultPerPage={25}
              />
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </Grid>
  );
});
