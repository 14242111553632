import React, { useEffect, useState } from 'react';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import { IFileInfo } from '../../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../../Data/FxAgreementLinkCard';
import FxCheckEdit from '../../../Input/FxCheck/FxCheckEdit';
import { AUTH_STRINGS } from '../../../../constants/strings';
import { ReactComponent as MultipleUser } from '../../../../assets/svg/multiple-user-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { ICardData } from './ReviewInfoCard';
import { ReviewInfoCard } from './ReviewInfoCard';

/**
 * Component: ReviewAndSubmitSignup
 */


Logger.debug("ReviewAndSubmitSignup.tsx", "Review and Submit Signup initializing")

interface InputErrType {
    type: string;
    message: string;
}


interface IReviewAndSubmitSignup {
  onSubmit: (data: any) => void;
  goBackClick: (step?: number) => void;
  loading: boolean;
  reviewDetails?: { info: ICardData, step: number}[];
}

export const ReviewAndSubmitSignup: React.FC<IReviewAndSubmitSignup> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        const [isLoading, setIsLoading] = useState(false);
        const [btnDisabled, setBtnDisabled] = useState(true);
        const [fileInfo, setFileInfo] = useState<IFileInfo[]>([]);
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

      // Method handles the submit for review
        async function onSubmit(data: any) {
            if (fileInfo) {
                data['fileData'] = fileInfo
            }
            if (props.onSubmit) {
                props.onSubmit(data);
            }
        }

        useEffect(() => {
            setIsLoading(props.loading)
        }, [props.loading])

        // Error render function
        const renderError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return AUTH_STRINGS.ERRORS.AGREE_PLEASE_ENTER;
            }
            return err.message;
        };
        // Privacy policy options
        const privacyPolicyData = [
            { label: 'By submitting this form, you agree to the Passport Account agreement, to receiving electronic communication from Passport, and you certify that the information provided is complete and correct.', value: 'yes' }
        ];
        /**
     * Method to handle form change events
     * @param event :event object
     */
        const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target.checked === true) {
                setBtnDisabled(false)
            }
            else {
                setBtnDisabled(true)
            }
        }
        // Method handles the onclick event for going back to previous screen
        const handleGoBack = (step?: number) => {
            if (props.goBackClick) {
                props.goBackClick(step);
            }
        }

        return (
            <Grid container id="create-external-account-main-grid" xs={12} justifyContent="center">
                <Grid item xs={12} sm={isSmallScreen ? undefined : 11} md={isSmallScreen ? undefined : 7}>
                    <FxCard id="create-external-account-form-card" className="fx-theme-passport-no-before fx-no-shadow">
                        <form id="create-external-account-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCardHeader id="create-external-account-form-card-header">
                               <Grid container xs={12}>
                                 <Grid item xs={12} className={'fx-signup-formkyc-topblock'}>
                                  <Grid item xs={12} container flexWrap={'nowrap'}>
                                    <Grid item className="fx-signup-formkyc-topblock-icon">
                                      <MultipleUser />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="h3">
                                        Review and Submit
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                               </Grid>
                            </FxCardHeader>
                            <FxCardBody id="create-external-account-form-card-body" className="fx-info-card fx-margin-top-reducer">
                              {props.reviewDetails?.map((cardData, index) => {
                                return (
                                  !!cardData?.info?.items?.length && <Grid container marginY={'2rem'}>
                                    <ReviewInfoCard key={index} id={`review-info-card-${index}`}
                                                    cardData={cardData?.info}
                                                    handleClick={() => handleGoBack(cardData?.step)} />
                                  </Grid>
                                )
                              })}
                                <Grid container className="fx-info-card-form fx-kyc-agree" >
                                    <Grid item xs={12}>
                                      <FxAgreementLinkCard
                                        agreementTypes={['PassportAccount','RateCard']}
                                        onFileInfoReady={setFileInfo}
                                      />
                                    </Grid>
                                    <Grid item container xs={12} className="flex column fx-kyc-agree-label fx-review-business-signup-agree">
                                        {!!fileInfo?.length && <Grid item xs={12} className="fx-submit-agree">
                                            <FxCheckEdit register={{ ...register("privacyPolicyAccepted") }} rules={{ required: "Please click Agree" }} control={control} onChange={(e: any) => { handleChange(e); }} id="privacy-policy-accepted" name="privacyPolicyAccepted" data={privacyPolicyData} className="fx-privacy-text" row="vertical" setValue={setValue} />
                                        </Grid>}
                                        <Grid item xs={12} className={'error-message'}>
                                            {errors.privacyPolicyAccepted && renderError(errors.privacyPolicyAccepted)}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} marginBottom={2}>
                                     <Divider />
                                    </Grid>
                                    {isSmallScreen && <><Grid item xs={12}></Grid><Grid item xs={12}></Grid></>}
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="create-external-account-form-card-footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className={isSmallScreen?"fx-modal-footer fx-footer-kyc":"fx-modal-footer"}>
                                    <FxButton variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="ea-modal"
                                        onClick={()=>handleGoBack()}>
                                        Go Back
                                    </FxButton>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className={btnDisabled ? "fx-button fx-button-theme-disabled" : "fx-button fx-button-theme"}
                                        id={"create-external-account-form-card-save-button"}
                                        type="submit"
                                        disabled={btnDisabled}
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })
