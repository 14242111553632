import React, { useEffect } from 'react';
import { Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { DataGrid } from '../../Data/DataGrid';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download.svg';
import { getImageDownload } from './DownloadImage';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxGridList } from '../../Data/FxGridList';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as ThreeDotMenu } from '../../../assets/svg/threedot-menu-icon.svg';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { getCustomerUrl, useIsMobileScreen } from '../../../libs/utils/utils';
Logger.debug("DownLoadDocs.tsx", "view documents initializing")


/**
 * This component handles the list of the linked documents
 */

interface IDownLoadDocs{
    data:any;
    options:any[];
    anchorEl?:any;
    id:string;
    docData?:any

}
export const DownLoadDocs: React.FC<IDownLoadDocs> = React.memo(
    (props) => {
        const isSmallScreen = useIsMobileScreen();
        const httpClientBlob = HttpClient.newClientBlob();
        let fileName: any;
        let fileType: any;
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        let linkedDocuments: any = [];
        let mobileDoc: any;
        const open = Boolean(props?.anchorEl);
        /**
         * Resting the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        /**
         * Method to set actions
         * @param item 
         * @returns 
         */
        const getActions = (item: any) => {
            return (<Grid id="filter-Grid" className="fx-view-action" >
                {
                    <>
                        <FxButton
                            id={props.id + '_' + item?.id + '-button'}
                            className="fx-button fx-button-dotted fx-button-dotted-icons"
                            onClick={(event: any) => { handleClick(event, item) }}
                        >
                            <ThreeDotMenu />
                        </FxButton>
                        {<Menu
                            id="download-docs-info-menu"
                            MenuListProps={{
                                'aria-labelledby': 'download-docs-info-menu',
                            }}
                            open={open}
                            anchorEl={props.anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}

                            className="fx-menu-dotted fx-menu-linked-document"
                        >
                            {(props.options.length > 0) && props.options.map((option: any) => (
                                <>
                                    {
                                        <MenuItem id={option.id} key={option} onClick={() => { handleChange(option.title, item) }}>
                                            <div className={`${option?.className} document-list`}>{option?.icon} &nbsp;&nbsp; {option.title}</div>
                                        </MenuItem>
                                    }
                                </>
                            ))
                            }
                        </Menu>}
                    </>}
            </Grid>)
        }

        /**
   * close the popup
   */
        const handleClose = () => {
            updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
        };
        /**
         * Handle change event for threedot menu
         * @param event 
         * @param data 
         */
        const handleChange = (event: any, data: any) => {
            try {
                if (event === 'Download') {
                    downloadFile(event, props?.docData)
                    updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
                }
            }
            catch (e) {
                Logger.error("DownLoadDocs.tsx", "error", e);
             }
        }
        /**
         * Method to transform data
         * @param data 
         * @returns 
         */
        const transformLinkedDocuments = (data: any) => (data?.map((item: any) => ({
            ...item,
            documentName: <Grid ><Typography className='fx-download-doc-color'>{item?.document?.name}</Typography></Grid> ,
            documentType: item?.document?.type,
            status: item?.status,
            action: getActions(item)
        })) || []);

        if (props?.data?.linkedDocument) {
            linkedDocuments = transformLinkedDocuments(props.data.linkedDocument);
            mobileDoc = props.data.linkedDocument
        }

        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Document Name",
                    "enable": true,
                    "fieldName": "documentName",
                    "type": "fri://fieldtype/custom",
                    "style": { "color": '#FF0000' },
                    "default": true
                },
                {
                    "label": "",
                    "enable": true,
                    "fieldName": "action",
                    "type": "fri://fieldtype/custom",
                    "style": { "width": "100px", "text-align": "center" },
                    "default": true
                }
            ]
        };

        /**
         * Async function handles the api call for downloading the file
         * @param event : event parameter
         * @param data : row data
         */
        async function downloadFile(event: any, data: any) {
            const downloadObj = data;
            if (downloadObj) {
                if (downloadObj.document && downloadObj.document.id && downloadObj.document.name) {
                    fileName = downloadObj.document.name?.split('.')[0];
                    fileType = downloadObj.document.name?.split('.')[1];
                    await httpClientBlob.get(getCustomerUrl('document/id/' + downloadObj.document.id)).then((response: any) => {
                        if (response.data) {
                            getImageDownload(response.data, fileName, "." + fileType)
                        }
                    })
                }
            }
        }
        /**
         * Layout for Document Id
         * @param id 
         * @returns 
         */
        const createDocumentId = (id: string) => (
            <Grid item container xs={12}>
                <Grid item>
                    <Typography variant='h4'>ID : {id}</Typography>
                </Grid>
            </Grid>
        );
        /**
        * Layout for Document Type and Status
        * @param id 
        * @returns 
        */
        const createDocumentTypeAndStatus = (type: string, status: any) => (
            <Grid item container xs={12}>
                <Grid item>
                    <Typography variant='h4'>{type}</Typography>
                </Grid>
                <Grid item>
                    <FxStatus id="recurring-history-status" value={status} fill={true} className="fx-status" />
                </Grid>
            </Grid>
        );
        /**
         * Layout for Document download button
         * @param id 
         * @returns 
         */
        const createDocumentNameAndDownloadButton = (name: string, item: any) => (
            <> <Grid item container xs={11}>
                <Grid item container xs={10}>
                    <Typography variant='h4'> {name}</Typography>
                </Grid>
            </Grid>
                <Grid item container xs={1} alignItems='center'>
                    <Grid >
                        <IconButton onClick={(e: any) => downloadFile(e, item)}>
                            <DownloadIcon />
                        </IconButton>
                    </Grid>
                </Grid></>
        );
        /**
        *  Handling the click event
        * */

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
            updateComponentParams(dispatch, props?.id, { docData:data, 'anchorEl': event?.currentTarget })
        };

        /**
         * Layout for Divider
         * @param id 
         * @returns 
         */
        const createDivider = () => (
            <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
                <Grid item xs>
                    <Divider />
                </Grid>
            </Grid>
        );
        /**
         * @param data : View document List for mobile
         * @returns : transformed data
         */
        const docListGridTransformerMobile = (data: any) => {
            const transformedData = data?.map((item: any) => ({
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        <Grid item container xs={11} alignItems='center'>
                            {createDocumentId(item.document?.id)}
                            {createDocumentTypeAndStatus(item.document?.type, item?.status)}
                            {createDocumentNameAndDownloadButton(item.document?.name, item)}
                        </Grid>
                    </Grid>
                ),
                divider: createDivider(),
            }));
            return transformedData || [];
        };

        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                },
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                }
            ]
        }
        return (
            <Grid id="downLoad-docs-documents-Grid" item xs={12}>
                <Grid id="DownLoadDocs-documents-Grid-b" item xs={12}>
                    <form>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="DownLoadDocs-header">
                                <Grid container item direction="row" justifyContent="space-between">
                                    <Typography id="title-DownLoadDocs-documents" variant="h4" >Attached Documents </Typography>
                                </Grid>

                            </FxCardHeader>
                            <FxCardBody id="DownLoadDocs-document-list-Card-Body" className="fx-card-body-no-border-no-shadow">
                                <Grid id="all-account-document-list-DataGrids" item xs={12}>
                                    {!isSmallScreen ? <DataGrid id="datagrid-view-documents-DownLoadDocs-list" tableClass="fx-table-passport" data={linkedDocuments} column={column} pagination={false} iconClick={downloadFile} />
                                        : <FxGridList id="datagrid-view-documents-DownLoadDocs-list" data={docListGridTransformerMobile(mobileDoc)} column={gridColumn} />
                                    }
                                </Grid>
                            </FxCardBody>
                        </FxCard>
                    </form>
                </Grid>
            </Grid>
        )
    });
