import {
  put,
  select,
  takeLeading,
  delay
} from "redux-saga/effects";
import { State } from "../reducer/rootReducer";
import { PageActions } from "../reducer/pageReducer";
import { getAPI } from "./apiSaga";

import { JSONConversion } from "../utils/layout";
import { LayoutAction } from "../reducer/layoutReducer";
import _ from "lodash";
import { Logger } from "../utils/logger";

Logger.debug("pageSaga.tsx", "Page Saga initializing")

/**
 * function to get page state values
 * @param state
 */

const getState = (state: State) => state;
const getLayout = (state: State) => state.config.layout;
const getPageComponentLoadStatus = (state: State) => state.context.page;

/**
 * Watcher function for PAGE_LOAD_JSON_API_DONE EVENT
 * @param action
 */

export default function* pageSaga() {
  yield takeLeading(PageActions.PAGE_LOAD, loadPageSaga);
}

function* loadPageSaga(action: any): any {
  Logger.info("pageSaga.tsx", "info", "## loading page ", action);
  let pageJson = yield buildPageJSON(action.payload.id);

  const context = buildPageContext(pageJson);
  yield put({
    type: "COMPONENT_CONTEXT_INITIALIZE",
    payload: {
      id: action.payload.id,
      context: context
    }
  });
  let status = null;
  do {
    Logger.info("pageSaga.tsx", "info", "Waiting the page context to set before loading...");
    delay(10);
    let pageContext = yield select(getPageComponentLoadStatus);
    status = pageContext.status;
  }
  while (status && status !== 2);
  yield put({
    type: "PAGE_SET_READY",
    payload: {
      id: action.payload.id
    }
  });
}

function buildPageContext(json: any, context: any = {}): any {
  if (Array.isArray(json)) {
    json.map(component => {
      return buildPageContext(component, context);
    });
  } else {
    if (Object.keys(context).length  !== 0 && json !== undefined) {
      let sJson = { ...json };
      sJson["components"] = null;
      if (sJson["source"]) {
        sJson["source"].status = 0;
      }
      if (json.id) context[json.id] = { config: sJson, data: {} };
    }
    if (json && json.components) {
      return buildPageContext(json.components, context);
    }
  }
  return _.cloneDeep(context);
}

export function* buildPageJSON(
  page: string,
  pageJson: any = null,
  layoutJson: any = null,
  force: boolean = false
): any {
  let pageState: any;
  let portal = "";
  if (force) {
    pageState = undefined;
  } else {
    let state = yield select(getState);
    pageState = { ...state.config.page[page] };
    portal = state.portal.name;
  }

  if (pageState === undefined || pageState.data === undefined) {
    // get the JSON from API SAGA async
    let json = pageJson;
    if (json == null) {
      json = yield getAPI(getPageJsonURL(portal, page), {});
      json = json.body;
    }
    let layout = layoutJson;
    if (layout == null) {
      layout = yield getAsyncLayoutJSON(portal, json.layout.name);
    }
    const finalJson = yield compilePage(page, json, layout);

    return finalJson;
  }
  return pageState.data;
}

export function* compilePage(page: string, json: any, layout: any) {
  const newJson = JSONConversion(layout, json);

  yield put({
    type: PageActions.PAGE_LOAD_JSON_UPDATE_COMBINE_JSON,
    payload: {
      id: page,
      data: newJson
    }
  });
  return newJson;
}

function* getAsyncLayoutJSON(portal: string, name: string): any {
  let layoutState = yield select(getLayout);
  if (name.indexOf(":") < 0) {
    name = "fri::layout:" + name;
  }
  let json = { ...layoutState[name] };

  if (Object.getOwnPropertyNames(json).length === 0) {
    json = yield getAPI(getLayoutURL(portal, name), {});
    json = json.body;
    yield put({
      type: LayoutAction.LAYOUT_LOAD_JSON_SET,
      payload: {
        id: name,
        data: json
      }
    });
  }
  return json;
}

function getPageJsonURL(portal: string, page: string) {
  page = page.substr(page.lastIndexOf(":") + 1);
  portal = portal.substr(portal.lastIndexOf(":") + 1);
  return "/api/portal/" + portal + "/page/" + page + ".json";
}

function getLayoutURL(portal: string, page: string) {
  page = page.substr(page.lastIndexOf(":") + 1);
  portal = portal.substr(portal.lastIndexOf(":") + 1);
  return "/api/portal/" + portal + "/layout/" + page + ".json";
}
