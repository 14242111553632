import React, { useEffect } from "react";
import "./App.css";
import { Grid } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import './assets/styles/integrated.scss';
import Logout from "./components/Page/Auth/Logout";
import UploadAvatar from "./components/Data/UploadAvatar";
import { isUserLoggedIn, getKey, setKey, setSessionStorageCustom } from "./libs/utils/storageManager";
import { matchPath, useLocation } from "react-router";
import { Logger } from "./libs/utils/logger";
import { PrivateRoute } from "./components/Page/PrivateRoute";
import { refreshToken } from "./libs/utils/api";
import ForgotPassword from "./components/Page/Auth/ForgotPassword";
import UserRegistration from "./components/Page/Auth/UserRegistration";
import LoginTerms from "./components/Page/Auth/LoginTerms";
import { VerifySetPassword } from "./components/Page/Auth/VerifySetPassword";
import { SignUpFlowHome } from "./components/Page/SignUp/SignUpFlowHome";
import { Fx404 } from "./components/Page/SignUp/Fx404";
import { SignUpSuccessRegistration } from "./components/Page/SignUp/SignUpSuccessRegistration";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


//idle timeout
import { useIdleTimer } from 'react-idle-timer';
import DEFAULT_CONFIG from './configs/default.json';
import { BusinessUserSignupWrapper } from "./components/Page/SignUp/Business/BusinessUserSignupWrapper";
import { SignUpIndividualWrapper } from "./components/Page/SignUp/Individual/SignUpIndividualWrapper";
import ScrollToTop from "./components/Utils/ScrollToTop";
import { FxPermissionDeniedHome } from "./components/Page/Index/Passport/PermissionDeniedHome";
import { ViewVirtualCardInfo } from "./components/Page/VirtualCard/ViewVirtualCardInfo";
import { setLocalStorage } from "./libs/utils/cacheHandler";
import FxSnackBar from './components/Utils/fx-snack-bar';
import FxModalBase from './components/Utils/fx-modal-base';
import { RegisterComponent } from "./libs/saga/dataSaga";
import { PartnerAgreement } from "./components/Page/Partner/Onboarding/PartnerAgreement";
import { CoOwnerAgreement } from "./components/Page/CoOwner/Onboarding/CoOwnerAgreement";
import { JointTenancySignUpWrapper } from "./components/Page/SignUp/JoinTenancy/JointTenancySignUpWrapper";

Logger.debug("App.tsx", "Application started")

declare global {
  interface Window {
    SMB_FLAG: any;
  }
}

//refresh token
setTimeout(() => {
  refreshToken();
}, 60000 * 2);

const useStyles = makeStyles()(() =>{
  return {
    root: {
      flexGrow: 1
    }
  }});

  const App: React.FC<any> = (props: any) => {
  ({ props } = RegisterComponent(props));
  const { classes } = useStyles();
  let loggedInStatus = isUserLoggedIn();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const MXMssoUser = window.location.pathname?.includes('/sso')
  const theme = props?.theme || params.get('theme') || getKey("theme");

  useEffect(()=>{
    const themeName = loadTheme(theme ? theme : MXMssoUser || window.SMB_FLAG ? 'mxm' : 'passport');
    setLocalStorage('smbUser',window?.SMB_FLAG)
    const iconEl = document.getElementById('favicon-link') as HTMLLinkElement | null;
    if(iconEl) {
      iconEl.href = `/favicon/${themeName}.png`;
    }
  },[theme, MXMssoUser]);

  const location:any = useLocation();
  const locationState = location.state; // Provide a default value for backUrl in case it is undefined

  useEffect(() => {
    window.onbeforeunload = function () {
      //if user tries to rteload the session token will reset
      //this will result logout while using secure api

      if (loggedInStatus) {
        setSessionStorageCustom("locationState", locationState)
        setSessionStorageCustom("reloadPath",window.location.pathname)
        setSessionStorageCustom("customerId",getKey('customerId'))
        setSessionStorageCustom("CustomerName",getKey('CustomerName') || getKey('CustomerIndividualFullName'))
        setSessionStorageCustom("doingBusinessAs",getKey('doingBusinessAs'))
        setSessionStorageCustom("mxmMerchant",getKey('mxmMerchant'))
        setSessionStorageCustom("mxmFastFunding",getKey('mxmFastFunding'))
        setSessionStorageCustom("authUserId",getKey('authUserId'))
        window.location.pathname !=='/logout' && setSessionStorageCustom("isUserRefreshed",true);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);


  //check for excluded URLs when session expired and redirect to login page
  const excludedPaths = ["/login", "/logout", "/auth/reset-password/:id", "/auth/forgot-password", "/auth/user-registration/:id", "/auth/upload-avatar/:id", "/auth/terms/:id","signup"];
  let isExcludedPath = false;
  for (let i = 0; i < excludedPaths.length; i++) {
    const match = matchPath(window.location.pathname, { path: excludedPaths[i], exact: true, strict: false }); // match path with :id
    if (match) {
      isExcludedPath = true;
      break;
    }
  }

  //idle timeout
  const onIdle = () => {
    // Do some idle action like log out your user
    if(loggedInStatus){
      window.location.href = "/logout";
    }
  }

  const idleTimeout = Number(DEFAULT_CONFIG['IDLE_TIMEOUT']);
  useIdleTimer({ onIdle,timeout:idleTimeout });

  if (!loggedInStatus && !isExcludedPath) {
    //  window.location.href = "/login";
  }


  return (
    <>
     <ScrollToTop />
      <div className={classes.root}>
        <Grid container>
          <Switch>
            <Route path="/logout" key="logout">
              <Logout />
            </Route>
            <Route path="/signup/invitation/:token" key="signup">
              <SignUpFlowHome />
            </Route>
            <Route path="/signup/success" key="registersuccess">
              <SignUpSuccessRegistration />
            </Route>
            <Route path="/individual/kyc" key="individual-kyc">
              <SignUpIndividualWrapper />
            </Route>
            <Route path="/joint_tenancy/kyc" key="individual-kyc">
              <JointTenancySignUpWrapper />
            </Route>
            <Route path="/business/kyc" key="business-kyc">
              <BusinessUserSignupWrapper />
            </Route>
            <Route path="/auth/terms/:id" key="auth-terms">
              <LoginTerms />
            </Route>
            <Route path="/auth/forgotpassword" key="auth-forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/auth/userregistration/:id" key="auth-user-registration">
              <UserRegistration />
            </Route>
            <Route path="/auth/uploadavatar/:id" key="auth-upload">
              <UploadAvatar />
            </Route>
            <Route path="/user/set-password/:token" key="auth-password-set">
              <VerifySetPassword />
            </Route>
            {/* <Route path="/user/set-password/:token" key="auth-set-password">
              <VerifyMFASetup />
            </Route> */}
            <Route path="page-not-found" key="page-not-found">
              <Fx404 />
            </Route>
            <Route exact path="/user/permission/denied">
              <FxPermissionDeniedHome id={'permission-denied-home'} key="permissionDeniedHome" />
            </Route>
            <Route path="/virtual-card/:token" key="virtual-card-info">
              <ViewVirtualCardInfo id={'view-virtual-card-info'} key="view-virtual-card-info-key" />
            </Route>
            <Route path='/partner/welcome' key='partner-welcome'>
              <PartnerAgreement id={'partner-aggrement-details'} key="partner-aggrement-layout" />
            </Route>
            <Route path='/co-owner/welcome' key='co-owner-welcome'>
              <CoOwnerAgreement id={'co-owner-aggrement-details'} key="co-owner-aggrement-layout" />
            </Route>
            {isUserLoggedIn() &&  <PrivateRoute /> }
            <Route component={Fx404} />

          </Switch>


        </Grid>
      </div>
      <FxSnackBar/>
      <FxModalBase/>
      </>
  );
}

export default App;
/**
 * choose a theme
 * @param theme
 */

function loadTheme(theme: string): string {
  const themes = [
    'passport',
    'mxm',
    'green',
    'blackstone',
    'clsa',
    'stax',
  ];
  const finalTheme = themes.includes(theme) ? theme : 'passport';
  document.documentElement.setAttribute('data-theme', finalTheme);
  setKey("theme", finalTheme);
  return finalTheme;
}