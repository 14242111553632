import React from 'react';
import { FxTopBannerView } from './FxTopBannerView';
import FxRightDrawer from '../fx-right-drawer';
import { FxInvestmentAccountDrawer } from '../../DrawerPages/Treasure/FxInvestmentAccountDrawer';
import { FxButton } from '../../Action/FxButton';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { FxFastFunding, IFastFundingPayload } from '../../DrawerPages/FxFastFunding/FxFastFunding';
import DEFAULT_CONFIG from '../../../configs/default.json'

const uiFeat = UIFeatureImpl.getInstance();

export type FxTopBannerContentType = React.ComponentType<{onClose:() => void, bannerData:any}>;

interface IState {
    Content?: FxTopBannerContentType;
    bannerData?: any;
}

export interface IFxTopBanner {
    show(Content: FxTopBannerContentType): void;
    closeForce(id: string): void;
    bannerData(data: any): void;
}

export class FxTopBannerController extends React.Component<{}, IState> implements IFxTopBanner {

    constructor(props:any) {
        super(props);
        this.state = {};
    }

    public show = (Content: FxTopBannerContentType) => {
        this.setState({ Content });
    }

    public bannerData = (data: any) => {
        const newBannerData = data?.map((item: any) => {
            let componentContent: any;
            if (item.component === 'topTreasuryBanner') {
                componentContent = <div><p><span className="fx-top-area-passport-text1">Earn up to {DEFAULT_CONFIG['CASH_BUILDER_INTEREST_YIELD_PERCENTAGE']}</span>&nbsp;<span className="fx-top-area-passport-text2">yield * revenue with Passport Cash Builder Account.</span></p>
                    <FxButton className={'fx-button fx-button-saturated-orange'} onClick={() => this.openPassportDrawer('CASH_BUILDER')}>LET'S GET STARTED</FxButton>
                </div>
            }
            else if (item.component === 'topCashBuilderBanner') {
                componentContent = <div><p><span className="fx-top-area-passport-text1">Earn up to {DEFAULT_CONFIG['CASH_BUILDER_PLUS_INTEREST_YIELD_PERCENTAGE']}</span>&nbsp;<span className="fx-top-area-passport-text2">yield * revenue with Passport Cash Builder+ Account.</span></p>
                    <FxButton className={'fx-button fx-button-saturated-orange'} onClick={() => this.openPassportDrawer('CASH_BUILDER_PLUS')}>LET'S GET STARTED</FxButton>
                </div>
            }
            else if (item.component === 'topFasterFundingcontent') {
                componentContent = !item.isMobileScreen ? (<div>
                    {uiFeat.getFasterFunding().banner}
                    <FxButton onClick={this.openFastFundingDrawer} className={'fx-button fx-button-saturated-orange'}>OPT IN</FxButton>
                </div>) : (<div><p>&nbsp;Opt in for Faster Funding.</p><FxButton onClick={this.openFastFundingDrawer} className={'fx-button fx-button-saturated-orange'}>OPT IN</FxButton></div>);
                if (!!item.clicked) {
                    this.openFastFundingDrawer()
                }
            }
            return { id: item.id, component: componentContent };
        })
        this.setState({ bannerData: newBannerData });
    }

    public closeForce = (id: string) => {
        let finalData = this.state?.bannerData?.filter((_d : any) => _d?.id !== id);
        this.setState({ bannerData: finalData });
    }

    /**
    * method to Open Investment Account Drawer
    */
    openPassportDrawer = (async (type: any) => {
        await FxRightDrawer.show(({ onClose }) => {
            return <FxInvestmentAccountDrawer type={type} onDrawerClose={onClose} />
        })
    })

    /**
    * method to draw fast funding banner
    */
    openFastFundingDrawer = (async () => {
        await FxRightDrawer.show<IFastFundingPayload>(({ onClose }) => {
            return <FxFastFunding onDrawerClose={onClose} />
        })
})
    
    render = () => {
        const { bannerData } = this.state;

        if (!bannerData || bannerData?.length === 0) {
            return null;
        }

        return (
            <FxTopBannerView bannerData={bannerData} />
        );
    }
}
