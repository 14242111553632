import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "../../Data/DataGrid";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import {
  IFilterColumns,
  IFilterFields,
  IDataGridProps,
} from "../../../types/common.interfaces";
import { Filter } from "../../Data/Filter";
import { FxDivider } from "../../Data/FxDivider";
import { Logger } from "../../../libs/utils/logger";
import { useHistory } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../assets/svg/add-new-icon.svg";
import { FxButton } from "../../Action/FxButton";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { useDispatch } from "react-redux";
import {
  FxPrimaryFilter,
  IPrimaryFilterData,
} from "../../Data/FxPrimaryFilter";

/**
 * Component: Webhooks List
 * Usage: To display the list of Webhooks created by the user
 */

export const WebhookList: React.FC<any> = React.memo(() => {
  const dispatch = useDispatch();
  const src = {
    url: "/webhookSubscription/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        sortBy: "lastUpdatedOn",
        sortOrder: "desc",
      },
      criteria: {
        filters: [
          {
            operator: 'eq',
            key: 'type',
            values: [
              'INSTANT'
            ]
          }
        ],
      },
    },
  };

  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "ID",
        enable: true,
        fieldName: "id",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "20%" },
      },
      {
        label: "Name",
        enable: true,
        fieldName: "name",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "20%" },
      },
      {
        label: "url",
        enable: true,
        fieldName: "url",
        type: "fri://fieldtype/default",
        "truncateChars": 70,
        default: true,
        style: { width: "20%" },
      },
      {
        label: "STATUS",
        enable: true,
        fieldName: "status",
        type: "fri://fieldtype/status",
        default: true,
        style: { width: "10%" },
      },
      {
        label: "Last Updated On",
        enable: true,
        fieldName: "lastUpdatedOn",
        type: "fri://fieldtype/date",
        default: true,
        style: { width: "20%" },
      },
      {
        label: "Last Updated By",
        enable: true,
        fieldName: "lastUpdatedBy",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "20%" },
      },
    ],
  };

  /**
   * useeffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: { id: "page-title-details", data: { title: "Webhooks" } },
    });
  }, [dispatch]);

  /**
   * useEffect to reset the filter while unmount the page
   */
  useEffect(
    () => () => {
      updateComponentParams(dispatch, "webhook-list-filter", {
        resetFilter: true,
      });
    },
    [dispatch]
  );

  const history = useHistory();
  /**
   * Method to redirect to Webhook details page
   * @param event :event object - unused
   * @param data : user data
   */
  function redirectDetailsPage(event: any, data: any) {
    try {
      const id = data?.id;
      if (id) {
        history.push(`/webhook/view/${id}`);
      }
    } catch (e) {
      Logger.error("WebhookList.tsx", "redirectDetailsPage", "error", e);
    }
  }

  const datagridProps: IDataGridProps = {
    id: "webhook-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  const filterFields: IFilterFields = {
    fields: [
      {
        label: "Status",
        fieldName: "status",
        type: "fri://fieldtype/checkbox",
        className: "filter-divider filter-tri-row-checkbox-divider",
        data: [
          { label: "Enabled", value: "ENABLED", checked: false },
          { label: "Disabled", value: "DISABLED", checked: false },
          { label: "Blocked", value: "BLOCKED", checked: false },
        ],
      },
      {
        label: "Last Updated On",
        toLabel: "To",
        fieldName: "lastUpdatedOn",
        type: "fri://fieldtype/daterange",
        data: [],
        defaultValue: false,
      },
    ],
  };
  
  /**
   * Method to handle the configuring of a new webhook URL
   */
  const handleAddWebhookUrl = () => {
    history.push("/webhook/add");
  };

  /**
   * Method to transform data for list columns
   * */
  function transformWebhookListData(data: any) {
    try {
      return data?.map((item: any) => {
        return {
          ...item,
          lastUpdatedBy: item?.lastUpdatedBy?.username,
        };
      });
    } catch (e) {
      Logger.error("WebhookList.tsx", "transformWebhookListData", "error", e);
      return false;
    }
  }

  //primary filters data
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: "Name", value: "name", operator: "eq" },
  ];

  /**
   * Permission Object for Webhook Add Button
   */
  const addWebhookPermission = {
    entity: "Other Entities",
    name: "Webhook",
    operation: "View"
  }

  return (
    <Grid id="webhook-list-main-grid" item xs={12}>
      <Grid container xs={12}>
        <FxCard id="webhook-list-card" className="fx-theme-passport">
          <FxCardHeader
            id="webhook-list-card-header"
            className="fx-card-header"
          >
            <Grid
              id="webhook-list-card-header-first-grid"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid id="webhook-list-card-header-second-grid">
                <Grid item className="fx-approval-primary-filter">
                  <FxPrimaryFilter
                    id="webhook-list-primary-filter"
                    name="webhook-list-primary-filter"
                    data={primaryFilterData}
                    dataGridProps={datagridProps}
                    defaultSelection="name"
                  />
                </Grid>
              </Grid>
              <Grid
                id="webhook-list-filter-grid"
                item
                container
                spacing={2}
                className="fx-action-block fx-inline-flex"
              >
                <Grid item>
                  <Filter
                    id="webhook-list-filter"
                    field={filterFields}
                    dataGridProps={datagridProps}
                  ></Filter>
                </Grid>
                <Grid item className="fx-action-block-divider">
                  <FxDivider />
                </Grid>
                <Grid item>
                  <FxButton
                    id="webhook-list-add-button"
                    variant="contained"
                    permissions={addWebhookPermission}
                    className="fx-button fx-button-theme fx-svg-theme"
                    onClick={handleAddWebhookUrl}
                    startIcon={<AddIcon />}
                  >
                    Add Webhook
                  </FxButton>
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="user-list-Card-Body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="webhook-list-card-body-grid" item xs={12}>
              <DataGrid
                id="webhook-list"
                tableClass="fx-table-passport"
                rowClick={redirectDetailsPage}
                dataTransformer={transformWebhookListData}
                source={src}
                column={column}
                pagination={true}
                pageNumber={1}
                pageSize={25}
                resultPerPage={25}
              />
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </Grid>
  );
});
