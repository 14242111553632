import Keycloak from "keycloak-js";
import HttpClient from '../utils/httpClient';
import { pushGTM,formatPhone } from '../utils/utils';
/**
 * Service used to manage authorization using keycloak
 */

import { setUserToken, setUserLoggedIn, setUserInfo, setKey, isUserLoggedIn, getSessionStorageCustom } from "../utils/storageManager";
import { getLocalStorage, setLocalStorage } from "../utils/cacheHandler";
import { setCookie } from "../utils/cookieManager";



declare global {
    interface Window {
        AUTH_CONFIG: any;
    }
}

let config: any = window.AUTH_CONFIG;
const _kc = new Keycloak(config);


const doLogin = _kc.login;

const doLogout = _kc.logout;
const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getName = () => _kc.tokenParsed?.name;


const getInfo = () => {
    return _kc;
}


const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (onAuthenticatedCallback: any) => {
    _kc.init({
        onLoad: 'login-required',
        pkceMethod: 'S256',
        redirectUri:getRedirectPath()
    })
        .then(async (authenticated) => {
            /**
             * If keycloak's current session id is equal to previous one
             * it means the user opens the app in the new tab or refresh the current tab
             * so we need to logout user in that case
             */
            // const sessionId = getCookie('sessionId');
            // if(sessionId === _kc.sessionId) {
            //     _kc.logout();
            //     return;
            // }
            setCookie('sessionId', _kc.sessionId);
            if (!authenticated) {
                console.log("User is not authenticated..!");
            }


            /**
             * setting access key token to call subsequent api calls
             */

            HttpClient.setToken(_kc.token || '');

            /**
             * refressing access token in rugular intervals
             */
            setInterval(async () => {
                if (_kc.isTokenExpired(50)) {
                    await _kc.updateToken(50);
                    HttpClient.setToken(_kc.token || '');
                    setUserToken(_kc.token || '')
                }
            }, 30000);
            setUserToken(_kc.token || '');
            const loggedInStatus = isUserLoggedIn();
            //user already logged in
            if (loggedInStatus) {
                onAuthenticatedCallback();
            }
            else {
                await __setSession(() => {
                    onAuthenticatedCallback();
                });
            }




        })
        .catch(console.error);
};

/**
 * method to get redirect path 
 */
const getRedirectPath = () => {
    const protocol = window.location.protocol;       
    const host = window.location.host;
    const redirectUrl   = protocol + '//' + host;
    let isUserRefreshed = getSessionStorageCustom("isUserRefreshed")
    const MXMSsoLogin = JSON.parse(getLocalStorage('MXMSsoLogin'))
    const loggedInStatus = isUserLoggedIn();
    
    let proxyToken :any;
 

    if(window?.location?.pathname.includes('sso/')){
        proxyToken=window?.location?.pathname?.split('/')[2];
    }
    else{
    proxyToken= getLocalStorage('proxyToken') && JSON.parse(getLocalStorage('proxyToken'));
    }
    let retUrl = undefined
    if(window.location.pathname==='/logout'){
        retUrl = undefined
    }
    else if (isUserRefreshed && loggedInStatus && !proxyToken && !MXMSsoLogin) {
        retUrl = redirectUrl+'/postauth'
    }
    else if (isUserRefreshed && loggedInStatus && JSON.parse(getLocalStorage('isProxyLogin') && !MXMSsoLogin)) {
        retUrl = redirectUrl+'/proxylogin/' + proxyToken
    }
    else if (isUserRefreshed && loggedInStatus && MXMSsoLogin) {
        retUrl = proxyToken ? redirectUrl + '/sso/' + proxyToken : redirectUrl + '/sso'
    }
    return retUrl
}
/**
 * Method to set user session variables
 */
const __setSession = async (cb: any) => {
    const user = _kc.idTokenParsed;
    setUserInfo(user?.given_name);
    setKey("isUserDetailsAvailable", 'FALSE');
    
    let gtmData = {
        event: "getCustomerInfo",
        "userAuthName": user?.given_name,
        "userId": user?.email,
        "userName": user?.given_name
    }

    pushGTM(gtmData)
    setUserLoggedIn();
    setKeys(user)
    return cb(true);
}

function setKeys(user: any){
    setKey("email", user?.email);
    setKey("firstName", user?.given_name);
    setKey("lastName", user?.family_name);
    if (_kc.tokenParsed?.MXM_USERID) {
        setLocalStorage('mxmUser',true)
    }
    setLocalStorage("referrer", document?.referrer);
    //phone number from keycloak including country code
    const kMob = user.mobileNumber

    kMob  && setKey("phone", formatPhone(kMob.slice(-10)));
    // setKey("countryCode", "91");
    setKey("email", user.email);
    setKey("firstName", user.given_name);
}

const AuthService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    getName,
    getInfo
};

export default AuthService;