import { useState } from 'react';
import React from 'react';
import { Grid } from '@mui/material';
import { Drawer } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close-icon.svg';
import { useMediaQuery } from 'react-responsive';

export type FxRightDrawerContentType<ReturnType> = React.ComponentType<{onClose: (event: React.KeyboardEvent | React.MouseEvent, payload?: ReturnType) => void, onCloseVisibilityChange?:(e:boolean) => void}>;

export type FxRightDrawerViewProps<ReturnType> = {
    Content?: FxRightDrawerContentType<ReturnType>;
    open: boolean;
    onClose: (event: React.KeyboardEvent | React.MouseEvent, payload?: ReturnType) => void;
}

export const FxRightDrawerView: React.FC<FxRightDrawerViewProps<any>> = ({open, onClose, Content}) => {

    const [hasCloseIcon, setHasCloseIcon] = useState(true);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

    if (!Content) {
        return null;
    }

    const handleCloseClick = (event:any) => {
        onClose(event);
    }

    return (
            <Drawer
                PaperProps={{
                    sx: {
                        width: isSmallScreen ? "100%" : "82.5%",
                        borderTopLeftRadius:'0.8rem',
                        borderBottomLeftRadius:'0.8rem',
                    },
                }}
                anchor={'right'}
                open={open}
                onClose={onClose}
            >
                <Grid container xs={12} className={'fx-right-drawer'}>
                    { hasCloseIcon && <CloseIcon className={'fx-right-drawer-close'} onClick={handleCloseClick}/> }
                    <Content onClose={onClose} onCloseVisibilityChange={setHasCloseIcon}/>
                </Grid>
            </Drawer>
    );
}
